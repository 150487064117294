const cz = {
  error: {
    generic: 'Něco se pokazilo...',
    unauthorized: 'Přihlášení se nezdařilo',
    validation: {
      document_file_size: 'Velikost souboru nesmí překročit 10 MB',
      document_file_type: 'Jsou podporována formáty JPG, PNG, a PDF',
      empty: 'Údaj je povinný',
      empty_consent:
        'Je nám líto, ale bez odsouhlasení výše uvedeného textu nelze provést žádost o půjčku',
      less_than_one: 'Číslo musí být větší než 1',
      not_a_number: 'Lze zadat pouze číslice',
      not_safe_text: 'Smí obsahovat pouze písmena a mezeru',
      too_short: 'Musí obsahovat nejméně 2 znaky',
      minLength: 'Musí obsahovat nejméně {length} znaky',
      requires_number: 'Musí obsahovat nejméně 1 číslici',
      restrict_diacritics_chars: 'Písmená s diakritikou nejsou povolené',
      passwords_not_equal: 'Hesla se neshodují',
      incorrect_password: 'Neplatné heslo',
      new_password_not_new: 'Nové heslo se musí lišit od stávajícího hesla',
      has_uppercase: 'Musí obsahovat nejméně 1 velké písmeno',
      has_lowercase: 'Musí obsahovat nejméně 1 malé písmeno',
      not_safe_name: 'Smí obsahovat pouze písmena a mezeru',
      not_phone_number: 'Telefonní číslo musí být ve formátu +420777123456',
      not_email: 'Zadaná emailová adresa není platná',
      personal_id_invalid: 'Prosím, zadejte své rodné číslo s lomítkem',
      personal_id_empty: 'Rodné číslo je vyžadováno',
      personal_id_doesnt_match_pattern: 'Formát rodného čísla neodpovídá vzoru',
      personal_id_wrong_length:
        'Rodné číslo musí mít délku 9 nebo 10 číslic včetně lomítka',
      personal_id_wrong_checksum: 'Kontrolní součet rodného čísla je neplatný',
      personal_id_month_invalid:
        'Zadané rodné číslo obsahuje neplatný měsíc (>12)',
      personal_id_date_invalid: 'Zadané rodné číslo obsahuje neplatné datum',
      bank_account: 'Číslo bankovního účtu není platné',
      id_card_invalid: 'Číslo občanského průkazu smí obsahovat pouze číslice',
      postal_code_invalid:
        'Poštovní směrovací číslo musí být ve formátu 150 00',
      house_number_invalid: 'Smí obsahovat pouze písmena a mezeru',
      weak_password:
        'Heslo je příliš krátké. Heslo musí mít délku nejméně 7 znaků a musí obsahovat minimálně 1 číslici',
      invalid_image:
        'Fotografii nelze použít. Prosíme nahrajte znovu, nebo zvolte jiný způsob nahrání',
      already_exists_in_db:
        'Pro zadané údaje už existuje zákaznický účet. Prosím, přihlaste se <a href="/login">ZDE</a> nebo nás kontaktujte na bezplatné zákaznické lince <span class=bold>800 399 399</span>',
      incorrect_code: 'Ověřovací kód je neplatný',
      invalid_phone_number: 'Telefonní číslo je neplatné',
      invalid_credentials: 'Neplatný email nebo heslo',
      too_young: 'Žádat o půjčku je možné od 18 let',
      too_old:
        'Věkový limit překročen. Maximální možný věk pro podání žádosti je 78 let.',
      wrong_digital_code: 'Zadejte prosím platný ověřovací kód',
      '{javax.validation.constraints.Pattern.message}': 'Nesprávný formát',
      wrong_postal_code_format:
        'Poštovní směrovací číslo musí být ve formátu 150 00',
      invalid_format: 'Nesprávný formát',
      out_of_range: 'Nevešli jste se do limitu',
      household_members_maxamount: 'Maximální počet členů domácnosti je 20.',
      household_employedmembers_toobig:
        'Číslo musí být menší nebo rovno počtu členů domácnosti',
    },
    bank_id: {
      general: 'Něco se pokazilo...',
    },
  },

  common: {
    continue: 'Pokračovat',
    confirm: 'Potvrdit',
    print: 'Stáhnout',
    back: 'Zpět na předchozí stránku',
    currency: 'Kč',
    skip: 'Zaslat později',
    loan_overview: 'Přehled půjčky',
    loan: 'Půjčka',
    term: 'Délka splatnosti',
    days: 'dnů',
    interest: 'Úrok',
    apr: 'Roční úroková sazba',
    logout: 'Odhlásit',
    footer_text:
      'Zaplo Finance s.r.o., 5.května 1746/22, 140 00 Praha 4 je vedeno u Městského soudu v Praze pod spisovou značkou C 205150.',
    edit: 'Upravit',
    retry: 'Zkusit znovu',
    show_more: 'Zobrazit více',
    show_less: 'Zobrazit méně',
  },

  progress: {
    intro: 'Žádost o půjčku',
    personalData: 'Osobní údaje',
    verification: 'Ověření',
    summary: 'Shrnutí půjčky',
  },

  not_found: {
    html_title: 'Omlouváme se, požadovaná stránka neexistuje - Zaplo.cz',
    title: 'Omlouváme se, požadovaná stránka neexistuje',
    description:
      'Je možné, že byla odstraněna, přejmenována nebo není dočasně dostupná.',
    button: 'Začít novou registraci',
    link: 'Zpět na hlavní stránku',
  },

  login: {
    html_title: 'Přihlášení do zákaznického účtu - Zaplo.cz',
    title: 'Přihlášení do zákaznického účtu',
    email: 'Email',
    password: 'Heslo',
    submit: 'Přihlásit se',
    forgot_password: 'Nepamatujete si heslo?',
  },

  intro: {
    html_title: 'Žádost o půjčku - Zaplo.cz',
    title: 'Žádost o půjčku',
    customer: 'Jste již zákazníkem? Jděte do svého zákaznického účtu',
    login: 'přihlášení zde',
    info: 'Vše, co potřebujete k žádosti',
    id: 'Občanský průkaz',
    mobile: 'Mobilní telefon',
    email: 'Email',
    info_optional: 'Pro rychlejší vyhodnocení žádosti',
    bank: 'Přístup do internetového bankovnictví',
    more_information: 'Více informací',
    internet_access: 'Přístup do internetového bankovnictví',
    how_it_works: 'Jak to funguje',
    bank_step_1: 'Požádáme Vás o souhlas k propojení s Vaším bankovním účtem.',
    bank_step_2:
      'Abychom mohli žádost zpracovat co nejrychleji, připravíme stažení výpisů z ůčtu a ověřovací platbu za Vás.',
    bank_step_3: 'Údaje pouze zkontrolujete a odsouhlasíte.',
    bank_step_4:
      'Po dokončení žádosti Vás z internetového bankovnictví bezpečně odhlásíme.',
  },

  personal_details: {
    html_title: 'Vyplňte, prosím, své osobní údaje - Zaplo.cz',
    title: 'Vyplňte, prosím, své osobní údaje',
    info: 'Vyplňte všechna pole ve formuláři. Při vyplňování používejte diakritiku (háčky a čárky), aby při ověřování Vašich údajů nedocházelo k chybám.',
    first_name: 'Jméno',
    last_name: 'Příjmení',
    mobile_phone: 'Telefonní číslo',
    personal_id: 'Rodné číslo',
    card_id: 'Číslo občanského průkazu',
    finance_employment_title: 'Zaměstnání a finance',
    employment_type: 'Zdroj příjmů',
    employment_type_hint:
      'Zvolte zdroj Vašich příjmů výběrem jedné z možností.',
    income_source: 'Zdroj příjmů',
    income_source_hint: 'Zvolte zdroj Vašich příjmů výběrem jedné z možností.',
    employee: 'Zaměstnanec',
    entrepreneur: 'OSVČ nebo podnikatel',
    student: 'Student',
    maternity_leave: 'Mateřská dovolená',
    in_household: 'V domácnosti',
    unemployed: 'Nezaměstnaný',
    retiree: 'Důchodce',
    other: 'Ostatní',
    monthly_income: 'Čistý měsíční příjem',
    monthly_income_hint:
      'Uveďte výši Vašeho čistého měsíčního příjmu. Čistý měšíční příjem (po zdanění)',
    monthly_expenses: 'Měsíční výdaje',
    monthly_expenses_hint: 'Uveďte Vaše čisté měsíční výdaje',
    number_of_household_members: 'Celkový počet členů Vaší domácnosti',
    number_of_employed_household_members:
      'Počet členů domácnosti majících příjem',
    permanent_address_title: 'Adresa trvalého bydliště',
    city: 'Město / obec',
    street: 'Ulice',
    house_flat_number: 'Číslo popisné',
    postal_code: 'PSČ',
    ownership: 'Druh bydlení',
    rent: 'Podnájem / nájem',
    mortgage: 'Vlastní dům / byt s hypotékou',
    owned: 'Vlastní dům / byt bez hypotéky',
    with_relatives: 'U rodičů / u příbuzných',
    collective: 'Družstevní byt',
    employer: 'Firemní byt',
    dormitory_or_hotel: 'Studentské koleje / hotel',
    account_setup_title: 'Zákaznický účet',
    email: 'Emailová adresa',
    email_title: 'Údaje k přihlášení do Zaplo účtu',
    email_hint:
      'Údaje slouží k přihlášení a spravování Vašeho zákaznického učtu online.',
    password: 'Heslo',
    password_hint:
      'Heslo musí mít délku nejméně 7 znaků a musí obsahovat minimálně 1 číslici. Pozor, heslo rozlišuje velká a malá písmena.',
    terms_and_conditions_title: 'Smluvní podmínky',
    terms_and_conditions_info_1:
      'Vaše osobní údaje zpracováváme, protože je to nezbytné pro splnění smlouvy, jejíž smluvní stranou jste nebo pro provedení opatření přijatých před uzavřením smlouvy na Vaši žádost, případně je to nezbytné pro splnění právní povinnosti, která se na nás vztahuje (například povinnost identifikace pro účely boje proti legalizaci výnosů z trestné činnosti, povinnost archivace apod.).',
    terms_and_conditions_info_2:
      'Podrobnosti, k tomu jak zpracováváme Vaše osobní údaje a jaká v této souvislosti máte práva, najdete na našich stánkách o ochraně osobních údajů.',
    consents_title:
      'Pro možnost čerpání našich služeb v plném rozsahu Vás žádáme i o udělení těchto souhlasů:',
  },

  offline_psd: {
    result: {
      DONE: {
        title: 'Děkujeme, Vaše žádost se zpracovává',
        subtitle: 'O jejím výsledku Vás budeme neprodleně informovat.',
      },
      ERROR: {
        title: 'Omlouváme se, něco se pokazilo',
        subtitle:
          'Pro dokončení žádosti prosím kontaktujte Zákaznickou linku 800 399 399.',
      },
      PAYMENT_FAILED: {
        title: 'Omlouváme se, provedení ověřovací platby se nezdařilo',
        subtitle:
          'Pro dokončení žádosti prosím kontaktujte Zákaznickou linku 800 399 399.',
      },
    },
  },

  phone_verification: {
    html_title: 'Ověření telefonního čísla - Zaplo.cz',
    verification: 'Ověření telefonního čísla',
    code_sent:
      'Pro ověření telefonního čísla zadejte kód, který Vám byl zaslán na Vámi poskytnuté telefonní číslo {phoneNumber}',
    wrong_number: '(špatné číslo?)',
    enter_code: 'Zadejte Váš ověřovací kód',
    get_new_code: 'Znovu zaslat kód',
    contact_us:
      'Může chvíli trvat, než Vám SMS dorazí. Pokud jste SMS neobdrželi, tak pro dokončení žádosti kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
    change_number: 'Změna telefonního čísla',
    insert_number:
      'Zadáte své telefonní číslo a my vám na něj pošleme SMS s jednorázovým heslem',
    mobile: 'Mobilní telefon',
  },

  upsale: {
    html_title: 'Máme pro Vás dobrou zprávu - Zaplo.cz',
    button: 'Pokračovat s vyšší částkou',
    button_change_amount: 'Požádat o půjčku',
    button_link: 'Pokračovat s původní částkou',
    card_title: 'Můžete si půjčit až',
    title: 'Máme pro Vás dobrou zprávu',
  },

  downsale: {
    html_title: 'Naše nabídka - Zaplo.cz',
    button: 'Pokračovat s nižší částkou',
    card_title: 'Přehled půjčky',
    info_text:
      'Na základě Vaší úvěruschopnosti Vám nemůžeme poskytnout půjčku v požadované výši, ale můžeme Vám nabídnout sníženou částku. <br><br>Pokud Vám bude nová nabídka vyhovovat, potvrďte ji a poté pokračujte na pročtení a souhlas s předsmluvními informacemi a smlouvě o úvěru.',
    info_title: 'Proč byla částka snížena?',
    prev_amount: 'Žádali jste o',
    sub_title:
      'Omlouváme se, ale v tuto chvíli Vám nemůžeme poskytnout půjčku ve Vámi požadované výši. Máme pro Vás ale novou nabídku.',
    title: 'Naše nabídka',
    proposal_amount: 'Můžeme Vám nabídnout',
  },

  consent: {
    html_title: 'Smluvní dokumentace - Zaplo.cz',
    contract_documentation: 'Smluvní dokumentace',
    read: 'Četl(a) jsem tyto <span>předsmluvní informace</span>, byly mi vysvětleny a rozumím jim.',
    comfirm:
      'Potvrzuji tímto, že jsem přečetl(a) a jsem srozuměn(a) s obsahem <span>smlouvy o úvěru</span> a souvisejícími obchodními podmínkami a chci ji uzavřít.',
    modal_agreement:
      'Formulář pro standardní informace o spotřebitelském úvěru',
    modal_legal: 'Smlouva o úvěru',
    banner:
      'Posunutím stránky dolů, si dokument přečtěte a pokračujte kliknutím na tlačítko potvrdit.',
    validation_scroll: 'Pro potvrzení dokumentu, posuňte stránku dolů.',
  },

  digital_signature: {
    modal_digital_signature: 'Podepsání smlouvy',
    digital_info_top: {
      part_1:
        'Pro podepsání této smlouvy o úvěru zadejte kód, který Vám byl zaslán na Vaši emailovou adresu ',
      part_2: ' a telefonní číslo ',
    },
    title: 'Podepsání smlouvy',
    enter_code: 'Zadejte Váš ověřovací kód',
    get_new_code: 'Znovu zaslat kód',
    contact_us:
      'Může chvíli trvat, než Vám SMS dorazí. Pokud jste SMS neobdrželi, tak pro dokončení žádosti kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
    btn: 'Podepsat a potvrdit',
  },

  identification: {
    html_title: 'Ověření totožnosti - Zaplo.cz',
    title: 'Ověření totožnosti',
    info: 'Připravte si, prosím, Váš občanský průkaz. Fotografii nahrajte prostřednictvím Vašeho mobilního zařízení.',
    phone_id_title:
      'Pořidte fotografii Vašeho občanského průkazu pomocí mobilního telefonu',
    phone_id_info:
      'Po otevření odkazu, který Vám zašleme SMS zprávou, vyfoťte pomocí mobilního telefonu obě dvě strany Vašeho občanského průkazu.',
    sms_button: 'Odeslat SMS',
    phone_id_footer:
      'Může chvíli trvat, než Vám SMS dorazí. Pokud jste SMS neobdrželi, tak pro dokončení žádosti kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
    video_banner_title: 'Chcete se podívat, jak to funguje?',
    video_banner_description:
      'Podívejte se na toto krátké video a prohlédněte si všechny kroky tohoto procesu.',
    video_banner_link: 'Podívejte se na video',
  },

  identification_conecting: {
    title: 'Pokračujte na mobilním telefonu',
    hint: '(Nezavírejte tuto stránku)',
    time: 'Platnost SMS zprávy vyprší za',
  },

  identification_expired: {
    title: 'Potřebujete více času?',
    confirm: 'Ano',
    close: 'Ne, děkuji. Zkusím to jinak nebo později.',
  },

  identification_processing: {
    title: 'Kontrola Vašich údajů',
    hint: 'Toto okno nezavírejte, nahrání může trvat několik minut.',
  },

  identification_failed: {
    title: 'Nahrávání dokumentu nebylo úspěšné',
    retry: 'Začít znovu',
  },

  identification_manual: {
    html_title: 'Ověření bankovního účtu - Zaplo.cz',
    title: 'Ověření bankovního účtu',
    message:
      'Pro dokončení žádost je nutné provést za účelem identifikace ověřovací platbu ve výši 0,01 Kč (tj. jeden haléř). Platbu proveďte pod variabilním symbolem na níže uvedený účet.',
    choose_bank: 'Účet pro zaslání platby:',
    important:
      'Důležité! Při převodu 0,01 Kč uveďte {identificationNumber} jako variabilní symbol.',
    info_message: 'Smlouva o úvěru a Obchodní podmínky',
    terms_and_conditions: 'Obchodní podmínky',
    loan_agreement: 'Smlouva o úvěru',
    confirm: 'Dokončit žádost',
    payee: 'Příjemce platby',
    bank_name: 'Název banky',
    account_number: 'Číslo účtu',
    variable_symbol: 'Variabilní symbol',
    copy: 'Kopírovat',
    copied: 'Zkopírováno',
    another_bank: 'Jiná banka',
    download: 'Stáhnout',
    qrcode_notice:
      'Naskenujte QR kód pomocí mobilního telefonu a proveďte bankovní převod',
    qrcode_tooltip:
      'Naskenováním QR kódu nemusíte ručně kopírovat všechny údaje.',
    bank_separator: 'NEBO',
  },

  ocr_results: {
    html_title: 'Potvrďte své osobní údaje - Zaplo.cz',
    title: 'Potvrďte své osobní údaje',
    info: 'Pokud zobrazené údaje nesouhlasí s Vaším dokladem, kontaktujte nás, prosím, na telefonním čísle <b>800 399 399</b>.',
    fullName: 'Jméno a příjmení',
    personal_id: 'Rodné číslo',
    dob: 'Datum narození',
    id_card: 'Číslo občanského průkazu',
    address: 'Trvalé bydliště',
  },

  calculator: {
    mobile_amount_swiper_title: 'Výše půjčky (Kč)',
    mobile_term_swiper_title: 'Délka splatnosti (dnů)',
    amount_slider_title: 'Výše půjčky',
    term_slider_title: 'Délka splatnosti',
    currency_amount: '{amount} Kč',
    amount_units: 'Kč',
    term_units: 'dnů',
    offer: {
      loan: 'Půjčka',
      due_date: 'Den splatnosti půjčky',
      interest: 'Úrok',
      interestRate: 'Zápůjční úroková sazba',
      totalRepayableAmount: 'Celkem splatíte',
      rpsn: 'RPSN',
    },
    representative_example:
      '{totalPrincipal} na {term} dnů, pevná roční úroková sazba {interestRate}, RPSN {annualPercentageRate}. Celková částka splatná spotřebitelem je {totalRepayableAmount}. Celkové náklady spotřebitelského úvěru jsou {newInterest}. Toto je pouze reprezentativní příklad půjčky, nikoliv návrh na uzavření smlouvy. Zaplo Finance s.r.o. si vyhrazuje právo na posouzení úvěrové žádosti.',
    btn: 'Potvrdit',
  },

  identification_upload: {
    html_title: 'Ověření totožnosti - Zaplo.cz',
    title: 'Ověření totožnosti',
    info: 'Připravte si, prosím Váš občanský průkaz. Fotografie dokladu pořídíte kliknutím na box občanský průkaz',
    additional_document_info:
      'Jako druhý doklad můžete použít řidičský průkaz, pas, nebo zbrojní průkaz',
    modal_info:
      'Před pořízením fotografie se ujistěte, že doklad je na neutrálně barevném pozadí a údaje jsou čitelné.',
  },

  proof_income: {
    html_title: 'Potvrzení o příjmu - Zaplo.cz',
    title: 'Potvrzení o příjmu',
    information:
      'Nahrajte nám doklad, potvrzující Váš příjem. Akceptujeme potvrzení o příjmu, vypis z bankovního účtu nebo tři vyplatní pásky.',
    card_title: 'Nahrát dokument z Vašeho zařízení',
    link_name: 'Zaslat později emailem',
  },

  rejected_offer: {
    html_title:
      'Je nám velmi líto. Ale Vaše žádost o půjčku byla zamítnuta na základě interního vyhodnocení - Zaplo.cz',
    title: 'Je nám velmi líto',
    sub_title:
      'Ale Vaše žádost o půjčku byla zamítnuta na základě interního vyhodnocení.',
    button: 'Úvodní stránka',
    info_text:
      'Až se Vaše situace změní, budeme velmi rádi, pokud se na nás znovu obrátíte a budeme Vám moci vyhovět a půjčku poskytnout.',
  },

  help_info: {
    title: 'Nějaké otázky?',
    contact_details_holiday:
      'Kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>. <div>Po — Pá / 8:00 — 18:00</div>',
    contact_details:
      'Kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>. <div>Po — Pá / 8:00 — 19:00</div>',
    text: 'Kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
  },

  expired: {
    html_title: 'Platnost Vaší žádosti již vypršela - Zaplo.cz',
    title: 'Platnost Vaší žádosti již vypršela',
    info: 'Obnovíte ji velmi jednoduše – vyberte si parametry půjčky a klikněte na tlačítko Požádat o půjčku.',
    button: 'Požádat o půjčku',
  },

  document_upload: {
    id_front_title: 'Občanský průkaz',
    id_front_subtitle: '(přední strana)',
    id_back_title: 'Občanský průkaz',
    id_back_subtitle: '(zadní strana)',
    selfie_title: 'Selfie',
    selfie_subtitle: '(autoportrét)',
    income_proof_title: 'Potvrzení o příjmu',
    other_document_title: 'Další dokument',
    other_document_subtitle: 'Volitelný dokument',
  },

  identification_upload_desktop: {
    html_title: 'Ověření totožnosti - Zaplo.cz',
    title: 'Ověření totožnosti',
    info: 'Připravte si, prosím, Váš občanský průkaz. Fotografii nahrajte prostřednictvím Vašeho mobilního zařízení.',
    magic_link_title: 'Pořiďte fotografii pomocí mobilního telefonu',
    magic_link_info:
      'Po otevření odkazu, který Vám zašleme SMS zprávou, vyfoťte pomocí mobilního telefonu obě dvě strany Vašeho občanského průkazu.',
    send_sms: 'Odeslat SMS',
    magic_link_hint:
      'Může chvíli trvat, než Vám SMS dorazí. Pokud jste SMS neobdrželi, tak pro dokončení žádosti kontaktujte naši zákaznickou linku na <a href="tel:800399399">800 399 399</a> nebo emailem <a href="mailto:info@zaplo.cz">info@zaplo.cz</a>.',
    desktop_title: 'Nahrát dokument z Vašeho zařízení',
  },

  bank_id: {
    html_title: 'Ověřit se s Bankovní Identitou',
    title: 'Ověřit se s Bankovní Identitou',
    subtitle:
      'Šetřete čas a nechejte si pohodlně a bezpečně ověřit Vaší identitu pomocí Bank iD. ',
    box_title:
      'Chcete-li používat BankID, musíte splňovat následující požadavky:',
    box_row_1: 'Bankovní účet vedený na Vaše jméno',
    box_row_2: 'Mít Bank iD u jedné z podporovaných bank v ČR',
    box_row_3: 'Platné telefonní číslo a e-mailovou adresu.',
    missing_id: 'Nemám bankovní identitu',
    mismatch_title: 'Chyba v údajích',
    mismatch_description:
      'Náš systém zjistil nesrovnalost mezi údaji, které jste zadali při první registraci a údaji, které jsme obdrželi od BankID. Může to být způsobeno překlepem nebo chybou v poskytnutých informacích, nebo technickým problémem s BankID.',
    mismatch_description2:
      'Prosím, zkuste to později nebo pokračujte v běžné registraci.',
    continue_with_standard_registration: 'Pokračovat v běžné registraci',
    try_again: 'Zkusit znovu',
    error_title: 'Omlouváme se, něco se pokazilo',
    error_description:
      'Nejsme schopni zpracovat údaje poskytnuté Vaší bankou. Prosím, pokračujte v žádosti vyplněním formuláře.',
  },

  bank_id_confirm: {
    title: 'Potvrďte Vaše osobní údaje',
    info: 'Zkontrolujte si prosím své osobní údaje. Ujistěte se, že v nich nejsou žádné chyby.',
    link_text: 'Ne, informace nejsou správné, chtěl bych zadat data ručně.',
    form: {
      full_name: 'Jméno a příjmení',
      full_age: 'Věk a datum narození',
      personal_Id: 'Rodné číslo',
      card_number: 'Číslo OP',
      address: 'Adresa',
      bank_list:
        'Ve Vašem bankovnictví jsme našli více účtů. Zvolte jeden účet, který chcete použít pro vyplaceni peněz:',
    },
    btn: 'Potvrdit',
  },
  bank_id_account_selection: {
    bank_details: 'Bankovní údaje',
    info_text_single_account:
      'Tento účet bude použit pro vyplacení půjčky. Zkontrolujte si, že je číslo účtu správně.',
    info_text_multiaccount:
      'Vybraný účet bude použit pro vyplacení půjčky. Zkontrolujte si, že je číslo účtu správně.',
    single_account_button: 'Ano, účet chci použít',
    info_text_multiaccount_button: `Použít vybraný účet`,
    reject_text: 'Ne, chci použít jiný účet',
  },

  psd2_banks: {
    html_title: 'Ověření bankovního účtu - Zaplo.cz',
    title: 'Ověření bankovního účtu',
    subtitle:
      'Vybraný účet bude použit pro odeslání ověřovací platby a ověření příjmu.',
    bankid_subtitle: 'Vybraný účet bude použit pro ověření příjmů.',
    search: 'Zadejte jméno banky',
    card_title:
      'Vyberte účet, na který pošleme peníze v případě schválení půjčky',
    card_subtitle:
      'Budete přesměrování na přihlašovací stránku Vaší banky, kde se přihlásíte stejným způsobem jako do Vašeho internetového bankovnictví.',
    show_all: 'Ostatní banky',
    manual:
      'Pokud Vaše banka není na seznamu, zadejte platbu ručně přímo v internetovém bankovnictví.',
    continue: 'Pokračovat',
    skip: 'Přeskočit a zaslat platbu později',
    card_btn: 'Moje banka není na seznamu',
  },

  psd2_guide: {
    html_title: 'Ověření bankovního účtu – Zaplo.cz',
    title: 'Návod pro přihlášení do internetového bankovnictví',
    continue: 'Pokračovat',
    back: 'Zpět na výběr banky',
    default: {
      step_1: 'Přihlaste se do zvolené banky.',
      step_2: 'Po přihlášení připravíme stažení výpisů a ověřovací platbu.',
      step_3: 'Údaje jen potvrdíte a z účtu Vás následně bezpečně odhlásíme.',
      additional_1:
        'Nezapomeňte, že pro úspěšné provedení ověřovací platby musí být na účtu alespoň minimální zůstatek 0,01 Kč.',
    },
  },

  psd2_consents: {
    skip: 'Přeskočit a zaslat platbu později',
    page_title: 'Udělení souhlasu',
    html_title: 'Udělení souhlasu – Zaplo.cz',
    description:
      'V následujícím kroku budete přesměrováni na přihlašovací stránku do internetového bankovnictví banky',
  },

  psd2_bank_account_select: {
    html_title: 'Ověření bankovního účtu',
    page_title: 'Ověření bankovního účtu',
    description:
      'Zvolte účet, který chcete použít pro odeslání ověřovací platby ve výši 0,01 Kč.',
    account_label: 'Osobní účet CZK',
  },

  psd2_receiving: {
    html_title: 'Ověření bankovního účtu',
    page_title: 'Ověření bankovního účtu',
    description:
      'Probíhá zpracování Vaší transakční historie. Prosíme o strpení, může to trvat několik minut ',
  },

  psd2_verified: {
    html_title: 'Ověření bankovního účtu proběhlo v pořádku.',
    page_title: 'Ověření bankovního účtu proběhlo v pořádku.',
    item_1: 'Zpracování transakční historie proběhlo v pořádku.',
    item_2: 'Z účtu jsme Vás bezpečně odhlásili.',
    complete: 'Dokončit žádost',
  },

  psd2_error: {
    html_title: 'Omlouváme se, něco se pokazilo.',
    page_title: 'Omlouváme se, něco se pokazilo.',
    info_text:
      'Omlouváme se, něco se pokazilo. Nejsme schopnit zpracovat údaje poskytnuté Vaší bankou. Prosím, pokračujte dále v žádosti.',
    continue_button: 'Pokračovat',
  },

  psd2_result: {
    no_error: {
      title: 'Pokračujte v žádosti o půjčku',
      button: 'Pokračovat',
    },
    any_error: {
      title: 'Omlouváme se, něco se pokazilo',
      text: 'Pokračujte k ověření účtu.',
      button: 'Pokračovat',
    },
    special_error: {
      title: 'Omlouváme se, něco se pokazilo',
      text: 'Zkuste to prosím znovu za chvíli.',
      button: 'Zkusit znovu',
    },
    offline_any: {
      title: 'Omlouváme se, něco se pokazilo',
      text: 'Pro dokončení žádosti prosím kontaktujte Zákaznickou linku 800 399 399.',
    },
    offline_case_finished: {
      title: 'Děkujeme, Vaše žádost se zpracovává',
      text: 'o jejím výsledku Vás budeme neprodleně informovat',
    },
    offline_finished_without_payment: {
      title: 'Omlouváme se, provedení ověřovací platby se nezdařilo',
      text: 'Pro dokončení žádosti prosím kontaktujte Zákaznickou linku 800 399 399.',
    },
    offline_finished_no_transaction_history: {
      title: 'Omlouváme se, ověření Vaší úvěruschopnosti se nezdařilo',
      text: 'Pro dokončení žádosti prosím kontaktujte Zákaznickou linku 800 399 399.',
    },
    offline_finished_without_payment_transaction_history: {
      title:
        'Omlouváme se, provedení ověřovací platby a ověření Vaší úvěryschopnosti se nezdařilo',
      text: 'Pro dokončení žádosti prosím kontaktujte Zákaznickou linku 800 399 399.',
    },
    html_title: 'Výsledek identifikace offline  - Zaplo.cz',
  },

  approved: {
    html_title: 'Gratulujeme! Vaše žádost byla dokončena - Zaplo.cz',
    title: 'Gratulujeme! Vaše žádost byla dokončena',
    description:
      'Vyhodnocení Vaší žádosti proběhne po obdržení kopie Vašeho občanského průkazu a po zaslání ověřovací platby ve výši 0,01 Kč. Pokud jste během žádosti o půjčku využili služeb Bankovní identity  spolu s ověřením bankovního účtu online, tak již není potřeba zasílat kopii Občanského průkazu a ověřovací platbu ve výši 0,01 Kč. O výsledku Vaší žádosti Vás budeme informovat prostřednictvím emailu a SMS.',
    in_case: 'V případě, že:',
    accordion: {
      title_1: 'Jste v žádosti nedodal/a kopii Vaše občanského průkazu',
      content_1:
        'Zašlete nám ofocenou kopii Vašeho občanského průkazu z obou stran na náš email: info@zaplo.cz',
      title_2: 'Jste neprovedl/a ověřovací platbu během vyplňování žádosti',
      content_2:
        'Zašlete platbu z Vašeho bankovního účtu, který je veden na Vaše jméno. Informace, na jaký bankovní účet a s jakým variabilním symbolem platbu zaslat, Vám byla zaslána emailem.',
    },
    go_to_account: 'PŘEJÍT NA ZÁKAZNICKÝ ÚČET',
  },

  not_finished_application: {
    page_title: 'Dokončení žádosti - Zaplo.cz',
    title: 'Dokončení žádosti o půjčku',
    info_title:
      'Pro dokončení žádosti o půjčku od Vás ještě budeme potřebovat provést několik kroků:',
    step_1: 'Nahrát fotografii občanského průkazu.',
    step_2: 'Zaslat identifikační platbu.',
    step_3:
      'V případě potřeby si od Vás vyžádáme ještě potvrzení o příjmu nebo výpis z bankovního účtu.',
  },
};

export default cz;
