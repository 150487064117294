import { fetchData } from '../../../utils';
import { useMutation } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useFullRiskEvaluationMutation() {
  const { showError } = useError();
  const { error } = useSmartlook();

  return useMutation({
    mutationFn: async () => {
      try {
        return await fetchData('/client/application/full-risk-evaluation', {
          method: 'post',
        });
      } catch (e) {
        error(
          '[useFullRiskEvaluationMutation][POST /client/application/full-risk-evaluation]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

// unfortunately BE documentation provides this behemoth. Apparently it can contain (or not) all those fields
export type FullRiskEvaluation = {
  applicationId?: number;
  clientId?: number;
  clientNumber?: string;
  webCode?: string;
  resolutionText?: string;
  rejected?: boolean;
  rejectedByBlacklist?: boolean;
  technicalError?: boolean;
  manual?: boolean;
  pendingProcessing?: boolean;
  status?: string;
  type?: string;
  typeDetail?: string;
  amount?: number;
  term?: number;
  closeDate?: string;
  createDate?: string;
  webCodeCreatedDate?: string;
  riskAssessed?: boolean;
  termBean?: {
    value?: number;
    unit?: ['DAYS', 'MONTHS'];
  };
  partnerRepresentative?: {
    id?: number;
    representativeId?: string;
  };
  applicationCategory?: string;
  scorecardResolution?: string;
  additionalDetails?: string;
  preferredAgreementType?: [
    'MAIN',
    'EXTENSION',
    'NEW_AMOUNT',
    'DEBT_COLLECTION',
    'DEBT_RESTRUCTURING',
    'PRINCIPAL_COLLECTION',
    'RESCHEDULED',
    'RENOUNCEMENT',
    'INTEREST_RATE_CHANGE',
    'MAIN_RESCH',
    'EXTENSION_RESCH',
    'NEW_AMOUNT_RESCH',
    'CANCELLED_DISCOUNTS',
    'RECALCULATED',
    'RECALCULATED_DELAYED',
    'CONTRACTUAL_RESCH',
    'TOPUP_AMOUNT',
    'MTP_OVERDUE_RESCH',
    'MTP_OVERDUE',
  ];
  confirmed?: boolean;
};
