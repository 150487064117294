import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useBanksQuery(bankBicBlacklist?: string[]) {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useQuery<Bank[]>({
    queryKey: ['banks', bankBicBlacklist],
    queryFn: async () => {
      try {
        const res = await fetchData('/client/banks');

        return res.templates.filter(
          (bank: Bank) => !bankBicBlacklist?.includes(bank.bic),
        );
      } catch (e) {
        error('[useBanksQuery][GET /client/banks]', e);
        showError(e);
      }
    },
    staleTime: Infinity,
  });
}

export type Bank = {
  receiver: string;
  bankName: string;
  accountNumber: string;
  clientIdentificationNumber: string;
  masterAccount: boolean;
  bic: string;
  branchPageUrl: string;
  clientPersonalId: string;
  homePageUrl: string;
  registrationNumber: string;
  shortName: string;
};
