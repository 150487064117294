import { fetchData } from '../../../utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useUpsaleDisplayQuery() {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useQuery<ApplicationUpsale>({
    queryKey: ['upsale', 'display'],
    queryFn: async () => {
      try {
        const res = await fetchData('/client/application/upsale/display');

        return res;
      } catch (e) {
        error(
          '[useUpsaleDisplayQuery][GET /client/application/upsale/display]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

export function useUpsaleRejectMutation() {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useMutation({
    mutationFn: async () => {
      try {
        const data = await fetchData('/client/application/upsale/reject', {
          method: 'post',
        });

        return data;
      } catch (e) {
        error(
          '[useUpsaleRejectMutation][POST /client/application/upsale/reject]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

export function useUpsaleConstraintsQuery() {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useQuery<UpsaleConstraints>({
    queryKey: ['upsale', 'constraints'],
    queryFn: async () => {
      try {
        const res = await fetchData('/client/application/upsale/constraints');

        return res;
      } catch (e) {
        error(
          '[useUpsaleConstraintsQuery][GET /client/application/upsale/constraints]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

export type ApplicationUpsale = {
  amount: number;
};

export type UpsaleConstraints = {
  amountFrom: number;
  amountTo: number;
  step: number;
  term: {
    value: number;
  };
};
