import { fetchData } from '../../../utils';
import { useMutation } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useSecciAcceptanceMutation() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useMutation({
    mutationFn: async () => {
      try {
        return await fetchData('/client/events/secci-acceptance', {
          method: 'post',
          body: '{}',
        });
      } catch (e) {
        error(
          '[useSecciAcceptanceMutation][POST /client/events/secci-acceptance]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

export function useAgreementAcceptanceMutation() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useMutation({
    mutationFn: async () => {
      try {
        return await fetchData('/client/events/agreement-acceptance', {
          method: 'post',
          body: '{}',
        });
      } catch (e) {
        error(
          '[useAgreementAcceptanceMutation][POST /client/events/agreement-acceptance]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}
