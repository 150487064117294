import { fetchData } from '../../utils';
import { useCallback, useMemo } from 'react';
import useError from '../useError';
import useSmartlook from '../useSmartlook';

// TODO: Refactor to react query
const useDigitalSignature = () => {
  const { showError } = useError();
  const { error } = useSmartlook();

  const postVereficationCodeToClient = useCallback(async () => {
    try {
      const data = await fetchData(
        '/client/application/send-verification-code',
        {
          method: 'post',
        },
      );

      return data;
    } catch (e) {
      error('[useDigitalSignature][postVereficationCodeToClient]', e);
      showError();
      throw e;
    }
  }, [showError]);

  const postDigitalSignature = useCallback(
    async ({ body }) => {
      try {
        const data = await fetchData('/client/application/verify-code', {
          method: 'post',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        error('[useDigitalSignature][postDigitalSignature]', e);
        if (e.name !== 'ValidationError') {
          showError();
          throw e;
        }
        throw e;
      }
    },
    [showError],
  );

  return useMemo(() => {
    return {
      postVereficationCodeToClient,
      postDigitalSignature,
    };
  }, [postVereficationCodeToClient, postDigitalSignature]);
};

export default useDigitalSignature;
