import { ReactComponent as CloseIcon } from '../../assets/icons/close-3.svg';
import { ReactComponent as PlayVideoIcon } from '../../assets/icons/play-video.svg';
import { useState } from 'react';
import classes from 'classnames';
import Link from '../Link/Link';
import ReactDOM from 'react-dom';
import styles from './VideoBanner.module.scss';
import translate from '../../utils/translate';
import Typography from '../Typography/Typography';
import VideoBannerPlayer from './VideoBannerPlayer';

interface VideoBannerProps {
  classNameContainer?: string;
  onCloseCallback?: () => void;
  content: {
    title: string;
    description: string;
    link: string;
  };
}

const VideoBanner = ({
  onCloseCallback,
  classNameContainer,
  content,
}: VideoBannerProps) => {
  const [showVideoBanner, setShowVideoBanner] = useState(true);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const portalRoot = document.getElementById('portal-root');

  const handleCloseVideoBanner = () => {
    setShowVideoBanner((prevState) => !prevState);
    onCloseCallback?.();
  };

  const handleOpenPlayerModal = () => {
    setShowPlayerModal((prevState) => !prevState);
  };

  const handleClosePlayerModal = () => {
    setShowPlayerModal(false);
  };

  return (
    <>
      {showPlayerModal &&
        portalRoot &&
        ReactDOM.createPortal(
          <VideoBannerPlayer
            desktopVideo={'https://www.youtube.com/embed/ugNz3fFf3vM'}
            mobileVideo={'https://www.youtube.com/embed/ddhRqhp0rjc'}
            onClose={handleClosePlayerModal}
          />,
          portalRoot,
        )}

      {showVideoBanner && (
        <div className={classes(styles.container, classNameContainer)}>
          <div
            className={styles['cta-container']}
            onClick={handleOpenPlayerModal}
          >
            <PlayVideoIcon />
          </div>
          <div className={styles['content-wrapper']}>
            <div
              className={styles['close-icon']}
              onClick={handleCloseVideoBanner}
            >
              <CloseIcon />
            </div>
            <Typography
              size="m"
              className={classes(
                styles['content-text'],
                styles['content-title'],
              )}
            >
              {translate(content.title)}
            </Typography>
            <Typography
              size="xs"
              className={classes(
                styles['content-text'],
                styles['content-description'],
              )}
            >
              {translate(content.description)}
            </Typography>
            <Link
              className={classes(
                styles['content-text'],
                styles.link,
                styles['content-link'],
              )}
              onClick={handleOpenPlayerModal}
            >
              {translate(content.link)}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

const VideoBannerPortal = ({
  onCloseCallback,
  classNameContainer,
  content,
}: VideoBannerProps) => {
  const portalRoot = document.getElementById('portal-root');

  return (
    portalRoot &&
    ReactDOM.createPortal(
      <VideoBanner
        classNameContainer={classNameContainer}
        onCloseCallback={onCloseCallback}
        content={content}
      />,
      portalRoot,
    )
  );
};

export { VideoBanner, VideoBannerPortal };
