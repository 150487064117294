import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useAgreementQuery({
  amount,
  term,
  fileType,
}: {
  amount?: number;
  term?: number;
  fileType: 'PDF' | 'HTML';
}) {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useQuery<Blob | string | null>({
    queryKey: [
      'agreement',
      {
        amount,
        term,
        fileType,
      },
    ],
    queryFn: async () => {
      if (!amount || !term) {
        return null;
      }

      const strObj = {
        amount: `${amount}`,
        term: `${term}`,
        fileType,
      };

      try {
        const urlParams = new URLSearchParams(strObj).toString();
        const res = await fetchData(
          `/client/agreements/preliminary?${urlParams}`,
          {
            headers: {
              'Content-Type':
                fileType === 'PDF' ? 'application/pdf' : 'text/html',
            },
          },
        );

        return res;
      } catch (e) {
        error(
          '[useAgreementQuery][POST /client/agreements/preliminary?{urlParams}]',
          e,
        );
        showError();
        throw e;
      }
    },
    staleTime: Infinity,
  });
}
