const data = [
  'Adléta',
  'Adéla',
  'Adriana',
  'Agáta',
  'Agnes',
  'Albína',
  'Alena',
  'Alexandra',
  'Alice',
  'Alžběta',
  'Amálie',
  'Anastazia',
  'Anastázie',
  'Anděla',
  'Andělína',
  'Andrea',
  'Aneta',
  'Anetta',
  'Anežka',
  'Angelina',
  'Anna',
  'Antonie',
  'Antonina',
  'Apolena',
  'Bára',
  'Barbara',
  'Barbora',
  'Beata',
  'Beáta',
  'Běla',
  'Berta',
  'Blanka',
  'Blažej',
  'Blažena',
  'Bohdana',
  'Bohumila',
  'Bohuslava',
  'Božena',
  'Brigita',
  'Cecílie',
  'Clara',
  'Claudia',
  'Dagmar',
  'Dagmara',
  'Dana',
  'Daniela',
  'Darina',
  'Darja',
  'Dáša',
  'Denisa',
  'Diana',
  'Dita',
  'Ditta',
  'Dobromila',
  'Dorota',
  'Dorotea',
  'Doubravka',
  'Drahomíra',
  'Drahoslava',
  'Edita',
  'Elena',
  'Eleonora',
  'Elisabeth',
  'Eliška',
  'Elsa',
  'Ema',
  'Emilia',
  'Emílie',
  'Emma',
  'Erika',
  'Ester',
  'Eva',
  'Evelin',
  'Evelina',
  'Evelína',
  'Francesca',
  'Františka',
  'Gabriela',
  'Gisela',
  'Gizela',
  'Hana',
  'Hanka',
  'Hanuš',
  'Hedvika',
  'Helena',
  'Henrieta',
  'Hermína',
  'Ida',
  'Ilja',
  'Ilona',
  'Ines',
  'Ingrid',
  'Irena',
  'Irini',
  'Irma',
  'Iva',
  'Ivana',
  'Iveta',
  'Ivona',
  'Izabela',
  'Jana',
  'Jarmila',
  'Jaroslava',
  'Jindřiška',
  'Jiřina',
  'Jitka',
  'Johana',
  'Jolana',
  'Jorga',
  'Julia',
  'Juliana',
  'Julie',
  'Justina',
  'Justýna',
  'Kamila',
  'Karin',
  'Karla',
  'Karolína',
  'Karolina',
  'Katarína',
  'Kateřina',
  'Katka',
  'Klára',
  'Klaudia',
  'Klaudie',
  'Kristen',
  'Kristina',
  'Kristýna',
  'Kveta',
  'Květa',
  'Květoslava',
  'Lada',
  'Laura',
  'Lenka',
  'Leona',
  'Liběna',
  'Libuše',
  'Lilian',
  'Liliana',
  'Linda',
  'Ljuba',
  'Lucia',
  'Luciana',
  'Lucie',
  'Ludmila',
  'Lydia',
  'Mahulena',
  'Marcela',
  'Margareta',
  'Margit',
  'Mariana',
  'Marie',
  'Marika',
  'Markéta',
  'Marta',
  'Martha',
  'Martina',
  'Matylda',
  'Michaela',
  'Michala',
  'Milada',
  'Milena',
  'Miluše',
  'Miriam',
  'Mirka',
  'Miroslava',
  'Monika',
  'Naděžda',
  'Natálie',
  'Nataša',
  'Nela',
  'Nikola',
  'Nina',
  'Nora',
  'Oldřiška',
  'Olga',
  'Olivia',
  'Olívie',
  'Ondřejka',
  'Otilie',
  'Otýlie',
  'Patricie',
  'Paulína',
  'Pavla',
  'Pavlína',
  'Petra',
  'Petronila',
  'Radana',
  'Radka',
  'Radmila',
  'Regina',
  'Regína',
  'Renáta',
  'Renata',
  'René',
  'Renée',
  'Romana',
  'Rosita',
  'Rozálie',
  'Rut',
  'Rút',
  'Růžena',
  'Sabina',
  'Sandra',
  'Sára',
  'Saskie',
  'Silva',
  'Silvie',
  'Simeona',
  'Simona',
  'Slavěna',
  'Soňa',
  'Sonia',
  'Sonja',
  'Stanislava',
  'Stefanie',
  'Stela',
  'Stella',
  'Susana',
  'Svatava',
  'Světlana',
  'Sylva',
  'Šárka',
  'Šarlota',
  'Šimona',
  'Štepánka',
  'Tamara',
  'Taťána',
  'Tatiana',
  'Tereza',
  'Valentýna',
  'Valerie',
  'Valérie',
  'Vanda',
  'Vendula',
  'Vendulka',
  'Věra',
  'Veronika',
  'Viera',
  'Viktorie',
  'Vilma',
  'Viola',
  'Vladěna',
  'Vlasta',
  'Wanda',
  'Xenie',
  'Yveta',
  'Zdenka',
  'Zdeňka',
  'Zdislava',
  'Zina',
  'Zita',
  'Zlata',
  'Zoe',
  'Zora',
  'Zuzana',
  'Žaneta',
  'Žofie',
];

export default data;
