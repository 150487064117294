import { LOGIN } from '../Router/routes';
import ValidationError, { FieldError } from './ValidationError';

export const fetchRetry = require('fetch-retry')(fetch);
export const defaultOptions = {
  retryOn: [503, 500],
  retries: (window as any).Cypress ? 0 : 3,
  retryDelay: 1500,
};

async function fetchData(
  path: string,
  customOptions: RequestInit = {},
  params?: Record<string, string | number | boolean | undefined | null>,
) {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    ...(sessionStorage.getItem('token') && {
      Authorization: `Basic ${sessionStorage.getItem('token')}`,
    }),
  };

  const options = {
    ...defaultOptions,
    ...customOptions,
    headers: {
      ...defaultHeaders,
      ...customOptions.headers,
    },
  };

  /* This will parse the params object to query parameters to format parameter=value, removing undefined fields.
  // Filter method removes potential undefined fields, but for some reasons the function returns [string]: string | undefined.
  // For that reason there is this ugly type assertion
  */

  const searchString =
    params &&
    new URLSearchParams(
      Object.fromEntries(Object.entries(params).filter(([, v]) => v)) as Record<
        string,
        string
      >,
    );

  const formattedParams = searchString ? `?${searchString}` : '';

  const url = `${process.env.REACT_APP_WEB_API}${path}${formattedParams}`;

  const response = await fetchRetry(url, options);

  if (
    (response.status === 401 || response.status === 403) &&
    (window as any).Cypress
  ) {
    // Return an empty object during cypress tests when a request hasn't been intercepted in tests
    return {};
  }

  if (response.status === 401) {
    if (!sessionStorage.getItem('crossLoginAuthInProgress')) {
      sessionStorage.clear();

      window.location.replace(LOGIN);
    }
    return;
  }

  if (response.status === 400) {
    const { errors }: { errors: FieldError[] } = await response.json();
    throw new ValidationError(errors);
  }

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  try {
    let data;
    if (options.headers['Content-Type'] === 'text/html') {
      data = await response.text();
    } else if (options.headers['Content-Type'] === 'application/pdf') {
      data = await response.blob();
    } else {
      data = await response.json();
    }
    return data;
  } catch {
    // If response doesn't have content return undefined;
    return;
  }
}

export default fetchData;
