import cities from './data/cities';
import emailProviders from './data/emailProviders';
import firstNamesFemale from './data/firstNamesFemale';
import firstNamesMale from './data/firstNamesMale';
import lastNamesFemale from './data/lastNamesFemale';
import lastNamesMale from './data/lastNamesMale';
import streets from './data/streets';

const firstNames = [...firstNamesFemale, ...firstNamesMale];
const lastNames = [...lastNamesMale, ...lastNamesFemale];

type Gender = 'male' | 'female';

const faker = (function fake() {
  function _getRandomElement(array: string[]) {
    return array[Math.floor(Math.random() * array.length)];
  }

  function _randomDate() {
    const from = new Date(1954, 0, 1);
    const to = new Date(2000, 0, 1);

    return new Date(
      from.getTime() + Math.random() * (to.getTime() - from.getTime()),
    );
  }

  function numericString(length: number) {
    if (typeof length !== 'number') {
      throw Error('length should be a number');
    }

    if (!length || length < 1) {
      throw Error('length is required and should be greater than 0');
    }

    return Math.random()
      .toString()
      .slice(2, length + 2);
  }

  function firstName(gender?: Gender) {
    if (gender === 'male') {
      return _getRandomElement(firstNamesMale);
    }

    if (gender === 'female') {
      return _getRandomElement(firstNamesFemale);
    }

    return _getRandomElement(firstNames);
  }

  function lastName(gender?: Gender) {
    if (gender === 'male') {
      return _getRandomElement(lastNamesMale);
    }

    if (gender === 'female') {
      return _getRandomElement(lastNamesFemale);
    }

    return _getRandomElement(lastNames);
  }

  function gender() {
    const gender = Math.floor(Math.random() * 2);

    if (gender === 0) {
      return 'male';
    }

    return 'female';
  }

  function integer(min: number, max: number) {
    if (typeof min !== 'number' || typeof max !== 'number') {
      throw Error('min and max should be a numbers');
    }

    if (min >= max) {
      throw Error('min should be greater than max');
    }

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function personalId(gender?: Gender) {
    const marker = gender === 'female' ? 50 : 0;

    const sss = numericString(3);
    const birthday = _randomDate();
    const yy = birthday.getFullYear().toString().substr(-2);
    const mm = (birthday.getMonth() + 1 + marker).toString().padStart(2, '0');
    const dd = birthday.getDate().toString().padStart(2, '0');
    const yymmddsss = parseInt(`${yy}${mm}${dd}${sss}`, 10);
    let c = yymmddsss % 11;

    if (c === 10) {
      c = 0;
    }

    return `${yy}${mm}${dd}/${sss}${c}`;
  }

  function email(name?: string, surname?: string) {
    const currentName = name || firstName();
    const currentLastName = surname || lastName();

    return (
      currentName +
      _getRandomElement(['.', '_', '']) +
      currentLastName +
      _getRandomElement(['.', '_', '']) +
      integer(0, 99) +
      '@' +
      _getRandomElement(emailProviders)
    )
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function city() {
    return _getRandomElement(cities);
  }

  function street() {
    return _getRandomElement(streets);
  }

  return {
    numericString,
    firstName,
    lastName,
    gender,
    integer,
    personalId,
    email,
    city,
    street,
  };
})();

export default faker;
