import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useSmartlook from '../../useSmartlook';

export function useClientQuery(enabled = false) {
  const { error } = useSmartlook();
  return useQuery<Client>({
    queryKey: ['client'],
    queryFn: async () => {
      try {
        const data = await fetchData('/client');
        return data;
      } catch (e) {
        error('[useClientQuery][GET /client]', e);
        throw e;
      }
    },
    enabled,
    keepPreviousData: true,
  });
}

type MobilePhone = {
  mobilePhone: string;
  self: string;
  clientLink: string;
  updatePhoneLink: string;
  status: string;
};

type Email = {
  self: string;
  clientLink: string;
  updateEMailLink: string;
  email: string;
};

type Embedded = {
  mobilePhone: MobilePhone;
  email: Email;
};

export type Client = {
  id: number;
  number: string;
  status:
    | 'UNKNOWN'
    | 'REGISTERED'
    | 'IDENTIFIED'
    | 'DELETED'
    | 'VALIDATED'
    | 'ACTIVE'
    | 'NOT_IDENTIFIED';
  actualAddress: {
    fullAddress: string;
    location2: string;
    postalCode: string;
  };
  mobilePhoneVerified: boolean;
  _embedded: Embedded;
  // mobilePhone: MobilePhone;  TODO: use this after BE updates response
  // email: Email               TODO: use this after BE updates response
  fullName: string;
  bankAccount?: string;
  personalId: string;
  identityDocumentId: string;
  dateOfBirth: string;
  age: number;
  identifiedBy:
    | 'BANK'
    | 'MANUAL'
    | 'ID_SERVICE'
    | 'BROKER'
    | 'EMAIL'
    | 'IMMEDIATE_TRANSFER'
    | 'FINTONIC'
    | 'PRESTALO'
    | 'NOT_IDENTIFIED'
    | 'BANK_ID';
  registeredBy:
    | 'WEB'
    | 'OFFLINE'
    | 'BROKER'
    | 'CALL_CENTER'
    | 'LEAD_GENERATOR'
    | 'WEB_OTHER_PRODUCT';
  firstName: string;
  lastName: string;
  declaredAddress?: {
    fullAddress: string;
    location1: string;
    location2: string;
    location3: string;
    location4: string;
    location5: string;
    location6: string;
    postalCode: string;
    postalCodePrefix: string;
  };
};
