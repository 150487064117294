export const LOGIN = '/login';
export const ONE_TIME_TOKEN_LOGIN = '/one-time-token-login';

export const EXTERNAL_DASHBOARD = '/profile';

export const APPROVED = '/first-loan/approved';
export const CONSENT = '/first-loan/consents';
export const DOWNSALE = '/first-loan/downsale';
export const EXPIRED = '/first-loan/expired-application';
export const IDENTIFICATION = '/first-loan/identification/ocr';
export const IDENTIFICATION_UPLOAD = '/first-loan/identification/upload-id';
export const IDENTIFICATION_UPLOAD_DESKTOP =
  '/first-loan/identification/desktop-upload-id';
export const INCOME_PROOF = '/first-loan/identification/income-proof';
export const INTRO = '/first-loan/start';
export const MANUAL_IDENTIFICATION = '/first-loan/identification/manual';
export const OCR_RESULTS = '/first-loan/identification/ocr/confirm';
export const PERSONAL_DETAILS = '/first-loan/personal-data';
export const PHONE_CHANGE = '/first-loan/change-phone';
export const PHONE_VERIFICATION = '/first-loan/phone-verification';
export const PSD2_BANKS = '/first-loan/psd/select-bank';
export const PSD2_GUIDE = '/first-loan/psd/helper';
export const PSD2_CONSENTS = '/first-loan/psd/consent';
export const PSD2_BANK_ACCOUNT_SELECT = '/first-loan/psd/select-account';
export const PSD2_RECEIVING = '/first-loan/psd/receiving';
export const PSD2_VERIFIED = '/first-loan/psd/verified';
export const PSD2_ERROR = '/first-loan/psd/error';

export const OFFLINE_PSD2_BANKS = '/psd/offline/select-bank';
export const OFFLINE_PSD2_GUIDE = '/psd/offline/helper';
export const OFFLINE_PSD2_CONSENTS = '/psd/offline/consent';
export const OFFLINE_PSD2_BANK_ACCOUNT_SELECT = '/psd/offline/select-account';
export const OFFLINE_PSD2_RECEIVING = '/psd/offline/receiving';
export const OFFLINE_PSD2_VERIFIED = '/psd/offline/result';
export const OFFLINE_PSD2_ERROR = '/psd/offline/error';

export const REJECTED_OFFER = '/first-loan/rejected';
export const UPSALE = '/first-loan/upsale';
export const UPSALE_CHANGE_AMOUNT = '/first-loan/upsale/offer';
export const NOT_FINISHED_APPLICATION = '/first-loan/continue';
export const STEP_BASICS = '/first-loan/basics';
export const STEP_ADDRESS = '/first-loan/address';
export const STEP_FINANCE = '/first-loan/finance';

export const CONTINUE_AFFILIATE = '/continue/affiliate';
export const OFFLINE_TOKEN = '/offline-identification/:token';
export const BANK_ID = '/first-loan/bank-id';
export const BANK_ID_CONFIRM = '/first-loan/bank-id/confirm';
export const BANK_ID_ERROR = '/first-loan/bank-id/error';
export const BANK_ID_ACCOUNT_SELECTION =
  '/first-loan/bank-id/account-selection';
