import styles from './Loader.module.scss';

export type Props = {
  outline?: boolean;
};

export default function Loader({ outline = false }: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loader}>
        <div className={outline ? styles['circle-gray'] : styles.circle} />
        <div className={outline ? styles['circle-gray'] : styles.circle} />
      </div>
    </div>
  );
}
