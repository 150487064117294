import { ReactComponent as Chevron } from '../../assets/icons/chevron.svg';
import { memo, ReactNode } from 'react';
import classes from 'classnames';
import Fade from 'react-reveal-effects/Fade';
import styles from './ToggleItem.module.scss';

export type Props = {
  title: string;
  children: ReactNode;
  onClick: (tab: string) => void;
  active: boolean;
};

export default memo(function ToggleItem({
  active,
  children,
  title,
  onClick,
}: Props) {
  const handleClick = () => {
    onClick(title);
  };
  return (
    <div className={styles.container}>
      <div
        className={styles.head}
        key={title}
        onClick={handleClick}
        data-test="togglelist-item-head"
      >
        <Chevron className={classes(styles.chevron, active && styles.open)} />
        <div className={styles.title}>{title}</div>
      </div>
      <Fade top distance="20px" collapse when={active}>
        <div className={styles.content} data-test="togglelist-item-content">
          {children}
        </div>
      </Fade>
    </div>
  );
});
