import { ReactComponent as CloseIcon } from '../../assets/icons/close-4.svg';
import React from 'react';
import styles from './VideoBannerPlayer.module.scss';

interface VideoBannerPlayerProps {
  desktopVideo: string;
  mobileVideo: string;
  onClose: () => void;
}

const VideoBannerPlayer = ({
  desktopVideo,
  mobileVideo,
  onClose,
}: VideoBannerPlayerProps) => {
  const handleClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles['video-overlay']} onClick={handleClick}>
      <div className={styles['desktop-video-container']}>
        <iframe
          width="560"
          height="315"
          src={desktopVideo}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="YouTube video player"
          allowFullScreen
        />
      </div>
      <div className={styles['mobile-video-container']}>
        <iframe
          width="560"
          height="315"
          src={mobileVideo}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className={styles['close-icon']} onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
  );
};

export default VideoBannerPlayer;
