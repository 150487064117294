import * as R from '../../../Router/routes';
import { fetchData } from '../../../utils';
import { navigate } from '@reach/router';
import { useQuery } from '@tanstack/react-query';
import { useStore } from '../../useStore';
import useSmartlook from '../../useSmartlook';

export function useBankIDSessionsQuery(
  { uuid }: { uuid?: string },
  enabled: boolean,
) {
  const { error } = useSmartlook();
  const [storeBankId, setStoreBankId] = useStore((store) => store.bankId);
  return useQuery<Profile>({
    queryKey: ['proxy', 'bankid', 'sessions', { uuid }],
    queryFn: async () => {
      try {
        return await fetchData(`/proxy/bankid/sessions/${uuid}`);
      } catch (e) {
        error('[useBankIDSessionsQuery][GET /proxy/bankid/sessions/{uuid}]', e);
        setStoreBankId({
          bankId: {
            ...storeBankId,
            error: true,
          },
        });
        navigate(R.BANK_ID_ERROR);
      }
    },
    enabled,
  });
}

// This is basically replica of what's coming from BE, don't mind the camelCase and wrong translations.
export type Profile = {
  givenName: string;
  familyName: string;
  age: number;
  birthdate: string;
  birthnumber: string;
  cardNumber: string;
  address: string;
  addresses: Array<Address>;
  paymentAccounts: Array<string>;
  idcards: Array<Card>;
  fullName: string;
  fullAge: string;
  sessionStatus:
    | 'ERROR'
    | 'SUCCESS'
    | 'PERSONAL_ID_MISMATCH'
    | 'NAME_SURNAME_MISMATCH'
    | 'ID_CARD_DUPLICATED'
    | 'BANK_ACCOUNT_DUPLICATED'
    | undefined;
};

export type Address = {
  buildingapartment: string;
  street: string;
  streetnumber: string;
  city: string;
  zipcode: string;
  type: string;
};

export type Card = {
  country: string;
  number: string;
};
