import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-circle.svg';
import { ReactComponent as EyeClosedIcon } from '../../assets/icons/eye-closed.svg';
import { ReactComponent as EyeOpenIcon } from '../../assets/icons/eye-open.svg';
import { translate } from '../../utils';
import classes from 'classnames';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import HeadShake from 'react-reveal-effects/HeadShake';
import MaskedInput, { Mask } from 'react-text-mask';
import React, { memo, useCallback, useState } from 'react';
import styles from './Input.module.scss';

export type Props = {
  type?: 'text' | 'password' | 'tel';
  value?: string | number;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  isTouched?: boolean;
  errorAnimationTrigger?: number; // Pass a different value to re-trigger error animaiton
  placeholder?: string;
  postFix?: string;
  showPasswordReveal?: boolean;
  dataTest?: string;
  stackable?: boolean;
  className?: string;
  isFramedError?: boolean;
  disabled?: boolean;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  autoComplete?:
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'username'
    | 'email'
    | 'new-password'
    | 'current-password'
    | 'one-time-code'
    | 'organization'
    | 'organization-title'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level1'
    | 'address-level2'
    | 'address-level3'
    | 'address-level4'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'url'
    | 'photo'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-extension';
  mask?: Mask | ((value: string) => Mask);
  guide?: boolean; // Show/Hide mask visual guidance
};

export default memo(function Input({
  type = 'text',
  onChange,
  value = '',
  name,
  onBlur,
  error,
  isTouched,
  errorAnimationTrigger,
  placeholder,
  postFix,
  showPasswordReveal = false,
  dataTest,
  stackable = false,
  className,
  isFramedError,
  inputMode,
  disabled = false,
  autoComplete,
  mask = false,
  guide = false,
}: Props) {
  const [passwordVisibility, setPasswordVisibility] = useState<
    'password' | 'text'
  >('password');

  const handlePasswordToggle = useCallback(() => {
    setPasswordVisibility(
      passwordVisibility === 'password' ? 'text' : 'password',
    );
  }, [passwordVisibility]);

  const renderInput = useCallback(
    (ref: (inputElement: HTMLElement) => void, props: any) => {
      // Do not use defaultValue to prevent React complaining about switching uncontrolled input to controlled
      const { defaultValue, ...rest } = props;

      return <input data-test={dataTest} ref={ref} {...rest} value={value} />;
    },
    [dataTest, value],
  );

  const showError = isTouched && error;
  const showCheck = isTouched && !error;
  const EyeIcon =
    passwordVisibility === 'password' ? EyeClosedIcon : EyeOpenIcon;
  return (
    <div className={classes(styles.container, className)}>
      <div
        className={classes(
          styles.field,
          stackable && styles.stackable,
          isFramedError && styles['field-error'],
        )}
      >
        <MaskedInput
          mask={mask}
          guide={guide}
          className={classes(
            styles.input,
            isFramedError && styles['input-error'],
          )}
          name={name}
          id={name}
          type={type === 'password' ? passwordVisibility : type}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          inputMode={inputMode}
          autoComplete={autoComplete}
          render={renderInput}
          disabled={disabled}
        />

        <label
          htmlFor={name}
          className={classes(
            styles.label,
            isFramedError && styles['label-error'],
            value.toString().length && styles.float,
          )}
        >
          {placeholder}
        </label>

        <div
          className={styles.badges}
          onClick={showPasswordReveal ? handlePasswordToggle : undefined}
        >
          {showPasswordReveal && (
            <EyeIcon
              className={classes(styles.badge, styles['password-icon'])}
            />
          )}
          {postFix && (
            <span className={classes(styles.badge, styles['post-fix'])}>
              {postFix}
            </span>
          )}
          {(showError || showCheck) && (
            <div
              className={classes(
                styles.divider,
                isFramedError && styles['divider-error'],
              )}
            />
          )}
          {showError && (
            <HeadShake
              spy={errorAnimationTrigger ? errorAnimationTrigger : undefined}
            >
              <ErrorIcon
                className={classes(styles.badge, styles['error-icon'])}
              />
            </HeadShake>
          )}
          {showCheck && <CheckIcon className={styles.badge} />}
        </div>
      </div>

      {!isFramedError && showError && (
        <div className={styles['error-message']}>
          <ErrorMessage errors={error} />
        </div>
      )}

      {isFramedError && (
        <span
          className={styles['error-message-be']}
          dangerouslySetInnerHTML={{
            __html: translate('error.validation.already_exists_in_db'),
          }}
        />
      )}
    </div>
  );
});
