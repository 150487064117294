import { useCallback } from 'react';
import { ValidationError } from '../utils';
import Smartlook from 'smartlook-client';

const useSmartlook = () => {
  const error = useCallback((whatFailed: string, e: unknown) => {
    if (!(window as any)?.smartlook) {
      return;
    }
    let strError = whatFailed;
    if (e instanceof ValidationError) {
      strError = `${strError}: ValidationError: ${JSON.stringify(
        e.fieldErrors,
      )}`;
    } else if (e instanceof Error) {
      strError = `${strError}: Error: ${e.message}`;
    } else if (typeof e === 'string') {
      strError = `${strError}: ${e}`;
    }
    Smartlook.error(strError);
  }, []);

  return {
    error,
  };
};

export default useSmartlook;
