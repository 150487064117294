import { FieldError } from './ValidationError';
import translate from './translate';

/** Format field/input errors that come from BE so they can be easily used with Formik's setErrors function */
const formatBackendValidationErrors = (fieldErrors: FieldError[] = []) => {
  const errors: { [index: string]: string } = {};
  fieldErrors.forEach((fieldError) => {
    let property = fieldError.property;
    if (property.includes('.')) {
      const split = property.split('.');
      property = split[split.length - 1]; // Get the field name of nested object
    }
    errors[property] = translate(
      `error.validation.${fieldError.messageTemplate}`,
    );
  });
  return errors;
};

export default formatBackendValidationErrors;
