import { createPortal } from 'react-dom';
import { memo, useEffect } from 'react';

export default memo(function Portal({ children }) {
  const mount = document.getElementById('portal-root');
  const el = document.createElement('div');

  useEffect(() => {
    if (!mount) return;

    mount.appendChild(el);

    return () => {
      mount.removeChild(el);
    };
  }, [el, mount]);

  return createPortal(children, el);
});
