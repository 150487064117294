import { useCallback, useMemo } from 'react';
import fetchData from '../../utils/fetchData';
import useError from '../useError';
import useSmartlook from '../useSmartlook';

type UploadStatus = {
  status:
    | 'NEW'
    | 'INITIATED'
    | 'USER_STARTED'
    | 'USER_FINISHED'
    | 'DONE'
    | 'ERROR'
    | 'EXPIRED';
};

const useDocumentUpload = () => {
  const { showError } = useError();
  const { error } = useSmartlook();

  const sendSms = useCallback(async () => {
    try {
      await fetchData('/client/document/upload', {
        method: 'post',
        body: JSON.stringify({
          documents: [{ type: 'ID_CARD' }],
        }),
      });
    } catch (e) {
      error('[useDocumentUpload][sendSms]', e);
      showError(e);
      throw e;
    }
  }, [showError]);

  const getUploadStatus =
    useCallback(async (): Promise<UploadStatus | void> => {
      try {
        const data = await fetchData('/client/document/uploadinfo');

        return data;
      } catch (e) {
        error('[useDocumentUpload][getUploadStatus]', e);
        showError();
        throw e;
      }
    }, [showError]);

  const uploadAttachment = useCallback(
    async ({ body }) => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_WEB_API}/client/attachments`,
          {
            method: 'post',
            headers: {
              ...(sessionStorage.getItem('token') && {
                Authorization: `Basic ${sessionStorage.getItem('token')}`,
              }),
            },
            body,
          },
        );

        if (!res.ok) {
          throw new Error(res.status.toString());
        }

        return res.json();
      } catch (e) {
        error('[useDocumentUpload][uploadAttachment]', e);
        showError();
        throw e;
      }
    },
    [showError],
  );

  const sendAttachmentsToOCR = useCallback(
    async ({ body }) => {
      try {
        const data = await fetchData('/client/document/ocr', {
          method: 'post',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        error('[useDocumentUpload][sendAttachmentsToOCR]', e);
        showError();
        throw e;
      }
    },
    [showError],
  );

  return useMemo(() => {
    return {
      sendSms,
      getUploadStatus,
      uploadAttachment,
      sendAttachmentsToOCR,
    };
  }, [sendSms, getUploadStatus, uploadAttachment, sendAttachmentsToOCR]);
};

export default useDocumentUpload;
