import { useEffect, useMemo, useState } from 'react';
import throttle from 'lodash.throttle';

type Breakpoint = {
  name: 'xs' | 's' | 'm' | 'l' | 'xl';
  index: 0 | 1 | 2 | 3 | 4;
};

const THROTTLE_TIME = 200; // ms

type UseBreakpoint = {
  size: Breakpoint;
};

const getSize = (width: number): Breakpoint => {
  if (width <= 540) {
    return {
      name: 'xs',
      index: 0,
    };
  } else if (width <= 768) {
    return {
      name: 's',
      index: 1,
    };
  } else if (width <= 1024) {
    return {
      name: 'm',
      index: 2,
    };
  } else if (width <= 1200) {
    return {
      name: 'l',
      index: 3,
    };
  } else {
    return {
      name: 'xl',
      index: 4,
    };
  }
};

const useBreakpoint = (): UseBreakpoint => {
  const [size, setSize] = useState<Breakpoint>(getSize(window.innerWidth));

  useEffect(() => {
    const handleResize = throttle(() => {
      const { innerWidth } = window;
      const newSize = getSize(innerWidth);

      setSize(newSize);
    }, THROTTLE_TIME);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return useMemo(
    () => ({
      size,
    }),
    [size],
  );
};

export default useBreakpoint;
