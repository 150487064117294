export default function sanitizeGmail(email: string) {
  // Split the email into local part and domain part
  const parts = email.split('@');

  if (!['gmail.com', 'googlemail.com'].includes(parts[1])) {
    return email;
  }

  // Remove periods from the local part
  const sanitizedLocalPart = parts[0].replace(/\./g, '');

  // Reconstruct the email
  return `${sanitizedLocalPart}@${parts[1]}`;
}
