import * as R from '../../../Router/routes';
import { fetchData } from '../../../utils';
import { navigate } from '@reach/router';
import { useMutation } from '@tanstack/react-query';
import { useStore } from '../../useStore';
import useSmartlook from '../../useSmartlook';

export function useBankIDAccountConfirmMutation() {
  const { error } = useSmartlook();
  const [storeBankId, setStoreBankId] = useStore((store) => store.bankId);
  return useMutation<
    void,
    unknown,
    { uuid: string; bankAccountNumber: string }
  >({
    mutationFn: async ({ uuid, bankAccountNumber }) => {
      try {
        return await fetchData(
          `/proxy/bankid/sessions/${uuid}/confirm/account`,
          {
            method: 'POST',
            body: JSON.stringify({ bankAccountNumber }),
          },
        );
      } catch (e) {
        error(
          '[useBankIDAccountConfirmMutation][POST /proxy/bankid/sessions/{uuid}/confirm/account]',
          e,
        );
        setStoreBankId({
          bankId: {
            ...storeBankId,
            error: true,
          },
        });
        navigate(R.BANK_ID_ERROR);
      }
    },
  });
}
