import { LoadableComponent } from '@loadable/component';
import { PHONE_VERIFICATION } from './routes';
import { Redirect } from '@reach/router';

type Props = {
  component: LoadableComponent<any>;
  path: string;
  fallback: JSX.Element;
};

export default function PublicRoute({ component: Component, ...props }: Props) {
  if (!sessionStorage.getItem('token')) {
    return <Component {...props} />;
  }

  return <Redirect to={PHONE_VERIFICATION} noThrow />;
}
