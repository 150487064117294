import { useCallback, useRef } from 'react';
import StringProcessor from './StringProcessor';

export default function useDataLayerHashedValues() {
  const phoneRef = useRef<string>();
  const hashedTelephoneNumberRef = useRef<string>();
  const emailRef = useRef<string>();
  const hashedEmailRef = useRef<string>();
  const firstNameRef = useRef<string>();
  const hashedFirstNameRef = useRef<string>();
  const lastNameRef = useRef<string>();
  const hashedLastNameRef = useRef<string>();

  const getHashedTelephoneNumber = useCallback(async (phone?: string) => {
    if (!phone) {
      return null;
    }

    if (phone === phoneRef.current) {
      return hashedTelephoneNumberRef.current;
    }

    const value = (
      await new StringProcessor(phone.trim()).sanitizePhone().sha256()
    ).getValue();

    phoneRef.current = phone;
    hashedTelephoneNumberRef.current = value;

    return value;
  }, []);

  const getHashedEmail = useCallback(async (email?: string) => {
    if (!email) {
      return null;
    }

    if (email === emailRef.current) {
      return hashedEmailRef.current;
    }

    const value = (
      await new StringProcessor(email.trim().toLowerCase())
        .sanitizeGmail()
        .sha256()
    ).getValue();

    emailRef.current = email;
    hashedEmailRef.current = value;

    return value;
  }, []);

  const getHashedFirstName = useCallback(async (firstName?: string) => {
    if (!firstName) {
      return null;
    }

    if (firstName === firstNameRef.current) {
      return hashedFirstNameRef.current;
    }

    const value = (
      await new StringProcessor(firstName.trim().toLowerCase()).sha256()
    ).getValue();

    firstNameRef.current = firstName;
    hashedFirstNameRef.current = value;

    return value;
  }, []);

  const getHashedLastName = useCallback(async (lastName?: string) => {
    if (!lastName) {
      return null;
    }

    if (lastName === lastNameRef.current) {
      return hashedLastNameRef.current;
    }

    const value = (
      await new StringProcessor(lastName.trim().toLowerCase()).sha256()
    ).getValue();

    lastNameRef.current = lastName;
    hashedLastNameRef.current = value;

    return value;
  }, []);

  return {
    getHashedTelephoneNumber,
    getHashedEmail,
    getHashedFirstName,
    getHashedLastName,
  };
}
