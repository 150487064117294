import { fetchData, ValidationError } from '../../../utils';
import { useMutation } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function usePrecheckApplicationMutation() {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useMutation<
    {
      rejected: boolean;
    },
    unknown,
    void
  >({
    mutationFn: async () => {
      try {
        const data = await fetchData('/client/application/pre-check', {
          method: 'post',
        });

        return data;
      } catch (e) {
        error(
          '[usePrecheckApplicationMutation][POST /client/application/pre-check]',
          e,
        );
        if (!(e instanceof ValidationError)) {
          showError();
          throw e;
        }
      }
    },
  });
}
