import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { memo } from 'react';
import Button from '../Button/Button';
import Offer, { OfferType } from './Offer';
import Placeholder from '../Placeholder/Placeholder';
import Slider from '../Slider/Slider';
import styles from './Calculator.module.scss';
import Swiper from '../Swiper/Swiper';
import translate, {
  numberToCurrency,
  numberToPercent,
} from '../../utils/translate';
import useBreakpoint from '../../hooks/useBreakpoint';

export type Constraints = {
  amountInterval: {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
  };
  termInterval: {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
  };
  loanLimits: {
    1: number;
    2: number;
  };
};

export type Props = {
  onCloseCalculator: () => void;
  onAmountChange: (value: number) => void;
  onTermChange: (value: number) => void;
  onConfirm: () => void;
  constraints: Constraints;
  offer: OfferType;
  loading?: boolean;
  calculatorAmount?: number;
  calculatorTerm?: number;
};

const Calculator = memo(function Calculator({
  onCloseCalculator,
  onAmountChange,
  onTermChange,
  onConfirm,
  constraints,
  offer,
  loading,
  calculatorAmount,
  calculatorTerm,
}: Props) {
  const { size } = useBreakpoint();

  return (
    <div className={styles['calculator-wrapper']}>
      <div className={styles['close-wrapper']}>
        <CloseIcon
          data-test="calculator-close"
          className={styles['close-icon']}
          onClick={onCloseCalculator}
        />
      </div>
      {size.index <= 2 ? (
        <div className={styles['mobile-swiper-wrapper']}>
          <Swiper
            dataTest="swiper-amount"
            min={constraints.amountInterval.min}
            max={constraints.loanLimits[1]}
            step={constraints.amountInterval.step}
            defaultValue={
              calculatorAmount !== undefined
                ? calculatorAmount
                : constraints.amountInterval.defaultValue
            }
            onChange={onAmountChange}
            snap={95}
            heading={translate('calculator.mobile_amount_swiper_title')}
          />

          <Swiper
            dataTest="swiper-term"
            min={constraints.termInterval.min}
            max={constraints.termInterval.max}
            step={constraints.termInterval.step}
            defaultValue={
              calculatorTerm !== undefined
                ? calculatorTerm
                : constraints.termInterval.defaultValue
            }
            onChange={onTermChange}
            snap={75}
            heading={translate('calculator.mobile_term_swiper_title')}
          />
        </div>
      ) : (
        <div className={styles['desktop-slider-wrapper']}>
          <Slider
            dataTest="amount-slider"
            min={constraints.amountInterval.min}
            max={constraints.loanLimits[1]}
            step={constraints.amountInterval.step}
            value={
              calculatorAmount !== undefined
                ? calculatorAmount
                : constraints.amountInterval.defaultValue
            }
            onAfterChange={onAmountChange}
            units={translate('calculator.amount_units')}
            heading={translate('calculator.amount_slider_title')}
          />
          <Slider
            dataTest="term-slider"
            min={constraints.termInterval.min}
            max={constraints.termInterval.max}
            step={constraints.termInterval.step}
            value={
              calculatorTerm !== undefined
                ? calculatorTerm
                : constraints.termInterval.defaultValue
            }
            onAfterChange={onTermChange}
            units={translate('calculator.term_units')}
            heading={translate('calculator.term_slider_title')}
          />
        </div>
      )}

      <Offer loading={loading} offer={offer} />

      <div className={styles['representative-example']}>
        <Placeholder loading={loading} dataTest="representative-example">
          {translate('calculator.representative_example', {
            totalPrincipal: numberToCurrency(calculatorAmount, 2),
            term: calculatorTerm,
            interestRate: numberToPercent(offer?.interestRate, 2),
            annualPercentageRate: numberToPercent(
              offer?.annualPercentageRate,
              2,
            ),
            totalRepayableAmount: numberToCurrency(
              offer?.totalRepayableAmount,
              2,
            ),
            newInterest: numberToCurrency(offer?.newInterest, 2),
          })}
        </Placeholder>
      </div>

      <section className={styles['button-section']}>
        <Button
          dataTest="calculator-confirm"
          wide
          size="l"
          className={styles.btn}
          onClick={onConfirm}
        >
          {translate('calculator.btn')}
        </Button>
      </section>
    </div>
  );
});

export default Calculator;
