import * as R from '../../../Router/routes';
import { fetchData } from '../../../utils';
import { navigate } from '@reach/router';
import { useMutation } from '@tanstack/react-query';
import { useStore } from '../../useStore';
import useSmartlook from '../../useSmartlook';

export function useBankIDAuthInitMutation() {
  const { error } = useSmartlook();
  const [storeBankId, setStoreBankId] = useStore((store) => store.bankId);
  return useMutation<
    Record<'url', string>,
    unknown,
    {
      product: 'ZAPLO_CZ'; // | 'KIMBI_CZ';
      clientNumber: string;
    }
  >({
    mutationFn: async (payload) => {
      try {
        const resp = await fetchData(`/proxy/bankid/auth/init`, {
          method: 'POST',
          body: JSON.stringify(payload),
        });

        return resp;
      } catch (e) {
        error('[useBankIDAuthInitMutation][POST /proxy/bankid/auth/init]', e);
        setStoreBankId({
          bankId: {
            ...storeBankId,
            error: true,
          },
        });
        navigate(R.BANK_ID_ERROR);
      }
    },
  });
}
