import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { fetchData, getUpperDomain } from '../../utils';
import { useEffect } from 'react';
import Cookie from 'js-cookie';
import useError from '../useError';
import useSmartlook from '../useSmartlook';

type Props = {
  children: ReactNode;
};

export type Affiliate = {
  redirectUrl?: string;
  token: string;
  provider: string;
  partner?: string;
  tier?: string;
  channel?: string;
};

type AffiliateForm = {
  [index: string]: string | number;
};

type FetchAffiliateParams = {
  provider: string;
  token?: string;
  subid?: string;
};

type AffiliateContextType = {
  affiliate?: Affiliate;
  fetchAffiliate: (params: FetchAffiliateParams) => Promise<Affiliate | void>;
  fetchAffiliateForm: (token: string) => Promise<AffiliateForm>;
  affiliateForm?: AffiliateForm;
  removeAffiliateCookieData: () => void;
  removeAffiliateData: () => void;
};

const AffiliateContext = createContext<AffiliateContextType>(
  {} as AffiliateContextType,
);

const useAffiliate = (): AffiliateContextType => useContext(AffiliateContext);

const AffiliateProvider = ({ children }: Props): JSX.Element => {
  const [affiliate, setAffiliate] = useState<Affiliate>();
  const [affiliateForm, setAffiliateForm] = useState<AffiliateForm>();
  const { showError } = useError();
  const { error } = useSmartlook();

  useEffect(() => {
    const provider = Cookie.get('affiliate_provider');
    const partner = Cookie.get('affiliate_partner') || '';
    const token = Cookie.get('affiliate_token') || '';
    const tier = Cookie.get('affiliate_tier');
    const channel = Cookie.get('affiliate_channel');

    if (!provider) return;

    const storedAffiliate: Affiliate = {
      token,
      provider,
      partner,
      tier,
      channel,
    };

    setAffiliate(storedAffiliate);
  }, []);

  /**
   * Fetches affiliate data based on passed query and url parameters.
   * Sets affiliate data in cookie for upper public domain so it's visible on other products
   */
  const fetchAffiliate = useCallback(
    async ({
      provider,
      token = '',
      subid = '',
    }: FetchAffiliateParams): Promise<Affiliate | void> => {
      try {
        const queryString = new URLSearchParams({ token, subid });
        const url = `/affiliate/data/${provider}?${queryString}`;

        const fetchedAffiliate: Affiliate = await fetchData(url.toString());
        setAffiliate(fetchedAffiliate);
        sessionStorage.setItem('affiliate', JSON.stringify(fetchedAffiliate));

        const expires = new Date();
        expires.setDate(expires.getDate() + 30);

        const domain = getUpperDomain();

        const cookieOptions = {
          expires,
          domain,
        };

        Cookie.set('rpts', Date.now().toString());
        Cookie.set('affiliate_token', token || '', cookieOptions);
        Cookie.set(
          'affiliate_provider',
          fetchedAffiliate.provider || '',
          cookieOptions,
        );
        Cookie.set(
          'vvs_utmsrc',
          fetchedAffiliate.provider || '',
          cookieOptions,
        );
        Cookie.set(
          'affiliate_partner',
          fetchedAffiliate.partner || '',
          cookieOptions,
        );
        Cookie.set('vvs_utmcpg', fetchedAffiliate.partner || '', cookieOptions);
        Cookie.set('vvs_utmmed', 'affiliate', cookieOptions);

        if (fetchedAffiliate.tier) {
          Cookie.set('affiliate_tier', fetchedAffiliate.tier, cookieOptions);
        }

        if (fetchedAffiliate.channel) {
          Cookie.set(
            'affiliate_channel',
            fetchedAffiliate.channel,
            cookieOptions,
          );
        }

        return fetchedAffiliate;
      } catch (e) {
        error('[AffiliateProvider][fetchAffiliate]', e);
        showError();
        throw e;
      }
    },
    [showError],
  );

  /** Fetches the prefilled application form data for registration from the affiliate */
  const fetchAffiliateForm = useCallback(
    async (token: string) => {
      try {
        const form: {
          [index: string]: string | number | null;
        } = await fetchData(`/lead-data?token=${token}`);

        // Removes null values from payload
        const cleanedForm = Object.fromEntries(
          Object.entries(form).filter(([_, v]) => v !== null),
        ) as AffiliateForm;

        setAffiliateForm(cleanedForm);

        return cleanedForm;
      } catch (e) {
        error('[AffiliateProvider][fetchAffiliateForm]', e);
        showError();
        throw e;
      }
    },
    [showError],
  );

  const removeAffiliateCookieData = useCallback(() => {
    try {
      const domain = getUpperDomain();

      const options = {
        domain,
      };

      Cookie.remove('affiliate_provider', options);
      Cookie.remove('affiliate_partner', options);
      Cookie.remove('affiliate_token', options);
      Cookie.remove('affiliate_tier', options);
      Cookie.remove('affiliate_channel', options);

      setAffiliate(undefined);
    } catch (e) {
      error('[AffiliateProvider][removeAffiliateCookieData]', e);
      throw e;
    }
  }, []);

  /** Remove the affiliate related cookies and state */
  const removeAffiliateData = useCallback(() => {
    try {
      removeAffiliateCookieData();
      setAffiliateForm(undefined);
    } catch (e) {
      showError();
      throw e;
    }
  }, [removeAffiliateCookieData, showError]);

  const affiliateContextValue = useMemo(
    () => ({
      affiliate,
      fetchAffiliate,
      fetchAffiliateForm,
      affiliateForm,
      removeAffiliateCookieData,
      removeAffiliateData,
    }),
    [
      affiliate,
      fetchAffiliate,
      fetchAffiliateForm,
      affiliateForm,
      removeAffiliateCookieData,
      removeAffiliateData,
    ],
  );
  return (
    <AffiliateContext.Provider value={affiliateContextValue}>
      {children}
    </AffiliateContext.Provider>
  );
};

export { useAffiliate as default, AffiliateProvider };
