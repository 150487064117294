import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function usePSD2RequestedQuery(isEnabled: boolean) {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useQuery<PSD2Availability>({
    queryKey: ['psd2', 'requested'],
    queryFn: async () => {
      try {
        const resp = await fetchData('/client/psd/psd2-requested');

        return resp;
      } catch (e) {
        error('[usePSD2RequestedQuery][GET /client/psd/psd2-requestedk]', e);
        showError();
        throw e;
      }
    },
    enabled: isEnabled,
  });
}

export type PSD2Availability = {
  psd2requested: boolean;
};
