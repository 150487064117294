import { memo, useCallback, useRef } from 'react';
import classes from 'classnames';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import styles from './MultiField.module.scss';

export type Props = {
  value: string[];
  length?: number;
  onChange?: (name: string, value: Array<string | undefined>) => void;
  dataTest?: string;
  name: string;
  error?: string;
  isTouched?: boolean;
};

export default memo(function MultiField({
  value = [],
  length = 4,
  onChange,
  dataTest,
  name,
  error,
  isTouched,
}: Props) {
  const fields = useRef<Array<HTMLInputElement | null>>([]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const index = Number(event.target.name);
      const newMultiFieldValue: Array<string | undefined> = [...value];
      const inputValue = event.target.value.toString();

      // Only select the last written char
      newMultiFieldValue[index] = inputValue[inputValue.length - 1];

      // Navigate to next input
      if (event.target.value) {
        fields.current[index + 1]?.focus();
      } else {
        fields.current[index - 1]?.focus();
      }

      if (onChange) {
        onChange(name, newMultiFieldValue);
      }
    },
    [value, onChange, name, fields],
  );

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const index = Number(event.currentTarget.name);
      const isBackspace = event.keyCode === 8;
      const isRightArrow = event.keyCode === 39;
      const isLeftArrow = event.keyCode === 37;

      // Navigate input fields with keys
      if ((isBackspace && !event.currentTarget.value) || isLeftArrow) {
        fields.current[index - 1]?.focus();
      } else if (isRightArrow) {
        fields.current[index + 1]?.focus();
      }
    },
    [],
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const ONLY_NUMBER = /^\d+$/;

      if (!ONLY_NUMBER.test(event.key)) {
        // Allow only digit inputs
        event.preventDefault();
      }
    },
    [],
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const ONLY_NUMBER = /^\d+$/;
      const paste: string[] = event.clipboardData
        .getData('text')
        .split('')
        .splice(0, length);

      const hasNonValidChar = paste.some((char) => !ONLY_NUMBER.test(char));

      if (hasNonValidChar) {
        return;
      }

      if (onChange) {
        onChange(name, paste);
      }

      if (paste.length >= length) {
        // All inputs are full, nowhere to navigate anymore
        fields.current[paste.length - 1]?.focus();
      } else {
        // Go to next input if available
        fields.current[paste.length]?.focus();
      }
    },
    [length, name, onChange],
  );

  return (
    <div className={styles.container} data-test={dataTest}>
      {Array(length)
        .fill(null)
        .map((_, index) => (
          <input
            key={index}
            inputMode="numeric"
            autoComplete={index === 0 ? 'one-time-code' : undefined}
            className={classes(
              styles.input,
              error && isTouched && styles.error,
            )}
            data-test={`input-${index}`}
            name={index.toString()}
            value={value[index] || ''}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            onKeyPress={handleKeyPress}
            onPaste={handlePaste}
            ref={(node) => (fields.current[index] = node)}
          />
        ))}
      {error && isTouched && (
        <div className={styles['error-message']}>
          <ErrorMessage errors={error} />
        </div>
      )}
    </div>
  );
});
