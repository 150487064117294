import { ClientApplication } from '../hooks/webapi/client/useApplicationQuery';
import { DOWNSALE, EXPIRED, REJECTED_OFFER, UPSALE } from '../Router/routes';
import { navigate } from '@reach/router';

export default function handleApplicationRedirect({
  status,
  resolution,
  resolutionDetail,
}: ClientApplication) {
  const rejected =
    status === 'CLOSED' &&
    resolution === 'REJECTED' &&
    !(resolutionDetail === 'UPSALE_OFFER' || resolutionDetail === 'NEW_OFFER');

  const upsale =
    status === 'CLOSED' &&
    resolution === 'REJECTED' &&
    resolutionDetail === 'UPSALE_OFFER';

  const downsale =
    status === 'CLOSED' &&
    resolution === 'REJECTED' &&
    resolutionDetail === 'NEW_OFFER';

  const expired =
    status === 'CLOSED' &&
    resolution === 'CANCELLED' &&
    resolutionDetail === 'EXPIRED';

  if (rejected) {
    navigate(REJECTED_OFFER);
  } else if (upsale) {
    navigate(UPSALE);
  } else if (downsale) {
    navigate(DOWNSALE);
  } else if (expired) {
    navigate(EXPIRED);
  }
}
