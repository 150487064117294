import { useQuery } from '@tanstack/react-query';
import fetchData from '../../../utils/fetchData';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useClientApplicationCheckStatusQuery(
  enabled = false,
  refetchInterval: number | false = 5000,
) {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useQuery<ClientApplicationCheckStatus>({
    queryKey: ['client', 'application', 'check-status'],
    queryFn: async () => {
      try {
        const resp = await fetchData('/client/application/check-status', {
          headers: {
            'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
          },
        });

        return resp;
      } catch (e) {
        error(
          '[useClientApplicationCheckQuery][GET /client/application/check-status]',
          e,
        );
        showError();
        throw e;
      }
    },
    cacheTime: 0,
    enabled,
    refetchInterval,
  });
}

export type ClientApplicationCheckStatus = {
  completed: boolean;
};
