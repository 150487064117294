import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useApplicationProposalsQuery() {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useQuery<ClientApplicationProposals>({
    queryKey: ['client', 'application', 'proposals'],
    queryFn: async () => {
      try {
        const resp = await fetchData('/client/application/proposals', {
          headers: {
            'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
          },
        });

        return resp;
      } catch (e) {
        error(
          '[useApplicationProposalsQuery][GET /client/application/proposals]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

type ClientApplicationProposals = {
  bestOfferProposal: {
    amount: number;
    term: number;
  };
};
