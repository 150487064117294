export const PERSONAL_ID_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const PHONE_MASK = [
  '+',
  '4',
  '2',
  '0',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
];
export const POSTCODE_MASK = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/];
