import * as R from '../../../Router/routes';
import { fetchData } from '../../../utils';
import { navigate } from '@reach/router';
import { useMutation } from '@tanstack/react-query';
import { useStore } from '../../useStore';
import useSmartlook from '../../useSmartlook';

export function useBankIDSessionsRejectMutation() {
  const { error } = useSmartlook();
  const [storeBankId, setStoreBankId] = useStore((store) => store.bankId);
  return useMutation<void, unknown, string>({
    mutationFn: async (uuid: string) => {
      try {
        return await fetchData(`/proxy/bankid/sessions/${uuid}/reject`, {
          method: 'POST',
        });
      } catch (e) {
        error(
          '[useBankIDSessionsRejectMutation][POST /proxy/bankid/sessions/{uuid}/reject]',
          e,
        );
        setStoreBankId({
          bankId: {
            ...storeBankId,
            error: true,
          },
        });
        navigate(R.BANK_ID_ERROR);
      }
    },
  });
}
