import { LoadableComponent } from '@loadable/component';
import { LOGIN } from './routes';
import { Redirect } from '@reach/router';

type Props = {
  component: LoadableComponent<any>;
  path: string;
  fallback: JSX.Element;
};

export default function PrivateRoute({
  component: Component,
  ...props
}: Props) {
  if (sessionStorage.getItem('token')) {
    return <Component {...props} />;
  }

  return <Redirect to={LOGIN} noThrow />;
}
