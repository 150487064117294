import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { memo } from 'react';
import classes from 'classnames';
import styles from './Progress.module.scss';

export type Props = {
  steps: Array<string>;
  currentStep: number;
  className?: string;
  dataTest?: string;
};

export default memo(function Progress({
  steps = [],
  currentStep = 0,
  className,
  dataTest,
}: Props) {
  return (
    <div data-test={dataTest} className={className}>
      {steps.map((step, index) => {
        const isCompletedStep = currentStep > index;
        const isCurrentStep = currentStep === index;

        return (
          <div
            className={classes(
              styles.section,
              index !== 0 && styles.line,
              (isCompletedStep || isCurrentStep) && styles.active,
            )}
            key={step}
          >
            <div className={styles.step}>
              <div
                data-test={`circle-${index}`}
                className={classes(
                  styles.circle,
                  (isCompletedStep || isCurrentStep) && styles.active,
                )}
              >
                {isCompletedStep && <CheckIcon />}
              </div>
              <span
                data-test={`label-${index}`}
                className={classes(
                  styles.label,
                  (isCompletedStep || isCurrentStep) && styles.active,
                )}
              >
                {step}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
});
