import { memo } from 'react';
import styles from './ErrorMessage.module.scss';

export type Props = {
  errors: string | undefined;
  className?: string;
  dataTest?: string;
};

export default memo(function ErrorMessage({
  errors,
  className,
  dataTest,
}: Props) {
  const list = errors?.split('-divider-');
  return (
    <div className={className} data-test={dataTest}>
      {list ? (
        <ul className={styles['error-list']}>
          {list.map(
            (item, i) =>
              item && (
                <li key={i}>
                  <span dangerouslySetInnerHTML={{ __html: item }} />
                </li>
              ),
          )}
        </ul>
      ) : (
        { errors }
      )}
    </div>
  );
});
