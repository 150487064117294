import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useSmartlook from '../../useSmartlook';

// SECCI stands for Standard European Consumer Credit Information
export function useSecciQuery() {
  const { error } = useSmartlook();
  return useQuery<string>({
    queryKey: ['secci'],
    queryFn: async () => {
      try {
        const res = await fetchData(`/client/application/secci-html`, {
          headers: {
            'Content-Type': 'text/html',
          },
        });

        return res as string;
      } catch (e) {
        error('[useSecciQuery][GET /client/application/secci-html]', e);
        throw e;
      }
    },
    staleTime: Infinity,
  });
}
