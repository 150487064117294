const data = [
  'Adam',
  'Adolf',
  'Alan',
  'Albert',
  'Aleš',
  'Alex',
  'Alexander',
  'Alexandr',
  'Alexej',
  'Alexis',
  'Alois',
  'Alojz',
  'Aloys',
  'Ambrož',
  'Andrej',
  'Antonin',
  'Antonín',
  'Arnošt',
  'Artur',
  'Augustin',
  'Augustýn',
  'Baltazar',
  'Bartoloměj',
  'Bartolomej',
  'Bedřich',
  'Benedikt',
  'Benjamin',
  'Bernard',
  'Blahoslav',
  'Bohdan',
  'Bohumil',
  'Bohumír',
  'Bohuslav',
  'Boleslav',
  'Bonifác',
  'Boris',
  'Bořek',
  'Bořivoj',
  'Božislav',
  'Bronislav',
  'Bruno',
  'Břetislav',
  'Ctibor',
  'Ctirad',
  'Cyril',
  'Čeněk',
  'Česlav',
  'Čestmír',
  'Dalemil',
  'Dalibor',
  'Dalimil',
  'Dan',
  'Daniel',
  'David',
  'Denis',
  'Dobroslav',
  'Dominik',
  'Drahoslav',
  'Dušan',
  'Eduard',
  'Edvard',
  'Emanuel',
  'Emil',
  'Erik',
  'Ervín',
  'Eugen',
  'Evžen',
  'Felix',
  'Ferdinand',
  'Filip',
  'František',
  'Gabriel',
  'George',
  'Gregor',
  'Gustav',
  'Havel',
  'Herbert',
  'Herman',
  'Heřman',
  'Horymír',
  'Hubert',
  'Hugo',
  'Hynek',
  'Ignác',
  'Igor',
  'Ivan',
  'Ivo',
  'Ivoš',
  'Jáchym',
  'Jakub',
  'Jan',
  'Jarmil',
  'Jarolím',
  'Jaromír',
  'Jaroslav',
  'Jeronym',
  'Jeroným',
  'Jindřich',
  'Jiří',
  'Joachim',
  'Jonáš',
  'Josef',
  'Jozef',
  'Judita',
  'Julian',
  'Július',
  'Julius',
  'Juraj',
  'Justin',
  'Kamil',
  'Karel',
  'Kašpar',
  'Kazimír',
  'Klement',
  'Kliment',
  'Kristián',
  'Krištof',
  'Kryštof',
  'Křišťan',
  'Květoslav',
  'Kvido',
  'Ladislav',
  'Leopold',
  'Leoš',
  'Libor',
  'Liboslav',
  'Lubomil',
  'Lubomír',
  'Lubor',
  'Luboslav',
  'Luboš',
  'Luděk',
  'Ludvík',
  'Lukáš',
  'Lumír',
  'Marcel',
  'Marek',
  'Marian',
  'Marián',
  'Marius',
  'Martin',
  'Matěj',
  'Matej',
  'Matouš',
  'Matyáš',
  'Maximilian',
  'Maxmilian',
  'Maxmilián',
  'Medard',
  'Melichar',
  'Metoděj',
  'Michael',
  'Michal',
  'Mikoláš',
  'Mikuláš',
  'Milan',
  'Milivoj',
  'Miloslav',
  'Miloš',
  'Mirek',
  'Miroslav',
  'Mojmír',
  'Nikolas',
  'Norbert',
  'Oldřich',
  'Oleg',
  'Oliver',
  'Ondřej',
  'Oskar',
  'Ota',
  'Otakar',
  'Otmar',
  'Oto',
  'Otokar',
  'Otomar',
  'Otta',
  'Otto',
  'Pankrác',
  'Patrik',
  'Pavel',
  'Petr',
  'Petr',
  'Pravoslav',
  'Prokop',
  'Přemysl',
  'Quido',
  'Radek',
  'Radim',
  'Radimír',
  'Radomil',
  'Radomír',
  'Radoslav',
  'Radovan',
  'René',
  'Richard',
  'Robert',
  'Robin',
  'Roland',
  'Rolf',
  'Roman',
  'Rudolf',
  'Rufin',
  'Řehoř',
  'Samuel',
  'Sáva',
  'Servác',
  'Silvester',
  'Silvestr',
  'Simeon',
  'Simon',
  'Slavomír',
  'Soběslav',
  'Stanislav',
  'Stibor',
  'Svatopluk',
  'Svatoslav',
  'Světoslav',
  'Sylvestr',
  'Šimon',
  'Štefan',
  'Štepán',
  'Štěpán',
  'Tadeáš',
  'Teodor',
  'Theodor',
  'Thomas',
  'Tibor',
  'Tom',
  'Tomáš',
  'Václav',
  'Valdemar',
  'Valentin',
  'Valentýn',
  'Vavřinec',
  'Věnceslav',
  'Vendelín',
  'Věroslav',
  'Victor',
  'Viktor',
  'Vilém',
  'Vincenc',
  'Vincent',
  'Vít',
  'Vítek',
  'Vítězslav',
  'Vladan',
  'Vladimír',
  'Vladislav',
  'Vlastimil',
  'Vlastislav',
  'Vojtěch',
  'Vratislav',
  'Waldemar',
  'Zbyněk',
  'Zbyšek',
  'Zdenek',
  'Zdeněk',
  'Zdenko',
  'Zikmun',
];

export default data;
