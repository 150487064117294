import * as R from './routes';
import { AsyncAffiliate } from '../pages/Affiliate/async';
import { AsyncAgreement } from '../pages/Registration/Agreement/async';
import { AsyncApproved } from '../pages/Registration/Approved/async';
import {
  AsyncBankIdAccountSelection,
  AsyncBankIdConfirm,
  AsyncBankIdError,
  AsyncBankIdInfo,
} from '../pages/Registration/BankId/async';
import { AsyncContinueAffiliate } from '../pages/ContinueAffiliate/async';
import { AsyncDownsale } from '../pages/Registration/Downsale/async';
import { AsyncExpired } from '../pages/Registration/Expired/async';
import {
  AsyncIdentification,
  AsyncManualIdentification,
} from '../pages/Registration/Identification/async';
import { AsyncIdentificationUpload } from '../pages/Registration/IdentificationUpload/async';
import { AsyncIdentificationUploadDesktop } from '../pages/Registration/IdentificationUploadDesktop/async';
import { AsyncIncomeProof } from '../pages/Registration/ProofIncome/async';
import { AsyncIntro } from '../pages/Registration/Intro/async';
import { AsyncLogin, AsyncOneTimeTokenLogin } from '../pages/Login/async';
import { AsyncNotFinishedApplication } from '../pages/Registration/NotFinishedApplication/async';
import { AsyncNotFound } from '../pages/NotFound/async';
import { AsyncOCRResults } from '../pages/Registration/OCRResults/async';
import { AsyncOfflinePSD2BankAccountSelect } from '../pages/Registration/OfflinePSD2SelectBankAccount/async';
import { AsyncOfflinePSD2Banks } from '../pages/Registration/OfflinePSD2Banks/async';
import { AsyncOfflinePSD2Consents } from '../pages/Registration/OfflinePSD2Consents/async';
import { AsyncOfflinePSD2Error } from '../pages/Registration/OfflinePSD2Error/async';
import { AsyncOfflinePSD2Guide } from '../pages/Registration/OfflinePSD2Guide/async';
import { AsyncOfflinePSD2Receiving } from '../pages/Registration/OfflinePSD2Receiving/async';
import { AsyncOfflinePSD2Token } from '../pages/Registration/OfflinePSD2Token/async';
import { AsyncOfflinePSD2Verified } from '../pages/Registration/OfflinePSD2Verified/async';
import { AsyncPersonalDetailAddress } from '../pages/Registration/PersonalDetails/Address/async';
import { AsyncPersonalDetailBasic } from '../pages/Registration/PersonalDetails/Basics/async';
import { AsyncPersonalDetailFinance } from '../pages/Registration/PersonalDetails/Finance/async';
import { AsyncPhoneChange } from '../pages/Registration/PhoneChange/async';
import { AsyncPhoneVerification } from '../pages/Registration/PhoneVerification/async';
import { AsyncPSD2BankAccountSelect } from '../pages/Registration/PSD2SelectBankAccount/async';
import { AsyncPSD2Banks } from '../pages/Registration/PSD2Banks/async';
import { AsyncPSD2Consents } from '../pages/Registration/PSD2Consents/async';
import { AsyncPSD2Error } from '../pages/Registration/PSD2Error/async';
import { AsyncPSD2Guide } from '../pages/Registration/PSD2Guide/async';
import { AsyncPSD2Receiving } from '../pages/Registration/PSD2Receiving/async';
import { AsyncPSD2Verified } from '../pages/Registration/PSD2Verified/async';
import { AsyncRegistration } from '../pages/Registration/async';
import { AsyncRejectedOffer } from '../pages/Registration/RejectedOffer/async';
import { AsyncUpsale } from '../pages/Registration/Upsale/async';
import { AsyncUpsaleChangeAmount } from '../pages/Registration/UpsaleChangeAmount/async';
import { Loader } from '../components';
import { Redirect, Router } from '@reach/router';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import styles from './Router.module.scss';

export default function AppRouter() {
  return (
    <Router className={styles.wrapper}>
      {/* PublicRoute should not be used as redirect specified on login itself based on user state */}
      <PublicRoute
        fallback={<Loader />}
        component={AsyncOneTimeTokenLogin}
        path={R.ONE_TIME_TOKEN_LOGIN}
      />
      <PublicRoute
        fallback={<Loader />}
        component={AsyncOfflinePSD2Token}
        path={R.OFFLINE_TOKEN}
      />
      <PublicRoute
        fallback={<Loader />}
        component={AsyncLogin}
        path={R.LOGIN}
      />
      <AsyncRegistration fallback={<Loader />} path="/">
        <PublicRoute
          fallback={<Loader />}
          component={AsyncIntro}
          path={R.INTRO}
        />
        <PublicRoute
          fallback={<Loader />}
          component={AsyncAffiliate}
          path="/affiliate/:provider"
        />
        <PublicRoute
          fallback={<Loader />}
          component={AsyncContinueAffiliate}
          path={R.CONTINUE_AFFILIATE}
        />
        <PublicRoute
          fallback={<Loader />}
          component={AsyncPersonalDetailBasic}
          path={R.STEP_BASICS}
        />

        <PublicRoute
          fallback={<Loader />}
          component={AsyncOfflinePSD2Banks}
          path={`${R.OFFLINE_PSD2_BANKS}/:token`}
        />
        <PublicRoute
          fallback={<Loader />}
          path={`${R.OFFLINE_PSD2_GUIDE}/:token`}
          component={AsyncOfflinePSD2Guide}
        />
        <PublicRoute
          fallback={<Loader />}
          path={`${R.OFFLINE_PSD2_CONSENTS}/:token`}
          component={AsyncOfflinePSD2Consents}
        />
        {/* NOTE: Workaround because of back-end */}
        <PublicRoute
          fallback={<Loader />}
          path={R.OFFLINE_PSD2_BANK_ACCOUNT_SELECT}
          component={AsyncOfflinePSD2BankAccountSelect}
        />
        <PublicRoute
          fallback={<Loader />}
          path={`${R.OFFLINE_PSD2_BANK_ACCOUNT_SELECT}/:token`}
          component={AsyncOfflinePSD2BankAccountSelect}
        />
        {/* NOTE: Workaround because of back-end */}
        <PublicRoute
          fallback={<Loader />}
          path={R.OFFLINE_PSD2_RECEIVING}
          component={AsyncOfflinePSD2Receiving}
        />
        <PublicRoute
          fallback={<Loader />}
          path={`${R.OFFLINE_PSD2_RECEIVING}/:token`}
          component={AsyncOfflinePSD2Receiving}
        />
        <PublicRoute
          fallback={<Loader />}
          path={`${R.OFFLINE_PSD2_VERIFIED}/:token`}
          component={AsyncOfflinePSD2Verified}
        />
        <PublicRoute
          fallback={<Loader />}
          path={`${R.OFFLINE_PSD2_ERROR}/:token`}
          component={AsyncOfflinePSD2Error}
        />

        <PrivateRoute
          fallback={<Loader />}
          path={R.BANK_ID}
          component={AsyncBankIdInfo}
        />
        <PrivateRoute
          fallback={<Loader />}
          path={R.BANK_ID_CONFIRM}
          component={AsyncBankIdConfirm}
        />
        <PrivateRoute
          fallback={<Loader />}
          path={R.BANK_ID_ACCOUNT_SELECTION}
          component={AsyncBankIdAccountSelection}
        />
        <PrivateRoute
          fallback={<Loader />}
          path={R.BANK_ID_ERROR}
          component={AsyncBankIdError}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncPersonalDetailAddress}
          path={R.STEP_ADDRESS}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncPersonalDetailFinance}
          path={R.STEP_FINANCE}
        />

        <PrivateRoute
          fallback={<Loader />}
          component={AsyncPhoneChange}
          path={R.PHONE_CHANGE}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncPhoneVerification}
          path={R.PHONE_VERIFICATION}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncIdentification}
          path={R.IDENTIFICATION}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncDownsale}
          path={R.DOWNSALE}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncUpsale}
          path={R.UPSALE}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncUpsaleChangeAmount}
          path={R.UPSALE_CHANGE_AMOUNT}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncRejectedOffer}
          path={R.REJECTED_OFFER}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncAgreement}
          path={R.CONSENT}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncExpired}
          path={R.EXPIRED}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncOCRResults}
          path={R.OCR_RESULTS}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncIdentificationUpload}
          path={R.IDENTIFICATION_UPLOAD}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncIdentificationUploadDesktop}
          path={R.IDENTIFICATION_UPLOAD_DESKTOP}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncManualIdentification}
          path={R.MANUAL_IDENTIFICATION}
        />

        <PrivateRoute
          fallback={<Loader />}
          component={AsyncPSD2Banks}
          path={R.PSD2_BANKS}
        />
        <PrivateRoute
          fallback={<Loader />}
          path={R.PSD2_GUIDE}
          component={AsyncPSD2Guide}
        />
        <PrivateRoute
          fallback={<Loader />}
          path={R.PSD2_CONSENTS}
          component={AsyncPSD2Consents}
        />
        <PrivateRoute
          fallback={<Loader />}
          path={R.PSD2_BANK_ACCOUNT_SELECT}
          component={AsyncPSD2BankAccountSelect}
        />
        <PrivateRoute
          fallback={<Loader />}
          path={R.PSD2_RECEIVING}
          component={AsyncPSD2Receiving}
        />
        <PrivateRoute
          fallback={<Loader />}
          path={R.PSD2_VERIFIED}
          component={AsyncPSD2Verified}
        />
        <PrivateRoute
          fallback={<Loader />}
          path={R.PSD2_ERROR}
          component={AsyncPSD2Error}
        />

        <PrivateRoute
          fallback={<Loader />}
          component={AsyncIncomeProof}
          path={R.INCOME_PROOF}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncApproved}
          path={R.APPROVED}
        />
        <PrivateRoute
          fallback={<Loader />}
          component={AsyncNotFinishedApplication}
          path={R.NOT_FINISHED_APPLICATION}
        />
        <AsyncNotFound default />
        <Redirect from="/" to={R.INTRO} noThrow />
      </AsyncRegistration>
    </Router>
  );
}
