import { fetchData } from '../../../utils';
import { useMutation } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useDeclineOfferMutation() {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useMutation<void, unknown, void>({
    mutationFn: async () => {
      try {
        await fetchData('/client/application/decline-offer', {
          method: 'post',
        });
      } catch (e) {
        error(
          '[useDeclineOfferMutation][POST /client/application/decline-offer]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}
