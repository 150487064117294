import { memo, ReactNode } from 'react';
import { Portal } from '..';
import Modal, { Props as ModalProps } from './Modal';

type Props = {
  renderContent: () => ReactNode;
} & ModalProps;

export default memo(function PortalModal(props: Props) {
  return (
    <Portal>
      <Modal {...props}>{props.renderContent()}</Modal>
    </Portal>
  );
});
