import { fetchData } from '../../../utils';
import { useMutation } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useConfirmApplicationMutation() {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useMutation<ApplicationConfirmationResponse, unknown, void>({
    mutationFn: async () => {
      try {
        const resp = await fetchData('/client/application', {
          method: 'put',
          headers: {
            'Content-Type': 'application/vnd.4finance.web.v2.hal+json',
          },
        });

        return resp;
      } catch (e) {
        error('[useConfirmApplicationMutation][PUT /client/application]', e);
        showError();
        throw e;
      }
    },
  });
}

type ApplicationConfirmationResponse = {
  status: 'IN_PROGRESS';
};
