import { fetchData, ValidationError } from '../../../utils';
import { useMutation } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function usePSD2SkipMutation() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useMutation<
    {
      rejected: boolean;
    },
    unknown,
    void
  >({
    mutationFn: async () => {
      try {
        return await fetchData(`/client/psd/skip`, {
          method: 'POST',
        });
      } catch (e) {
        error('[usePSD2SkipMutation][POST /client/psd/skip]', e);
        if (!(e instanceof ValidationError)) {
          showError();
          throw e;
        }
      }
    },
  });
}
