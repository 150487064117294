import { fetchData, ValidationError } from '../../../utils';
import { useMutation } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useCreateApplicationMutation() {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useMutation<
    {
      rejected: boolean;
    },
    unknown,
    CreateApplicationOptions
  >({
    mutationFn: async (payload) => {
      try {
        const data = await fetchData('/client/application', {
          method: 'post',
          body: JSON.stringify(payload),
        });

        return data;
      } catch (e) {
        error('[useCreateApplicationMutation][POST /client/application]', e);
        if (!(e instanceof ValidationError)) {
          showError();
          throw e;
        }
      }
    },
  });
}

export function useCreateApplicationWithoutRiskMutation() {
  const { showError } = useError();
  return useMutation<
    {
      rejected: boolean;
    },
    unknown,
    CreateApplicationOptions
  >({
    mutationFn: async (payload) => {
      try {
        const data = await fetchData('/client/application/without-risk-eval', {
          method: 'post',
          body: JSON.stringify(payload),
        });

        return data;
      } catch (e) {
        if (!(e instanceof ValidationError)) {
          showError();
          throw e;
        }
      }
    },
  });
}

export type CreateApplicationOptions = {
  amount: number;
  term: number;
  type?:
    | 'SMS'
    | 'WEB'
    | 'MOBILE'
    | 'PHONE'
    | 'OFFLINE_TRANSFER'
    | 'OFFLINE_CASH'
    | 'BROKER'
    | 'POS'
    | 'FINTONIC'
    | 'PRESTALO';
  source:
    | 'DESKTOP'
    | 'MOBILE'
    | 'CALL_CENTER'
    | 'BROKER'
    | 'IOS'
    | 'ANDROID'
    | 'HYBRID_IOS'
    | 'HYBRID_ANDROID'
    | 'EXTRA_SERVICE_APPLICATION'
    | 'CRM'
    | 'WEB_OTHER_PRODUCT';
  typeDetail?:
    | 'MOBILE_APPLICATION'
    | 'ESHOP'
    | 'E_WALLET_ANDROID'
    | 'E_WALLET_IOS'
    | 'E_WALLET_BOON'
    | 'CALL_CENTER'
    | 'CAMPAIGN_DISTRIBUTED'
    | 'LEAD_GENERATOR'
    | 'EXTRA_SERVICE_APPLICATION'
    | 'MULTIFACTOR_AUTHENTIFICATION'
    | 'IOS'
    | 'HYBRID_IOS'
    | 'HYBRID_ANDROID'
    | 'ANDROID'
    | 'WEB_OFFER'
    | 'PHONE_OFFER'
    | 'AFFILIATE'
    | 'CRM'
    | 'E_MONEY'
    | 'MOBILE_APPLICATION_OFFER'
    | 'REFINANCE'
    | 'OFFLINE_OFFER';
  affiliateInfo?: Affiliate;
  ioBlackBox?: string;
};

export type Affiliate = {
  redirectUrl?: string;
  token: string;
  provider: string;
  partner?: string;
  tier?: string;
  channel?: string;
};
