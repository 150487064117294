import loadable from '@loadable/component';

export const AsyncBankIdAccountSelection = loadable(
  () => import('./BankIdAccountSelection'),
);

export const AsyncBankIdInfo = loadable(() => import('./BankIdInfo'));

export const AsyncBankIdConfirm = loadable(() => import('./BankIdConfirm'));

export const AsyncBankIdError = loadable(() => import('./BankIdError'));
