import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { translate, ValidationError } from '../../utils';
import { useQueryClient } from '@tanstack/react-query';
import Cookie from 'js-cookie';
import fetchData, { defaultOptions, fetchRetry } from '../../utils/fetchData';
import useError from '../useError';
import useSmartlook from '../useSmartlook';

type CrossLoginParams = {
  invalidateSession?: boolean;
};

type LoginResponse = {
  authorizationToken: string;
  fromTemporaryPassword: boolean;
};

type Props = {
  children: ReactNode;
};

type AuthContextType = {
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  crossLoginRedirect: (params?: CrossLoginParams) => void;
  crossLoginAuth: (token: string) => Promise<LoginResponse>;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const useAuth = (): AuthContextType => useContext(AuthContext);

const AuthProvider = ({ children }: Props): JSX.Element => {
  const { showError } = useError();
  const { error } = useSmartlook();
  const queryClient = useQueryClient();

  const login = useCallback(
    async (email: string, password: string) => {
      try {
        const encodedCredentials = btoa(`${email}:${password}`);

        const res = await fetchRetry(
          `${process.env.REACT_APP_WEB_API}/token/basic`,
          {
            ...defaultOptions,
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${encodedCredentials}`,
            },
          },
        );

        if (res.status === 401) {
          throw new ValidationError([
            {
              message: 'Invalid credentials',
              messageTemplate: 'invalid_credentials',
              property: 'password',
            },
          ]);
        }

        if (!res.ok) {
          throw new Error(res.status.toString());
        }

        const data = await res.json();

        const authorizationToken = btoa(`${data.username}:${data.token}`);
        const crossLoginToken = btoa(`${data.username}:${data.token}:${email}`);

        sessionStorage.setItem('token', authorizationToken);
        sessionStorage.setItem('crossLoginToken', crossLoginToken);
      } catch (e) {
        error('[AuthProvider][login]', e);
        if (e instanceof ValidationError) {
          showError({
            message: translate('error.unauthorized'),
            type: 'notification',
          });
          throw e;
        }
      }
    },
    [showError],
  );

  const logout = useCallback(async () => {
    try {
      if (sessionStorage.getItem('token')) {
        await fetchData('/token/invalidate', {
          method: 'delete',
        });

        sessionStorage.clear();
        Cookie.remove('token');
        queryClient.invalidateQueries();
      }
    } catch {
      // If invalidate request fails for some reason, at least remove token
      sessionStorage.clear();
    }
  }, []);

  /** Log in user in dashboard app */
  const crossLoginRedirect = useCallback(
    async (params: CrossLoginParams = {}) => {
      const { invalidateSession = true } = params;

      try {
        const { oneTimeToken } = await fetchData(
          '/client/security/one-time-token',
          {
            method: 'post',
          },
        );

        if (invalidateSession) {
          await logout();
        }

        const queryString = window.location.search
          ? `&${window.location.search.substring(1)}`
          : '';
        const redirectURL = `${process.env.REACT_APP_DASHBOARD_URL}/one-time-token-login?token=${oneTimeToken}${queryString}`;

        // disable cross-origin redirect for cypress
        if ((window as any).Cypress) {
          return console.log(redirectURL); // eslint-disable-line no-console
        }

        window.location.assign(redirectURL);
      } catch (e) {
        error('[AuthProvider][crossLoginRedirect]', e);
      }
    },
    [logout],
  );

  const crossLoginAuth = useCallback(async (oneTimeToken: string) => {
    try {
      sessionStorage.setItem('crossLoginAuthInProgress', 'true');
      const { username, token, fromTemporaryPassword } = await fetchData(
        '/token/one-time',
        {
          method: 'post',
          body: JSON.stringify({
            oneTimeToken,
          }),
        },
      );

      const authorizationToken = btoa(`${username}:${token}`);

      sessionStorage.setItem('token', authorizationToken);
      sessionStorage.setItem('fromTemporaryPassword', fromTemporaryPassword);
      sessionStorage.removeItem('crossLoginAuthInProgress');

      return {
        authorizationToken,
        fromTemporaryPassword,
      };
    } catch (e) {
      sessionStorage.removeItem('crossLoginAuthInProgress');
      error('[AuthProvider][crossLoginAuth]', e);
      throw e;
    }
  }, []);

  const profileContextValue = useMemo(
    () => ({
      login,
      logout,
      crossLoginRedirect,
      crossLoginAuth,
    }),
    [login, logout, crossLoginRedirect, crossLoginAuth],
  );

  return (
    <AuthContext.Provider value={profileContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { useAuth as default, AuthProvider };
