import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useRiskEvaluationSolvencyQuery(enabled = true) {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useQuery<RiskEvaluation>({
    queryKey: ['client', 'application', 'risk-evaluations-results', 'solvency'],
    queryFn: async () => {
      try {
        const resp = await fetchData(
          '/client/application/risk-evaluation-results/solvency',
        );
        return resp;
      } catch (e) {
        error(
          '[useRiskEvaluationSolvencyQuery][GET /client/application/risk-evaluation-results/solvency]',
          e,
        );
        showError();
        throw e;
      }
    },
    enabled,
  });
}

export type RiskEvaluation = {
  providerName: string;
  resolution: string;
  resolutionDetails: string;
  message: string;
  score: number;
};
