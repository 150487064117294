import classes from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import styles from './Timeout.module.scss';

export type Props = {
  seconds?: number;
  onTimeout: () => void;
  className?: string;
};

const lessThanTenMinutes = { from: 15, length: 4 };
const lessThanOneHour = { from: 14, length: 5 };
const lessThanTenHours = { from: 12, length: 7 };
const lessThanOneDay = { from: 11, length: 8 };

const Timeout = memo(({ seconds = 60, onTimeout, className }: Props) => {
  const [time, setTime] = useState(seconds);

  const date = new Date(0);
  date.setSeconds(time);

  let substring = lessThanTenMinutes;
  if (seconds >= 600 && seconds < 3600) {
    substring = lessThanOneHour;
  } else if (seconds >= 3600 && seconds < 36000) {
    substring = lessThanTenHours;
  } else if (seconds >= 36000 && seconds < 86400) {
    substring = lessThanOneDay;
  }

  const timeString = date
    .toISOString()
    .substr(substring.from, substring.length);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTime((prevTime) => {
        const newTime = prevTime - 1;
        if (newTime <= 0) {
          clearInterval(countdown);
          onTimeout();
        }
        return newTime;
      });
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [onTimeout]);

  return <span className={classes(styles.timer, className)}>{timeString}</span>;
});

export default Timeout;
