import { ClientApplication } from './webapi/client/useApplicationQuery';
import { Offer } from './webapi/application/useFirstLoanOfferQuery';
import createFastContext from './createFastContext';

type BankId = {
  paymentAccounts?: Array<string>;
  sessionId?: string | null;
  error?: boolean;
  confirmed?: boolean;
};

type Store = {
  offer?: Offer;
  application?: ClientApplication;
  bankId?: BankId;
  is61DaysProduct?: boolean;
};

const { StoreProvider, useStore } = createFastContext<Store>({});

export { StoreProvider, useStore };
