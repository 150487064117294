import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useSmartlook from '../../useSmartlook';

export function useExternalConsentsDataQuery() {
  const { error } = useSmartlook();
  return useQuery<Record<string, Consent>>({
    queryKey: ['external', 'consents', 'data'],
    queryFn: async () => {
      try {
        const resp = await fetchData('/external/consents/data');

        const formattedConsents = convertConsentsToObject(resp.entries);

        return formattedConsents;
      } catch (e) {
        error('[useExternalConsentsDataQuery][GET /external/consents/data]', e);
        throw e;
      }
    },
    staleTime: Infinity,
  });
}

export type Consent = {
  active: boolean;
  collectNew: boolean;
  name: string;
  parentConsent?: string;
  createdDate: string;
  effectiveFromDate: string;
  content: Array<{
    locale: 'cz';
    text: string;
  }>;
  version: number;
};

function convertConsentsToObject(consents: Consent[]): Record<string, Consent> {
  return consents.reduce((acc, curr) => {
    acc[curr.name] = curr;
    return acc;
  }, {} as { [index: string]: Consent });
}
