import { IconElement } from '../../types/IconElement';
import { memo, ReactNode, useState } from 'react';
import AccordionItem from './AccordionItem';
import classes from 'classnames';
import styles from './Accordion.module.scss';

export type Props = {
  items: Array<{
    title: string;
    icon?: IconElement;
    content: string | ReactNode;
  }>;
  dataTest?: string;
  className?: string;
  isDividerIncluded?: boolean;
  titleClassName?: string;
};

export default memo(function Accordion({
  items,
  dataTest,
  className,
  isDividerIncluded = true,
  titleClassName,
}: Props) {
  const [openTab, setOpenTab] = useState('');

  const toggleTab = (tab: string) => {
    openTab === tab ? setOpenTab('') : setOpenTab(tab);
  };

  const divider = <div className={styles.divider}>nebo</div>;

  return (
    <div data-test={dataTest} className={classes(styles.accordion, className)}>
      {items?.map((item, i) => (
        <div className={styles.tab} key={item.title}>
          {i > 0 && isDividerIncluded && divider}
          <AccordionItem
            titleClassName={titleClassName}
            title={item.title}
            Icon={item.icon}
            onClick={() => toggleTab(item.title)}
            active={openTab === item.title}
          >
            {item.content}
          </AccordionItem>
        </div>
      ))}
    </div>
  );
});
