import {
  createRef,
  PureComponent,
  ReactNode,
  RefObject,
  SyntheticEvent,
} from 'react';
import classes from 'classnames';
import styles from './Swiper.module.scss';

export type Props = {
  defaultValue: number;
  disabled: boolean;
  className?: string;
  heading: string;
  limit?: number;
  limitMessage?: ReactNode;
  max: number;
  min: number;
  onBeforeChange?: Function;
  onChange: Function;
  snap: number;
  step: number;
  transition: number;
  dataTest?: string;
};

type State = {
  amplitude: number;
  dragging: boolean;
  frame: number;
  max: number;
  min: number;
  offset: number;
  pressed?: boolean;
  reference: number;
  target: number;
  timestamp: number;
  velocity: number;
  moving: boolean;
};

export default class Swiper extends PureComponent<Props, State> {
  static defaultProps = {
    disabled: false,
    transition: 150,
  };

  private baseRef: RefObject<HTMLDivElement>;
  private viewRef: RefObject<HTMLDivElement>;
  private ticker: number | undefined;

  constructor(props: Props) {
    super(props);

    this.baseRef = createRef();
    this.viewRef = createRef();
    this.state = {
      amplitude: 0,
      dragging: false,
      frame: 0,
      max: 0,
      min: 0,
      offset: 0,
      pressed: false,
      reference: 0,
      target: 0,
      timestamp: 0,
      velocity: 0,
      moving: false,
    };
  }

  componentDidMount() {
    const { step, defaultValue, min } = this.props;
    const index: number = (defaultValue - min) / step;

    this.setMaxTransform();
    this.scrollToItem(index);
  }

  componentDidUpdate(prevProps: Props) {
    const {
      min: prevMin,
      max: prevMax,
      defaultValue: prevDefaultValue,
    } = prevProps;
    const { defaultValue, max, min, step } = this.props;

    if (prevMin !== min || prevMax !== max) {
      this.update();
    }

    if (prevDefaultValue !== defaultValue) {
      const index: number = (defaultValue - min) / step;

      this.scrollToItem(index);
    }
  }

  preventEvent(event: SyntheticEvent) {
    event.preventDefault();
  }

  range(start: number, end: number, step: number): Array<number> {
    const length: number = Math.floor((end - start) / step) + 1;

    return Array.from(Array(length), (_, index) => start + index * step);
  }

  setMaxTransform() {
    const viewElement = this.viewRef.current;
    const baseElement = this.baseRef.current;

    if (viewElement && baseElement) {
      const max: number = viewElement.offsetWidth - baseElement.offsetWidth;

      this.setState({ max });
    }
  }

  update() {
    this.setMaxTransform();

    window.requestAnimationFrame(this.autoScroll);
  }

  position = (event: any): number => {
    if (event.targetTouches && event.targetTouches.length > 0) {
      return event.targetTouches[0].clientX;
    }

    return event.clientX;
  };

  scroll = (x: number) => {
    const { min, max } = this.state;
    let offset: number | null = x > max ? max : null;

    if (!offset) {
      offset = x < min ? min : x;
    }

    const viewElement = this.viewRef.current;

    if (viewElement) {
      viewElement.style.transform = `translate3d(${-offset}px, 0, 0)`;
    }

    this.setState({ offset });
  };

  track = () => {
    const { frame, offset, timestamp, velocity } = this.state;
    const now: number = Date.now();
    const elapsed: number = now - timestamp;
    const delta: number = offset - frame;
    const vector: number = (1000 * delta) / (1 + elapsed);

    this.setState({
      timestamp: now,
      frame: offset,
      velocity: 0.8 * vector + 0.2 * velocity,
    });
  };

  autoScroll = () => {
    const { timestamp, amplitude, target } = this.state;
    let elapsed: number;
    let delta: number;

    if (amplitude) {
      elapsed = Date.now() - timestamp;
      delta = -amplitude * Math.exp(-elapsed / this.props.transition);

      if (delta > 1 || delta < -1) {
        this.scroll(target + delta);
        window.requestAnimationFrame(this.autoScroll);
      } else {
        this.scroll(target);
        this.onChange();
        this.setState({ moving: false });
      }

      this.setState({ dragging: false });
    }
  };

  scrollToItem(index: number) {
    const target: number = index * this.props.snap;
    const amplitude: number = target - this.state.offset;

    this.setState(
      {
        target,
        amplitude,
        timestamp: Date.now(),
      },
      () => this.scroll(target),
    );
  }

  prev = () => {
    this.scrollToItem(this.current() - 1);
  };

  next = () => {
    this.scrollToItem(this.current() + 1);
  };

  current(): number {
    const { snap } = this.props;
    const { offset } = this.state;
    let current: number;

    if (offset === 0) {
      current = 0;
    } else {
      current = Math.round(offset / snap);
    }

    return current;
  }

  atBegin(): boolean {
    return this.state.offset === 0;
  }

  atEnd(): boolean {
    return this.state.offset === this.state.max;
  }

  handleTap = (event: SyntheticEvent) => {
    if (this.props.min === this.props.max || this.props.disabled) {
      return;
    }

    if (!this.state.moving) {
      this.onBeforeChange();
    }

    this.setState(
      {
        pressed: true,
        moving: true,
        reference: this.position(event),
        velocity: 0,
        amplitude: 0,
        timestamp: Date.now(),
        frame: this.state.offset,
      },
      () => {
        clearInterval(this.ticker);

        this.ticker = window.setInterval(this.track, 100);

        // Update event listeners to be attached to the viewElement
        const viewElement = this.viewRef.current;

        if (viewElement) {
          viewElement.addEventListener('mousemove', this.handleDrag);
          viewElement.addEventListener('mouseup', this.handleRelease);
          viewElement.addEventListener('touchmove', this.handleDrag);
          viewElement.addEventListener('touchend', this.handleRelease);
        }
      },
    );
  };

  handleDrag = (event: Event) => {
    let x: number;
    let delta: number;

    if (this.state.pressed) {
      x = this.position(event);
      delta = this.state.reference - x;

      if (delta > 2 || delta < -2) {
        this.setState(
          {
            reference: x,
            dragging: true,
          },
          () => {
            this.scroll(this.state.offset + delta);
          },
        );
      }
    }
  };

  handleRelease = () => {
    if (this.props.min === this.props.max || this.props.disabled) {
      return;
    }

    const { snap } = this.props;
    const { velocity, offset } = this.state;

    this.setState(
      {
        pressed: false,
      },
      () => {
        clearInterval(this.ticker);
      },
    );

    let amplitude: number;
    let target: number = offset;

    if (velocity > 10 || velocity < -10) {
      amplitude = 0.8 * velocity;
      target = Math.round(offset + amplitude);
    }

    target = Math.round(target / snap) * snap;
    amplitude = target - offset;

    this.setState(
      {
        target,
        amplitude,
        timestamp: Date.now(),
      },
      () => {
        // Remove the event listeners added during handleTap
        const viewElement = this.viewRef.current;
        if (viewElement) {
          window.requestAnimationFrame(this.autoScroll);
          viewElement.removeEventListener('mousemove', this.handleDrag);
          viewElement.removeEventListener('mouseup', this.handleRelease);
          viewElement.removeEventListener('touchmove', this.handleDrag);
          viewElement.removeEventListener('touchend', this.handleRelease);
        }
      },
    );
  };

  getValue(): number {
    return this.props.min + this.current() * this.props.step;
  }

  onChange = () => {
    this.props.onChange(this.getValue());
  };

  onBeforeChange = () => {
    const { onBeforeChange } = this.props;

    if (onBeforeChange) {
      onBeforeChange(this.getValue());
    }
  };

  renderStep(item: number, index: number): ReactNode {
    const { disabled, max, min, snap } = this.props;
    const isCurrent: boolean = this.current() === index;
    const disabledSwiper: boolean = disabled || min === max;
    const classNames: string = classes(
      styles.item,
      disabledSwiper && styles.disabled,
      isCurrent && styles.active,
    );
    const handleClick = () => {
      if (!this.state.dragging && !disabledSwiper) {
        this.scrollToItem(index);
      }
    };

    return (
      <div
        onClick={handleClick}
        className={classNames}
        key={index}
        data-test={`swiper-item-${index}${
          isCurrent ? ' swiper-item-active' : ''
        }`}
        style={{ width: `${snap}px` }}
      >
        {item}
      </div>
    );
  }

  renderSteps(): ReactNode {
    const { min, max, step } = this.props;
    const range = this.range(min, max, step);

    return range.map((item, index) => this.renderStep(item, index));
  }

  render() {
    const {
      disabled,
      className,
      heading,
      limit,
      limitMessage,
      max,
      min,
      snap,
      dataTest,
    } = this.props;
    const showLimitMessage: boolean =
      !!limit && !!limitMessage && this.getValue() > limit;
    const disabledSwiper: boolean = disabled || min === max;
    const wrapperClassNames: string = classes(
      styles.wrapper,
      disabledSwiper && styles.disabled,
      showLimitMessage && styles.limit,
      className,
    );

    return (
      <div data-test={dataTest} className={wrapperClassNames}>
        <div
          data-test="swiper-heading"
          className={classes(styles.heading, disabledSwiper && styles.disabled)}
        >
          {showLimitMessage ? limitMessage : heading}
        </div>

        <div
          data-test="swiper-container"
          onMouseDown={this.handleTap}
          onMouseUp={this.handleRelease}
          onTouchStart={this.handleTap}
          onTouchMove={this.preventEvent}
          onTouchEnd={this.handleRelease}
          className={classes(
            styles.container,
            disabledSwiper && styles.disabled,
          )}
        >
          <div
            className={styles.base}
            ref={this.baseRef}
            style={{ width: `${snap}px` }}
          >
            <div className={styles.view} ref={this.viewRef}>
              {this.renderSteps()}
            </div>
          </div>
        </div>

        <div
          className={classes(
            styles.measurements,
            disabledSwiper && styles.disabled,
          )}
        />

        {showLimitMessage && <div className={styles['limit-measurements']} />}
      </div>
    );
  }
}
