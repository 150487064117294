import { memo, ReactNode } from 'react';
import classes from 'classnames';
import styles from './Typography.module.scss';

export type Props = {
  children: ReactNode;
  className?: string;
  dataTest?: string;
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700;
  color?: string;
  center?: boolean;
  kind?: 'p' | 'h3' | 'h2' | 'h1';
};

export default memo(function Typography({
  children,
  className,
  dataTest,
  size = 's',
  kind = 'p',
}: Props) {
  const Text = kind as keyof JSX.IntrinsicElements;

  return (
    <Text
      className={classes(styles.typography, styles[size], className)}
      data-test={dataTest}
    >
      {children as JSX.Element}
    </Text>
  );
});
