import './Pollyfills';
import { AffiliateProvider } from './hooks/webapi/useAffiliate';
import { AuthProvider } from './hooks/webapi/useAuth';
import { ErrorProvider } from './hooks/useError';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StoreProvider } from './hooks/useStore';
import { useEffect } from 'react';
import AppRouter from './Router/Router';
import Cookie from 'js-cookie';
import DataLayer from './utils/dataLayer/DataLayer';
const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    const handleOnLoadEvent = () => {
      const cookieToken = Cookie.get('token');
      const cookieCrossLoginToken = Cookie.get('crossLoginToken');

      if (Boolean(cookieToken) && !Boolean(sessionStorage.getItem('token'))) {
        sessionStorage.setItem('token', cookieToken || '');
      }

      if (
        Boolean(cookieCrossLoginToken) &&
        !Boolean(sessionStorage.getItem('crossLoginToken'))
      ) {
        sessionStorage.setItem('crossLoginToken', cookieCrossLoginToken || '');
      }
      Cookie.remove('token');
      Cookie.remove('crossLoginToken');
    };
    window.addEventListener('load', handleOnLoadEvent);

    return () => {
      window.removeEventListener('load', handleOnLoadEvent);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <DataLayer />
      <StoreProvider>
        <ErrorProvider>
          <AuthProvider>
            <AffiliateProvider>
              <AppRouter />
            </AffiliateProvider>
          </AuthProvider>
        </ErrorProvider>
      </StoreProvider>
    </QueryClientProvider>
  );
}

export default App;
