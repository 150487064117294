import { Card } from '..';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-2.svg';
import classes from 'classnames';
import React, { memo, ReactNode, useEffect, useRef } from 'react';
import styles from './Modal.module.scss';

export type Props = {
  onClose?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent,
  ) => void;
  children?: ReactNode;
  backdropClose?: boolean;
  className?: string;
  containerClassName?: string;
  dataTest?: string;
  withCloseButton?: boolean;
};

function getScrollbarWidth() {
  const hasVerticalScrollbar =
    document.body.scrollHeight > document.documentElement.clientHeight;

  if (!hasVerticalScrollbar) {
    return 0;
  }

  const outer = document.createElement('div');

  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  const inner = document.createElement('div');

  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
}

export default memo(function Modal({
  onClose,
  children,
  backdropClose = true,
  className,
  containerClassName,
  dataTest,
  withCloseButton = true,
}: Props) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (overlayRef.current && backdropClose) {
      overlayRef.current.addEventListener('mouseup', (event) => {
        if (onClose) {
          onClose(event);
        }
      });
    }
  }, [overlayRef, onClose, backdropClose]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${getScrollbarWidth()}px`;

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0';
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('mouseup', (event) => {
        event.stopPropagation();
      });
    }
  }, [containerRef]);

  return (
    <div ref={overlayRef} className={styles.overlay} data-test={dataTest}>
      <div
        className={classes(styles.container, styles.fade, containerClassName)}
      >
        <Card className={classes(styles.card, className)} ref={containerRef}>
          {onClose && withCloseButton && (
            <button className={styles['close-button']} onClick={onClose}>
              <CloseIcon className={styles.close} />
            </button>
          )}
          {children}
        </Card>
      </div>
    </div>
  );
});
