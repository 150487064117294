import { memo } from 'react';
import classes from 'classnames';
import styles from './ProgressBar.module.scss';

export type Props = {
  steps: number;
  currentStep: number;
  className?: string;
};

export default memo(function ProgressBar({
  steps,
  currentStep,
  className,
}: Props) {
  return (
    <div className={classes(styles.bar, className)}>
      <div
        className={styles.fill}
        style={{ width: `${(++currentStep / ++steps) * 100}%` }}
      />
    </div>
  );
});
