import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useSmartlook from '../../useSmartlook';

export function useGeneralTermsQuery() {
  const { error } = useSmartlook();
  return useQuery<Blob>({
    queryKey: ['general-terms'],
    queryFn: async () => {
      try {
        const res = await fetchData(`/client/general-terms/pdf`, {
          headers: {
            'Content-Type': 'application/pdf',
          },
        });

        return res as Blob;
      } catch (e) {
        error('[useGeneralTermsQuery][GET /client/general-terms/pdf]', e);
        throw e;
      }
    },
    staleTime: Infinity,
  });
}
