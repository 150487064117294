import faker from './faker';

export default function randomFill(income: string) {
  const gender = faker.gender();
  const firstName = faker.firstName(gender);
  const lastName = faker.lastName(gender);

  return {
    source: 'DESKTOP',

    // Basic info
    firstName,
    lastName,
    personalId: faker.personalId(gender),

    // Contact info
    mobilePhone: '+420' + faker.numericString(9),
    email: faker.email(firstName, lastName),
    cardId: faker.numericString(9),

    // Password
    password: 'Password1',
    passwordRepeat: 'Password1',

    // Personal Details
    // age: faker.integer(18, 70).toString(),
    employmentType: 'EMPLOYEE',
    monthlyIncome: income,
    monthlyExpenses: '3000',
    numberOfHouseholdMembers: 3,
    numberOfEmployedHouseholdMembers: 2,

    // Address
    city: faker.city(),
    streetName: faker.street(),
    houseNumber: faker.integer(1, 999).toString(),
    postalCode: faker.numericString(5),
    accommodationType: 'RENT',

    // Consents
    agreeGetNews: true,
    acceptAgreement: true,
    acceptNews: true,
    acceptProfiling: true,
    acceptFaceIdProcessing: true,
  };
}
