import { fetchData } from '../../../utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useBudgetQuery(isEnabled: boolean) {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useQuery<ClientBudget | null>({
    queryKey: ['budget'],
    queryFn: async () => {
      try {
        const res = await fetchData('/client/budget/info');
        return res ?? null;
      } catch (e) {
        error('[useBudgetQuery][GET /client/budget/info]', e);
        showError();
      }
    },
    enabled: isEnabled,
  });
}

export function useBudgetMutation() {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useMutation({
    mutationFn: async (body: ClientBudget) => {
      try {
        return await fetchData('/client/budget', {
          method: 'post',
          body: JSON.stringify(body),
        });
      } catch (e) {
        error('[useBudgetMutation][POST /client/budget]', e);
        showError();
        throw e;
      }
    },
  });
}

export type ClientBudget = {
  monthlyIncome: number;
  monthlyExpenses: number;
  numberOfEmployedHouseholdMembers: number;
  numberOfHouseholdMembers: number;
};
