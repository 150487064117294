import classes from 'classnames';
import Placeholder from '../Placeholder/Placeholder';
import styles from './Currency.module.scss';
import translate, { numberToLocale } from '../../utils/translate';

export type Props = {
  amount?: number;
  amountClassName?: string;
  loading?: boolean;
  unitClassName?: string;
  className?: string;
  dataTest?: string;
};

export default function Currency({
  amount,
  amountClassName,
  unitClassName,
  loading,
  className,
  dataTest,
}: Props) {
  return (
    <span data-test={dataTest} className={classes(styles.currency, className)}>
      <span className={classes(styles.amount, amountClassName)}>
        <Placeholder loading={loading} placeholder="## ###">
          {numberToLocale(amount)}
        </Placeholder>
      </span>
      <span className={classes(styles.unit, unitClassName)}>
        &nbsp;
        {translate('common.currency')}
      </span>
    </span>
  );
}
