import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import { useStore } from '../../useStore';
import useSmartlook from '../../useSmartlook';

export function useFirstLoanOfferQuery(
  {
    amount,
    term,
  }: {
    amount?: number;
    term?: number;
  },
  enabled = true,
) {
  const { error } = useSmartlook();
  const [is61DaysProduct] = useStore((store) => store.is61DaysProduct);
  return useQuery<Offer>({
    queryKey: ['application', 'first-loan-offer', { amount, term }],
    queryFn: async () => {
      try {
        const data = await fetchData(
          is61DaysProduct
            ? `/application/first-loan-offer/product-number/61?amount=${amount}&term=61`
            : `/application/first-loan-offer?amount=${amount}&term=${term}`,
          {
            headers: {
              Accept: 'application/json',
            },
          },
        );
        return data;
      } catch (e) {
        error('[useConstraintsQuery][GET /application/first-loan-offer]', e);
        throw e;
      }
    },
    staleTime: Infinity,
    enabled: !!amount && !!term && enabled,
    keepPreviousData: true,
  });
}

export type Offer = {
  term: number;
  newPrincipal: number;
  totalPrincipal: number;
  interestRate: number;
  newInterest: number;
  newInterestBeforeDiscount: number;
  firstPaymentInterest: number;
  currentPrincipal: number;
  currentPrincipalWithInterest: number;
  currentPrincipalWithInterestAndInitialCommission: number;
  monthlyPayment: number;
  agreementEndDate: string;
  isAdditionalAmount: boolean;
  discountAmount: number;
  discountPercent: number;
  discounts: any[];
  annualPercentageRate: number;
  totalInterest: number;
  initialCommission: number;
  newInitialCommission: number;
  totalCostOfCredit: number;
  totalRepayableAmount: number;
  firstPaymentDate: string;
  extensionFees: {
    7: number;
    14: number;
    30: number;
  };
  loanExtensionFees: [
    {
      term: number;
      unit: string;
      fee: number;
      interest: number;
      feeWithoutInterest: number;
    },
    {
      term: number;
      unit: string;
      fee: number;
      interest: number;
      feeWithoutInterest: number;
    },
    {
      term: number;
      unit: string;
      fee: number;
      interest: number;
      feeWithoutInterest: number;
    },
  ];
  newAmountUsedDays: number;
  newAmountWithInterestAndInitialCommission: number;
  newPrincipalApr: number;
  totalRoundedAmount: number;
  loanApplicationEnhancements: any[];
  capitalizableCommission: number;
  newPrincipalWithAllCapitalizableFees: number;
  newCapitalizedInterest: number;
  _links: {
    self: {
      href: string;
      title: string;
    };
  };
};
