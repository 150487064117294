import { ReactComponent as Chevron } from '../../assets/icons/chevron.svg';
import { IconElement } from '../../types/IconElement';
import { memo, ReactNode } from 'react';
import classes from 'classnames';
import styles from './AccordionItem.module.scss';

export type AccordionItemProps = {
  title: string;
  Icon?: IconElement;
  children: ReactNode;
  onClick: () => void;
  active: boolean;
  titleClassName?: string;
};

export default memo(function AccordionItem({
  active,
  children,
  title,
  onClick,
  Icon,
  titleClassName,
}: AccordionItemProps) {
  return (
    <div className={styles.container}>
      <div
        className={styles.head}
        onClick={onClick}
        data-test="accordion-item-head"
      >
        <div className={classes(styles.title, titleClassName)}>
          {Icon && <div className={styles.icon}>{<Icon />}</div>}
          {title}
        </div>
        <Chevron className={classes(styles.chevron, active && styles.open)} />
      </div>
      <div
        className={classes(
          styles.accordion,
          active ? styles.open : styles.close,
        )}
      >
        <div className={styles.content} data-test="accordion-item-content">
          {children}
        </div>
      </div>
    </div>
  );
});
