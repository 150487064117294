import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useSmartlook from '../../useSmartlook';

export function useConstraintsQuery() {
  const { error } = useSmartlook();
  return useQuery<Constraints>({
    queryKey: ['constraints'],
    queryFn: async () => {
      try {
        const data = await fetchData('/application/constraints');

        return data;
      } catch (e) {
        error('[useConstraintsQuery][GET /application/constraints]', e);
        throw e;
      }
    },
    staleTime: Infinity,
  });
}

export type Constraints = {
  loanLimits: {
    1: number;
    2: number;
  };
  amountInterval: {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
  };
  termInterval: {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
  };
  termLimits?: any[];
  _links?: {
    self: {
      href: string;
      title: string;
    };
  };
};
