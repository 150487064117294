import { memo } from 'react';
import classes from 'classnames';
import Placeholder from '../Placeholder/Placeholder';
import styles from './Offer.module.scss';
import translate, {
  dateFormat,
  numberToCurrency,
  numberToPercent,
} from '../../utils/translate';

export type OfferType = {
  newPrincipal: number;
  agreementEndDate: string;
  newInterestBeforeDiscount: number;
  interestRate: number;
  annualPercentageRate: number;
  totalRepayableAmount: number;
  totalPrincipal: number;
  newInterest: number;
};

export type Props = {
  offer?: OfferType;
  loading?: boolean;
  className?: string;
};

const Offer = memo(function Offer({ offer, loading, className }: Props) {
  return (
    <section className={classes(styles['offer-wrapper'], className)}>
      <hr className={styles['hr-first']} />
      <div className={styles.row}>
        <div>{translate('calculator.offer.loan')}</div>
        <div>
          <Placeholder
            dataTest="offer-agreement-total-principal"
            loading={loading}
          >
            {numberToCurrency(offer?.totalPrincipal)}
          </Placeholder>
        </div>
      </div>
      <hr className={styles.hr} />
      <div className={styles.row}>
        <div>{translate('calculator.offer.due_date')}</div>
        <div>
          <Placeholder dataTest="offer-agreement-end-date" loading={loading}>
            {dateFormat(offer?.agreementEndDate)}
          </Placeholder>
        </div>
      </div>
      <hr className={styles.hr} />
      <div className={styles.row}>
        <div>{translate('calculator.offer.interest')}</div>
        <div>
          <Placeholder
            dataTest="offer-agreement-total-interest"
            loading={loading}
          >
            {numberToCurrency(offer?.newInterest)}
          </Placeholder>
        </div>
      </div>
      <hr className={styles.hr} />
      <div className={styles.row}>
        <div>{translate('calculator.offer.interestRate')}</div>
        <div>
          <Placeholder
            dataTest="offer-agreement-total-interest-rate"
            loading={loading}
          >
            {numberToPercent(offer?.interestRate)}
          </Placeholder>
        </div>
      </div>
      <hr className={styles.hr} />
      <div className={styles.row}>
        <div>{translate('calculator.offer.rpsn')}</div>
        <div>
          <Placeholder dataTest="offer-agreement-rpsn" loading={loading}>
            {numberToPercent(offer?.annualPercentageRate)}
          </Placeholder>
        </div>
      </div>
      <hr className={styles.hr} />
      <div className={styles.row}>
        <div className={styles['bold-info']}>
          {translate('calculator.offer.totalRepayableAmount')}
        </div>
        <div className={styles['bold-info']}>
          <Placeholder
            dataTest="offer-total-repayable-amount"
            loading={loading}
          >
            {numberToCurrency(offer?.totalRepayableAmount)}
          </Placeholder>
        </div>
      </div>
    </section>
  );
});

export default Offer;
