/** Get the upper public domain based on the current environment */
const getUpperDomain = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return '.zaplo.cz';
  } else if (
    process.env.REACT_APP_ENV === 'test' ||
    process.env.REACT_APP_ENV === 'staging'
  ) {
    return '.4finance.com';
  } else {
    return 'localhost';
  }
};

export default getUpperDomain;
