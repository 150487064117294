import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as PDF } from '../../assets/icons/pdf.svg';
import { translate } from '../../utils';
import { ReactComponent as VectorIcon } from '../../assets/icons/vector.svg';
import Button from '../Button/Button';
import classes from 'classnames';
import Modal from '../Modal/Modal';
import React, { memo, ReactNode, useCallback, useRef, useState } from 'react';
import styles from './DocumentModal.module.scss';

export type Props = {
  onClose?: (event: React.MouseEvent<Element, MouseEvent> | MouseEvent) => void;
  children?: ReactNode;
  backdropClose?: boolean;
  options?: ModalOptions;
  className?: string;
  dataTest?: string;
};

export type ModalOptions = {
  header?: {
    title?: string;
    closeButton?: boolean;
    banner?: string;
  };
  footer?: {
    acceptTitle?: string;
    acceptPrint?: string;
    onAccept?: (
      event: React.MouseEvent<Element, MouseEvent> | MouseEvent,
    ) => void;
    print?: boolean;
  };
  onClose?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent,
  ) => void;
  children?: ReactNode;
  backdropClose?: boolean;
  className?: string;
};

export default memo(function DocumentModal({
  onClose,
  children,
  backdropClose = true,
  dataTest,
  options,
  className,
}: Props) {
  const documentRef = useRef<HTMLDivElement>(null);
  const printFrameRef = useRef<HTMLIFrameElement>(null);
  const [isScrolled, setScrolled] = useState(true);

  const handleAccept = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      if (!onClose) return;

      onClose(e);

      if (options?.footer?.onAccept) {
        options.footer.onAccept(e);
      }
    },
    [onClose, options?.footer],
  );

  const print = useCallback(() => {
    var printWindow = printFrameRef.current?.contentWindow;

    if (printWindow && documentRef.current) {
      printWindow.document.open();
      printWindow.document.write(documentRef.current.innerHTML);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    }
  }, []);

  const handleScroll = () => {
    if (documentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = documentRef.current;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setScrolled(false);
      }
    }
  };

  return (
    <Modal
      dataTest={dataTest}
      backdropClose={backdropClose}
      className={classes(className, styles.card)}
    >
      {options?.header && (
        <div className={styles.header}>
          <div className={styles['header-title']}>{options?.header.title}</div>
          <div className={styles['window-options']}>
            <Close className={styles.close} onClick={onClose} />
          </div>
        </div>
      )}
      <div
        ref={documentRef}
        onScroll={() => handleScroll()}
        className={styles.content}
      >
        {options?.header?.banner && (
          <div className={styles.banner}>
            <VectorIcon width={30} height={30} />
            <span>{options.header.banner}</span>
          </div>
        )}
        {children}
      </div>
      {options?.footer && (
        <div className={styles.footer}>
          <Button
            dataTest="modal-button"
            size="l"
            className={styles.button}
            onClick={handleAccept}
            disabled={isScrolled}
          >
            {options.footer.acceptTitle}
          </Button>
          {isScrolled && (
            <div className={styles.tooltip}>
              {translate('consent.validation_scroll')}
            </div>
          )}
          {options?.footer?.print && (
            <span className={styles['print-wrapper']} onClick={print}>
              <PDF className={styles.pdf} />
              <span className={styles.print}>{options.footer.acceptPrint}</span>
            </span>
          )}
        </div>
      )}
      <iframe
        title="Print frame"
        ref={printFrameRef}
        className={styles['print-frame']}
      />
    </Modal>
  );
});
