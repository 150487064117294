import { ReactComponent as HandleIcon } from '../../assets/icons/vertical-hamburger.svg';
import { memo, useCallback } from 'react';
import classes from 'classnames';
import ReactSlider from 'react-slider';
import styles from './Slider.module.scss';

export type Props = {
  min: number;
  max: number;
  value?: number;
  heading?: string;
  step?: number;
  units?: string;
  onChange?: (value: any) => void;
  onAfterChange: (value: any) => void;
  dataTest?: string;
};

type Thumb = {
  valueNow: number;
};

const Slider = memo(function Slider({
  min,
  max,
  value,
  heading,
  step,
  units,
  onChange,
  onAfterChange,
  dataTest,
}: Props) {
  const leftMargin = min + (max - min) * 0.1;
  const rightMargin = max - (max - min) * 0.1;

  const renderThumb = useCallback(
    (props: Object, state: Thumb) => {
      const { valueNow } = state;

      return (
        <div {...props}>
          <div className={styles.handle}>
            <HandleIcon />
            <div
              className={classes(
                styles.tooltip,
                valueNow > rightMargin && styles['tooltip-right'],
                valueNow < leftMargin && styles['tooltip-left'],
              )}
            >
              <div className={styles.amount}>
                <span data-test="thumb" className={styles.value}>
                  {valueNow}
                </span>
                {units && <span className={styles.units}>{units}</span>}
              </div>
            </div>
          </div>
        </div>
      );
    },
    [leftMargin, rightMargin, units],
  );

  return (
    <div data-test={dataTest} className={styles.wrapper}>
      {heading && <span className={styles.heading}>{heading}</span>}
      <ReactSlider
        className={styles.slider}
        min={min}
        max={max}
        step={step}
        value={value}
        thumbClassName={styles.thumb}
        trackClassName={styles.track}
        renderThumb={renderThumb}
        onChange={onChange}
        onAfterChange={onAfterChange}
      />
      <div className={styles.limits}>
        <span>
          {min} {units}
        </span>
        <span>
          {max} {units}
        </span>
      </div>
    </div>
  );
});

export default Slider;
