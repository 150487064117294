import dayjs from 'dayjs';
import escapeRegExp from './escapeRegExp';
import localization from '../localizations/cz';

type Options = { [index: string]: string | number | undefined | null };
type Cache = { [index: string]: string | number };

const memo: Cache = {};

export const NBSP_SYMBOL = '\xa0';
export const NUMBER_DELIMETER = ',';
export const NUMBER_GROUP_SIZE = 3;

export function numberToLocale(
  number: number | undefined | null,
  precision: number = 2,
): string {
  if (number === undefined || number === null || Number.isNaN(number)) {
    return '';
  }

  const numberString = String(number);
  const isInteger = Number.isInteger(number);
  const numberParts = isInteger ? [numberString] : numberString.split('.');
  const isNegative = number < 0;
  const integer = isNegative ? numberParts[0].substring(1) : numberParts[0];
  const numberOfIterations = Math.ceil(integer.length / NUMBER_GROUP_SIZE);
  let result = '';

  for (let i = integer.length, j = 1; i > 0; i -= NUMBER_GROUP_SIZE, j++) {
    const append = result;
    const prepend = integer.substring(i - NUMBER_GROUP_SIZE, i);

    if (prepend.length < NUMBER_GROUP_SIZE || j === numberOfIterations) {
      result = `${prepend}${append}`;
    } else {
      result = `${NBSP_SYMBOL}${prepend}${append}`;
    }
  }

  if (!isInteger) {
    const fraction = numberParts[1];
    result += `${NUMBER_DELIMETER}${fraction.substring(0, precision)}`;
  }

  if (isNegative) {
    result = `-${result}`;
  }

  return result;
}

export function numberWithSuffix(
  number: number,
  suffix: string,
  precision?: number,
): string {
  const prepend = numberToLocale(number, precision);

  return `${prepend}${NBSP_SYMBOL}${suffix}`;
}

export function numberToCurrency(number?: number, precision?: number): string {
  if (number === undefined) {
    return '';
  }

  const suffix = translate('common.currency');

  return numberWithSuffix(number, suffix, precision);
}

export function numberToPercent(number?: number, precision?: number): string {
  if (number === undefined) {
    return '';
  }

  const suffix = '%';

  return numberWithSuffix(number, suffix, precision);
}

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

export function dateFormat(
  date: string | undefined,
  format: string = DEFAULT_DATE_FORMAT,
): string {
  if (date === undefined) {
    return '';
  }

  return dayjs(date).format(format);
}

function translate(
  key: string,
  options?: Options | null | undefined,
  messages: Object = localization,
): string {
  if (memo[key]) {
    return String(memo[key]);
  }

  let optionsKey = '';

  if (options) {
    optionsKey = `${key}${JSON.stringify(options)}`;

    if (memo[optionsKey]) {
      return String(memo[optionsKey]);
    }
  }

  const path: string = key.replace(/\[(\w+)\]/g, '.$1');
  const sanitizedPath: string = path.replace(/^\./, '');
  const arr: any[] = sanitizedPath.split('.');

  try {
    const message = arr.reduce(
      (accumulator, currentValue) => accumulator[currentValue],
      messages,
    );

    if (message) {
      if (options) {
        const objectKeys = Object.keys(options);
        const messageWithOptions = objectKeys.reduce(
          (accumulator, currentValue) => {
            const optionValue = options[currentValue] || '';
            const prefix =
              typeof optionValue === 'number' ? `=${optionValue}` : optionValue;
            const PHRAZE_REGEXP = new RegExp(
              `${escapeRegExp(prefix)} \\[([^[\\]]+(?=]))\\]`,
            );

            const PHRAZE_FALLBACK_REGEXP = new RegExp(
              'other \\[([^[\\]]+(?=]))\\]',
            );
            let phraze = '';
            const SANITIZE_REGEXP = new RegExp(`{${currentValue}}`, 'g');
            const sanitizedMessage = accumulator.replace(
              SANITIZE_REGEXP,
              optionValue,
            );

            if (sanitizedMessage.match(PHRAZE_REGEXP)) {
              phraze = sanitizedMessage.match(PHRAZE_REGEXP)[1];
            } else if (sanitizedMessage.match(PHRAZE_FALLBACK_REGEXP)) {
              phraze = sanitizedMessage.match(PHRAZE_FALLBACK_REGEXP)[1];
            }

            const phrazeWithNumber = phraze.replace(
              /#/,
              String(options[currentValue]),
            );
            const PLURALIZED_MESSAGE_REGEXP = new RegExp(
              `{${currentValue}, plural, .*?}`,
            );
            const pluralizedMessage = sanitizedMessage.replace(
              PLURALIZED_MESSAGE_REGEXP,
              phrazeWithNumber,
            );

            return pluralizedMessage;
          },
          message,
        );

        memo[optionsKey] = messageWithOptions;

        return messageWithOptions;
      }

      memo[key] = message;

      return message;
    }

    memo[key] = `{${sanitizedPath}}`;

    return `{${sanitizedPath}}`;
  } catch (exception) {
    memo[key] = `{${sanitizedPath}}`;

    return `{${sanitizedPath}}`;
  }
}

export default translate;
