// Making React Context FAST!
// Jack Herrington
// https://www.youtube.com/watch?v=ZKlXqrcBx88

import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export default function createFastContext<Store>(initialState: Store) {
  function useStoreData(): {
    get: () => Store;
    set: (value: Partial<Store>) => void;
    subscribe: (callback: () => void) => () => void;
  } {
    // Holds current state
    const store = useRef(initialState);

    // Holds a set of subscribers (callbacks)
    const subscribers = useRef(new Set<() => void>());

    // Returns current state
    const get = useCallback(() => store.current, []);

    // Sets (part of) state
    // Calls all subscribers
    const set = useCallback((value: Partial<Store>) => {
      store.current = { ...store.current, ...value };
      subscribers.current.forEach((callback) => callback());
    }, []);

    // Adds the callback to the list of subscribers
    // Returns a function that deletes that callback from a list of subscribers
    const subscribe = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      set,
      subscribe,
    };
  }

  type UseStoreDataType = ReturnType<typeof useStoreData>;

  const StoreContext = createContext<UseStoreDataType | null>(null);

  function useStore<SelectorOutput>(
    selector: (store: Store) => SelectorOutput,
  ): [SelectorOutput, (value: Partial<Store>) => void] {
    const store = useContext(StoreContext);

    if (!store) {
      throw Error('No store found.');
    }

    const [state, setState] = useState(selector(store.get()));

    useEffect(() => {
      return store.subscribe(() => setState(selector(store.get())));
    }, []);

    return [state, store.set];
  }

  const StoreProvider = ({ children }: { children?: ReactNode }) => (
    <StoreContext.Provider value={useStoreData()}>
      {children}
    </StoreContext.Provider>
  );

  return {
    useStore,
    StoreProvider,
  };
}
