import classes from 'classnames';
import styles from './Loader.module.scss';

type Props = {
  className?: string;
};

export default function Loader({ className }: Props) {
  return (
    <div className={classes(styles.wrapper, className)}>
      <div className={styles.loader}>
        <div className={styles.circle} />
        <div className={styles.circle} />
      </div>
    </div>
  );
}
