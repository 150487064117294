import { Button, Currency } from '..';
import { ReactComponent as Chevron } from '../../assets/icons/chevron-sharp.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as LogoFooter } from '../../assets/logo-muted.svg';
import { ReactComponent as LogoSmall } from '../../assets/logo-small.svg';
import { memo, ReactNode, useCallback, useState } from 'react';
import {
  Placeholder,
  Progress,
  ProgressBar,
  Typography,
  VideoBanner,
} from '..';
import Calculator from '../Calculator/Calculator';
import classes from 'classnames';
import styles from './Layout.module.scss';
import translate, {
  numberToCurrency,
  numberToPercent,
} from '../../utils/translate';

import { INTRO } from '../../Router/routes';
import { useLocation } from '@reach/router';
import type { Constraints } from '../../hooks/webapi/application/useConstraintsQuery';

export type Props = {
  showSidebar?: boolean;
  steps: Array<string>;
  currentStep: number;
  children: ReactNode;
  showFooter?: boolean;
  showProgress?: boolean;
  dataTest?: string;
  transitionDirection?: 'top' | 'bottom';
  url?: string;
  renderNavigationItems?: () => ReactNode;
  withCalculator?: boolean;
  constraints: Constraints | undefined;
  onAmountChange: (value: number) => void;
  onTermChange: (value: number) => void;
  onCalculatorClose: ({
    amount,
    term,
  }: {
    amount: number;
    term: number;
  }) => void;
  offer?: {
    annualPercentageRate: number;
    agreementEndDate: string;
    interestRate: number;
    newInterestBeforeDiscount: number;
    newPrincipal: number;
    term: number;
    totalInterest: number;
    totalRepayableAmount: number;
    totalPrincipal: number;
    newInterest: number;
  };
  loading?: boolean;
  calculatorAmount?: number;
  calculatorTerm?: number;
};

export default memo(function Layout({
  steps,
  currentStep,
  children,
  offer,
  showSidebar = true,
  showFooter = false,
  showProgress = true,
  dataTest,
  transitionDirection,
  url,
  renderNavigationItems,
  withCalculator = false,
  constraints,
  loading,
  onAmountChange,
  onTermChange,
  onCalculatorClose,
  calculatorAmount,
  calculatorTerm,
}: Props) {
  const [showCalculator, setShowCalculator] = useState(false);
  const [prevCalculator, setPrevCalculator] = useState<{
    amount: number;
    term: number;
  }>();
  const location = useLocation();
  const shouldRenderVideoBanner = location.pathname === INTRO;

  const handleOnCalculatorOpen = useCallback(() => {
    if (offer) {
      setPrevCalculator({ amount: offer.newPrincipal, term: offer.term });
    }

    setShowCalculator(true);
  }, [offer]);

  const handleOnCalculatorConfirm = useCallback(() => {
    setShowCalculator(false);
  }, []);

  const handleOnCalculatorClose = useCallback(() => {
    setShowCalculator(false);

    if (
      prevCalculator &&
      (prevCalculator.amount !== offer?.newPrincipal ||
        prevCalculator.term !== offer?.term)
    ) {
      onCalculatorClose(prevCalculator);
    }
  }, [onCalculatorClose, prevCalculator, offer]);

  const toggleCalculator = useCallback(() => {
    if (showCalculator) {
      handleOnCalculatorClose();
    } else {
      handleOnCalculatorOpen();
    }
  }, [handleOnCalculatorOpen, handleOnCalculatorClose, showCalculator]);

  return (
    <>
      {shouldRenderVideoBanner && (
        <VideoBanner
          classNameContainer={styles['video-banner-wrapper']}
          content={{
            title: 'identification.video_banner_title',
            description: 'identification.video_banner_description',
            link: 'identification.video_banner_link',
          }}
        />
      )}

      <div
        data-test={dataTest}
        className={classes(
          styles.wrapper,
          !showSidebar && styles['no-sidebar'],
        )}
      >
        {showSidebar && (
          <aside className={styles.sidebar}>
            <div className={styles['sidebar-wrapper']}>
              <section className={styles.section}>
                <Typography size="m">
                  {translate('common.loan_overview')}
                </Typography>
                <hr className={styles.hr} />
                <Currency
                  loading={loading}
                  amount={offer?.newPrincipal}
                  amountClassName={styles.value}
                  unitClassName={styles.unit}
                  dataTest="sidebar-amount"
                />
                <Typography size="xs">{translate('common.loan')}</Typography>
              </section>

              <section className={styles.section}>
                <p className={styles.value}>
                  <Placeholder
                    dataTest="sidebar-term"
                    loading={loading}
                    placeholder="##"
                  >
                    {offer?.term}
                  </Placeholder>{' '}
                  <span className={styles.unit}>
                    {translate('common.days')}
                  </span>
                </p>
                <Typography size="xs">{translate('common.term')}</Typography>
              </section>

              {withCalculator && (
                <Button
                  disabled={showCalculator || !constraints}
                  onClick={handleOnCalculatorOpen}
                  size="m"
                  wide
                  outline
                  className={styles.edit}
                  dataTest="sidebar-btn-edit-calculator"
                >
                  {translate('common.edit')}
                </Button>
              )}

              <hr className={styles.hr} />

              <section className={styles.section}>
                <Typography size="xs">
                  {translate('common.interest')}
                </Typography>
                <p className={styles['value-small']}>
                  <Placeholder
                    dataTest="sidebar-new-interest-before-discount"
                    loading={loading}
                    placeholder="# Kč"
                  >
                    {numberToCurrency(offer?.newInterestBeforeDiscount)}
                  </Placeholder>
                </p>
              </section>

              <section className={styles.section}>
                <Typography size="xs">{translate('common.apr')}</Typography>
                <p className={styles['value-small']}>
                  <Placeholder
                    dataTest="sidebar-interest-rate"
                    loading={loading}
                    placeholder="# %"
                  >
                    {numberToPercent(offer?.interestRate)}
                  </Placeholder>
                </p>
              </section>

              <hr className={styles.hr} />

              {showFooter && (
                <footer className={styles.footer}>
                  <LogoFooter />
                  <Typography size="xs" className={styles['footer-text']}>
                    {translate('common.footer_text')}
                  </Typography>
                </footer>
              )}
            </div>
          </aside>
        )}

        <div className={styles.progress}>
          <div className={styles['progress-wrapper']}>
            {withCalculator && constraints && offer && (
              <div
                data-test="calculator"
                className={classes(
                  styles.calculator,
                  showCalculator && styles.opened,
                )}
              >
                <Calculator
                  loading={loading}
                  constraints={constraints}
                  offer={offer}
                  onCloseCalculator={handleOnCalculatorClose}
                  onAmountChange={onAmountChange}
                  onTermChange={onTermChange}
                  onConfirm={handleOnCalculatorConfirm}
                  calculatorAmount={calculatorAmount}
                  calculatorTerm={calculatorTerm}
                />
              </div>
            )}

            <div className={styles.desktop}>
              <Logo className={styles.logo} />
              {showProgress && (
                <Progress steps={steps} currentStep={currentStep} />
              )}
            </div>
          </div>
          <div className={styles.mobile}>
            <div className={styles.header}>
              {showProgress && (
                <ProgressBar
                  steps={steps.length}
                  currentStep={currentStep}
                  className={styles['progress-bar']}
                />
              )}
              <LogoSmall className={styles['small-logo']} />
            </div>
            {showSidebar && (
              <div className={styles.details}>
                <div className={styles['details-cell']}>
                  <Typography size="xs">{translate('common.loan')}</Typography>
                  <Currency
                    loading={loading}
                    amount={offer?.newPrincipal}
                    amountClassName={styles.value}
                    unitClassName={styles.unit}
                  />
                </div>
                <div className={styles['details-cell']}>
                  <Typography size="xs">{translate('common.term')}</Typography>
                  <p className={styles.value}>
                    <Placeholder loading={loading} placeholder="##">
                      {offer?.term}
                    </Placeholder>{' '}
                    <span className={styles.unit}>
                      {translate('common.days')}
                    </span>
                  </p>
                </div>
                {withCalculator && constraints && offer && (
                  <div
                    data-test="calculator-mobile-edit"
                    onClick={toggleCalculator}
                    className={styles['edit-mobile']}
                  >
                    <Chevron
                      className={classes(
                        styles.chevron,
                        showCalculator && styles.opened,
                      )}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          className={classes(
            styles.content,
            showCalculator ? styles.blur : styles['no-blur'],
          )}
        >
          <nav className={styles.nav}>
            {renderNavigationItems && renderNavigationItems()}
          </nav>
          <main
            className={classes(
              styles.main,
              shouldRenderVideoBanner && styles['video-banner-spacing'],
            )}
          >
            <div
              key={url}
              className={
                transitionDirection === 'top'
                  ? styles['top-fade-in']
                  : styles['bottom-fade-in']
              }
            >
              {children}
            </div>
          </main>
        </div>

        {showFooter && (
          <footer className={styles['footer-mobile']}>
            <div className={styles['nav-mobile-wrapper']}>
              <LogoFooter className={styles['logo-mobile']} />
              <nav className={styles['nav-mobile']}>
                {renderNavigationItems && renderNavigationItems()}
              </nav>
            </div>
            <Typography className={styles['footer-mobile-text']}>
              {translate('common.footer_text')}
            </Typography>
          </footer>
        )}
      </div>
    </>
  );
});
