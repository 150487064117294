import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';
import { memo } from 'react';
import styles from './HelpInfo.module.scss';
import Typography from '../Typography/Typography';
export type Props = {
  title: string;
  text: string;
  className?: string;
  dataTest?: string;
};

export default memo(function HelpInfo({
  title,
  text,
  className,
  dataTest,
}: Props) {
  return (
    <div className={styles['help-wrapper']} data-test={dataTest}>
      <div className={styles.icon}>
        <HelpIcon />
      </div>
      <div className={styles['help-info-wrapper']}>
        <Typography kind="h1" size="s">
          {title}
        </Typography>
        <p
          className={styles['information-text']}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
    </div>
  );
});
