import { fetchData } from '../../utils';
import { useCallback, useMemo } from 'react';
import useError from '../useError';
import useSmartlook from '../useSmartlook';

const usePhoneVerification = () => {
  const { showError } = useError();
  const { error } = useSmartlook();

  const requestPhoneVerification = useCallback(
    async ({ body }) => {
      try {
        const data = await fetchData('/client/phone', {
          method: 'post',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        error('[usePhoneVerification][requestPhoneVerification]', e);
        showError();
        throw e;
      }
    },
    [showError],
  );

  const changePhoneWithVerification = useCallback(
    async ({ body }, recaptchaToken?: string | null) => {
      try {
        const data = await fetchData(
          '/client/phone',
          {
            method: 'put',
            body: JSON.stringify(body),
          },
          { responseToken: recaptchaToken },
        );

        return data;
      } catch (e) {
        error('[usePhoneVerification][changePhoneWithVerification]', e);
        if (e.name !== 'ValidationError') {
          showError();
        }
        throw e;
      }
    },
    [showError],
  );

  const sendCode = useCallback(
    async ({ body }) => {
      try {
        const data = await fetchData('/client/phone-confirmation', {
          method: 'put',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        error('[usePhoneVerification][sendCode]', e);
        if (e.name !== 'ValidationError') {
          showError();
        }
        throw e;
      }
    },
    [showError],
  );

  return useMemo(() => {
    return {
      changePhoneWithVerification,
      requestPhoneVerification,
      sendCode,
    };
  }, [changePhoneWithVerification, requestPhoneVerification, sendCode]);
};

export default usePhoneVerification;
