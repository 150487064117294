const data = [
  '17. Listopadu',
  '17. Listopadu',
  '28. Pluku',
  '28. Října',
  '28. Října',
  '5. Května',
  '5. Května',
  '5. Máje',
  '7. Května',
  '8. Listopadu',
  '9. Května',
  'Achátová',
  'Adamova',
  'Adamovská',
  'Adélčina',
  'Africká',
  'Akademická',
  'Aksamitova',
  'Akátová',
  'Alabastrová',
  'Albertov',
  'Albrechtická',
  'Albánská',
  'Albíny Hochové',
  'Aldašínská',
  'Alej Českých Exulantů',
  'Aleny Santarové',
  'Aloisovská',
  'Aloisovská',
  'Aloisovská',
  'Altajská',
  'Alšovo Nábř.',
  'Alšovo Nábřeží',
  'Alšovy Sady',
  'Alžírská',
  'Ambrožova',
  'Americká',
  'Ametystová',
  'Amforová',
  'Amortova',
  'Ampérova',
  'Amurská',
  'Anastázova',
  'Anderleho',
  'Andersenova',
  'Andrštova',
  'Andělova',
  'Anenská',
  'Anenské Nám.',
  'Anenské Náměstí',
  'Anežky Malé',
  'Anežská',
  'Angelovova',
  'Anglická',
  'Angolská',
  'Anhaltova',
  'Ankarská',
  'Anny Drabíkové',
  'Anny Letenské',
  'Anny Rybníčkové',
  'Anny Čížkové',
  'Anny Čížkové',
  'Antala Staška',
  'Antonína Hodného',
  'Antonína Čermáka',
  'Antonínská',
  'Anýzová',
  'Apolinářská',
  'Arabská',
  'Aranžérská',
  'Arbesovo Nám.',
  'Arbesovo Náměstí',
  'Archangelská',
  'Archeologická',
  'Archimédova',
  'Archivní',
  'Argentinská',
  'Aristotelova',
  'Arkalycká',
  'Armádní',
  'Armádního Sboru',
  'Armády',
  'Arménská',
  'Arnošta Valenty',
  'Astlova',
  'Athénská',
  'Atletická',
  'Aubrechtové',
  'Augustinova',
  'Augustova',
  'Austova',
  'Aviatická',
  'Axmanova',
  'Azalková',
  'Azuritová',
  'Ašská',
  'Baarova',
  'Babická',
  'Babiččina',
  'Babočková',
  'Babská',
  'Babylonská',
  'Babákova',
  'Bachmačské Nám.',
  'Bachmačské Náměstí',
  'Bachova',
  'Bacháčkova',
  'Badeniho',
  'Badeniho',
  'Bajgarova',
  'Bajkalská',
  'Bajkonurská',
  'Bakalářská',
  'Bakovská',
  'Bakurinova',
  'Balabánova',
  'Balbínova',
  'Banskobystrická',
  'Baranova',
  'Barchovická',
  'Barešova',
  'Barrandova',
  'Barrandovská',
  'Bartolomějská',
  'Bartoňkova',
  'Bartoňova',
  'Bartoškova',
  'Bartoškova',
  'Bartoškova',
  'Bartákova',
  'Bartůňkova',
  'Barunčina',
  'Barvířská',
  'Barákova',
  'Basilejské Nám.',
  'Basilejské Náměstí',
  'Bassova',
  'Batelovská',
  'Batličkova',
  'Bavorovská',
  'Bavorská',
  'Bazalková',
  'Bazovského',
  'Bačetínská',
  'Baňská',
  'Baškirská',
  'Bašteckého',
  'Baštýřská',
  'Bažantní',
  'Beaufortova',
  'Bechlínská',
  'Bechyňova',
  'Bechyňská',
  'Beckovská',
  'Bedlová',
  'Bednářská',
  'Bedrnova',
  'Bedřichovská',
  'Beethovenova',
  'Beldova',
  'Belgická',
  'Bellova',
  'Bellušova',
  'Bendlova',
  'Bendova',
  'Benecká',
  'Benediktská',
  'Benešovská',
  'Benická',
  'Benkova',
  'Benákova',
  'Benátská',
  'Benáčanova',
  'Beníškové',
  'Beranových',
  'Bergerova',
  'Bergmanova',
  'Berkovská',
  'Berlínská',
  'Bermanova',
  'Bernartická',
  'Bernolákova',
  'Berounská',
  'Bertrámová',
  'Berylová',
  'Besední',
  'Beskydská',
  'Betlémská',
  'Betlémské Nám.',
  'Betlémské Náměstí',
  'Betáňská',
  'Bezdrevská',
  'Bezděkovská',
  'Bezinková',
  'Bezová',
  'Bezprašná',
  'Bečovská',
  'Bečvářova',
  'Bečvářská',
  'Bečvářská',
  'Beřkovická',
  'Bešťákova',
  'Bieblova',
  'Binarova',
  'Biskupcova',
  'Biskupská',
  'Biskupský Dvůr',
  'Blachutova',
  'Blahníkova',
  'Blahoslavova',
  'Blanická',
  'Blatenská',
  'Blatnická',
  'Blatovská',
  'Blatská',
  'Blattného',
  'Blažimská',
  'Blažkova',
  'Blažíčkova',
  'Blešnovská',
  'Blodkova',
  'Bludovická',
  'Blériotova',
  'Blšanecká',
  'Bobkova',
  'Bochovská',
  'Bodláková',
  'Bohdalec',
  'Bohdalec',
  'Bohdalecká',
  'Bohdalecká',
  'Bohdanečská',
  'Bohdašínská',
  'Bohnická',
  'Bohrova',
  'Bohumínská',
  'Bohuslava Martinů',
  'Bohuslava Martinů',
  'Bohuslava Ze Švamberka',
  'Bohuslavická',
  'Bohušovická',
  'Bohušovická',
  'Boháčova',
  'Bohúňova',
  'Bojanovická',
  'Bojasova',
  'Bojetická',
  'Boješická',
  'Bojkovická',
  'Bojovská',
  'Bojínková',
  'Bojčenkova',
  'Bolebořská',
  'Boleratická',
  'Boleslavova',
  'Boleslavská',
  'Boletická',
  'Bolevecká',
  'Bolinská',
  'Boloňská',
  'Bolzanova',
  'Bolívarova',
  'Borecká',
  'Borečkova',
  'Borodinská',
  'Borotínská',
  'Borovanská',
  'Borovanského',
  'Borovnická',
  'Borovská',
  'Borová',
  'Borošova',
  'Borská',
  'Borského',
  'Boršov',
  'Boršovská',
  'Borůvková',
  'Boseňská',
  'Botevova',
  'Botičská',
  'Botičská',
  'Boudova',
  'Bousovská',
  'Boučkova',
  'Bouřilova',
  'Boušova',
  'Bozděchova',
  'Boční I',
  'Boční Ii',
  'Bořanovická',
  'Bořetická',
  'Bořetínská',
  'Bořivojova',
  'Bořivojova',
  'Boříkova',
  'Bošická',
  'Bošilecká',
  'Bošínská',
  'Božanovská',
  'Božecká',
  'Božejovická',
  'Boženy Hofmeisterové',
  'Boženy Jandlové',
  'Boženy Němcové',
  'Boženy Němcové',
  'Boženy Stárkové',
  'Božetická',
  'Božetěchova',
  'Božkova',
  'Božkovská',
  'Božídarská',
  'Brabcova',
  'Bramboříková',
  'Branaldova',
  'Brandejsova',
  'Brandejsovo Nám.',
  'Brandejsovo Náměstí',
  'Brandlova',
  'Brandýská',
  'Branická',
  'Branická',
  'Branické Nám.',
  'Branické Náměstí',
  'Branislavova',
  'Branišovská',
  'Branská',
  'Bratislavská',
  'Bratranců Veverkových',
  'Bratří Dohalských',
  'Bratří Venclíků',
  'Bratří Čapků',
  'Bratříkovská',
  'Braunerova',
  'Braunova',
  'Braškovská',
  'Brdecká',
  'Brdičkova',
  'Brdlíkova',
  'Brechtova',
  'Brechtova',
  'Brehmova',
  'Breitcetlova',
  'Brichtova',
  'Brigádnická',
  'Brigádníků',
  'Brixiho',
  'Brodecká',
  'Brodecká',
  'Brodského',
  'Bromova',
  'Bronzová',
  'Broskvoňová',
  'Broumarská',
  'Broumovská',
  'Brozánská',
  'Brožíkova',
  'Brtecká',
  'Brtnická',
  'Brumovická',
  'Brunclíkova',
  'Brunelova',
  'Brunnerova',
  'Bruselská',
  'Brusinková',
  'Bruslařská',
  'Bryksova',
  'Brzická',
  'Brzorádových',
  'Brázdimská',
  'Brňovská',
  'Bubenečská',
  'Bubenečská',
  'Bubenská',
  'Bubenské Nábř.',
  'Bubenské Nábřeží',
  'Bubeníčkova',
  'Bublavská',
  'Bublíkova',
  'Bubnova',
  'Bucharova',
  'Buchlovská',
  'Buchovcova',
  'Budapešťská',
  'Budečská',
  'Budilova',
  'Budilovská',
  'Budovatelská',
  'Budyňská',
  'Budyšínská',
  'Budínova',
  'Budčická',
  'Budějovická',
  'Budějovická',
  'Bukolská',
  'Bukovecká',
  'Bukovinská',
  'Buková',
  'Bulharská',
  'Buližníková',
  'Bulovka',
  'Burdova',
  'Burešova',
  'Burianova',
  'Butovická',
  'Butovická',
  'Buzulucká',
  'Buštěhradská',
  'Bydhošťská',
  'Bydžovská',
  'Bydžovského',
  'Bylanská',
  'Bystrá',
  'Bystřická',
  'Bystřičná',
  'Byšická',
  'Byškovická',
  'Bzenecká',
  'Bártlova',
  'Bášťská',
  'Bílenecké Nám.',
  'Bílenecké Náměstí',
  'Bílinská',
  'Bílkova',
  'Bílkova',
  'Bílovská',
  'Bílá',
  'Bílčická',
  'Bínova',
  'Bítovská',
  'Böhmova',
  'Býšovská',
  'Běchorská',
  'Běchovická',
  'Běhounkova',
  'Bělehradská',
  'Bělehradská',
  'Bělehradská',
  'Bělečská',
  'Bělinského',
  'Bělocerkevská',
  'Bělocká',
  'Bělohorská',
  'Bělohorská',
  'Bělomlýnská',
  'Bělomlýnská',
  'Běloveská',
  'Běluňská',
  'Bělušická',
  'Bělásková',
  'Bělčická',
  'Bělčická',
  'Běžecká',
  'Běžná',
  'Břeclavská',
  'Břehová',
  'Břehová',
  'Břetislavova',
  'Břevnovská',
  'Březanova',
  'Březecká',
  'Březenská',
  'Březinova',
  'Březiněveská',
  'Březnická',
  'Březnová',
  'Březovická',
  'Březovského',
  'Březová',
  'Břečťanová',
  'Břežanská',
  'Břežánecká',
  'Břidlicová',
  'Břidličná',
  'Břízova',
  'Bříšťanská',
  'Cafourkova',
  'Cedrová',
  'Celetná',
  'Celniční',
  'Celsiova',
  'Cementářská',
  'Ceplechova',
  'Cerhenická',
  'Cerhýnská',
  'Cetyňská',
  'Chabařovická',
  'Chaberská',
  'Chabeřická',
  'Chabská',
  'Chalabalova',
  'Chaloupeckého',
  'Chaloupky',
  'Chaltická',
  'Chalupkova',
  'Chalupnická',
  'Chaplinovo Nám.',
  'Chaplinovo Náměstí',
  'Charkovská',
  'Charlese De Gaulla',
  'Charvátova',
  'Chatařská',
  'Chatová',
  'Chebská',
  'Chelčického',
  'Chemická',
  'Chilská',
  'Chittussiho',
  'Chladírenská',
  'Chlebovická',
  'Chlumecká',
  'Chlumecká',
  'Chlumecká',
  'Chlumova',
  'Chlumínská',
  'Chlumčanského',
  'Chlupova',
  'Chlupáčova',
  'Chládkova',
  'Chmelařská',
  'Chmelická',
  'Chmelová',
  'Chmelířova',
  'Choceradská',
  'Choceňská',
  'Chocholouškova',
  'Chocholova',
  'Chodecká',
  'Chodovecké Nám.',
  'Chodovecké Náměstí',
  'Chodovická',
  'Chodovská',
  'Chodovská',
  'Chodovská',
  'Chodská',
  'Cholupická',
  'Chomutovická',
  'Chomutovská',
  'Chopinova',
  'Choratická',
  'Chorošová',
  'Chorušická',
  'Chorvatská',
  'Chotečská',
  'Chotkova',
  'Chotouchovská',
  'Chotouňská',
  'Chotovická',
  'Chotutická',
  'Chotěbuzská',
  'Chotěnovská',
  'Chotětovská',
  'Chotěšovská',
  'Chovatelská',
  'Chrastavská',
  'Chrobolská',
  'Chrpová',
  'Chrudimská',
  'Chráněná',
  'Chrášťanská',
  'Chuchelská',
  'Chudenická',
  'Chudoměřická',
  'Churnajevova',
  'Churáňovská',
  'Chvaletická',
  'Chvaletická',
  'Chvalečská',
  'Chvalkovická',
  'Chvalova',
  'Chvalská',
  'Chvalská',
  'Chvalšovická',
  'Chvatěrubská',
  'Chvojenecká',
  'Chyjická',
  'Chýnická',
  'Chýnovská',
  'Chýňská',
  'Chřibská',
  'Cibulka',
  'Cidlinská',
  'Cigánkova',
  'Cihelná',
  'Cihlářova',
  'Cihlářská',
  'Cimburkova',
  'Ciolkovského',
  'Cirkusová',
  'Cisterciácká',
  'Citolibská',
  'Coriových',
  'Ctiborova',
  'Ctiněveská',
  'Ctiradova',
  'Ctěnická',
  'Cukerní',
  'Cukrovarnická',
  'Cukrovarská',
  'Cuřínova',
  'Cvikovská',
  'Cvičebná',
  'Cvrčkova',
  'Cvrčkova',
  'Cvrčkova',
  'Cyprichova',
  'Cíglerova',
  'Cílkova',
  'Cínovecká',
  'Církova',
  'Církvická',
  'Církvičná',
  'Císařská Louka',
  'Císařský Ostrov',
  'Císařský Ostrov',
  'Císařský Ostrov',
  'Cítovská',
  'Daimlerova',
  'Dalejská',
  'Dalejská',
  'Dalešická',
  'Daliborova',
  'Dalimilova',
  'Dalovická',
  'Dandova',
  'Danielova',
  'Dany Medřické',
  'Darwinova',
  'Dasnická',
  'Davelská',
  'Davidovičova',
  'Davídkova',
  'Davídkova',
  'Dačická',
  'Dačického',
  'Daňkova',
  'Dašická',
  'Daškova',
  'Dehtínská',
  'Dejvická',
  'Dejvická',
  'Demlova',
  'Demoliční',
  'Desenská',
  'Destinnové',
  'Destinové',
  'Devonská',
  'Deylova',
  'Deštná',
  'Dešťová',
  'Diabasová',
  'Diamantová',
  'Diblíkova',
  'Diblíkova',
  'Dienzenhoferovy Sady',
  'Dieselova',
  'Diskařská',
  'Diskařská',
  'Dismanova',
  'Dittrichova',
  'Divadelní',
  'Divadelní',
  'Divecká',
  'Diviznová',
  'Divišova',
  'Divišovská',
  'Divoká Šárka',
  'Divoká Šárka',
  'Dlabačov',
  'Dlabačov',
  'Dlouhá',
  'Dlážděná',
  'Do Blatin',
  'Do Borovin',
  'Do Chuchle',
  'Do Dolnic',
  'Do Dubin',
  'Do Dubče',
  'Do Hlinek',
  'Do Klukovic',
  'Do Kopečka',
  'Do Koutů',
  'Do Koutů',
  'Do Lipan',
  'Do Lipin',
  'Do Lipin',
  'Do Luk',
  'Do Panenek',
  'Do Podkovy',
  'Do Polí',
  'Do Potoků',
  'Do Píšovic',
  'Do Roklí',
  'Do Rybníčků',
  'Do Svépravic',
  'Do Vozovny',
  'Do Vrchu',
  'Do Vršku',
  'Do Zahrádek I',
  'Do Zahrádek I',
  'Do Zahrádek I',
  'Do Zahrádek Ii',
  'Do Zahrádek Ii',
  'Do Zátiší',
  'Do Údolí',
  'Do Újezda',
  'Do Čertous',
  'Do Čtvrti',
  'Do Říčan',
  'Dobevská',
  'Dobnerova',
  'Dobratická',
  'Dobronická',
  'Dobronická',
  'Dobropolská',
  'Dobrovická',
  'Dobrovolného',
  'Dobrovolského',
  'Dobrovského',
  'Dobrovízská',
  'Dobročovická',
  'Dobrošovská',
  'Dobrušská',
  'Dobřanská',
  'Dobřejovická',
  'Dobřenická',
  'Dobřichovská',
  'Dobšická',
  'Dobšínská',
  'Dohalická',
  'Doksanská',
  'Dolanská',
  'Dolejškova',
  'Doležalova',
  'Dolina',
  'Dolnobranská',
  'Dolnobřežanská',
  'Dolnocholupická',
  'Dolnojirčanská',
  'Dolnokrčská',
  'Dolnokřeslická',
  'Dolnomlýnská',
  'Dolnoměcholupská',
  'Dolnoměcholupská',
  'Dolnopočernická',
  'Dolnočernošická',
  'Dolní',
  'Dolní',
  'Dolní Chaloupky',
  'Dolomitová',
  'Dolská',
  'Dolákova',
  'Dolínecká',
  'Dolňanská',
  'Domanovická',
  'Domašínská',
  'Domažlická',
  'Dominova',
  'Dominínská',
  'Domkovská',
  'Domkářská',
  'Domousnická',
  'Donatellova',
  'Donovalská',
  'Donská',
  'Donátova',
  'Donínská',
  'Dopplerova',
  'Dopravní',
  'Dopraváků',
  'Dopraváků',
  'Dostihová',
  'Dostojevského',
  'Doubecká',
  'Doubická',
  'Doubravická',
  'Doubravská',
  'Doubravínova',
  'Doubravčická',
  'Doudlebská',
  'Doudova',
  'Doupovská',
  'Dr. Marodyho',
  'Dr. Zikmunda Wintra',
  'Dr.Zikmunda Wintra',
  'Dragounská',
  'Drahanská',
  'Drahanská',
  'Drahelická',
  'Drahelčická',
  'Drahobejlova',
  'Drahorádova',
  'Drahotická',
  'Drahotínská',
  'Drahovská',
  'Drahovská',
  'Drahoňovského',
  'Draženovská',
  'Draženovská',
  'Dražetická',
  'Dražická',
  'Dražického',
  'Dražického Nám.',
  'Dražického Náměstí',
  'Dražkovská',
  'Dreyerova',
  'Drimlova',
  'Drnovská',
  'Drobná',
  'Drtikolova',
  'Drtinova',
  'Druhanická',
  'Druhého Odboje',
  'Družicová',
  'Družnosti',
  'Družná',
  'Družstevní',
  'Družstevní Ochoz',
  'Družstevní Ochoz',
  'Drážní',
  'Drůbežnická',
  'Drůbežářská',
  'Dubanská',
  'Dubenecká',
  'Dubečská',
  'Dubečské Horky',
  'Dubinská',
  'Dubnická',
  'Dubnova',
  'Dubovická',
  'Dubová',
  'Dubrovnická',
  'Dubská',
  'Duchcovská',
  'Duchoslávka',
  'Dudkova',
  'Dudínská',
  'Duhová',
  'Dukelská',
  'Dukelských Hrdinů',
  'Dunajevského',
  'Dunajská',
  'Dunická',
  'Dunovského',
  'Durychova',
  'Durychova',
  'Dusíkova',
  'Duškova',
  'Duškova',
  'Dušní',
  'Dušní',
  'Dvorecká',
  'Dvorecké Nám.',
  'Dvorecké Náměstí',
  'Dvorní',
  'Dvorská',
  'Dvoudílná',
  'Dvouletky',
  'Dvouramenná',
  'Dvořeckého',
  'Dvořišťská',
  'Dvořákova',
  'Dvořákovo Nábř.',
  'Dvořákovo Nábřeží',
  'Dygrýnova',
  'Dyjská',
  'Dykova',
  'Dářská',
  'Dürerova',
  'Dýšinská',
  'Děbolínská',
  'Dědická',
  'Dědinova',
  'Dědinská',
  'Děkanská',
  'Děkanská Vinice I',
  'Děkanská Vinice Ii',
  'Dělená',
  'Dělnická',
  'Dělostřelecká',
  'Dětenická',
  'Dětská',
  'Dětský Ostrov',
  'Děvínská',
  'Děčínská',
  'Děčínská',
  'Dřevařská',
  'Dřevnická',
  'Dřevná',
  'Dřevčická',
  'Dřínovská',
  'Dřínová',
  'Dřítenská',
  'Eberlova',
  'Ebrova',
  'Edisonova',
  'Edvardova',
  'Egyptská',
  'Eichlerova',
  'Einsteinova',
  'Ejpovická',
  'Ekonomická',
  'Eledrova',
  'Elektrárenská',
  'Eliášova',
  'Eliášova',
  'Elišky Junkové',
  'Elišky Krásnohorské',
  'Elišky Krásnohorské',
  'Elišky Peškové',
  'Elišky Přemyslovny',
  'Ellnerové',
  'Elsnicovo Náměstí',
  'Emilie Hyblerové',
  'Emlerova',
  'Engelmüllerova',
  'Engelova',
  'Engelova',
  'Englerova',
  'Erbenova',
  'Erbenova',
  'Estonská',
  'Etiopská',
  'Euklidova',
  'Evropská',
  'Evropská',
  'Evropská',
  'Evropská',
  'Evropská',
  'Evy Olmerové',
  'Exnárova',
  'F.V.Veselého',
  'Fabiánova',
  'Fabiánská',
  'Fadějevova',
  'Fajmanové',
  'Fajtlova',
  'Falcká',
  'Faltysova',
  'Famfulíkova',
  'Fantova',
  'Faradayova',
  'Farkašova',
  'Farní',
  'Farská',
  'Farského',
  'Fastrova',
  'Federova',
  'Fejfarova',
  'Felberova',
  'Fenyklová',
  'Fetrovská',
  'Feřtekova',
  'Fialková',
  'Fibichova',
  'Fikerova',
  'Filipova',
  'Filipovského',
  'Filipíny Welserové',
  'Fillova',
  'Filmařská',
  'Filosofská',
  'Fingerova',
  'Finkovská',
  'Finská',
  'Firkušného',
  'Fischlova',
  'Fišerova',
  'Flemingovo Nám.',
  'Flemingovo Náměstí',
  'Flájská',
  'Flöglova',
  'Foerstrova',
  'Folmavská',
  'Formanská',
  'Formánkova',
  'Fořtova',
  'Fragnerova',
  'Francouzská',
  'Francouzská',
  'Francouzská',
  'Františka Diviše',
  'Františka Jansy',
  'Františka Kadlece',
  'Františka Křížka',
  'Františka Černého',
  'Františka Červeného',
  'Františka Šimáčka',
  'Františkova',
  'Franty Kocourka',
  'Frančíkova',
  'Freiwaldova',
  'Freyova',
  'Frimlova',
  'Fričova',
  'Froncova',
  'Frostova',
  'Froňkova',
  'Frydrychova',
  'Fryčovická',
  'Fráni Šrámka',
  'Frézařská',
  'Frýdecká',
  'Frýdlantská',
  'Fuchsova',
  'Fügnerovo Nám.',
  'Fügnerovo Náměstí',
  'Gabinova',
  'Gabčíkova',
  'Gagarinova',
  'Galandova',
  'Galileova',
  'Gallašova',
  'Galvaniho',
  'Gaussova',
  'Gdaňská',
  'Generála Janouška',
  'Generála Mejstříka',
  'Generála Píky',
  'Generála Šišky',
  'Generála Šišky',
  'Gensovská',
  'Geologická',
  'Gercenova',
  'Gerstnerova',
  'Ginzova',
  'Glazunovova',
  'Glinkova',
  'Glowackého',
  'Goetheho',
  'Gogolova',
  'Golfová',
  'Gollova',
  'Golčova',
  'Gončarenkova',
  'Gončarenkova',
  'Gorazdova',
  'Gotthardská',
  'Goyova',
  'Gočárova',
  'Grafická',
  'Grafitová',
  'Grammova',
  'Granátová',
  'Gregorova',
  'Grussova',
  'Gruzínská',
  'Gutfreundova',
  'Gutova',
  'Gymnasijní',
  'Gymnastická',
  'Habartická',
  'Habartická',
  'Habartovská',
  'Haberfeldova',
  'Habrovská',
  'Habrová',
  'Habřická',
  'Habřická',
  'Hackerova',
  'Hadovitá',
  'Hadravská',
  'Hajní',
  'Hakenova',
  'Halasova',
  'Halenkovská',
  'Halštatská',
  'Hamerská',
  'Hamplova',
  'Hamrová',
  'Hamsíkova',
  'Hankova',
  'Hanouškova',
  'Hanusova',
  'Hanušova',
  'Hanzelkova',
  'Hanzlíkova',
  'Harantova',
  'Harcovská',
  'Harlacherova',
  'Harmonická',
  'Harrachovská',
  'Hartenberská',
  'Hasičská',
  'Hasičů',
  'Hasova',
  'Hastrmanská',
  'Haunerova',
  'Hauptova',
  'Hausmannova',
  'Havanská',
  'Havelská',
  'Havelská Ulička',
  'Havlovického',
  'Havlovického',
  'Havlovská',
  'Havlínova',
  'Havlíčkova',
  'Havlíčkovo Nám.',
  'Havlíčkovo Náměstí',
  'Havlíčkovy Sady',
  'Havlůjové',
  'Havlůjové',
  'Havranická',
  'Havraní',
  'Havránkova',
  'Havířovská',
  'Havířská',
  'Haškova',
  'Hašlerova',
  'Haštalská',
  'Haštalské Nám.',
  'Haštalské Náměstí',
  'Heckelova',
  'Heineho',
  'Heinemannova',
  'Hejnická',
  'Hejnická',
  'Hejplíkova',
  'Hejtmanská',
  'Hejtmánkova',
  'Hekova',
  'Hekrova',
  'Heldova',
  'Heleny Malířové',
  'Hellichova',
  'Helmova',
  'Helsinská',
  'Helénská',
  'Hennerova',
  'Heranova',
  'Herbenova',
  'Herdovská',
  'Herlíkovická',
  'Hermanická',
  'Hermelínská',
  'Hermíny Týrlové',
  'Heroldovy Sady',
  'Herrmannova',
  'Herrova',
  'Hertzova',
  'Herálecká I',
  'Herálecká Ii',
  'Herálecká Iii',
  'Herálecká Iv',
  'Herčíkova',
  'Hevlínská',
  'Heydukova',
  'Heyrovského Nám.',
  'Heyrovského Nám.',
  'Heyrovského Náměstí',
  'Heyrovského Náměstí',
  'Hečkova',
  'Heřmanova',
  'Heřmánková',
  'Hildy Čihákové',
  'Hillebrantova',
  'Hilmarova',
  'Hiršlova',
  'Hlavatého',
  'Hlavenecká',
  'Hlavní',
  'Hlavova',
  'Hlaváčkova',
  'Hlaváčova',
  'Hlaďova',
  'Hledíková',
  'Hlinská',
  'Hlivická',
  'Hlohová',
  'Hloubětínská',
  'Hloubětínská',
  'Hlubocká',
  'Hluboká',
  'Hlubočepská',
  'Hlušičkova',
  'Hládkov',
  'Hládkov',
  'Hlávkova',
  'Hněvkovská',
  'Hněvkovského',
  'Hnězdenská',
  'Hoblířská',
  'Hodkovická',
  'Hodkovská',
  'Hodonínská',
  'Hodčina',
  'Hodějovská',
  'Hodějovská',
  'Hoděšovická',
  'Hofbauerova',
  'Hoffmannova',
  'Hokejová',
  'Hokešovo Nám.',
  'Hokešovo Náměstí',
  'Holandská',
  'Holekova',
  'Holenická',
  'Holenská',
  'Holečkova',
  'Holečkova',
  'Holešovické Nábřeží',
  'Holešovický Přístav',
  'Holická',
  'Hollarovo Nám.',
  'Hollarovo Náměstí',
  'Holohlavská',
  'Holotínská',
  'Holoubkova',
  'Holoubkovská',
  'Holubická',
  'Holubinková',
  'Holubkova',
  'Holubova',
  'Holubí',
  'Holušická',
  'Holyňská',
  'Holátova',
  'Holínská',
  'Holýšovská',
  'Holčovická',
  'Holšická',
  'Homolová',
  'Homérova',
  'Honzíkova',
  'Hornická',
  'Hornocholupická',
  'Hornocholupická',
  'Hornofova',
  'Hornokrčská',
  'Hornokřeslická',
  'Hornomlýnská',
  'Hornoměcholupská',
  'Hornoměcholupská',
  'Hornopočernická',
  'Horní',
  'Horní Chaloupky',
  'Horní Hrdlořezská',
  'Horní Stromky',
  'Horníčkova',
  'Horolezecká',
  'Horoměřická',
  'Horoměřická',
  'Horoušanská',
  'Horoušanská',
  'Horovo Nám.',
  'Horovo Náměstí',
  'Horská',
  'Horusická',
  'Horymírovo Nám.',
  'Horymírovo Náměstí',
  'Horákova',
  'Horáčkova',
  'Horčičkova',
  'Horňátecká',
  'Horšovská',
  'Horšovská',
  'Hospodářská',
  'Hostavická',
  'Hostavická',
  'Hostinského',
  'Hostivařská',
  'Hostivařské Nám.',
  'Hostivařské Náměstí',
  'Hostivická',
  'Hostivítova',
  'Hostišovská',
  'Hostouňská',
  'Hostošova',
  'Hostýnská',
  'Hostýnská',
  'Houbařská',
  'Houdova',
  'Hovorčovická',
  'Hořanská',
  'Hořejší Náb.',
  'Hořejší Nábřeží',
  'Hořejšího',
  'Hořelická',
  'Hořická',
  'Hořovského',
  'Hořínecká',
  'Hoškova',
  'Hoštická',
  'Hošťálkova',
  'Hrabačovská',
  'Hrabákova',
  'Hrachovská',
  'Hrad I. Nádvoří',
  'Hrad Ii. Nádvoří',
  'Hrad Iii. Nádvoří',
  'Hradební',
  'Hradecká',
  'Hradeckých',
  'Hradečkova',
  'Hradešínská',
  'Hradčanské Nám.',
  'Hradčanské Náměstí',
  'Hraniční',
  'Hrazanská',
  'Hrazanská',
  'Hrdinova',
  'Hrdličkova',
  'Hrdlořezská',
  'Hrdoňovická',
  'Hroncova',
  'Hronovská',
  'Hronětická',
  'Hrozenkovská',
  'Hroznová',
  'Hrozného',
  'Hrubého',
  'Hrubínova',
  'Hrudičkova',
  'Hrusická',
  'Hruškovská',
  'Hruškovská',
  'Hrušovanské Nám.',
  'Hrušovanské Náměstí',
  'Hrušovická',
  'Hrušovská',
  'Hrušínského',
  'Hrušňová',
  'Hrušňová',
  'Hrádková',
  'Hráského',
  'Huberova',
  'Hubičkova',
  'Hubáčkova',
  'Hudcova',
  'Hudební',
  'Hudečkova',
  'Hudečkova',
  'Hugo Haase',
  'Hulanova',
  'Hulická',
  'Humenecká',
  'Humpolecká',
  'Huntířovská',
  'Hurbanova',
  'Husařská',
  'Husinecká',
  'Husitská',
  'Husitská',
  'Husníkova',
  'Husova',
  'Husovo Nám.',
  'Husovo Náměstí',
  'Hustopečská',
  'Hutnická',
  'Huťská',
  'Hviezdoslavova',
  'Hviezdoslavova',
  'Hvozdecká',
  'Hvozdnická',
  'Hvozdíková',
  'Hvožďanská',
  'Hvězdonická',
  'Hvězdova',
  'Hvězdářská',
  'Hyacintová',
  'Hybernská',
  'Hybešova',
  'Hynaisova',
  'Hypšmanova',
  'Hábova',
  'Hájecká',
  'Hájenská',
  'Hájkova',
  'Hájovna U Podjezdu',
  'Hájovna V Šárce',
  'Hájová',
  'Hájíčkova',
  'Hájčí',
  'Hákova',
  'Hálkova',
  'Hálova',
  'Hálův Statek',
  'Högerova',
  'Hübnerové',
  'Hřbitovní',
  'Hřebenová',
  'Hřebíkova',
  'Hřenská',
  'Hřibojedská',
  'Hřibská',
  'Hříbková',
  'Hřídelecká',
  'Hůlkova',
  'Hůlkova',
  'Hůrská',
  'Ibsenova',
  'Imrychova',
  'Ingrišova',
  'Internacionální',
  'Irkutská',
  'Irská',
  'Irvingova',
  'Italská',
  'Italská',
  'Italská',
  'Ivančická',
  'Izraelská',
  'Izraelská',
  'Jabkenická',
  'Jablonecká',
  'Jablonecká',
  'Jablonského',
  'Jabloňová',
  'Jablunkovská',
  'Jagellonská',
  'Jagellonská',
  'Jahodnická',
  'Jahodová',
  'Jakobiho',
  'Jakubovská',
  'Jakubská',
  'Jakutská',
  'Jalodvorská',
  'Jalovcová',
  'Jaltská',
  'Jamborova',
  'Jamská',
  'Jana Bílka',
  'Jana Jindřicha',
  'Jana Karafiáta',
  'Jana Kašpara',
  'Jana Marka',
  'Jana Masaryka',
  'Jana Ouřady',
  'Jana Přibíka',
  'Jana Růžičky',
  'Jana Srba',
  'Jana Zajíce',
  'Jana Čerstvého',
  'Jana Želivského',
  'Janderova',
  'Jandova',
  'Janečkova',
  'Jankovcova',
  'Jankovská',
  'Janouchova',
  'Janouškova',
  'Janovická',
  'Janovská',
  'Janovského',
  'Jansenova',
  'Janského',
  'Jansova',
  'Jantarová',
  'Janákova',
  'Janáčkovo Nábř.',
  'Janáčkovo Nábř.',
  'Janáčkovo Nábřeží',
  'Janáčkovo Nábřeží',
  'Janýrova',
  'Jančova',
  'Jarešova',
  'Jarkovská',
  'Jarmily Novotné',
  'Jarní',
  'Jarníkova',
  'Jaromíra Jindry',
  'Jaromíra Vejvody',
  'Jaromírova',
  'Jaroměřská',
  'Jaroslava Foglara',
  'Jaroslava Švehly',
  'Jaroslavická',
  'Jasanová',
  'Jaselská',
  'Jaselská',
  'Jasenická',
  'Jasenná',
  'Jasmínová',
  'Jasná I',
  'Jasná Ii',
  'Jaspisová',
  'Jateční',
  'Jaurisova',
  'Jaurisova',
  'Javorenská',
  'Javornická',
  'Javorová',
  'Javorská',
  'Javořická',
  'Jašíkova',
  'Jažlovická',
  'Jedlová',
  'Jednostranná',
  'Jednostranná',
  'Jednotného Zemědělského Družstva',
  'Jednořadá',
  'Jelenovská',
  'Jelení',
  'Jelínkova',
  'Jemenská',
  'Jemnická',
  'Jenerálka',
  'Jenečská',
  'Jenišovská',
  'Jenská',
  'Jeníkovická',
  'Jenštejnská',
  'Jeremenkova',
  'Jeremenkova',
  'Jeremenkova',
  'Jeremiášova',
  'Jeremiášova',
  'Jerevanská',
  'Jeronýmova',
  'Jeruzalémská',
  'Jesenická',
  'Jeseniova',
  'Jestřebická',
  'Jetelová',
  'Jetřichovická',
  'Jevanská',
  'Jezdecká',
  'Jezdovická',
  'Jezerní',
  'Jezerská',
  'Jezevčí',
  'Ječná',
  'Jeřabinová',
  'Jeřabinová',
  'Jeřická',
  'Jeřábkova',
  'Jeřábnická',
  'Jeřábová',
  'Ješetická',
  'Ještědská',
  'Ježdíkova',
  'Ježkova',
  'Ježovická',
  'Ježovická',
  'Ježovská',
  'Jihlavská',
  'Jihovýchodní I',
  'Jihovýchodní Ii',
  'Jihovýchodní Iii',
  'Jihovýchodní Iv',
  'Jihovýchodní Ix',
  'Jihovýchodní V',
  'Jihovýchodní Vi',
  'Jihovýchodní Vii',
  'Jihovýchodní Viii',
  'Jihozápadní I',
  'Jihozápadní Ii',
  'Jihozápadní Iii',
  'Jihozápadní Iv',
  'Jihozápadní V',
  'Jihozápadní Vi',
  'Jihočeská',
  'Jilemnická',
  'Jilemnická',
  'Jilemnického',
  'Jilmová',
  'Jilská',
  'Jindrova',
  'Jindřicha Jindřicha',
  'Jindřicha Plachty',
  'Jindřichova',
  'Jindřišská',
  'Jinolická',
  'Jinonická',
  'Jinonická',
  'Jinočanská',
  'Jirenská',
  'Jirečkova',
  'Jirkovská',
  'Jirsákova',
  'Jirsíkova',
  'Jiránkova',
  'Jiráskovo Nám.',
  'Jiráskovo Náměstí',
  'Jirčanská',
  'Jiskrova',
  'Jistebnická',
  'Jitkovská',
  'Jitravská',
  'Jitravská',
  'Jitrocelová',
  'Jitřní',
  'Jivenská',
  'Jizerská',
  'Jičínská',
  'Jičínská',
  'Jiřická',
  'Jiřinková',
  'Jiřiny Štěpničkové',
  'Jiřská',
  'Jiřího Jandy',
  'Jiřího Mašína',
  'Jiřího Ze Vtelna',
  'Jiříčkova',
  'Jiříčkové',
  'Jižní I',
  'Jižní Ii',
  'Jižní Iii',
  'Jižní Iv',
  'Jižní Ix',
  'Jižní Nám.',
  'Jižní Náměstí',
  'Jižní Spojka',
  'Jižní Spojka',
  'Jižní Spojka',
  'Jižní Spojka',
  'Jižní V',
  'Jižní Vi',
  'Jižní Vii',
  'Jižní Viii',
  'Jižní Xi',
  'Jižní Xii',
  'Jižní Xiii',
  'Jižní Xiv',
  'Jižní Xv',
  'Jižní Xvi',
  'Jižní Xvii',
  'Johanitská',
  'Jordana Jovkova',
  'Jordánská',
  'Josefa Bíbrdlíka',
  'Josefa Houdka',
  'Josefa Houdka',
  'Josefa Kočího',
  'Josefa Němce',
  'Josefa Vašíčka',
  'Josefa Šimůnka',
  'Josefská',
  'José Martího',
  'Juarézova',
  'Jugoslávská',
  'Jugoslávských Partyzánů',
  'Jugoslávských Partyzánů',
  'Jungmannova',
  'Jungmannova',
  'Jungmannovo Náměstí',
  'Junácká',
  'Jupiterova',
  'Jurkovičova',
  'Juárezova',
  'Jzd',
  'Jáchymova',
  'Jáchymova',
  'Jáchymovská',
  'Jánošíkova',
  'Jánská',
  'Jánský Vršek',
  'Jíchova',
  'Jílkova',
  'Jílovická',
  'Jílovišťská',
  'Jílovská',
  'Jílovská',
  'Jílová',
  'Jírova',
  'Jírovcovo Nám.',
  'Jírovcovo Náměstí',
  'Jívanská',
  'Jívová',
  'K Austisu',
  'K Avii',
  'K Barrandovu',
  'K Bateriím',
  'K Bažantnici',
  'K Belvederu',
  'K Berance',
  'K Beranovu',
  'K Berounce',
  'K Beránku',
  'K Betonárně',
  'K Betáni',
  'K Blatovu',
  'K Bohnicím',
  'K Borovíčku',
  'K Botiči',
  'K Brance',
  'K Brnkám',
  'K Brusce',
  'K Brusce',
  'K Brůdku',
  'K Bílému Vrchu',
  'K Běchovicům',
  'K Březince',
  'K Březiněvsi',
  'K Břečkám',
  'K Celinám',
  'K Cementárně',
  'K Chabům',
  'K Chabům',
  'K Chaloupce',
  'K Chaloupkám',
  'K Chatám',
  'K Chmelnici',
  'K Chumberku',
  'K Cihelně',
  'K Cikánce',
  'K Cíli',
  'K Dalejím',
  'K Dobré Vodě',
  'K Dobré Vodě',
  'K Dolům',
  'K Drahani',
  'K Drahani',
  'K Drazdům',
  'K Drsnici',
  'K Dubinám',
  'K Dubovému Mlýnu',
  'K Dubu',
  'K Dubči',
  'K Dálnici',
  'K Dálnici',
  'K Dýmači',
  'K Děrám',
  'K Fantovu Mlýnu',
  'K Farkám',
  'K Fialce',
  'K Fišpance',
  'K Habrovce',
  'K Habru',
  'K Haltýři',
  'K Havlínu',
  'K Hluboké Cestě',
  'K Hlásku',
  'K Holyni',
  'K Holému Vrchu',
  'K Holému Vrchu',
  'K Homolce',
  'K Horkám',
  'K Horkám',
  'K Horkám',
  'K Horním Počernicím',
  'K Horoměřicům',
  'K Hořavce',
  'K Hradišti',
  'K Hrnčířům',
  'K Hrušovu',
  'K Hrušovu',
  'K Hrázi',
  'K Hutím',
  'K Hutím',
  'K Hutím',
  'K Hádku',
  'K Háječku',
  'K Háji',
  'K Háji',
  'K Hájku',
  'K Hájovně',
  'K Hájovně',
  'K Hájovně',
  'K Hájům',
  'K Hárunce',
  'K Interně',
  'K Jalovce',
  'K Jasánkám',
  'K Jelenu',
  'K Jelenám',
  'K Jezeru',
  'K Jezeru',
  'K Jezu',
  'K Jezírku',
  'K Jihu',
  'K Jihu',
  'K Jinočanům',
  'K Jinočanům',
  'K Jižnímu Městu',
  'K Juliáně',
  'K Jízdárně',
  'K Labeškám',
  'K Ladům',
  'K Lahovičkám',
  'K Lahovské',
  'K Lažance',
  'K Lesoparku',
  'K Lesu',
  'K Lesu',
  'K Lesíku',
  'K Letišti',
  'K Letňanům',
  'K Libuši',
  'K Lindě',
  'K Lipanům',
  'K Lipinám',
  'K Lipám',
  'K Lochkovu',
  'K Lomu',
  'K Louži',
  'K Luhu',
  'K Lukám',
  'K Lučinám',
  'K Lužinám',
  'K Ládví',
  'K Ládví',
  'K Lánu',
  'K Lávce',
  'K Lázním',
  'K Lípě',
  'K Markétě',
  'K Matěji',
  'K Mejtu',
  'K Metru',
  'K Metru',
  'K Milíčovu',
  'K Mlíčníku',
  'K Mlýnu',
  'K Modřanskému Nádraží',
  'K Mohyle',
  'K Moravině',
  'K Moravině',
  'K Mostku',
  'K Mostu',
  'K Motelu',
  'K Motolu',
  'K Mírám',
  'K Měcholupům',
  'K Měchurce',
  'K Nedvězí',
  'K Netlukám',
  'K Noskovně',
  'K Nouzovu',
  'K Nové Vsi',
  'K Nové Vsi',
  'K Nové Škole',
  'K Novému Dvoru',
  'K Novému Hradu',
  'K Novému Sídlišti',
  'K Novým Domkům',
  'K Nádraží',
  'K Nádrži',
  'K Náhonu',
  'K Náměstí',
  'K Náplavce',
  'K Náplavce',
  'K Návrší',
  'K Návrší',
  'K Návsi',
  'K Obci',
  'K Obecním Hájovnám',
  'K Oboře',
  'K Obsinám',
  'K Ochozu',
  'K Ohradě',
  'K Okrouhlíku',
  'K Olympiku',
  'K Opatřilce',
  'K Opatřilce',
  'K Oplocení',
  'K Orionce',
  'K Osmidomkům',
  'K Otočce',
  'K Ovčínu',
  'K Ovčínu',
  'K Padesátníku',
  'K Palečku',
  'K Panenkám',
  'K Parku',
  'K Pastvinám',
  'K Pazderkám',
  'K Pekárně',
  'K Peluňku',
  'K Petrově Komoře',
  'K Pitkovicům',
  'K Podchodu',
  'K Podjezdu',
  'K Podjezdu',
  'K Polím',
  'K Pomníku',
  'K Popelce',
  'K Popelce',
  'K Potoku',
  'K Poště',
  'K Pramenu',
  'K Prelátům',
  'K Prádelně',
  'K Průhonicům',
  'K Průhonu',
  'K Průmstavu',
  'K Pyramidce',
  'K Pérovně',
  'K Pískovně',
  'K Písnici',
  'K Přehradám',
  'K Přejezdu',
  'K Přístavišti',
  'K Přívozu',
  'K Radhošti',
  'K Radonicům',
  'K Radotínu',
  'K Radotínu',
  'K Remízku',
  'K Rokli',
  'K Rokytce',
  'K Rotundě',
  'K Rovinám',
  'K Rozkoši',
  'K Rozmezí',
  'K Roztokům',
  'K Rozvodně',
  'K Rukavičkárně',
  'K Rybníku',
  'K Rybníčku',
  'K Rybníčkům',
  'K Rybárně',
  'K Ryšánce',
  'K Ryšánce',
  'K Sadu',
  'K Safině',
  'K Samoobsluze',
  'K Samotě',
  'K Sedlišti',
  'K Sibřině',
  'K Sokolovně',
  'K Sopce',
  'K Sopce',
  'K Starému Bubenči',
  'K Starému Lomu',
  'K Stavebninám',
  'K Sukovu',
  'K Sádkám',
  'K Sádkám',
  'K Sídlišti',
  'K Sídlišti',
  'K Teplárně',
  'K Topolům',
  'K Topírně',
  'K Transformátoru',
  'K Trati',
  'K Trninám',
  'K Trnkám',
  'K Trníčku',
  'K Truhlářce',
  'K Tržišti',
  'K Tuchoměřicům',
  'K Táboru',
  'K Třebonicům',
  'K Třešňovce',
  'K Tůni',
  'K Ubytovnám',
  'K Uhříněvsi',
  'K Uhříněvsi',
  'K Učilišti',
  'K Valu',
  'K Vejvoďáku',
  'K Velké Ohradě',
  'K Velké Ohradě',
  'K Velkému Dvoru',
  'K Verneráku',
  'K Viaduktu',
  'K Vidouli',
  'K Vilkám',
  'K Vinici',
  'K Vinicím',
  'K Vinoři',
  'K Vizerce',
  'K Višňovce',
  'K Višňovce',
  'K Višňovému Sadu',
  'K Vltavě',
  'K Vlásence',
  'K Vodici',
  'K Vodojemu',
  'K Vodárně',
  'K Vodě',
  'K Vrbičkám',
  'K Vrbě',
  'K Vrcholu',
  'K Vrtilce',
  'K Vršíčku',
  'K Vyhlídce',
  'K Vysoké Cestě',
  'K Vystrkovu',
  'K Václavce',
  'K Vápence',
  'K Váze',
  'K Výboru',
  'K Výtopně',
  'K Výzkumným Ústavům',
  'K Větrolamu',
  'K Zabrkům',
  'K Zadní Kopanině',
  'K Zadní Kopanině',
  'K Zahradnictví',
  'K Zahradám',
  'K Zahrádkám',
  'K Zastávce',
  'K Zatáčce',
  'K Zelené Louce',
  'K Zeleným Domkům',
  'K Zelenči',
  'K Zámku',
  'K Zátiší',
  'K Závodišti',
  'K Závorám',
  'K Závěrce',
  'K Závětinám',
  'K Údolí',
  'K Údolí Hvězd',
  'K Újezdu',
  'K Ústavu',
  'K Úvozu',
  'K Černošicím',
  'K Červenému Dvoru',
  'K Červenému Dvoru',
  'K Červenému Dvoru',
  'K Červenému Vrchu',
  'K Čestlicům',
  'K Čihadlům',
  'K Ďáblicům',
  'K Řece',
  'K Řeporyjím',
  'K Řeporyjím',
  'K Říčanům',
  'K Šafránce',
  'K Šafránce',
  'K Šancím',
  'K Šeberovu',
  'K Šeberáku',
  'K Šedivce',
  'K Šubrtce',
  'K Železnici',
  'K Žižkovu',
  'Kabeláčova',
  'Kabešova',
  'Kabátové',
  'Kadaňská',
  'Kadeřávkovská',
  'Kafkova',
  'Kahovská',
  'Kaizlovy Sady',
  'Kakosova',
  'Kakostová',
  'Kalabisova',
  'Kalašova',
  'Kalinová',
  'Kališnická',
  'Kališťská',
  'Kalská',
  'Kalvodova',
  'Kamelova',
  'Kamencová',
  'Kamenická',
  'Kamenická',
  'Kamenitá',
  'Kamenná',
  'Kameníků',
  'Kamerunská',
  'Kampanova',
  'Kamzíková',
  'Kamýcká',
  'Kamýcká',
  'Kamýcká',
  'Kanadská',
  'Kandertova',
  'Kanovnická',
  'Kapitulská',
  'Kaplanova',
  'Kaplická',
  'Kapraďová',
  'Kaprova',
  'Kaprova',
  'Kapucínská',
  'Karafiátová',
  'Karasova',
  'Karasovská',
  'Kardausova',
  'Kardašovská',
  'Kardašovská',
  'Karenova',
  'Karfíkova',
  'Karla Engliše',
  'Karla Hlaváčka',
  'Karla Kryla',
  'Karla Křížka',
  'Karla Michala',
  'Karla Rachůnka',
  'Karla Tomáše',
  'Karla Zicha',
  'Karla Černého',
  'Karlická',
  'Karlova',
  'Karlovarská',
  'Karlovarská',
  'Karlovická',
  'Karlovo Nám.',
  'Karlovo Nám.',
  'Karlovo Náměstí',
  'Karlovo Náměstí',
  'Karlínské Nám.',
  'Karlínské Náměstí',
  'Karlštejnská',
  'Karmelitská',
  'Karolinská',
  'Karoliny Světlé',
  'Karpatská',
  'Kartounářů',
  'Kartouzská',
  'Kasalická',
  'Kateřinská',
  'Kateřinské Nám.',
  'Kateřinské Náměstí',
  'Katovická',
  'Katusická',
  'Kavkazská',
  'Kazaňská',
  'Kazašská',
  'Kazimírova',
  'Kaznějovská',
  'Kazín',
  'Kazínská',
  'Kačerovská',
  'Kačínská',
  'Kaňkova',
  'Kaňkovského',
  'Kaňovská',
  'Kašeho',
  'Kaškova',
  'Kašovická',
  'Kašparovo Nám.',
  'Kašparovo Náměstí',
  'Kašperská',
  'Kaštanová',
  'Kbelská',
  'Kbelská',
  'Kbelská',
  'Kbelská',
  'Kdoulová',
  'Ke Březině',
  'Ke Břvům',
  'Ke Cvičišti',
  'Ke Dračkám',
  'Ke Dráze',
  'Ke Dvoru',
  'Ke Džbánu',
  'Ke Garážím',
  'Ke Golfu',
  'Ke Goniu',
  'Ke Hlásce',
  'Ke Hrádku',
  'Ke Hrázi',
  'Ke Hrázi',
  'Ke Hřbitovu',
  'Ke Hřišti',
  'Ke Kablu',
  'Ke Kablu',
  'Ke Kalvárii',
  'Ke Kaménce',
  'Ke Kamínce',
  'Ke Kamýku',
  'Ke Kapličce',
  'Ke Kapslovně',
  'Ke Karlovu',
  'Ke Kateřinkám',
  'Ke Kazínu',
  'Ke Kašně',
  'Ke Kinu',
  'Ke Kladivům',
  'Ke Klimentce',
  'Ke Klubovně',
  'Ke Klínku',
  'Ke Klínku',
  'Ke Klíčovu',
  'Ke Koh-I-Nooru',
  'Ke Kolodějskému Zámku',
  'Ke Kolodějům',
  'Ke Kolonii',
  'Ke Konstruktivě',
  'Ke Kopečku',
  'Ke Korunce',
  'Ke Kostelu',
  'Ke Kostelíčku',
  'Ke Kotlářce',
  'Ke Koulce',
  'Ke Koupališti',
  'Ke Kovárně',
  'Ke Kozím Hřbetům',
  'Ke Královicům',
  'Ke Krči',
  'Ke Krčské Stráni',
  'Ke Kulišce',
  'Ke Kulturnímu Domu',
  'Ke Kurtům',
  'Ke Kyjovu',
  'Ke Kálku',
  'Ke Křížku',
  'Ke Křížkám',
  'Ke Lhoteckému Lesu',
  'Ke Mlýnku',
  'Ke Mlýnu',
  'Ke Mlýnu',
  'Ke Schodům',
  'Ke Skalce',
  'Ke Skalkám',
  'Ke Skladům',
  'Ke Sklárně',
  'Ke Skále',
  'Ke Slatinám',
  'Ke Slivenci',
  'Ke Smrčině',
  'Ke Smíchovu',
  'Ke Smíchovu',
  'Ke Splávku',
  'Ke Spofě',
  'Ke Spořilovu',
  'Ke Spálence',
  'Ke Srážku',
  'Ke Stadionu',
  'Ke Stanici',
  'Ke Starému Hřišti',
  'Ke Starým Rybníkům',
  'Ke Stinkovskému Rybníku',
  'Ke Strašnické',
  'Ke Strouze',
  'Ke Stráni',
  'Ke Strži',
  'Ke Studni',
  'Ke Studni',
  'Ke Studánce',
  'Ke Stupicím',
  'Ke Stáčírně',
  'Ke Stírce',
  'Ke Střelnici',
  'Ke Střelnici',
  'Ke Sv. Izidoru',
  'Ke Třem Mostům',
  'Ke Xaverovu',
  'Ke Zbraslavi',
  'Ke Zbrojnici',
  'Ke Zbuzanům',
  'Ke Zdibům',
  'Ke Zdravotnímu Středisku',
  'Ke Zděři',
  'Ke Zlatému Kopci',
  'Ke Zličínu',
  'Ke Znaku',
  'Ke Zvonici',
  'Ke Zvoničce',
  'Ke Školce',
  'Ke Škole',
  'Ke Šmejkalu',
  'Ke Štvanici',
  'Ke Štítu',
  'Ke Štěpcům',
  'Ke Štěrkovně',
  'Ke Švestkovce',
  'Kecova',
  'Kejhova',
  'Kejnická',
  'Kellnerova',
  'Keltská',
  'Keltů',
  'Kelvinova',
  'Kemrova',
  'Keplerova',
  'Keplerova',
  'Keramická',
  'Kesnerka',
  'Kestřanská',
  'Keteňská',
  'Kettnerova',
  'Keřová',
  'Khodlova',
  'Kischova',
  'Kišiněvská',
  'Kladenská',
  'Kladenská',
  'Kladenská',
  'Kladinovská',
  'Kladrubská',
  'Kladská',
  'Klamovka',
  'Klapkova',
  'Klapálkova',
  'Klatovská',
  'Klausova',
  'Klecandova',
  'Klecanská',
  'Klenečská',
  'Klenovická',
  'Klenovská',
  'Klenová',
  'Klečkova',
  'Klečákova',
  'Klešická',
  'Klicperova',
  'Klidná',
  'Klihařská',
  'Klikatá',
  'Klikatá',
  'Klimentská',
  'Klivarova',
  'Kloboukova',
  'Kloboučnická',
  'Kloknerova',
  'Klokotská',
  'Klostermannova',
  'Klouzková',
  'Kludských',
  'Klukovická',
  'Klánova',
  'Klánova',
  'Klánova',
  'Klánovická',
  'Klánovická',
  'Klárov',
  'Klášterecká',
  'Klášterská',
  'Klášterského',
  'Klímova',
  'Klímova',
  'Klínecká',
  'Klínovecká',
  'Klínová',
  'Klírova',
  'Klíčanská',
  'Klíčova',
  'Klíčovská',
  'Klíčovská',
  'Kmochova',
  'Knínická',
  'Kněževeská',
  'Kněžická',
  'Koberkova',
  'Kobrova',
  'Kobyliská',
  'Kobyliské Nám.',
  'Kobyliské Náměstí',
  'Kobylákova',
  'Kochanova',
  'Kocianova',
  'Koclířova',
  'Kocourova',
  'Kodaňská',
  'Kodicilova',
  'Kodymova',
  'Kohoutovská',
  'Kohoutových',
  'Kojetická',
  'Kojická',
  'Kokořínská',
  'Kolbenova',
  'Kolbenova',
  'Kolbenova',
  'Koldínova',
  'Kolejní',
  'Kolektivní',
  'Kolešovská',
  'Kollárova',
  'Kolmistrova',
  'Kolmá',
  'Kolocova',
  'Kolodějská',
  'Kolonie U Obecní Cihelny',
  'Kolonka',
  'Kolovečská',
  'Kolovratská',
  'Kolová',
  'Kolátorova',
  'Koláčkova',
  'Koláře Kaliny',
  'Kolářova',
  'Kolínova',
  'Kolínská',
  'Kolčavka',
  'Komenského Nám.',
  'Komenského Náměstí',
  'Komornická',
  'Komořanská',
  'Komořanská',
  'Komořanská',
  'Komunardů',
  'Komárkova',
  'Komárovská',
  'Koncová',
  'Konecchlumského',
  'Konečná',
  'Kongresová',
  'Konojedská',
  'Konopišťská',
  'Konopova',
  'Konopáskova',
  'Konstantinova',
  'Konvalinková',
  'Konviktská',
  'Konzumní',
  'Konzumní',
  'Koníčkovo Nám.',
  'Koníčkovo Náměstí',
  'Konětopská',
  'Koněvova',
  'Konšelská',
  'Konžská',
  'Kopalova',
  'Kopanina',
  'Kopanská',
  'Kopeckého',
  'Koperníkova',
  'Kopečná',
  'Kopretinová',
  'Kopřivnická',
  'Korandova',
  'Korandova',
  'Korunní',
  'Korunní',
  'Korunní',
  'Korunovační',
  'Korunovační',
  'Korybutova',
  'Korycanská',
  'Korytná',
  'Kosatcová',
  'Kosařova',
  'Kosmická',
  'Kosmonoská',
  'Kosova',
  'Kosořická',
  'Kosořská',
  'Kostelecká',
  'Kostelecká',
  'Kostelní',
  'Kostelní Náměstí',
  'Kostečná',
  'Kostková',
  'Kostlivého',
  'Kostnické Nám.',
  'Kostnické Náměstí',
  'Kostomlatská',
  'Kostrbova',
  'Kostřínská',
  'Kosárkovo Nábř.',
  'Kosárkovo Nábřeží',
  'Kosí',
  'Koterovská',
  'Koterovská',
  'Kotevní',
  'Kotlaska',
  'Kotlářka',
  'Kotorská',
  'Kotovka',
  'Kotrčová',
  'Kotršálova',
  'Kotíkova',
  'Kotěrova',
  'Koubkova',
  'Koubkova',
  'Koubova',
  'Koukolová',
  'Koulka',
  'Koulova',
  'Kounická',
  'Kounovská',
  'Koutská',
  'Kouřimská',
  'Kovanecká',
  'Kovařovicova',
  'Kovriginova',
  'Kováků',
  'Kovárenská',
  'Kovářova',
  'Kovářská',
  'Kováříkova',
  'Kozinova',
  'Kozinovo Náměstí',
  'Kozlova',
  'Kozlovská',
  'Kozmíkova',
  'Kozomínská',
  'Kozácká',
  'Kozákovská',
  'Kozáková',
  'Kozí',
  'Kočova',
  'Kořenského',
  'Košařova',
  'Košická',
  'Koštířova',
  'Košátecká',
  'Košíkářská',
  'Košířské Nám.',
  'Košířské Náměstí',
  'Košťálkova',
  'Koťátkova',
  'Koželužská',
  'Kožlanská',
  'Kožná',
  'Kožíškova',
  'Kpt. Nálepky',
  'Kpt. Stránského',
  'Krabošická',
  'Krahulčí',
  'Krajanská',
  'Krajní',
  'Krajová',
  'Krajánkova',
  'Krakovská',
  'Kralická',
  'Kralupská',
  'Krameriova',
  'Kramlova',
  'Kramolná',
  'Kramolínská',
  'Kramperova',
  'Kraslická',
  'Krasnická',
  'Krasnojarská',
  'Kratochvílova',
  'Krausova',
  'Krbická',
  'Krchlebská',
  'Krejnická',
  'Krejčího',
  'Kremličkova',
  'Kremnická',
  'Kremnická',
  'Krhanická',
  'Krhanická',
  'Kristiánova',
  'Kriváňská',
  'Krkonošská',
  'Krnovská',
  'Krnská',
  'Krocínova',
  'Krocínovská',
  'Kroftova',
  'Krohova',
  'Krokova',
  'Krolmusova',
  'Kropáčkova',
  'Krosenská',
  'Kroupova',
  'Kroupova',
  'Krouzova',
  'Krovova',
  'Krteňská',
  'Kruhová',
  'Krumlovská',
  'Krupkovo Nám.',
  'Krupkovo Náměstí',
  'Krupná',
  'Krupská',
  'Krušovická',
  'Kružberská',
  'Krylovecká',
  'Krylovecká',
  'Krymská',
  'Krynická',
  'Krystalová',
  'Kryšpínova',
  'Kryštofova',
  'Krále Václava Iv.',
  'Králodvorská',
  'Králova',
  'Královická',
  'Královny Žofie',
  'Královská Obora',
  'Královská Obora',
  'Krásnolipská',
  'Krásného',
  'Krásova',
  'Krátká',
  'Krátká',
  'Krátkého',
  'Krátký Lán',
  'Krčmářovská',
  'Krčská',
  'Krčínovo Nám.',
  'Krčínovo Náměstí',
  'Krčínská',
  'Krňovická',
  'Krškova',
  'Kubatova',
  'Kubaštova',
  'Kubelíkova',
  'Kubišova',
  'Kubištova',
  'Kubova',
  'Kubánské Nám.',
  'Kubánské Náměstí',
  'Kubíkova',
  'Kubínova',
  'Kuchařská',
  'Kudeříkové',
  'Kudrnova',
  'Kukelská',
  'Kukelská',
  'Kukulova',
  'Kukulova',
  'Kukučínova',
  'Kulhavého',
  'Kulhánkovská',
  'Kuncova',
  'Kundratka',
  'Kunešova',
  'Kunická',
  'Kunratická',
  'Kunratická Spojka',
  'Kunratická Spojka',
  'Kuní',
  'Kuní',
  'Kunínova',
  'Kunčická',
  'Kunětická',
  'Kupeckého',
  'Kupkova',
  'Kurandové',
  'Kurkova',
  'Kurta Konráda',
  'Kurzova',
  'Kurčatovova',
  'Kusá',
  'Kusého',
  'Kutilova',
  'Kutnauerovo Náměstí',
  'Kutnohorská',
  'Kutnohorská',
  'Kutrovická',
  'Kuttelwascherova',
  'Kutvirtova',
  'Kučerova',
  'Kučerové',
  'Kuťatská',
  'Kuželova',
  'Kvapilova',
  'Kvasinská',
  'Kvestorská',
  'Květinková',
  'Květinářská',
  'Květnická',
  'Květnová',
  'Květnového Povstání',
  'Květnového Povstání',
  'Květnového Vítězství',
  'Květnového Vítězství',
  'Květná',
  'Květoslavova',
  'Květová',
  'Kyjevská',
  'Kyjevská',
  'Kyjovská',
  'Kyjská',
  'Kyjská',
  'Kykalova',
  'Kymrova',
  'Kynická',
  'Kyselova',
  'Kyslíková',
  'Kysucká',
  'Kysúcká',
  'Kytlická',
  'Kytínská',
  'Kácovská',
  'Kádnerova',
  'Kálikova',
  'Kálmánova',
  'Káranská',
  'Křejpského',
  'Křelovická',
  'Křemelná',
  'Křemencova',
  'Křemenná',
  'Křemenáčová',
  'Křemílkova',
  'Křenická',
  'Křenova',
  'Křepelčí',
  'Křepelčí',
  'Křesadlova',
  'Křesanovská',
  'Křeslická',
  'Křesomyslova',
  'Křešínská',
  'Křimická',
  'Křimovská',
  'Křivatcová',
  'Křivenická',
  'Křivoklátská',
  'Křivá',
  'Křičkova',
  'Křišťanova',
  'Křišťálová',
  'Křižovnická',
  'Křižovnické Nám.',
  'Křižovnické Náměstí',
  'Křižíkova',
  'Křižíkova',
  'Křovinovo Nám.',
  'Křovinovo Náměstí',
  'Křtinská',
  'Kříženeckého Nám.',
  'Kříženeckého Náměstí',
  'Křížkovského',
  'Křížová',
  'Křížová',
  'Labská',
  'Labětínská',
  'Ladislava Coňka',
  'Ladova',
  'Laglerové',
  'Lahovská',
  'Lahovská',
  'Lamačova',
  'Langweilova',
  'Lannova',
  'Lanýžová',
  'Lanžhotská',
  'Lanžovská',
  'Laténská',
  'Laubova',
  'Laudonova',
  'Laudova',
  'Laurinova',
  'Lazarská',
  'Lazarská',
  'Lačnovská',
  'Lažanská',
  'Lažanská',
  'Lažanského',
  'Lebeděvova',
  'Ledařská',
  'Ledecká',
  'Ledečská',
  'Ledkovská',
  'Lednická',
  'Lednová',
  'Ledvická',
  'Ledvinova',
  'Ledč',
  'Ledčická',
  'Legerova',
  'Legerova',
  'Legerova',
  'Legerova',
  'Legionářů',
  'Lehárova',
  'Leitzova',
  'Leknínová',
  'Leopoldova',
  'Leskovecká',
  'Lesnická',
  'Lesného',
  'Lesní',
  'Lessnerova',
  'Lesáků',
  'Letců',
  'Letecká',
  'Letenská',
  'Letenské Nám.',
  'Letenské Nám.',
  'Letenské Náměstí',
  'Letenské Náměstí',
  'Letenské Sady',
  'Letní',
  'Letohradská',
  'Letovská',
  'Letňanská',
  'Letňanská',
  'Levandulová',
  'Levobřežní',
  'Levského',
  'Levá',
  'Lexova',
  'Lečkova',
  'Lešanská',
  'Lešenská',
  'Lešetínská',
  'Lešovská',
  'Leštínská',
  'Lhenická',
  'Lhotecká',
  'Lhotecká',
  'Lhotská',
  'Lhotákova',
  'Liberecká',
  'Liberijská',
  'Libečkova',
  'Libeňská',
  'Libeňský Ostrov',
  'Libeňský Ostrov',
  'Libeřská',
  'Libichovská',
  'Libická',
  'Libišanská',
  'Libišská',
  'Libkovská',
  'Liblická',
  'Liblická',
  'Libochovická',
  'Libocká',
  'Liborova',
  'Libotovská',
  'Libovická',
  'Libočanská',
  'Liboňovská',
  'Libošovická',
  'Libuňská',
  'Libušina',
  'Libušská',
  'Libušská',
  'Libušská',
  'Libušská',
  'Libáňská',
  'Libínská',
  'Libčanská',
  'Libčická',
  'Liběchovská',
  'Libědická',
  'Liběšická',
  'Libřická',
  'Lichá',
  'Lidečská',
  'Lidická',
  'Lidického',
  'Lihovarská',
  'Liliová',
  'Lilková',
  'Limuzská',
  'Limuzská',
  'Lindavská',
  'Lindleyova',
  'Lindnerova',
  'Linhartova',
  'Linhartská',
  'Lipanská',
  'Lipecká',
  'Lipenecká',
  'Lipenská',
  'Lipenská',
  'Lipenské Nám.',
  'Lipenské Náměstí',
  'Lipnická',
  'Lipoltická',
  'Lipovická',
  'Lipovská',
  'Lipová Alej',
  'Lipové Náměstí',
  'Lipského',
  'Lipí',
  'Lisabonská',
  'Lisabonská',
  'Listopadová',
  'Lisztova',
  'Litavská',
  'Litevská',
  'Litická',
  'Litochlebská',
  'Litoměřická',
  'Litoměřická',
  'Litovická',
  'Litošická',
  'Litošická',
  'Litožnická',
  'Litvínovská',
  'Litvínovská',
  'Livornská',
  'Lišanská',
  'Lišická',
  'Liškova',
  'Lišovická',
  'Liščí',
  'Liščí',
  'Lnářská',
  'Lobečská',
  'Lochenická',
  'Lochkovská',
  'Lochotínská',
  'Lodecká',
  'Lodní Mlýny',
  'Loděnická',
  'Lodžská',
  'Lodžská',
  'Lohenická',
  'Lohniského',
  'Lojovická',
  'Lojovická',
  'Lojovická',
  'Lolkova',
  'Lomařská',
  'Lomecká',
  'Lomená',
  'Lomnická',
  'Lomnického',
  'Lomová',
  'Londýnská',
  'Loosova',
  'Lopatecká',
  'Lopatecká',
  'Lopuchová',
  'Loretánská',
  'Loretánské Nám.',
  'Loretánské Náměstí',
  'Losinská',
  'Lotyšská',
  'Loucká',
  'Loudova',
  'Lounská',
  'Lounských',
  'Loutkářská',
  'Loučanská',
  'Loučimská',
  'Loučná',
  'Louňovická',
  'Lovecká',
  'Lovosická',
  'Lovosická',
  'Lovosická',
  'Lovčenská',
  'Lovčická',
  'Lozická',
  'Lošetická',
  'Lošáková',
  'Lstibořská',
  'Lubenecká',
  'Lublaňská',
  'Lublaňská',
  'Lublinská',
  'Lubnická',
  'Lucemburská',
  'Lucemburská',
  'Lucinková',
  'Ludmilina',
  'Ludvíkova',
  'Luhovská',
  'Lukavecká',
  'Lukavského',
  'Lukešova',
  'Lukešova',
  'Lukovská',
  'Lukášova',
  'Lumiérů',
  'Lumírova',
  'Lumírova',
  'Luníkovská',
  'Lupenická',
  'Lupáčova',
  'Lutínská',
  'Luční',
  'Luštěnická',
  'Lužanská',
  'Lužecká',
  'Lužická',
  'Lužnická',
  'Lužná',
  'Lužní',
  'Lužská',
  'Lvovská',
  'Lysinská',
  'Lysolajská',
  'Lysolajské Údolí',
  'Lyčkovo Nám.',
  'Lyčkovo Náměstí',
  'Lyžařská',
  'Ládevská',
  'Lánovská',
  'Lánská',
  'Lásenická',
  'Láskova',
  'Lázeňská',
  'Lékařská',
  'Lékořicová',
  'Líbalova',
  'Líbeznická',
  'Lípová',
  'Lískovická',
  'Lísková',
  'Líšnická',
  'Lýskova',
  'M. J. Lermontova',
  'Macešková',
  'Macharovo Nám.',
  'Macharovo Náměstí',
  'Machatého',
  'Machkova',
  'Machnova',
  'Machovcova',
  'Machovická',
  'Machovská',
  'Machuldova',
  'Macháčkova',
  'Madarova',
  'Madaťjanova',
  'Madridská',
  'Magd. Rettigové',
  'Magdalény Rettigové',
  'Magistrů',
  'Magnitogorská',
  'Mahenova',
  'Mahlerovy Sady',
  'Mahulenina',
  'Maiselova',
  'Maiselova',
  'Majerové',
  'Majerského',
  'Makedonská',
  'Makovská',
  'Makovského',
  'Maková',
  'Malachitová',
  'Malebná',
  'Malenická',
  'Malešická',
  'Malešická',
  'Malešická',
  'Malešické Nám.',
  'Malešické Náměstí',
  'Malešovská',
  'Malinová',
  'Maličká',
  'Malkovského',
  'Malletova',
  'Malletova',
  'Malobřevnovská',
  'Malostranské Nábř.',
  'Malostranské Nábřeží',
  'Malostranské Náměstí',
  'Malotická',
  'Malovická',
  'Maltézské Nám.',
  'Maltézské Náměstí',
  'Malá',
  'Malá Bylanská',
  'Malá Houdova',
  'Malá Klášterní',
  'Malá Lada',
  'Malá Michnovka',
  'Malá Plynární',
  'Malá Skloněná',
  'Malá Smidarská',
  'Malá Tyršovka',
  'Malá Xaveriova',
  'Malá Štupartská',
  'Malá Štěpánská',
  'Malátova',
  'Malé Nám.',
  'Malé Náměstí',
  'Malého',
  'Malínská',
  'Malířská',
  'Malý Dvůr',
  'Malý Okrouhlík',
  'Malšovická',
  'Malšovské Nám.',
  'Malšovské Náměstí',
  'Mandloňová',
  'Mandova',
  'Mansfeldova',
  'Manská Zahrada',
  'Mantovská',
  'Manželů Dostálových',
  'Manželů Kotrbových',
  'Manželů Lyčkových',
  'Marciho',
  'Marešova',
  'Marie Cibulkové',
  'Marie Podvalové',
  'Mariánská',
  'Mariánská',
  'Mariánské Hradby',
  'Mariánské Hradby',
  'Mariánské Nám.',
  'Mariánské Náměstí',
  'Markova',
  'Markupova',
  'Markušova',
  'Markvartická',
  'Markyta',
  'Markétská',
  'Maroldova',
  'Martinelliho',
  'Martinická',
  'Martinova',
  'Martinovská',
  'Martinská',
  'Marty Krásové',
  'Marvanova',
  'Maršovská',
  'Masarykovo Nábř.',
  'Masarykovo Nábř.',
  'Masarykovo Nábřeží',
  'Masarykovo Nábřeží',
  'Masná',
  'Matek',
  'Matenská',
  'Maternova',
  'Mateřská',
  'Mateřídoušková',
  'Matjuchinova',
  'Matoušova',
  'Mattioliho',
  'Matúškova',
  'Matěchova',
  'Matějkova',
  'Matějovského',
  'Matějská',
  'Maxovská',
  'Mazancova',
  'Mazovská',
  'Mazurská',
  'Maďarská',
  'Maňákova',
  'Mařatkova',
  'Mařákova',
  'Maříkova',
  'Mašatova',
  'Maškova',
  'Mašovická',
  'Maštěřovského',
  'Mašínova',
  'Mechovka',
  'Mechová',
  'Medinská',
  'Medkova',
  'Medlovská',
  'Medová',
  'Meduňková',
  'Meinlinova',
  'Mejstříkova',
  'Melantrichova',
  'Meliorační',
  'Melodická',
  'Melounová',
  'Menclova',
  'Mendelova',
  'Mendíků',
  'Menšíkova',
  'Menšíkovská',
  'Merhoutova',
  'Merkurova',
  'Meruňková',
  'Meskářova',
  'Meteorologická',
  'Meteorologická',
  'Metodějova',
  'Metujská',
  'Mexická',
  'Mezi Chatami',
  'Mezi Domky',
  'Mezi Domy',
  'Mezi Humny',
  'Mezi Lysinami',
  'Mezi Lány',
  'Mezi Poli',
  'Mezi Potoky',
  'Mezi Rolemi',
  'Mezi Rybníky',
  'Mezi Sklady',
  'Mezi Stráněmi',
  'Mezi Vodami',
  'Mezi Úvozy',
  'Mezi Školami',
  'Mezibranská',
  'Mezihorská',
  'Mezihoří',
  'Mezilehlá',
  'Mezilesní',
  'Mezilesí',
  'Meziluží',
  'Mezipolí',
  'Mezitraťová',
  'Mezitraťová',
  'Mezitraťová',
  'Mezivrší',
  'Meziškolská',
  'Mečislavova',
  'Mečovská',
  'Mečíková',
  'Michalovicova',
  'Michalská',
  'Michelangelova',
  'Michelská',
  'Michelská',
  'Michnova',
  'Michnovka',
  'Mickiewiczova',
  'Mikanova',
  'Mikova',
  'Mikovcova',
  'Mikovická',
  'Mikulandská',
  'Mikuleckého',
  'Mikulova',
  'Mikulovická',
  'Mikuláše Z Husi',
  'Mikulášská',
  'Mikulčická',
  'Mikšovského',
  'Milady Horákové',
  'Milady Horákové',
  'Milady Horákové',
  'Milady Horákové',
  'Milady Horákové',
  'Milana Kadlece',
  'Milenovská',
  'Milerova',
  'Miletická',
  'Miletínská',
  'Milevská',
  'Milevská',
  'Milešovská',
  'Milotická',
  'Milovická',
  'Milovická',
  'Milánská',
  'Milínská',
  'Milíčova',
  'Milíčovská',
  'Mimoňská',
  'Minaříkova',
  'Minerální',
  'Minická',
  'Minská',
  'Miranova',
  'Miroslava Hajna',
  'Miroslava Hamra',
  'Mirotická',
  'Mirotická',
  'Mirovická',
  'Mirošovická',
  'Mirošovská',
  'Mistrovská',
  'Mistřínská',
  'Miřetická',
  'Miškovická',
  'Mladenovova',
  'Mladoboleslavská',
  'Mladoboleslavská',
  'Mladoboleslavská',
  'Mladoboleslavská',
  'Mladoboleslavská',
  'Mladotická',
  'Mladotova',
  'Mladých',
  'Mladých Běchovic',
  'Mladčina',
  'Mladějovská',
  'Mlynářská',
  'Mládeže',
  'Mládežnická',
  'Mládkova',
  'Mládí',
  'Mlázovická',
  'Mlékárenská',
  'Mlýnská',
  'Mlýnská',
  'Mnichovická',
  'Mochovská',
  'Mochovská',
  'Modenská',
  'Modlanská',
  'Modletická',
  'Modletínská',
  'Modravská',
  'Modrá',
  'Modrého',
  'Modřanská',
  'Modřanská',
  'Modřanská',
  'Modřanská',
  'Modřínová',
  'Mohelnická',
  'Mohylová',
  'Mojmírova',
  'Mokrá',
  'Mokřanská',
  'Moldavská',
  'Molitorovská',
  'Molákova',
  'Mongolská',
  'Moravanská',
  'Moravanů',
  'Moravská',
  'Morseova',
  'Morstadtova',
  'Morušová',
  'Morušová',
  'Morávkova',
  'Moskevská',
  'Mostecká',
  'Motolská',
  'Moulíkova',
  'Moysesova',
  'Mozambická',
  'Mozartova',
  'Mošnova',
  'Možného',
  'Mramorová',
  'Mratínská',
  'Mračnická',
  'Mrkosova',
  'Mrkvičkova',
  'Mrákovská',
  'Mrázkova',
  'Mrázovka',
  'Mráčkova',
  'Mrštíkova',
  'Mrštíkova',
  'Muchomůrková',
  'Muchova',
  'Mukařovská',
  'Mukařovského',
  'Murgašova',
  'Murmanská',
  'Musilova',
  'Musorgského',
  'Musílkova',
  'Mutěnínská',
  'Muzejní',
  'Muzikova',
  'Muškova',
  'Mydlářka',
  'Myjavská',
  'Mylnerovka',
  'Myslbekova',
  'Myslbekova',
  'Myslivecká',
  'Myslivečkova',
  'Myslíkova',
  'Myslíkova',
  'Myšlínská',
  'Máchova',
  'Máchova',
  'Mádrova',
  'Májovková',
  'Májová',
  'Málkovská',
  'Mánesova',
  'Márova',
  'Máslova',
  'Máslovická',
  'Mátová',
  'Mílovská',
  'Mílová',
  'Mírová',
  'Mírového Hnutí',
  'Mírového Hnutí',
  'Místecká',
  'Míčova',
  'Míšeňská',
  'Míšovická',
  'Münzbergerových',
  'Mýtní',
  'Měchenická',
  'Měcholupská',
  'Měděnecká',
  'Mělická',
  'Mělnická',
  'Městská',
  'Měsíčková',
  'Měsíční',
  'Měšická',
  'Měšínská',
  'Mšecká',
  'Mšenská',
  'N. A. Někrasova',
  'Na Babách',
  'Na Babě',
  'Na Bahnech',
  'Na Balkáně',
  'Na Balkáně',
  'Na Bambouzku',
  'Na Baních',
  'Na Barikádách',
  'Na Bartoňce',
  'Na Bateriích',
  'Na Bateriích',
  'Na Bačálkách',
  'Na Baště Sv. Jiří',
  'Na Baště Sv. Ludmily',
  'Na Baště Sv. Tomáše',
  'Na Bendovce',
  'Na Benátkách',
  'Na Beránce',
  'Na Betonce',
  'Na Bečvářce',
  'Na Bitevní Pláni',
  'Na Blanici',
  'Na Blanseku',
  'Na Blatech',
  'Na Bluku',
  'Na Bohdalci',
  'Na Bojišti',
  'Na Boleslavce',
  'Na Borovém',
  'Na Botiči',
  'Na Botě',
  'Na Božkovně',
  'Na Brabenci',
  'Na Brázdě',
  'Na Bučance',
  'Na Bělici',
  'Na Bělidle',
  'Na Bělohorské Pláni',
  'Na Břehu',
  'Na Břevnovské Pláni',
  'Na Březince',
  'Na Celné',
  'Na Cestě',
  'Na Chmelnici',
  'Na Chobotě',
  'Na Chodovci',
  'Na Chvalce',
  'Na Chvalské Tvrzi',
  'Na Cihelně',
  'Na Cihlářce',
  'Na Cikorce',
  'Na Cikánce',
  'Na Cimbále',
  'Na Cípu',
  'Na Císařce',
  'Na Dionysce',
  'Na Dlouhé Mezi',
  'Na Dlouhé Mezi',
  'Na Dlouhé Mezi',
  'Na Dlouhé Mezi',
  'Na Dlouhém Lánu',
  'Na Dlážděnce',
  'Na Dlážděnce',
  'Na Dlážděnce',
  'Na Dlážděnce',
  'Na Dobešce',
  'Na Dobré Vodě',
  'Na Dolinách',
  'Na Dolinách',
  'Na Dolnici',
  'Na Dolíku',
  'Na Domovině',
  'Na Doubkové',
  'Na Drahách',
  'Na Dračkách',
  'Na Dračkách',
  'Na Dražkách',
  'Na Dubině',
  'Na Dvorcích',
  'Na Dyrince',
  'Na Dílcích',
  'Na Dílech',
  'Na Dědince',
  'Na Dědinách',
  'Na Děkance',
  'Na Děkance',
  'Na Dělostřílnách',
  'Na Džbánu',
  'Na Fabiánce',
  'Na Farkách',
  'Na Farkáně I',
  'Na Farkáně Ii',
  'Na Farkáně Iii',
  'Na Farkáně Iv',
  'Na Fialce I',
  'Na Fialce Ii',
  'Na Fidlovačce',
  'Na Fišerce',
  'Na Florenci',
  'Na Florenci',
  'Na Floře',
  'Na Folimance',
  'Na Formance',
  'Na Františku',
  'Na Groši',
  'Na Habrovce',
  'Na Habrové',
  'Na Hanspaulce',
  'Na Harfě',
  'Na Havránce',
  'Na Hlavní',
  'Na Hlinách',
  'Na Hloubětínské Vinici',
  'Na Hlídce',
  'Na Holém Vrchu',
  'Na Homolce',
  'Na Homoli',
  'Na Horce',
  'Na Horkách',
  'Na Hradním Vodovodu',
  'Na Hranicích',
  'Na Hranicích',
  'Na Hrobci',
  'Na Hroudě',
  'Na Hroudě',
  'Na Hrádku',
  'Na Hrázi',
  'Na Hubálce',
  'Na Humnech',
  'Na Hupech',
  'Na Hutmance',
  'Na Hutích',
  'Na Hutích',
  'Na Hvížďalce',
  'Na Hvězdárně',
  'Na Hádku',
  'Na Hájku',
  'Na Hřebenech I',
  'Na Hřebenech Ii',
  'Na Hřebenech Ii',
  'Na Hřebenkách',
  'Na Hůrce',
  'Na Jabloňce',
  'Na Jabloňce',
  'Na Jahodách',
  'Na Jarově',
  'Na Jelenách',
  'Na Jelenách',
  'Na Jetelce',
  'Na Jetelce',
  'Na Jezerce',
  'Na Jezerách',
  'Na Jitřence',
  'Na Jivinách',
  'Na Julisce',
  'Na Jílech',
  'Na Jílu',
  'Na Kameni',
  'Na Kampě',
  'Na Kapličce',
  'Na Karlovce',
  'Na Kavčích Horách',
  'Na Kazance',
  'Na Kačence',
  'Na Kačerově',
  'Na Kindlovce',
  'Na Klaudiánce',
  'Na Klaudiánce',
  'Na Kleovce',
  'Na Klikovce',
  'Na Klimentce',
  'Na Klášterním',
  'Na Klínech',
  'Na Klínech',
  'Na Klínku',
  'Na Knížce',
  'Na Kocourkách',
  'Na Kocínce',
  'Na Kodymce',
  'Na Kolejním Statku',
  'Na Komořsku',
  'Na Komořsku',
  'Na Konci',
  'Na Konečné',
  'Na Konvářce',
  'Na Kopanině',
  'Na Kopci',
  'Na Kopečku',
  'Na Kopytářce',
  'Na Korunce',
  'Na Korábě',
  'Na Korálově',
  'Na Kotlářce',
  'Na Koupaliště',
  'Na Kovárně',
  'Na Kozačce',
  'Na Kozinci',
  'Na Košince',
  'Na Košíku',
  'Na Kraji',
  'Na Krocínce',
  'Na Krutci',
  'Na Královce',
  'Na Královně',
  'Na Krčské Stráni',
  'Na Kuthence',
  'Na Kvintusce',
  'Na Květnici',
  'Na Kyjově',
  'Na Křemínku',
  'Na Křenkově',
  'Na Křečku',
  'Na Křivce',
  'Na Křivce',
  'Na Křivce',
  'Na Křivině',
  'Na Křtině',
  'Na Křídle',
  'Na Labuťce',
  'Na Labuťce I',
  'Na Labuťce Ii',
  'Na Labuťce Iii',
  'Na Labuťce Iv',
  'Na Ladách',
  'Na Lahovské',
  'Na Laurové',
  'Na Lepším',
  'Na Lhotech',
  'Na Lhotkách',
  'Na Libušince',
  'Na Losách',
  'Na Louce',
  'Na Loukoti',
  'Na Louži',
  'Na Loužku',
  'Na Luka',
  'Na Lukách',
  'Na Luzích',
  'Na Lučinách',
  'Na Lužci',
  'Na Lysinách',
  'Na Lysině',
  'Na Ládví',
  'Na Lánech',
  'Na Lávce',
  'Na Lázeňce',
  'Na Líše',
  'Na Malovance',
  'Na Malé Šárce',
  'Na Malém Klínu',
  'Na Maninách',
  'Na Manoušce',
  'Na Markvartce',
  'Na Marně',
  'Na Mezi',
  'Na Mlejnku',
  'Na Moklině',
  'Na Mokřině',
  'Na Moráni',
  'Na Močále',
  'Na Mrázovce',
  'Na Musilech',
  'Na Mírách',
  'Na Míčánce',
  'Na Míčánkách',
  'Na Mýtě',
  'Na Můstku',
  'Na Neklance',
  'Na Nežárce',
  'Na Nivách',
  'Na Novině',
  'Na Nové Silnici',
  'Na Náspu',
  'Na Návrati',
  'Na Návrší',
  'Na Návsi',
  'Na Obrátce',
  'Na Obrátce',
  'Na Odbočce',
  'Na Ohradě',
  'Na Okraji',
  'Na Okraji',
  'Na Okrouhlíku',
  'Na Okruhu',
  'Na Opyši',
  'Na Opyši',
  'Na Ostrohu',
  'Na Ostrově',
  'Na Ostrůvku',
  'Na Ovesníku',
  'Na Ovčinách',
  'Na Ovčáckém',
  'Na Ovčíně',
  'Na Ořechovce',
  'Na Padesátníku I',
  'Na Padesátníku Ii',
  'Na Padesátníku Iii',
  'Na Padesátníku Iv',
  'Na Padesátníku V',
  'Na Padesátém',
  'Na Pahorku',
  'Na Pahoubce',
  'Na Palouku',
  'Na Paloučku',
  'Na Pankráci',
  'Na Panorámě',
  'Na Parcelách',
  'Na Parkáně',
  'Na Parukářce',
  'Na Pasece',
  'Na Pasece',
  'Na Pastvinách',
  'Na Pavím Vrchu',
  'Na Pazderce',
  'Na Pecích',
  'Na Pernikářce',
  'Na Perštýně',
  'Na Petynce',
  'Na Petynce',
  'Na Petřinách',
  'Na Petřinách',
  'Na Placích',
  'Na Planině',
  'Na Plužině',
  'Na Plzeňce',
  'Na Plácku',
  'Na Pláni',
  'Na Plískavě',
  'Na Podkovce',
  'Na Pokraji',
  'Na Pokraji',
  'Na Poli',
  'Na Polníku',
  'Na Pomezí',
  'Na Pomezí',
  'Na Popelce',
  'Na Popelce',
  'Na Potůčku',
  'Na Poustkách',
  'Na Pozorce',
  'Na Poříčním Právu',
  'Na Poříčí',
  'Na Poříčí',
  'Na Požáru',
  'Na Požáru',
  'Na Pramenech',
  'Na Pramenech',
  'Na Prosecké Vyhlídce',
  'Na Proseku',
  'Na Prostřední Cestě',
  'Na Proutcích',
  'Na Provaznici',
  'Na Průhonu',
  'Na Průseku',
  'Na Pučálce',
  'Na Pískovně',
  'Na Písku',
  'Na Pískách',
  'Na Pěkné Vyhlídce',
  'Na Pěšinách',
  'Na Pěšinách',
  'Na Pěšině',
  'Na Předevsi',
  'Na Přesypu',
  'Na Přesypu',
  'Na Přídole',
  'Na Příkopě',
  'Na Příkopě',
  'Na Přívozích',
  'Na Příčce',
  'Na Příčné Mezi',
  'Na Radosti',
  'Na Radosti',
  'Na Rampách',
  'Na Rejdišti',
  'Na Roháčku',
  'Na Rokytce',
  'Na Rolích',
  'Na Rovinách',
  'Na Rovině',
  'Na Rovni',
  'Na Rovnosti',
  'Na Rovném',
  'Na Rozcestí',
  'Na Rozdílu',
  'Na Rozdílu',
  'Na Rozhledu',
  'Na Rozhraní',
  'Na Rozhraní',
  'Na Rozvodí',
  'Na Ročkově',
  'Na Rybníčku',
  'Na Rybářce',
  'Na Rybářce',
  'Na Rymáni',
  'Na Rynku',
  'Na Salabce',
  'Na Samotě',
  'Na Schodech',
  'Na Schůdkách',
  'Na Sedlišti',
  'Na Sekyrce',
  'Na Selském',
  'Na Seníku',
  'Na Skalce',
  'Na Skalách',
  'Na Sklonku',
  'Na Skále',
  'Na Slatince',
  'Na Slatinách',
  'Na Slatinách',
  'Na Slatinách',
  'Na Slavíkově',
  'Na Slovance',
  'Na Slupi',
  'Na Slupi',
  'Na Smetance',
  'Na Souvrati',
  'Na Souvrati',
  'Na Spojce',
  'Na Spádu',
  'Na Spáleništi',
  'Na Srpečku',
  'Na Srázu',
  'Na Srážku',
  'Na Staré',
  'Na Staré Cestě',
  'Na Staré Návsi',
  'Na Staré Silnici',
  'Na Staré Vinici',
  'Na Stezce',
  'Na Stezce',
  'Na Struze',
  'Na Stráni',
  'Na Stráňkách',
  'Na Stráži',
  'Na Stráži',
  'Na Strži',
  'Na Strži',
  'Na Stupních',
  'Na Stárce',
  'Na Stírce',
  'Na Střelnici',
  'Na Svahu',
  'Na Svěcence',
  'Na Sychrově',
  'Na Sychrově',
  'Na Sypkém',
  'Na Sypčině',
  'Na Sádce',
  'Na Terase',
  'Na Topolce',
  'Na Topolce',
  'Na Truhlářce',
  'Na Tržišti',
  'Na Tykačce',
  'Na Táboře',
  'Na Třebešíně',
  'Na Třebešíně',
  'Na Universitním Statku',
  'Na Usedlosti',
  'Na Vackově',
  'Na Valech',
  'Na Valentince',
  'Na Vartě',
  'Na Vaňhově',
  'Na Veselí',
  'Na Vidouli',
  'Na Viktorce',
  'Na Vinici',
  'Na Viničce',
  'Na Viničkách',
  'Na Viničních Horách',
  'Na Vinobraní',
  'Na Vinohradu',
  'Na Višňovce',
  'Na Vlasačce',
  'Na Vlastní Půdě',
  'Na Vlastním',
  'Na Vlku',
  'Na Vlčovce',
  'Na Volánové',
  'Na Vrchmezí',
  'Na Vrchmezí',
  'Na Vrchmezí',
  'Na Vrcholu',
  'Na Vrchu',
  'Na Vrchu',
  'Na Vrchách',
  'Na Vrchách',
  'Na Vrstevnici',
  'Na Vrstvách',
  'Na Vršku',
  'Na Vrškách',
  'Na Vrších',
  'Na Vrších',
  'Na Vydrholci',
  'Na Vyhlídce',
  'Na Vypichu',
  'Na Vypichu',
  'Na Vysoké I',
  'Na Vysoké I',
  'Na Vysoké Ii',
  'Na Vysočanských Vinicích',
  'Na Vysočině',
  'Na Václavce',
  'Na Vápence',
  'Na Vápenném',
  'Na Vítězné Pláni',
  'Na Výběžku',
  'Na Výhledech',
  'Na Výhonku',
  'Na Výrovně',
  'Na Výsledku I',
  'Na Výsledku Ii',
  'Na Výsluní',
  'Na Výspě',
  'Na Výspě',
  'Na Výstupu',
  'Na Výtoni',
  'Na Výši',
  'Na Výšince',
  'Na Výšinách',
  'Na Výšině',
  'Na Věnečku',
  'Na Větrníku',
  'Na Větrníku',
  'Na Větrově',
  'Na Větru',
  'Na Zahrádkách',
  'Na Zatlance',
  'Na Zavadilce',
  'Na Zbořenci',
  'Na Zderaze',
  'Na Zedníkové',
  'Na Zelené Louce',
  'Na Zemance',
  'Na Zkratce',
  'Na Zlatnici',
  'Na Zlaté',
  'Na Zlíchově',
  'Na Zlíchově',
  'Na Zmrzlíku',
  'Na Znělci',
  'Na Zvoničce',
  'Na Zábradlí',
  'Na Záhonech',
  'Na Zájezdu',
  'Na Zámecké',
  'Na Zámkách',
  'Na Zámyšli',
  'Na Zástřelu',
  'Na Zástřelu',
  'Na Zátorce',
  'Na Zátorách',
  'Na Závěji',
  'Na Úbočí',
  'Na Úhoru',
  'Na Úlehli',
  'Na Úseku',
  'Na Úspěchu',
  'Na Černé Hoře',
  'Na Černé Strouze',
  'Na Černém Vrchu',
  'Na Července',
  'Na Čečeličce',
  'Na Čihadle',
  'Na Čisté',
  'Na Říháku',
  'Na Šabatce',
  'Na Šachtě',
  'Na Šafránce',
  'Na Šancích',
  'Na Šedivé',
  'Na Šejdru',
  'Na Šejdru',
  'Na Šmukýřce',
  'Na Špejcharu',
  'Na Špitálce',
  'Na Špitálsku',
  'Na Štamberku',
  'Na Štěpnici',
  'Na Šubě',
  'Na Šumavě',
  'Na Šutce',
  'Na Švihance',
  'Na Šťáhlavce',
  'Na Žertvách',
  'Na Žvahově',
  'Naardenská',
  'Nad Akcízem',
  'Nad Akáty',
  'Nad Alejí',
  'Nad Belvederem',
  'Nad Belárií',
  'Nad Berounkou',
  'Nad Bertramkou',
  'Nad Botičem',
  'Nad Bořislavkou',
  'Nad Bořislavkou',
  'Nad Branickým Pivovarem',
  'Nad Brůdkem',
  'Nad Brůdkem',
  'Nad Buďánkami I',
  'Nad Buďánkami Ii',
  'Nad Buďánkami Iii',
  'Nad Cementárnou',
  'Nad Chaloupkami',
  'Nad Chuchlí',
  'Nad Cihelnou',
  'Nad Dalejským Údolím',
  'Nad Doly',
  'Nad Dolíky',
  'Nad Drahou',
  'Nad Dubovým Mlýnem',
  'Nad Dvorem',
  'Nad Dálnicí',
  'Nad Elektrárnou',
  'Nad Elektrárnou',
  'Nad Flajšnerkou',
  'Nad Habrovkou',
  'Nad Havlem',
  'Nad Helmrovkou',
  'Nad Hercovkou',
  'Nad Hercovkou',
  'Nad Hliníkem',
  'Nad Hliníkem',
  'Nad Horizontem',
  'Nad Hradním Potokem',
  'Nad Hradním Vodojemem',
  'Nad Husovými Sady',
  'Nad Hutěmi',
  'Nad Hutěmi',
  'Nad Hájem',
  'Nad Hřištěm',
  'Nad Jenerálkou',
  'Nad Jetelkou',
  'Nad Jezem',
  'Nad Jezerkou',
  'Nad Jordánkem',
  'Nad Kajetánkou',
  'Nad Kamínkou',
  'Nad Kaplankou',
  'Nad Kapličkou',
  'Nad Kavalírkou',
  'Nad Kazankou',
  'Nad Kazínem',
  'Nad Kelerkou',
  'Nad Kesnerkou',
  'Nad Klamovkou',
  'Nad Klikovkou',
  'Nad Klíčovem',
  'Nad Kolonií',
  'Nad Kolčavkou',
  'Nad Komornickou',
  'Nad Konečnou',
  'Nad Konvářkou',
  'Nad Kostelem',
  'Nad Kotlaskou I',
  'Nad Kotlaskou Ii',
  'Nad Kotlaskou Iii',
  'Nad Kotlaskou Iv',
  'Nad Kotlaskou V',
  'Nad Koulkou',
  'Nad Koupadly',
  'Nad Koupalištěm',
  'Nad Košinkou',
  'Nad Košíkem',
  'Nad Krocínkou',
  'Nad Krocínkou',
  'Nad Královskou Oborou',
  'Nad Kuliškou',
  'Nad Kundratkou',
  'Nad Kundratkou',
  'Nad Kundratkou',
  'Nad Křížkem',
  'Nad Laurovou',
  'Nad Lesem',
  'Nad Lesním Divadlem',
  'Nad Lesíkem',
  'Nad Libeňským Nádražím',
  'Nad Libeřským Potokem',
  'Nad Libušským Potokem',
  'Nad Libří',
  'Nad Lomem',
  'Nad Lomy',
  'Nad Lukami',
  'Nad Lávkou',
  'Nad Malým Mýtem',
  'Nad Manovkou',
  'Nad Markytou',
  'Nad Mazankou',
  'Nad Meandry',
  'Nad Mlynářkou',
  'Nad Mlýnem',
  'Nad Mlýnským Potokem',
  'Nad Mohylou',
  'Nad Mokřinou',
  'Nad Mostem',
  'Nad Motolskou Nemocnicí',
  'Nad Motolskou Nemocnicí',
  'Nad Mrázovkou',
  'Nad Mušlovkou',
  'Nad Mušlovkou',
  'Nad Novou Libní',
  'Nad Nuslemi',
  'Nad Nádražím',
  'Nad Nádrží',
  'Nad Náhonem',
  'Nad Náměstím',
  'Nad Návsí',
  'Nad Obcí I',
  'Nad Obcí Ii',
  'Nad Octárnou',
  'Nad Odbočkou',
  'Nad Ohradou',
  'Nad Okrouhlíkem',
  'Nad Olšinami',
  'Nad Olšinami',
  'Nad Ondřejovem',
  'Nad Opatovem',
  'Nad Ostrovem',
  'Nad Pahorkem',
  'Nad Palatou',
  'Nad Panenskou',
  'Nad Parkem',
  'Nad Parkánem',
  'Nad Paťankou',
  'Nad Pentlovkou',
  'Nad Petruskou',
  'Nad Petynkou',
  'Nad Plynovodem',
  'Nad Podbabskou Skálou',
  'Nad Pomníkem',
  'Nad Popelkou',
  'Nad Popelářkou',
  'Nad Potůčkem',
  'Nad Prahou',
  'Nad Pramenem',
  'Nad Primaskou',
  'Nad Primaskou',
  'Nad Propustí',
  'Nad Pruhy',
  'Nad Pískovnou',
  'Nad Přehradou',
  'Nad Přívozem',
  'Nad Radotínem',
  'Nad Rohatci',
  'Nad Roklí',
  'Nad Rokoskou',
  'Nad Rokytkou',
  'Nad Rybníkem',
  'Nad Rybníkem',
  'Nad Rybníčky',
  'Nad Ryšánkou',
  'Nad Rážákem',
  'Nad Sadem',
  'Nad Sady',
  'Nad Santoškou',
  'Nad Schody',
  'Nad Skálou',
  'Nad Slávií',
  'Nad Slávií',
  'Nad Smetankou',
  'Nad Sokolovnou',
  'Nad Soutokem',
  'Nad Soutokem',
  'Nad Splavem',
  'Nad Spádem',
  'Nad Spáleným Mlýnem',
  'Nad Stanicí',
  'Nad Starou Pískovnou',
  'Nad Statkem',
  'Nad Strakovkou',
  'Nad Strouhou',
  'Nad Strání',
  'Nad Strání',
  'Nad Studánkou',
  'Nad Svahem',
  'Nad Sýpkou',
  'Nad Tejnkou',
  'Nad Teplárnou',
  'Nad Topoly',
  'Nad Tratí',
  'Nad Trnkovem',
  'Nad Trojou',
  'Nad Turbovou',
  'Nad Třebešínem I',
  'Nad Třebešínem Ii',
  'Nad Třebešínem Ii',
  'Nad Třebešínem Iii',
  'Nad Třebešínem Iii',
  'Nad Vavrouškou',
  'Nad Vernerákem',
  'Nad Vinicí',
  'Nad Vinným Potokem',
  'Nad Vinným Potokem',
  'Nad Vinným Potokem',
  'Nad Vinohradem',
  'Nad Višňovkou',
  'Nad Vltavou',
  'Nad Vodovodem',
  'Nad Vodovodem',
  'Nad Vojenským Hřbitovem',
  'Nad Vokolky',
  'Nad Volyňkou',
  'Nad Vrbami',
  'Nad Vrstvami',
  'Nad Vršovskou Horou',
  'Nad Vsí',
  'Nad Vysočany',
  'Nad Václavkou',
  'Nad Výpustí',
  'Nad Výšinkou',
  'Nad Zahradnictvím',
  'Nad Zatáčkou',
  'Nad Zavážkou',
  'Nad Zbraslaví',
  'Nad Zbrojnicí',
  'Nad Zemankou',
  'Nad Zemankou',
  'Nad Zlatnicí',
  'Nad Zlíchovem',
  'Nad Záložnou',
  'Nad Zámečkem',
  'Nad Zámečnicí',
  'Nad Zátiším',
  'Nad Závodištěm',
  'Nad Závěrkou',
  'Nad Údolím',
  'Nad Údolím Hvězd',
  'Nad Úpadem',
  'Nad Úvozem',
  'Nad Úžlabinou',
  'Nad Úžlabinou',
  'Nad Šafránkou',
  'Nad Šancemi',
  'Nad Šauerovými Sady',
  'Nad Šeberákem',
  'Nad Šejdrem',
  'Nad Šestikopy',
  'Nad Šetelkou',
  'Nad Štolou',
  'Nad Šutkou',
  'Nad Šálkovnou',
  'Nad Šárkou',
  'Nad Želivkou',
  'Nad Žlábkem',
  'Nademlejnská',
  'Nadějovská',
  'Narcisová',
  'Naskové',
  'Natanaelka',
  'Navarova',
  'Navigátorů',
  'Navrátilova',
  'Načeradecká',
  'Načešická',
  'Neapolská',
  'Nebeského',
  'Nebovidská',
  'Nebozízek-Sady',
  'Nebušická',
  'Nechanická',
  'Nechanského',
  'Nechvalická',
  'Nechvílova',
  'Nechybova',
  'Nedašovská',
  'Nedbalova',
  'Nedokončená',
  'Nedokončená',
  'Nedošínské',
  'Nedražická',
  'Nedvědická',
  'Nedvědovo Nám.',
  'Nedvědovo Náměstí',
  'Nedvězská',
  'Neffova',
  'Nefritová',
  'Neherovská',
  'Nehvizdská',
  'Nehvizdská',
  'Nejdkova',
  'Neklanova',
  'Nekvasilova',
  'Nekázanka',
  'Nemocniční',
  'Nemošická',
  'Nepasické Nám.',
  'Nepasické Náměstí',
  'Nepelova',
  'Nepilova',
  'Nepomucká',
  'Nepomuckých',
  'Nepovolená',
  'Nepravidelná',
  'Neprůjezdná',
  'Nepálská',
  'Neratovická',
  'Nerudova',
  'Nerudova',
  'Nesměřická',
  'Nespecká',
  'Nesvadbova',
  'Netlucká',
  'Netluky',
  'Netolická',
  'Netušilská',
  'Netínská',
  'Netřebická',
  'Netřebská',
  'Neumannova',
  'Neustupného',
  'Neužilova',
  'Nevanova',
  'Neveklovská',
  'Newtonova',
  'Nezamyslova',
  'Nezdova',
  'Nezvalova',
  'Nečova',
  'Nešporova',
  'Nežárská',
  'Nickerleho',
  'Niederleho',
  'Nikodémova',
  'Nikoly Tesly',
  'Nikoly Vapcarova',
  'Niská',
  'Nitranská',
  'Nitranská',
  'Nivnická',
  'Nobelova',
  'Norbertov',
  'Norská',
  'Nosická',
  'Nosticova',
  'Notečská',
  'Noutonická',
  'Nouzov',
  'Nouzovské Nám.',
  'Nouzovské Náměstí',
  'Nouzová',
  'Novgorodská',
  'Novobohdalecká',
  'Novoborská',
  'Novoborská',
  'Novochuchelská',
  'Novodvorská',
  'Novodvorská',
  'Novodvorská',
  'Novodvorská',
  'Novohradská',
  'Novohrádecká',
  'Novohrádecká',
  'Novolhotská',
  'Novolipanská',
  'Novomeského',
  'Novomeského',
  'Novomlýnská',
  'Novopacká',
  'Novopetrovická',
  'Novorossijská',
  'Novosibřinská',
  'Novostrašnická',
  'Novosuchdolská',
  'Novosvětská',
  'Novotného Lávka',
  'Novoveská',
  'Novoveská',
  'Novovysočanská',
  'Novovysočanská',
  'Novovysočanská',
  'Novozámecká',
  'Novozámecká',
  'Novoškolská',
  'Novoštěrboholská',
  'Nová',
  'Nová Cesta',
  'Nová Kolonie',
  'Nová Ves',
  'Nová Ves',
  'Nová Šárka',
  'Novákovo Nám.',
  'Novákovo Náměstí',
  'Novákových',
  'Nové Domy',
  'Nové Dvory',
  'Nové Mlýny',
  'Nové Náměstí',
  'Nového',
  'Nový Lesík',
  'Nový Svět',
  'Nový Zlíchov',
  'Nový Zlíchov',
  'Nupacká',
  'Nuselská',
  'Nuselská',
  'Nučická',
  'Nušlova',
  'Nymburská',
  'Nábř. Edvarda Beneše',
  'Nábř. Edvarda Beneše',
  'Nábř. Edvarda Beneše',
  'Nábř. Kapitána Jaroše',
  'Nábř. Kapitána Jaroše',
  'Nábřežní',
  'Nábřeží Edvarda Beneše',
  'Nábřeží Edvarda Beneše',
  'Nábřeží Edvarda Beneše',
  'Nábřeží Kapitána Jaroše',
  'Nábřeží Ludvíka Svobody',
  'Náchodská',
  'Nádražní',
  'Nádražní',
  'Nádvorní',
  'Náhorní',
  'Nákupní',
  'Nám. 14. Října',
  'Nám. 25. Března',
  'Nám. Antonína Pecáka',
  'Nám. Barikád',
  'Nám. Bořislavka',
  'Nám. Bratří Synků',
  'Nám. Chuchelských Bojovníků',
  'Nám. Chuchleských Bojovníků',
  'Nám. Curieových',
  'Nám. Dr. V. Holého',
  'Nám. Franze Kafky',
  'Nám. Generála Kutlvašra',
  'Nám. Hrdinů',
  'Nám. I. P. Pavlova',
  'Nám. Interbrigády',
  'Nám. Jana Palacha',
  'Nám. Jana Palacha',
  'Nám. Jiřího Berana',
  'Nám. Jiřího Z Lobkovic',
  'Nám. Jiřího Z Poděbrad',
  'Nám. Jiřího Z Poděbrad',
  'Nám. Josefa Machka',
  'Nám. Kinských',
  'Nám. Kinských',
  'Nám. Mezi Zahrádkami',
  'Nám. Na Balabence',
  'Nám. Na Farkáně',
  'Nám. Na Lužinách',
  'Nám. Na Santince',
  'Nám. Na Stráži',
  'Nám. Omladiny',
  'Nám. Osvoboditelů',
  'Nám. Padlých',
  'Nám. Pod Kaštany',
  'Nám. Pod Lípou',
  'Nám. Prezidenta Masaryka',
  'Nám. Před Bateriemi',
  'Nám. Republiky',
  'Nám. Smiřických',
  'Nám. Svatopluka Čecha',
  'Nám. Svobody',
  'Nám. U Lva',
  'Nám. U Lípy Svobody',
  'Nám. U Svatého Jiří',
  'Nám. Winstona Churchilla',
  'Nám. Českého Povstání',
  'Nám.Organizace Spojených Národ',
  'Nám.Plukovníka Vlčka',
  'Náměstí 14. Října',
  'Náměstí 25. Března',
  'Náměstí Antonína Pecáka',
  'Náměstí Barikád',
  'Náměstí Bořislavka',
  'Náměstí Bořislavka',
  'Náměstí Bratří Jandusů',
  'Náměstí Bratří Synků',
  'Náměstí Chuchelských Bojovníků',
  'Náměstí Curieových',
  'Náměstí Dr. Václava Holého',
  'Náměstí Generála Kutlvašra',
  'Náměstí Hrdinů',
  'Náměstí I. P. Pavlova',
  'Náměstí Interbrigády',
  'Náměstí Jana Palacha',
  'Náměstí Jana Palacha',
  'Náměstí Jiřího Berana',
  'Náměstí Jiřího Z Lobkovic',
  'Náměstí Jiřího Z Poděbrad',
  'Náměstí Jiřího Z Poděbrad',
  'Náměstí Josefa Machka',
  'Náměstí Junkových',
  'Náměstí Kinských',
  'Náměstí Kinských',
  'Náměstí Kosmonautů',
  'Náměstí Mezi Zahrádkami',
  'Náměstí Míru',
  'Náměstí Na Balabence',
  'Náměstí Na Farkáně',
  'Náměstí Na Lužinách',
  'Náměstí Na Santince',
  'Náměstí Na Stráži',
  'Náměstí Omladiny',
  'Náměstí Organizace Spojených Národů',
  'Náměstí Osvoboditelů',
  'Náměstí Padlých',
  'Náměstí Plukovníka Vlčka',
  'Náměstí Pod Emauzy',
  'Náměstí Pod Kaštany',
  'Náměstí Pod Lípou',
  'Náměstí Prezidenta Masaryka',
  'Náměstí Protifašistických Bojovníků',
  'Náměstí Před Bateriemi',
  'Náměstí Přátelství',
  'Náměstí Republiky',
  'Náměstí Republiky',
  'Náměstí Smiřických',
  'Náměstí Sv. Petra A Pavla',
  'Náměstí Svatopluka Čecha',
  'Náměstí Svobody',
  'Náměstí U Lva',
  'Náměstí U Lípy Svobody',
  'Náměstí U Svatého Jiří',
  'Náměstí Winstona Churchilla',
  'Náměstí Zdenky Braunerové',
  'Náměstí Českého Povstání',
  'Náplavní',
  'Náprstkova',
  'Národní',
  'Národní',
  'Národní Obrany',
  'Národních Hrdinů',
  'Nárožní',
  'Násirovo Nám.',
  'Násirovo Náměstí',
  'Nástrojářská',
  'Návazná',
  'Návršní',
  'Návětrná',
  'Návětrná',
  'Názovská',
  'Nýdecká',
  'Nýrská',
  'Nýřanská',
  'Němčická',
  'Něvská',
  'Obchodní',
  'Obchodní Nám.',
  'Obchodní Náměstí',
  'Obilní',
  'Objízdná',
  'Oblouková',
  'Obora Hvězda',
  'Oborská',
  'Obrataňská',
  'Obrovského',
  'Obsiny',
  'Obslužná',
  'Obvodová',
  'Obědovická',
  'Obětí 6. Května',
  'Obětí 6.Května',
  'Ocelkova',
  'Ocelářská',
  'Ocelářská',
  'Ocelíkova',
  'Ochozská',
  'Ochranovská',
  'Od Rozcestí',
  'Od Vysoké',
  'Od Školy',
  'Odboje',
  'Odborů',
  'Odbočná',
  'Oddechová',
  'Oddělená',
  'Oderská',
  'Odlehlá',
  'Ohmova',
  'Ohnivcova',
  'Ohnišťanská',
  'Ohradní',
  'Ohradní',
  'Ohradská',
  'Ohradské Nám.',
  'Ohradské Náměstí',
  'Ohrobecká',
  'Okenská',
  'Okořská',
  'Okrajní',
  'Okrajová',
  'Okrajová',
  'Okrasná',
  'Okrouhlická',
  'Okrouhlíkova',
  'Okrová',
  'Okruhová',
  'Okružní',
  'Okružní',
  'Okřínecká',
  'Olbrachtova',
  'Olbramovická',
  'Oldřichova',
  'Olešnická',
  'Olešská',
  'Olgy Havlové',
  'Olivova',
  'Olomoucká',
  'Olympijská',
  'Olšanská',
  'Olšanské Nám.',
  'Olšanské Náměstí',
  'Olšovická',
  'Olšová',
  'Olštýnská',
  'Omladinářů',
  'Omská',
  'Ondřejovská',
  'Ondříčkova',
  'Ondříčkova',
  'Onšovecká',
  'Opata Konráda',
  'Opatovická',
  'Opatovská',
  'Opatovská',
  'Opatřilka',
  'Opatřilka',
  'Opařanská',
  'Oplanská',
  'Opletalova',
  'Opolská',
  'Opočenská',
  'Opočínská',
  'Opravářská',
  'Opuková',
  'Opálkova',
  'Opálová',
  'Oravská',
  'Ordovická',
  'Orebitská',
  'Orelská',
  'Orlická',
  'Ortenovo Náměstí',
  'Osadní',
  'Osamocená',
  'Osecká',
  'Osetá',
  'Osická',
  'Osiková',
  'Osinalická',
  'Osluněná',
  'Osmého Listopadu',
  'Osnická',
  'Osnická',
  'Osnická',
  'Ostravická',
  'Ostravská',
  'Ostromečská',
  'Ostrov Štvanice',
  'Ostrovní',
  'Ostrovského',
  'Ostruženská',
  'Ostružinová',
  'Ostrá',
  'Ostrčilovo Nám.',
  'Ostrčilovo Náměstí',
  'Ostředecká',
  'Ostřicová',
  'Osvobození',
  'Osvětová',
  'Otakara Vrby',
  'Otakarova',
  'Otavova',
  'Otavova',
  'Otavská',
  'Otevřená',
  'Otická',
  'Otlíkovská',
  'Otopašská',
  'Otovická',
  'Otradovická',
  'Ottova',
  'Otvovická',
  'Oty Pavla',
  'Otínská',
  'Otěšínská',
  'Ouholická',
  'Ouhrabkova',
  'Ovenecká',
  'Ovenecká',
  'Ovesná',
  'Ovocná',
  'Ovocnářská',
  'Ovocný Trh',
  'Ovsíková',
  'Oválová',
  'Ovčárská',
  'Ovčí Hájek',
  'Ořechová',
  'Ořešská',
  'Paběnická',
  'Paběnická',
  'Pacajevova',
  'Paceřická',
  'Pacholíkova',
  'Pacovská',
  'Paculova',
  'Padovská',
  'Pajerova',
  'Pakoměřická',
  'Palackého',
  'Palackého Nám.',
  'Palackého Náměstí',
  'Palmetová',
  'Palmovka',
  'Paláskova',
  'Pampelišková',
  'Pancířova',
  'Panelová',
  'Panenky',
  'Panenská',
  'Pankrácké Náměstí',
  'Panská',
  'Panská Zahrada',
  'Panský Dvůr',
  'Panuškova',
  'Paprsková',
  'Papírenská',
  'Papírníkova',
  'Parašutistů',
  'Pardubická',
  'Park Přátelství',
  'Parková',
  'Parléřova',
  'Parléřova',
  'Parmská',
  'Paroplavební',
  'Partyzánská',
  'Pasecká',
  'Pasteurova',
  'Pastevců',
  'Patočkova',
  'Patočkova',
  'Patočkova',
  'Pavelkova',
  'Pavla Beneše',
  'Pavla Švandy Ze Semčic',
  'Pavlická',
  'Pavlišovská',
  'Pavlovická',
  'Pavlovská',
  'Pavlíkova',
  'Pavrovského',
  'Paříkova',
  'Pařízkova',
  'Pařížská',
  'Pařížská',
  'Paškova',
  'Paťanka',
  'Peceradská',
  'Pecharova',
  'Pechlátova',
  'Pechlátova',
  'Pecháčkova',
  'Peckova',
  'Pejevové',
  'Pekařova',
  'Pekařova',
  'Pekařská',
  'Pekárenská',
  'Pekárkova',
  'Pelclova',
  'Pelechovská',
  'Pelhřimovská',
  'Pelikánova',
  'Pelléova',
  'Pelléova',
  'Pelnářova',
  'Pelušková',
  'Pelyňková',
  'Pelzova',
  'Penízovková',
  'Perlitová',
  'Perlitová',
  'Perlová',
  'Pernerova',
  'Pernerova',
  'Peroutkova',
  'Peroutkova',
  'Peroutkova',
  'Peroutkova',
  'Perspektivní',
  'Pertoldova',
  'Perucká',
  'Perunova',
  'Perštejnská',
  'Petra Bezruče',
  'Petra Rezka',
  'Petra Slezáka',
  'Petrbokova',
  'Petrklíčová',
  'Petrohradská',
  'Petrovická',
  'Petrovská',
  'Petrská',
  'Petrské Nám.',
  'Petrské Náměstí',
  'Petráčkova',
  'Petržílkova',
  'Petržílova',
  'Petýrkova',
  'Petříkova',
  'Petříkovská',
  'Petřínská',
  'Petřínská',
  'Petřínské Sady',
  'Petřínské Sady',
  'Pevnostní',
  'Pečárková',
  'Pešinova',
  'Peškova',
  'Pešlova',
  'Pešova',
  'Peštukova',
  'Pešákova',
  'Picassova',
  'Pickova',
  'Pihelská',
  'Pikovická',
  'Pikrtova',
  'Pilařská',
  'Pilníkovská',
  'Pilotů',
  'Pilovská',
  'Pilovská',
  'Pilská',
  'Pirinská',
  'Pirnerova',
  'Pitkovická',
  'Pitterova',
  'Pivcova',
  'Pivovarnická',
  'Pivovarská',
  'Pivoňková',
  'Pištěkova',
  'Placina',
  'Placina',
  'Plajnerova',
  'Plamínkové',
  'Plaská',
  'Platanová',
  'Platnéřská',
  'Platónova',
  'Plavecká',
  'Plavínová',
  'Plačická',
  'Plaňanská',
  'Plevenská',
  'Plečnikova',
  'Plhovská',
  'Plickova',
  'Plkovská',
  'Plojharova',
  'Ploskovická',
  'Ploučnická',
  'Plovdivská',
  'Plošná',
  'Ploštilova',
  'Plukovníka Mráze',
  'Plumlovská',
  'Plutova',
  'Plynární',
  'Plzeňská',
  'Plzeňská',
  'Plzeňská',
  'Plzeňská',
  'Plzeňská',
  'Plánická',
  'Pláničkova',
  'Poberova',
  'Pobočná',
  'Pobořská',
  'Poběžovická',
  'Pobřežní',
  'Pobřežní Cesta',
  'Pod Akáty',
  'Pod Altánem',
  'Pod Altánem',
  'Pod Andělkou',
  'Pod Areálem',
  'Pod Aritmou',
  'Pod Ateliéry',
  'Pod Bahnivkou',
  'Pod Balkánem',
  'Pod Barvířkou',
  'Pod Bateriemi',
  'Pod Baštami',
  'Pod Belvederem',
  'Pod Belárií',
  'Pod Beránkem',
  'Pod Beránkou',
  'Pod Betání',
  'Pod Bohdalcem I',
  'Pod Bohdalcem I',
  'Pod Bohdalcem Ii',
  'Pod Brentovou',
  'Pod Bruskou',
  'Pod Buďánkou',
  'Pod Bání',
  'Pod Březinou',
  'Pod Chaloupkami',
  'Pod Chodovem',
  'Pod Cihelnou',
  'Pod Cihelnou',
  'Pod Cukrákem',
  'Pod Císařkou',
  'Pod Dlážděnkou',
  'Pod Domky',
  'Pod Drinopolem',
  'Pod Dráhou',
  'Pod Duby',
  'Pod Dvorem',
  'Pod Dálnicí',
  'Pod Děkankou',
  'Pod Děkankou',
  'Pod Děvínem',
  'Pod Farou',
  'Pod Fialkou',
  'Pod Formankou',
  'Pod Fořtem',
  'Pod Garážemi',
  'Pod Habrovkou',
  'Pod Habrovou',
  'Pod Haltýřem',
  'Pod Harfou',
  'Pod Havlínem',
  'Pod Havránkou',
  'Pod Havránkou',
  'Pod Hliništěm',
  'Pod Hloubětínskou Zastávkou',
  'Pod Hláskem',
  'Pod Homolkou',
  'Pod Hotelem',
  'Pod Hořavkou',
  'Pod Hrachovkou',
  'Pod Hradbami',
  'Pod Hradem',
  'Pod Hranicí',
  'Pod Hrází',
  'Pod Hvězdou',
  'Pod Hvězdárnou',
  'Pod Hvězdárnou',
  'Pod Hybšmankou',
  'Pod Hájem',
  'Pod Hájkem',
  'Pod Hájovnou',
  'Pod Hřbitovem',
  'Pod Hřištěm',
  'Pod Jalovým Dvorem',
  'Pod Jankovem',
  'Pod Jarovem',
  'Pod Javory',
  'Pod Jiráskovou Čtvrtí',
  'Pod Juliskou',
  'Pod Kamínkou',
  'Pod Kapličkou',
  'Pod Kapličkou',
  'Pod Karlovarskou Silnicí',
  'Pod Karlovem',
  'Pod Kavalírkou',
  'Pod Kaštany',
  'Pod Kaštany',
  'Pod Kesnerkou',
  'Pod Kladenskou Silnicí',
  'Pod Klamovkou',
  'Pod Klapicí',
  'Pod Klaudiánkou',
  'Pod Klikovkou',
  'Pod Kopcem',
  'Pod Kostelem',
  'Pod Kotlaskou',
  'Pod Kotlářkou',
  'Pod Kotlářkou',
  'Pod Kotlářkou',
  'Pod Krejcárkem',
  'Pod Krocínkou',
  'Pod Královkou',
  'Pod Krčským Lesem',
  'Pod Kulturním Domem',
  'Pod Kynclovkou',
  'Pod Křížem',
  'Pod Křížkem',
  'Pod Labuťkou',
  'Pod Lahovskou',
  'Pod Lesem',
  'Pod Lesíkem',
  'Pod Letištěm',
  'Pod Lečí',
  'Pod Lipami',
  'Pod Lipkami',
  'Pod Lisem',
  'Pod Lisem',
  'Pod Lochkovem',
  'Pod Lomem',
  'Pod Lysinami',
  'Pod Lázní',
  'Pod Marjánkou',
  'Pod Markétou',
  'Pod Martinem',
  'Pod Meliškou',
  'Pod Mlýnkem',
  'Pod Mohylou',
  'Pod Mostem',
  'Pod Napětím',
  'Pod Nouzovem',
  'Pod Novou Školou',
  'Pod Novým Lesem',
  'Pod Novým Lesem',
  'Pod Nuselskými Schody',
  'Pod Náměstím',
  'Pod Náplavkou',
  'Pod Náplavkou',
  'Pod Náspem',
  'Pod Návsí',
  'Pod Oborou',
  'Pod Ovčínem',
  'Pod Ořechovkou',
  'Pod Palatou',
  'Pod Palírkou',
  'Pod Parukářkou',
  'Pod Paťankou',
  'Pod Paťankou',
  'Pod Pekařkou',
  'Pod Pekárnami',
  'Pod Petřinami',
  'Pod Plynojemem',
  'Pod Plynojemem',
  'Pod Plynojemem',
  'Pod Plískavou',
  'Pod Poštou',
  'Pod Pramenem',
  'Pod Prodejnou',
  'Pod Průsekem',
  'Pod Písečnou',
  'Pod Přehradou',
  'Pod Přesypem',
  'Pod Radnicí',
  'Pod Rapidem',
  'Pod Rapidem',
  'Pod Rapidem',
  'Pod Remízkem',
  'Pod Rovinou',
  'Pod Rozvodnou',
  'Pod Rybníkem',
  'Pod Rybníčkem',
  'Pod Sady',
  'Pod Salabkou',
  'Pod Sirénou',
  'Pod Skalkou',
  'Pod Skalou',
  'Pod Sklenářkou',
  'Pod Slovany',
  'Pod Smetankou',
  'Pod Sokolovnou',
  'Pod Soutratím',
  'Pod Spalovnou',
  'Pod Spiritkou',
  'Pod Spravedlností',
  'Pod Srázem',
  'Pod Stadiony',
  'Pod Stanicí',
  'Pod Starou Školou',
  'Pod Starákem',
  'Pod Statky',
  'Pod Strašnickou Vinicí',
  'Pod Strojírnami',
  'Pod Strání',
  'Pod Studánkou',
  'Pod Stupni',
  'Pod Stárkou',
  'Pod Stárkou',
  'Pod Stírkou',
  'Pod Svahem',
  'Pod Sychrovem I',
  'Pod Sychrovem I',
  'Pod Sychrovem I',
  'Pod Sychrovem Ii',
  'Pod Sídlištěm',
  'Pod Terasami',
  'Pod Terebkou',
  'Pod Topoly',
  'Pod Tratí',
  'Pod Turnovskou Tratí',
  'Pod Turnovskou Tratí',
  'Pod Táborem',
  'Pod Táborem',
  'Pod Třebešínem',
  'Pod Třešněmi',
  'Pod Třešňovkou',
  'Pod Urnovým Hájem',
  'Pod Valem',
  'Pod Vartou',
  'Pod Vavřincem',
  'Pod Velkým Hájem',
  'Pod Viaduktem',
  'Pod Vidoulí',
  'Pod Viktorkou',
  'Pod Vilami',
  'Pod Vinicemi',
  'Pod Vinicí',
  'Pod Vinohradem',
  'Pod Višňovkou',
  'Pod Vlachovkou',
  'Pod Vlastním Krovem',
  'Pod Vlkem',
  'Pod Vodojemem',
  'Pod Vodovodem',
  'Pod Vodárenskou Věží',
  'Pod Vrchem',
  'Pod Vrcholem',
  'Pod Vrstevnicí',
  'Pod Vrškem',
  'Pod Vrškem',
  'Pod Vršovickou Vodárnou I',
  'Pod Vršovickou Vodárnou Ii',
  'Pod Vršovickou Vodárnou Iii',
  'Pod Vsí',
  'Pod Vyhlídkou',
  'Pod Vysokou',
  'Pod Vysokou Mezí',
  'Pod Vysílačkou',
  'Pod Vyšehradem',
  'Pod Václavem',
  'Pod Vítkovem',
  'Pod Výtopnou',
  'Pod Výšinkou',
  'Pod Větrolamem',
  'Pod Větrovem',
  'Pod Věží',
  'Pod Zahradami',
  'Pod Zahrádkami',
  'Pod Zastávkou',
  'Pod Zatáčkou',
  'Pod Zbuzany',
  'Pod Zemankou',
  'Pod Zličínem',
  'Pod Zvonařkou',
  'Pod Zvoničkou',
  'Pod Zámečkem',
  'Pod Závěrkou',
  'Pod Útesy',
  'Pod Čertovou Skalou',
  'Pod Čihadlem',
  'Pod Čimickým Hájem',
  'Pod Šancemi',
  'Pod Školou',
  'Pod Šmukýřkou',
  'Pod Špejcharem',
  'Pod Špitálem',
  'Pod Štěpem',
  'Pod Žvahovem',
  'Podbabská',
  'Podbabská',
  'Podbělohorská',
  'Podbělová',
  'Podchýšská',
  'Podedvorská',
  'Podhajská Pole',
  'Podholí',
  'Podhorská',
  'Podhořská',
  'Podivínská',
  'Podjavorinské',
  'Podjezd',
  'Podkovářská',
  'Podkrkonošská',
  'Podkrkonošských Tkalců',
  'Podle Kačerova',
  'Podle Lomu',
  'Podle Lomu',
  'Podle Náhonu',
  'Podle Náhonu',
  'Podle Sadů',
  'Podle Trati',
  'Podlesek',
  'Podleská',
  'Podlesní',
  'Podlešínská',
  'Podlibská',
  'Podlipného',
  'Podlišovská',
  'Podlužanská',
  'Podléšková',
  'Podnikatelská',
  'Podnádražní',
  'Podohradská',
  'Podolanská',
  'Podolská',
  'Podolská',
  'Podolské Nábř.',
  'Podolské Nábřeží',
  'Podolské Schody',
  'Podpěrova',
  'Podskalská',
  'Podsychrovská',
  'Podvinný Mlýn',
  'Podvinný Mlýn',
  'Podzámecká',
  'Podéšťova',
  'Poděbradova',
  'Poděbradova',
  'Poděbradská',
  'Poděbradská',
  'Poděbradská',
  'Podůlší',
  'Pohledná',
  'Pohnertova',
  'Pohořelec',
  'Pohořelec',
  'Pokojná',
  'Pokorného',
  'Pokřivená',
  'Polabská',
  'Polabská',
  'Polaneckého',
  'Polední',
  'Polední',
  'Polenská',
  'Polepská',
  'Poleradská',
  'Polesná',
  'Polešovická',
  'Politických Vězňů',
  'Poličanská',
  'Poljanovova',
  'Polní',
  'Polovnická',
  'Polská',
  'Polygrafická',
  'Polákova',
  'Poláčkova',
  'Políkenská',
  'Polívkova',
  'Pomezní',
  'Pomněnková',
  'Pomořanská',
  'Ponrepova',
  'Poplužní',
  'Popovická',
  'Popovova',
  'Poslední',
  'Pospíchalova',
  'Pospíšilova',
  'Postlova',
  'Postranní',
  'Postupická',
  'Postřekovská',
  'Postřižínská',
  'Postřižínská',
  'Potocká',
  'Potoční',
  'Pouchova',
  'Poupětova',
  'Poustka',
  'Povltavská',
  'Povltavská',
  'Povltavská',
  'Povodňová',
  'Pozdeňská',
  'Poznaňská',
  'Počeradská',
  'Počernická',
  'Počernická',
  'Počátecká',
  'Počátecká',
  'Poříčanská',
  'Poříčanská',
  'Poříčská',
  'Pošepného Nám.',
  'Pošepného Náměstí',
  'Poštovská',
  'Požárnická',
  'Pplk. Nováčka',
  'Pplk. Sochora',
  'Prachatická',
  'Prachnerova',
  'Prachovická',
  'Prachovská',
  'Pramenná',
  'Pramenná',
  'Pravoúhlá',
  'Pravská',
  'Pravá',
  'Prašná',
  'Pražská',
  'Pražského',
  'Pražského Povstání',
  'Pražský Okruh',
  'Pražákovská',
  'Prefátova',
  'Preislerova',
  'Preláta',
  'Prelátská',
  'Preslova',
  'Primátorská',
  'Probluzská',
  'Proboštská',
  'Procházkova',
  'Prodloužená',
  'Prokofjevova',
  'Prokopka',
  'Prokopova',
  'Prokopovo Nám.',
  'Prokopovo Náměstí',
  'Prokopových',
  'Prokopská',
  'Prokopské Údolí',
  'Prokopské Údolí',
  'Prorektorská',
  'Prosecká',
  'Prosecká',
  'Prosecká',
  'Prosincová',
  'Prosluněná',
  'Prosná',
  'Prostřední',
  'Proti Proudu',
  'Protilehlá',
  'Protivínská',
  'Proutěná',
  'Prouzova',
  'Provaznická',
  'Provozní',
  'Prunéřovská',
  'Prusická',
  'Prusíkova',
  'Prušánecká',
  'Prvního Pluku',
  'Prvního Pluku',
  'Prvomájová',
  'Prácheňská',
  'Práčská',
  'Průběžná',
  'Průchodní',
  'Průchova',
  'Průhledová',
  'Průhonek',
  'Průhonek',
  'Průhonická',
  'Průhonská',
  'Průjezdná',
  'Průmyslová',
  'Průmyslová',
  'Průmyslová',
  'Průmyslová',
  'Průtažní',
  'Průčelní',
  'Průškova',
  'Psohlavců',
  'Pstružná',
  'Psárská',
  'Ptáčnická',
  'Puchmajerova',
  'Puchmajerova',
  'Pujmanové',
  'Pujmanové',
  'Pujmanové',
  'Purkrabská',
  'Purkyňova',
  'Putimská',
  'Pučova',
  'Puškinovo Nám.',
  'Puškinovo Náměstí',
  'Pyšelská',
  'Pálavská',
  'Pálkařská',
  'Pámelníková',
  'Pánkova',
  'Pátkova',
  'Pávovské Náměstí',
  'Písecká',
  'Píseckého',
  'Písečná',
  'Pískařská',
  'Pískovcová',
  'Pískovna',
  'Písková',
  'Písnická',
  'Písnická',
  'Písnické Zahrady',
  'Písčitá',
  'Píškova',
  'Píšovická',
  'Pöslova',
  'Púchovská',
  'Púchovská',
  'Pýchavková',
  'Pýrová',
  'Pěnkaví',
  'Pěstitelská',
  'Pětidomí',
  'Pětipeského',
  'Pěší',
  'Přecechtělova',
  'Přechodní',
  'Před Cibulkami',
  'Před Dráhou',
  'Před Mosty',
  'Před Nádražím',
  'Před Oborou',
  'Před Rybníkem',
  'Před Skalkami I',
  'Před Skalkami Ii',
  'Před Skálou',
  'Před Sokolovnou',
  'Před Tratí',
  'Před Ústavem',
  'Předbořská',
  'Předměřická',
  'Přední',
  'Předpolní',
  'Předposlední',
  'Předvoje',
  'Předvoje',
  'Předškolní',
  'Přeletová',
  'Přeloučská',
  'Přemyslova',
  'Přemyslovská',
  'Přemyslovská',
  'Přemyšlenská',
  'Přerušená',
  'Přesličková',
  'Přespolní',
  'Přetlucká',
  'Přeučilova',
  'Převoznická',
  'Přezletická',
  'Přeštická',
  'Přeštínská',
  'Přeťatá',
  'Při Hranici',
  'Při Hranici',
  'Při Trati',
  'Přibyslavská',
  'Přibíkova',
  'Přistoupimská',
  'Přádova',
  'Přátelství',
  'Příborská',
  'Příbramská',
  'Příběnická',
  'Příchovická',
  'Přídolská',
  'Příkrá',
  'Přílepská',
  'Přímské Nám.',
  'Přímské Náměstí',
  'Přímá',
  'Přímětická',
  'Přípotoční',
  'Přípřežní',
  'Přírodní',
  'Přístavní',
  'Přívorská',
  'Přívozní',
  'Příčka',
  'Příčná',
  'Pšeničná',
  'Pšenčíkova',
  'Pšovanská',
  'Pštrossova',
  'Půdova',
  'Půlkruhová',
  'Půlnoční',
  'Půtova',
  'R.A. Dvorského',
  'Rabasova',
  'Rabyňská',
  'Rackova',
  'Rackova Zahrada',
  'Radbuzská',
  'Radechovská',
  'Radešovská',
  'Radhošťská',
  'Radhošťská',
  'Radimova',
  'Radimovická',
  'Radimská',
  'Radiová',
  'Radiová',
  'Radistů',
  'Radkovská',
  'Radlická',
  'Radlická',
  'Radlická',
  'Radnické Schody',
  'Radomská',
  'Radonická',
  'Radostavická',
  'Radostná',
  'Radotínská',
  'Radotínská',
  'Radouňova',
  'Radouňova',
  'Radouňova',
  'Radova',
  'Radovská',
  'Radošovická',
  'Radvanická',
  'Radúzova',
  'Radčina',
  'Radějovská',
  'Raffaelova',
  'Raichlova',
  'Raisova',
  'Rajhradská',
  'Rajmonova',
  'Rajská',
  'Rakousova',
  'Rakovnická',
  'Rakovského',
  'Randova',
  'Ranská',
  'Ratajova',
  'Ratajská',
  'Ratbořská',
  'Ratibořická',
  'Ratibořská',
  'Ratibořská',
  'Ravennská',
  'Račická',
  'Račiněveská',
  'Rašilovova',
  'Rašova',
  'Rašovická',
  'Rašovská',
  'Rašínovo Nábř.',
  'Rašínovo Nábř.',
  'Rašínovo Nábřeží',
  'Rašínovo Nábřeží',
  'Rašínská',
  'Ražická',
  'Reinerova',
  'Rejchova',
  'Rejskova',
  'Rekreační',
  'Rektorská',
  'Rembrandtova',
  'Remízková',
  'Renoirova',
  'Resslova',
  'Revoluce',
  'Revoluční',
  'Revoluční',
  'Rezedová',
  'Rezlerova',
  'Rečkova',
  'Richtrova',
  'Riegrova',
  'Riegrovy Sady',
  'Rilská',
  'Ringhofferova',
  'Ringhofferova',
  'Rižská',
  'Roblínská',
  'Rochovská',
  'Rochovská',
  'Rodopská',
  'Rodovská',
  'Rodvinovská',
  'Roentgenova',
  'Rohanovská',
  'Rohanské Nábřeží',
  'Rohanský Ostrov',
  'Rohatecká',
  'Rohenická',
  'Rohlovská',
  'Rohová',
  'Rohozecká',
  'Rohožnická',
  'Roháčova',
  'Roithova',
  'Rojická',
  'Roklova',
  'Rokycanova',
  'Rokycanská',
  'Rokytnická',
  'Rokytná',
  'Rolnická',
  'Rolní',
  'Romaina Rollanda',
  'Romana Blahníka',
  'Ronalda Reagana',
  'Ronešova',
  'Ronkova',
  'Ronovská',
  'Rooseveltova',
  'Rorýsová',
  'Rosečská',
  'Rosická',
  'Rostislavova',
  'Rostoklatská',
  'Rostovská',
  'Rotavská',
  'Rotenská',
  'Roudnická',
  'Rousovická',
  'Rousínovská',
  'Rovenská',
  'Rovnoběžná',
  'Rovná',
  'Rozdělená',
  'Rozdělovská',
  'Rozhovická',
  'Rozkošného',
  'Rozkošská',
  'Rozmarýnová',
  'Rozrazilová',
  'Roztocká',
  'Roztylská',
  'Roztylské Náměstí',
  'Roztylské Sady',
  'Rozvadovská',
  'Rozvodova',
  'Rozvojová',
  'Rozárčina',
  'Rozýnova',
  'Rozšířená',
  'Ročovská',
  'Rošických',
  'Roškotova',
  'Rošovická',
  'Rožmberská',
  'Rožmitálská',
  'Rožnovská',
  'Rožďalovická',
  'Rtyňská',
  'Rubensova',
  'Rubeška',
  'Rubešova',
  'Rubličova',
  'Rubínová',
  'Rudečská',
  'Rudníkovská',
  'Rudolfa Holeky',
  'Rudoltická',
  'Rudoltická',
  'Rujanská',
  'Rumburská',
  'Rumunská',
  'Rumunská',
  'Ruprechtická',
  'Ruská',
  'Ruská',
  'Ruzyňská',
  'Ruzyňská',
  'Ruzyňské Schody',
  'Ružinovská',
  'Rybalkova',
  'Rybalkova',
  'Rybalkova',
  'Rybničná',
  'Rybná',
  'Rybova',
  'Rybářská',
  'Rybízová',
  'Rychnovská',
  'Rychtáře Petříka',
  'Rychtáře Šimona',
  'Rychtářská',
  'Rypkova',
  'Rytířova',
  'Rytířská',
  'Ryzcová',
  'Ryzlinková',
  'Ryšánkova',
  'Rájecká',
  'Rámová',
  'Rápošovská',
  'Rážova',
  'Révová',
  'Rýmařovská',
  'Rýnská',
  'Rýznerova',
  'Růženínová',
  'Růženínská',
  'Růženínská',
  'Růžová',
  'S. K. Neumanna',
  'Sabinova',
  'Sadařská',
  'Sadová',
  'Sadská',
  'Sadská',
  'Sady Bratří Čapků',
  'Safírová',
  'Salabova',
  'Salačova',
  'Salmovská',
  'Salvátorská',
  'Samcova',
  'Samohelova',
  'Samota U Podleského Rybníka',
  'Sarajevská',
  'Saratovská',
  'Sartoriova',
  'Sasanková',
  'Saská',
  'Satalická',
  'Saturnova',
  'Saudkova',
  'Sauerova',
  'Saveljevova',
  'Savojská',
  'Sazečská',
  'Sazečská',
  'Sazovická',
  'Sbíhavá I',
  'Sbíhavá Ii',
  'Schnirchova',
  'Schodišťová',
  'Schodová',
  'Schoellerova',
  'Schoellerova',
  'Schulhoffova',
  'Schwaigerova',
  'Schwarzenberská',
  'Schöfflerova',
  'Sdružení',
  'Sechterova',
  'Sedlecká',
  'Sedlovická',
  'Sedloňovská',
  'Sedlčanská',
  'Sedmidomky',
  'Sedmidomky',
  'Sedmikrásková',
  'Sedmnáctého Listopadu',
  'Seidlova',
  'Seifertova',
  'Sekaninova',
  'Sekeřická',
  'Sekorova',
  'Selmická',
  'Selská',
  'Selských Baterií',
  'Semanského',
  'Semická',
  'Semilská',
  'Semilská',
  'Seminární',
  'Seminářská',
  'Seminářská Zahrada',
  'Semonická',
  'Semtínská',
  'Semčická',
  'Sendražická',
  'Senegalská',
  'Senohrabská',
  'Senovážná',
  'Senovážné Nám.',
  'Senovážné Náměstí',
  'Senožatská',
  'Sestupná',
  'Sestupná',
  'Setbová',
  'Sevastopolská',
  'Severní I',
  'Severní Ii',
  'Severní Iii',
  'Severní Iv',
  'Severní Ix',
  'Severní V',
  'Severní Vi',
  'Severní Vii',
  'Severní Viii',
  'Severní X',
  'Severní Xi',
  'Severovýchodní I',
  'Severovýchodní Ii',
  'Severovýchodní Iii',
  'Severovýchodní Iv',
  'Severovýchodní V',
  'Severovýchodní Vi',
  'Severozápadní I',
  'Severozápadní Ii',
  'Severozápadní Iii',
  'Severozápadní Iv',
  'Severozápadní V',
  'Severozápadní Vi',
  'Severýnova',
  'Sevřená',
  'Seydlerova',
  'Sezemická',
  'Sezemínská',
  'Sezimova',
  'Sečská',
  'Sibeliova',
  'Sibiřské Nám.',
  'Sibiřské Náměstí',
  'Sicherova',
  'Sichrovského',
  'Siemensova',
  'Silurská',
  'Sinkulova',
  'Sinkulova',
  'Sitteho',
  'Siwiecova',
  'Skalecká',
  'Skalnatá',
  'Skalnická',
  'Skalní',
  'Skalská',
  'Skaláků',
  'Skandinávská',
  'Skandinávská',
  'Skautská',
  'Sklenská',
  'Skloněná',
  'Sklářská',
  'Skokanská',
  'Skorkovská',
  'Skorkovská',
  'Skotská',
  'Skořepka',
  'Skořicová',
  'Skryjská',
  'Skupova',
  'Skuteckého',
  'Skálova',
  'Skřivanova',
  'Skřivanská',
  'Skřivánčí',
  'Sladkovského Nám.',
  'Sladkovského Náměstí',
  'Sladovnická',
  'Slancova',
  'Slaná',
  'Slapská',
  'Slatinová',
  'Slatinská',
  'Slatiny',
  'Slatiňanská',
  'Slavatova',
  'Slaviborské Nám.',
  'Slaviborské Náměstí',
  'Slavická',
  'Slavičí',
  'Slavičínská',
  'Slavníkova',
  'Slavojova',
  'Slavonická',
  'Slavíkova',
  'Slavíkova',
  'Slavíkova',
  'Slavínského',
  'Slavíčkova',
  'Slavětínská',
  'Slepá I',
  'Slepá Ii',
  'Slezanů',
  'Slezská',
  'Slezská',
  'Sliačská',
  'Sliačská',
  'Slibná',
  'Slinková',
  'Slivenecká',
  'Slovanský Ostrov',
  'Slovačíkova',
  'Slovenská',
  'Slovenská',
  'Slovinská',
  'Slunečnicová',
  'Slunečná',
  'Sluneční',
  'Sluneční Nám.',
  'Sluneční Náměstí',
  'Slunná',
  'Sluštická',
  'Služeb',
  'Služeb',
  'Služská',
  'Sládkova',
  'Sládkovičova',
  'Slámova',
  'Slánská',
  'Slávy Horníka',
  'Slévačská',
  'Slévačská',
  'Slévačská',
  'Slídová',
  'Slívová',
  'Smaragdová',
  'Smetanovo Nábř.',
  'Smetanovo Nábřeží',
  'Smetáčkova',
  'Smidarská',
  'Smikova',
  'Smiřická',
  'Smiřického',
  'Smolenská',
  'Smolkova',
  'Smolíkova',
  'Smotlachova',
  'Smotlachova',
  'Smrková',
  'Smrčinská',
  'Smržovská',
  'Smržová',
  'Smíchovská',
  'Smíchovská',
  'Smíchovská',
  'Smírná',
  'Snopkova',
  'Sněmovní',
  'Sněženková',
  'Sněžná',
  'Sobolákova',
  'Soborská',
  'Sobotecká',
  'Sobínská',
  'Soběslavova',
  'Soběslavská',
  'Sobětická',
  'Sobětušská',
  'Soběšínská',
  'Sochařská',
  'Socháňova',
  'Sodomkova',
  'Sofijské Nám.',
  'Sofijské Náměstí',
  'Sojkovská',
  'Sojovická',
  'Sojčí',
  'Sojčí',
  'Sokolovská',
  'Sokolovská',
  'Sokolovská',
  'Sokolovská',
  'Sokolská',
  'Sokratova',
  'Solidarity',
  'Solnická',
  'Solná',
  'Sopotská',
  'Sosnovecká',
  'Souběžná I',
  'Souběžná Ii',
  'Souběžná Iii',
  'Souběžná Iv',
  'Soudní',
  'Soukalova',
  'Soukenická',
  'Soumarská',
  'Sousední',
  'Sousední',
  'Sousedská',
  'Sousedíkova',
  'Soustružnická',
  'Soustružnická',
  'Souvratní',
  'Součkova',
  'Sovenická',
  'Sovova',
  'Sovákova',
  'Soví Vršek',
  'Spinozova',
  'Spiritka',
  'Splavná',
  'Spodní',
  'Spojařů',
  'Spojenců',
  'Spojená',
  'Spojná',
  'Spojovací',
  'Spojovací',
  'Spojovací',
  'Spojovací',
  'Spojová',
  'Společná',
  'Spolská',
  'Spolupráce',
  'Sportovců',
  'Sportovců',
  'Sportovní',
  'Spotřebitelská',
  'Spořická',
  'Spořilovská',
  'Spytihněvova',
  'Spádná',
  'Spádová',
  'Spálená',
  'Spálená',
  'Spálený Mlýn',
  'Srbova',
  'Srbská',
  'Srbínská',
  'Srnečkova',
  'Srnčí',
  'Srnčí',
  'Srpnová',
  'Srázná',
  'Stachova',
  'Stadická',
  'Stadionová',
  'Stadiónová',
  'Stallichova',
  'Stamicova',
  'Staniční',
  'Starobylá',
  'Starochodovská',
  'Starochuchelská',
  'Starodejvická',
  'Starodubečská',
  'Starodvorská',
  'Staroklánovická',
  'Starokolínská',
  'Starokošířská',
  'Starolázeňská',
  'Staromlýnská',
  'Staromodřanská',
  'Staroměstské Nám.',
  'Staroměstské Náměstí',
  'Staropacká',
  'Staropramenná',
  'Starostrašnická',
  'Starostřešovická',
  'Starosuchdolská',
  'Staroújezdská',
  'Staročeská',
  'Stará Cesta',
  'Stará Náves',
  'Stará Obec',
  'Stará Spojovací',
  'Stará Stodůlecká',
  'Staré Nám.',
  'Staré Náměstí',
  'Staré Zámecké Schody',
  'Staré Zámecké Schody',
  'Starého',
  'Starý Lis',
  'Statenická',
  'Statková',
  'Stavbařů',
  'Stavební',
  'Stavitelská',
  'Stavovská',
  'Staňkova',
  'Staňkovka',
  'Staňkovská',
  'Stehlíkova',
  'Steinerova',
  'Stejskalova',
  'Stiessova',
  'Stinkovská',
  'Stochovská',
  'Stodůlecká',
  'Stojická',
  'Stoličkova',
  'Stoliňská',
  'Stoupající',
  'Stoupající',
  'Stradonická',
  'Strahovská',
  'Strahovské Nádvoří',
  'Strakatého',
  'Strakonická',
  'Strakonická',
  'Strakonická',
  'Strakonická',
  'Strakonická',
  'Strakonická',
  'Strakošová',
  'Strančická',
  'Stratovská',
  'Strašnická',
  'Strašnická',
  'Strašovská',
  'Strašínská',
  'Strmá',
  'Strmý Vrch',
  'Strnadova',
  'Strnady',
  'Strojická',
  'Strojnická',
  'Strojírenská',
  'Stromovka',
  'Stromovka',
  'Stropnická',
  'Stropnická',
  'Stropnická',
  'Strossmayerovo Nám.',
  'Strossmayerovo Náměstí',
  'Strouhalova',
  'Stroupežnického',
  'Struhařovská',
  'Strunkovská',
  'Stružky',
  'Stružná',
  'Strážkovická',
  'Strážnická',
  'Strážní',
  'Strážovská',
  'Stržná',
  'Studenecká',
  'Studentská',
  'Studená',
  'Studnická',
  'Studničkova',
  'Studniční',
  'Studánková',
  'Stulíková',
  'Stupická',
  'Stupkova',
  'Stupská',
  'Stupňová',
  'Stádlecká',
  'Stárkova',
  'Stýblova',
  'Střední',
  'Středohorská',
  'Středová',
  'Střekovská',
  'Střelecký Ostrov',
  'Střelečská',
  'Střelničná',
  'Střelničná',
  'Střemchová',
  'Střešovická',
  'Střešovická',
  'Střimelická',
  'Stříbrná',
  'Stříbrského',
  'Stříbrského',
  'Střížkovská',
  'Střížkovská',
  'Střížkovská',
  'Suchardova',
  'Suchdolská',
  'Suchdolská',
  'Suchdolská',
  'Suchdolské Nám.',
  'Suchdolské Náměstí',
  'Suchý Vršek',
  'Sudkova',
  'Sudoměřská',
  'Sudějovická',
  'Sukova',
  'Sulanského',
  'Sulická',
  'Sulická',
  'Sulova',
  'Sulovická',
  'Sumova',
  'Suppého',
  'Suttnerové',
  'Sušická',
  'Sušilova',
  'Svahová',
  'Svatavina',
  'Svatojánská',
  'Svatoplukova',
  'Svatoslavova',
  'Svatovítská',
  'Svatovítská',
  'Svatoňovická',
  'Svažitá',
  'Svijanská',
  'Svitavská',
  'Svitákova',
  'Svobodova',
  'Svobodova',
  'Svojetická',
  'Svojsíkova',
  'Svojšická',
  'Svojšovická',
  'Svornosti',
  'Svratecká',
  'Svárovská',
  'Svátkova',
  'Svážná',
  'Svépomoci',
  'Svépomocná',
  'Svépravická',
  'Svépravická',
  'Svídnická',
  'Svěceného',
  'Světická',
  'Světova',
  'Světská',
  'Sychrovská',
  'Symfonická',
  'Synkovická',
  'Synkovská',
  'Syrská',
  'Sádky',
  'Sádovská',
  'Sámova',
  'Sárská',
  'Sárská',
  'Sárská',
  'Sázavská',
  'Sáňkařská',
  'Sídlištní',
  'Sídlištní',
  'Sídliště',
  'Súdánská',
  'Sýkorčí',
  'Sýkovecká',
  'Tachlovická',
  'Tachovská',
  'Tachovské Nám.',
  'Tachovské Náměstí',
  'Tadrova',
  'Tajovského',
  'Talafúsova',
  'Talichova',
  'Talmberská',
  'Tanvaldská',
  'Tasovská',
  'Tatarkova',
  'Tatranská',
  'Tauerova',
  'Tauferova',
  'Taussigova',
  'Tavolníková',
  'Tařicová',
  'Taškentská',
  'Technická',
  'Technologická',
  'Tehovská',
  'Tejnická',
  'Tejnka',
  'Telčská',
  'Templová',
  'Tenisová',
  'Teplická',
  'Teplárenská',
  'Teplárenská',
  'Terasovitá',
  'Tererova',
  'Terezínská',
  'Terronská',
  'Tesaříkova',
  'Tetínská',
  'Theinova',
  'Thomayerova',
  'Thunovská',
  'Thurnova',
  'Thákurova',
  'Thámova',
  'Tibetská',
  'Tichnova',
  'Tichnova',
  'Tichonická',
  'Tichá',
  'Tichého',
  'Tigridova',
  'Tikovská',
  'Tilleho Nám.',
  'Tilleho Náměstí',
  'Tilschové',
  'Tiskařská',
  'Tismická',
  'Tišická',
  'Tlumačovská',
  'Tlustého',
  'Tobrucká',
  'Tolstého',
  'Tomanova',
  'Tomická',
  'Tomkova',
  'Tomsova',
  'Tomáškova',
  'Tomášská',
  'Tomíčkova',
  'Topasová',
  'Topolová',
  'Toruňská',
  'Toulovská',
  'Toušeňská',
  'Toušická',
  'Toužimská',
  'Toužimská',
  'Tovarova',
  'Tovačovského',
  'Tovární',
  'Točenská',
  'Točitá',
  'Trabantská',
  'Trachtova',
  'Trampotova',
  'Travnatá',
  'Travná',
  'Travná',
  'Trenčínská',
  'Trhanovské Náměstí',
  'Trmická',
  'Trnavská',
  'Trnavská',
  'Trnitá',
  'Trnkovo Nám.',
  'Trnkovo Náměstí',
  'Trnková',
  'Trnovanská',
  'Trní',
  'Trocnovská',
  'Troilova',
  'Trojanova',
  'Trojanův Mlýn',
  'Trojdílná',
  'Trojická',
  'Trojmezní',
  'Trojmezní',
  'Trojská',
  'Trojská',
  'Trojská',
  'Trojská',
  'Troskovická',
  'Trousilova',
  'Truhlářka',
  'Truhlářova',
  'Truhlářská',
  'Trutnovská',
  'Tryskovická',
  'Tryskovická',
  'Trytova',
  'Trávnická',
  'Trávníčkova',
  'Tréglova',
  'Tržiště',
  'Tuchoměřická',
  'Tuchorazská',
  'Tuchotická',
  'Tuháňská',
  'Tuklatská',
  'Tulešická',
  'Tulipánová',
  'Tulkova',
  'Tulská',
  'Tunelářů',
  'Tuniská',
  'Tupolevova',
  'Turgeněvova',
  'Turistická',
  'Turkmenská',
  'Turkovická',
  'Turkovská',
  'Turnovská',
  'Turnovského',
  'Turská',
  'Turínská',
  'Tusarova',
  'Tuřická',
  'Tušimická',
  'Tužebníková',
  'Tvrdonická',
  'Tvrdého',
  'Tychonova',
  'Tylišovská',
  'Tylovická',
  'Tylovo Nám.',
  'Tylovo Náměstí',
  'Tymiánová',
  'Tyrkysová',
  'Tyršova',
  'Táboritská',
  'Táborská',
  'Tádžická',
  'Táhlá',
  'Tálínská',
  'Türkova',
  'Týmlova',
  'Týmlova',
  'Týn',
  'Týnecká',
  'Týnská',
  'Týnská Ulička',
  'Týřovická',
  'Tělovýchovná',
  'Těšnov',
  'Těšovická',
  'Těšíkova',
  'Těšínská',
  'Třanovského',
  'Třebanická',
  'Třebechovická',
  'Třebenická',
  'Třebešovská',
  'Třebihošťská',
  'Třebohostická',
  'Třebonická',
  'Třeboradická',
  'Třebotovská',
  'Třeboňská',
  'Třebízského',
  'Třebějická',
  'Třebětínská',
  'Třešňová',
  'Třešňová',
  'Třešňová',
  'Třinecká',
  'Třtinová',
  'Třídomá',
  'Třístoličná',
  'Tůmova',
  'U Akademie',
  'U Akátů',
  'U Albrechtova Vrchu',
  'U Andělky',
  'U Arborky',
  'U Bakaláře',
  'U Balabenky',
  'U Bazénu',
  'U Bažantnice',
  'U Berounky',
  'U Beránky',
  'U Besedy',
  'U Blaženky',
  'U Boroviček',
  'U Botiče',
  'U Botiče',
  'U Božích Bojovníků',
  'U Branek',
  'U Bruských Kasáren',
  'U Brusnice',
  'U Brusnice',
  'U Bubce',
  'U Bulhara',
  'U Bulhara',
  'U Bílého Mlýnku',
  'U Břehu',
  'U Chaloupek',
  'U Chmelnice',
  'U Chodovského Hřbitova',
  'U Cibulky',
  'U Cihelny',
  'U Cikánky',
  'U Cukrovaru',
  'U Císařské Cesty',
  'U Dejvického Rybníčku',
  'U Demartinky',
  'U Divadla',
  'U Divadla',
  'U Dobešky',
  'U Dobráků',
  'U Dobráků',
  'U Dobřenských',
  'U Domu Služeb',
  'U Drahaně',
  'U Druhé Baterie',
  'U Druhé Baterie',
  'U Drupolu',
  'U Družstev',
  'U Družstva Ideál',
  'U Družstva Klid',
  'U Družstva Práce',
  'U Družstva Práce',
  'U Družstva Repo',
  'U Družstva Tempo',
  'U Družstva Život',
  'U Dráhy',
  'U Dráhy',
  'U Drážky',
  'U Drůbežárny',
  'U Dubečské Tvrze',
  'U Dubu',
  'U Dvojdomů',
  'U Dvora',
  'U Dvou Srpů',
  'U Dálnice',
  'U Dívčích Hradů',
  'U Dívčích Hradů',
  'U Děkanky',
  'U Dělnického Cvičiště',
  'U Dětského Domova',
  'U Dětského Hřiště',
  'U Elektry',
  'U Elektry',
  'U Elektrárny',
  'U Floriána',
  'U Fořta',
  'U Gabrielky',
  'U Garáží',
  'U Golfu',
  'U Gymnázia',
  'U Habeše',
  'U Habrovky',
  'U Hadovky',
  'U Harfy',
  'U Hasičské Zbrojnice',
  'U Hasičské Zbrojnice',
  'U Havlíčkových Sadů',
  'U Hellady',
  'U Hercovky',
  'U Hliníku',
  'U Hodin',
  'U Homolky',
  'U Hostavického Potoka',
  'U Hostivařského Nádraží',
  'U Hostivařského Nádraží',
  'U Hotelu',
  'U Hranic',
  'U Hrnčířského Rybníka',
  'U Hrocha',
  'U Hrušky',
  'U Hráze',
  'U Hudební Školy',
  'U Hvozdu',
  'U Hvězdy',
  'U Hvězdy',
  'U Háje',
  'U Hájku',
  'U Hájovny',
  'U Házů',
  'U Hřbitovů',
  'U Hřiště',
  'U Invalidovny',
  'U Jamské',
  'U Jankovky',
  'U Javoru',
  'U Jedličkova Ústavu',
  'U Jednoty',
  'U Jeslí',
  'U Jezera',
  'U Jezerky',
  'U Jezu',
  'U Jezírka',
  'U Jinonického Rybníčka',
  'U Jirkovské',
  'U Jizby',
  'U Járku',
  'U Jízdárny',
  'U Kabelovny',
  'U Kabelovny',
  'U Kaménky',
  'U Kamýku',
  'U Kanálky',
  'U Kapliček',
  'U Kapličky',
  'U Karlova Stánku',
  'U Kasáren',
  'U Kavalírky',
  'U Kazína',
  'U Kašny',
  'U Kaštanu',
  'U Kempinku',
  'U Kina',
  'U Klavírky',
  'U Klikovky',
  'U Klimentky',
  'U Kloubových Domů',
  'U Klubovny',
  'U Klubu',
  'U Kněžské Louky',
  'U Kola',
  'U Kolejí',
  'U Kolejí',
  'U Koloděj',
  'U Kolonie',
  'U Koloniálu',
  'U Kombinátu',
  'U Konečné',
  'U Koní',
  'U Kosinů',
  'U Kostela',
  'U Kostrounku',
  'U Kotlářky',
  'U Koupadel',
  'U Košíku',
  'U Krbu',
  'U Krbu',
  'U Krelovy Studánky',
  'U Kruhovky',
  'U Královské Louky',
  'U Krčské Vodárny',
  'U Krčského Nádraží',
  'U Kublova',
  'U Kunratického Lesa',
  'U Křižovatky',
  'U Kříže',
  'U Kříže',
  'U Křížku',
  'U Laboratoře',
  'U Ladronky',
  'U Lanové Dráhy',
  'U Ledáren',
  'U Lesa',
  'U Lesa',
  'U Lesíka',
  'U Letenského Sadu',
  'U Letiště',
  'U Letohrádku Královny Anny',
  'U Libeňského Pivovaru',
  'U Libeňského Zámku',
  'U Libušiných Lázní',
  'U Libušské Sokolovny',
  'U Lidového Domu',
  'U Lip',
  'U Lipové Aleje',
  'U Lisu',
  'U Loděnice',
  'U Lomu',
  'U Loskotů',
  'U Louky',
  'U Lužického Semináře',
  'U Lázeňky',
  'U Lázní',
  'U Lékárny',
  'U Líhní',
  'U Lípy',
  'U Malvazinky',
  'U Malé Řeky',
  'U Markéty',
  'U Mateřské Školy',
  'U Matěje',
  'U Maří Magdaleny',
  'U Meteoru',
  'U Mezníku',
  'U Michelské Školy',
  'U Michelského Lesa',
  'U Michelského Lesa',
  'U Michelského Mlýna',
  'U Milosrdných',
  'U Mlýna',
  'U Mlýna',
  'U Mlýnského Rybníka',
  'U Modré Školy',
  'U Modřanské Školy',
  'U Močálu',
  'U Mrázovky',
  'U Mydlárny',
  'U Myslivny',
  'U Městských Domů',
  'U Měšťanského Pivovaru',
  'U Měšťanských Škol',
  'U Nadýmače',
  'U Nemocenské Pojišťovny',
  'U Nemocnice',
  'U Nesypky',
  'U Nikolajky',
  'U Nové Dálnice',
  'U Nové Louky',
  'U Nové Školy',
  'U Nového Dvora',
  'U Nového Suchdola',
  'U Nového Suchdola',
  'U Nových Domů I',
  'U Nových Domů Ii',
  'U Nových Domů Iii',
  'U Nových Vil',
  'U Nádražní Lávky',
  'U Nádraží',
  'U Nádrže',
  'U Náhonu',
  'U Náhonu',
  'U Nákladového Nádraží',
  'U Nákladového Nádraží',
  'U Národní Galerie',
  'U Nás',
  'U Obce',
  'U Obecního Domu',
  'U Obecního Dvora',
  'U Obory',
  'U Okrouhlíku',
  'U Olšiček',
  'U Opatrovny',
  'U Ovčína',
  'U Palaty',
  'U Paliárky',
  'U Paloučku',
  'U Památníku',
  'U Panské Zahrady',
  'U Papírny',
  'U Parku',
  'U Parkánu',
  'U Parního Mlýna',
  'U Pastoušky',
  'U Pavilónu',
  'U Pazderek',
  'U Pejřárny',
  'U Pekařky',
  'U Pekáren',
  'U Pentlovky',
  'U Pergamenky',
  'U Pernikářky',
  'U Pernštejnských',
  'U Petřin',
  'U Pily',
  'U Plovárny',
  'U Plynárny',
  'U Plynárny',
  'U Plátenice',
  'U Podchodu',
  'U Podjezdu',
  'U Podolského Hřbitova',
  'U Podolského Sanatoria',
  'U Pohádky',
  'U Polikliniky',
  'U Pomníku',
  'U Potoka',
  'U Poustek',
  'U Poštovky',
  'U Pošty',
  'U Pramene',
  'U Prašné Brány',
  'U Prašného Mostu',
  'U Prašného Mostu',
  'U Pražských Lomů',
  'U Prefy',
  'U Prioru',
  'U Prknovky',
  'U Prodejny',
  'U Propusti',
  'U Prosecké Školy',
  'U Proseckého Kostela',
  'U První Baterie',
  'U První Baterie',
  'U Prádelny',
  'U Průhonu',
  'U Průseku',
  'U Pumpy',
  'U Párníků',
  'U Páté Baterie',
  'U Páté Baterie',
  'U Písecké Brány',
  'U Pískovny',
  'U Přechodu',
  'U Přehrady',
  'U Přejezdu',
  'U Půjčovny',
  'U Radiály',
  'U Radnice',
  'U Rajské Zahrady',
  'U Rakovky',
  'U Roháčových Kasáren',
  'U Rokytky',
  'U Rokytky',
  'U Rokytky',
  'U Rozkoše',
  'U Roztockého Háje',
  'U Rybníka',
  'U Rybníčka',
  'U Rybářství',
  'U Rychty',
  'U Rychty',
  'U Ryšánky',
  'U Ryšánky',
  'U Sadu',
  'U Sanatoria',
  'U Sanopzu',
  'U Santošky',
  'U Schodů',
  'U Sedlecké Školy',
  'U Seřadiště',
  'U Sila',
  'U Silnice',
  'U Silnice',
  'U Skalky',
  'U Skladu',
  'U Skládky',
  'U Skopců',
  'U Skály',
  'U Sladovny',
  'U Slavie',
  'U Sloupu',
  'U Slovanky',
  'U Slovanské Pojišťovny',
  'U Sluncové',
  'U Slévárny',
  'U Smaltovny',
  'U Smetanky',
  'U Smolnic',
  'U Smíchovského Hřbitova',
  'U Sokolovny',
  'U Soutoku',
  'U Sovových Mlýnů',
  'U Sparty',
  'U Splavu',
  'U Spojky',
  'U Spojů',
  'U Společenské Zahrady',
  'U Sportoviště',
  'U Spořitelny',
  'U Stanice',
  'U Staré Cihelny',
  'U Staré Plynárny',
  'U Staré Pošty',
  'U Staré Skládky',
  'U Staré Sokolovny',
  'U Staré Studánky',
  'U Staré Tvrze',
  'U Staré Školy',
  'U Staré Školy',
  'U Starého Hřbitova',
  'U Starého Hřiště',
  'U Starého Mlýna',
  'U Starého Nádraží',
  'U Starého Splavu',
  'U Starého Stadionu',
  'U Starého Stadiónu',
  'U Starého Židovského Hřbitova',
  'U Starého Židovského Hřbitova',
  'U Statku',
  'U Stavoservisu',
  'U Stojanu',
  'U Strouhy',
  'U Strže',
  'U Studny',
  'U Studánky',
  'U Studánky',
  'U Stárovny',
  'U Státní Dráhy',
  'U Státní Dráhy',
  'U Stírky',
  'U Střediska',
  'U Střešovických Hřišť',
  'U Sušičky',
  'U Svahu',
  'U Svatého Ducha',
  'U Svobodárny',
  'U Svodnice',
  'U Svornosti',
  'U Svépomoci',
  'U Světličky',
  'U Synagogy',
  'U Sádek',
  'U Sídliště',
  'U Tabulky',
  'U Technoplynu',
  'U Tenisu',
  'U Teplárny',
  'U Topíren',
  'U Továren',
  'U Transformační Stanice',
  'U Transformátoru',
  'U Trati',
  'U Trativodu',
  'U Trezorky',
  'U Trojice',
  'U Trojského Zámku',
  'U Trpce',
  'U Tržnice',
  'U Tvrze',
  'U Tyrše',
  'U Tyršovky',
  'U Tyršovy Školy',
  'U Třetí Baterie',
  'U Třešňovky',
  'U Třešňového Sadu',
  'U Tůně',
  'U Uhříněveské Obory',
  'U Uranie',
  'U Učiliště',
  'U Valu',
  'U Velké Skály',
  'U Vesny',
  'U Viktorky',
  'U Vinice',
  'U Viniček',
  'U Vinné Révy',
  'U Vinných Sklepů',
  'U Vinohradské Nemocnice',
  'U Vinohradského Hřbitova',
  'U Vinohradského Hřbitova',
  'U Vizerky',
  'U Višňovky',
  'U Višňovky',
  'U Vlachovky',
  'U Vlasačky',
  'U Vlečky',
  'U Vlečky',
  'U Vltavy',
  'U Voborníků',
  'U Vodice',
  'U Vodojemu',
  'U Vodojemu',
  'U Vodotoku',
  'U Vody',
  'U Vodárny',
  'U Vojanky',
  'U Vojenské Nemocnice',
  'U Vojtěšky',
  'U Vokovické Školy',
  'U Vorlíků',
  'U Vozovny',
  'U Vrbiček',
  'U Vrby',
  'U Vrtilky',
  'U Vršovického Hřbitova',
  'U Vršovického Hřbitova',
  'U Vršovického Nádraží',
  'U Vysočanského Cukrovaru',
  'U Vysočanského Pivovaru',
  'U Václava',
  'U Váhy',
  'U Vápenice',
  'U Vápenky',
  'U Vápenné Skály',
  'U Výkupního Střediska',
  'U Výstavby',
  'U Výstaviště',
  'U Výstaviště',
  'U Výzkumu',
  'U Včely',
  'U Větrníku',
  'U Větrolamu',
  'U Větrolamu',
  'U Věže',
  'U Waltrovky',
  'U Zahradnictví',
  'U Zahradního Města',
  'U Zahrady',
  'U Zahrádek',
  'U Zahrádkářské Kolonie',
  'U Zastávky',
  'U Zbrojnice',
  'U Zdravotního Ústavu',
  'U Zeleného Ptáka',
  'U Zemníku',
  'U Zeměpisného Ústavu',
  'U Zlaté Studně',
  'U Zličína',
  'U Zličína',
  'U Zličínského Hřiště',
  'U Zvonařky',
  'U Zvoničky',
  'U Záběhlického Zámku',
  'U Zájezdku',
  'U Zákrutu',
  'U Zámeckého Parku',
  'U Zámečku',
  'U Zámečnice',
  'U Zásobní Zahrady',
  'U Zátiší',
  'U Závodiště',
  'U Závor',
  'U Úlů',
  'U Čekárny',
  'U Černé Rokle',
  'U Červeného Mlýnku',
  'U Červeného Mlýnku',
  'U Českých Loděnic',
  'U Čihadel',
  'U Čističky',
  'U Čokoládoven',
  'U Čtvrté Baterie',
  'U Čtyř Domů',
  'U Řempa',
  'U Říčanky',
  'U Šalamounky',
  'U Šalamounky',
  'U Šesté Baterie',
  'U Šesté Baterie',
  'U Školičky',
  'U Školky',
  'U Školního Pole',
  'U Školské Zahrady',
  'U Školy',
  'U Štěpu',
  'U Šumavy',
  'U Šumavěnky',
  'U Šálkovny',
  'U Šíchů',
  'U Šípků',
  'U Železnice',
  'U Železničního Mostu',
  'U Železné Lávky',
  'U Želivky',
  'U Židovského Hřbitova',
  'U Žlábku',
  'U Županských',
  'Uhelný Trh',
  'Uherská',
  'Uhříněveská',
  'Ukončená',
  'Ukrajinská',
  'Uljanovská',
  'Ulrychova',
  'Ulčova',
  'Umělecká',
  'Ungarova',
  'Unhošťská',
  'Univerzitní',
  'Upolínová',
  'Upravená',
  'Uralská',
  'Urbanická',
  'Urbanova',
  'Urbánkova',
  'Urešova',
  'Uruguayská',
  'Urxova',
  'Utěšilova',
  'Uzavřená',
  'Uzbecká',
  'Uzoučká',
  'Učitelská',
  'Učňovská',
  'Užocká',
  'V Aleji',
  'V Alejích',
  'V Americe',
  'V Babyku',
  'V Bambouskách',
  'V Bažinách',
  'V Benátkách',
  'V Bezpečí',
  'V Bokách I',
  'V Bokách Ii',
  'V Bokách Iii',
  'V Borovičkách',
  'V Botanice',
  'V Brance',
  'V Brůdku',
  'V Brůdku',
  'V Bytovkách',
  'V Bílce',
  'V Březinkách',
  'V Březině',
  'V Březí',
  'V Břízkách',
  'V Celnici',
  'V Cestičkách',
  'V Cestkách',
  'V Chaloupkách',
  'V Chaloupkách',
  'V Chatách',
  'V Chotejně',
  'V Cibulkách',
  'V Cihelně',
  'V Cípu',
  'V Dolinách',
  'V Dolině',
  'V Dolině',
  'V Dolích',
  'V Domcích',
  'V Domově',
  'V Doubcích',
  'V Dílcích',
  'V Edenu',
  'V Haltýři',
  'V Hliništi',
  'V Hluboké',
  'V Hodkovičkách',
  'V Holešovičkách',
  'V Honu',
  'V Horkách',
  'V Horní Stromce',
  'V Hrobech',
  'V Humenci',
  'V Humenci',
  'V Humnech',
  'V Háji',
  'V Hájkách',
  'V Hájích',
  'V Hůrkách',
  'V Jahodách',
  'V Javorech',
  'V Javoříčku',
  'V Jehličině',
  'V Jehličí',
  'V Jezerách',
  'V Jezevčinách',
  'V Jezírkách',
  'V Jirchářích',
  'V Jámě',
  'V Kališti',
  'V Kališti',
  'V Kapslovně',
  'V Klukovicích',
  'V Kole',
  'V Kolkovně',
  'V Korytech',
  'V Korytech',
  'V Kotcích',
  'V Koutku',
  'V Koutě',
  'V Kratinách',
  'V Kruhu',
  'V Kuťatech',
  'V Kálku',
  'V Křepelkách',
  'V Křovinách',
  'V Křížkách',
  'V Ladech',
  'V Lesíčku',
  'V Lipinách',
  'V Lipinách',
  'V Lipkách',
  'V Lipách',
  'V Listnáčích',
  'V Lomech',
  'V Louce',
  'V Luhu',
  'V Lukách',
  'V Lučinách',
  'V Lužích',
  'V Lánech',
  'V Lázních',
  'V Lískách',
  'V Malých Domech I',
  'V Malých Domech Ii',
  'V Malých Domech Iii',
  'V Mezihoří',
  'V Milíři',
  'V Mokřinách',
  'V Mydlinkách',
  'V Nové Hostivaři',
  'V Nové Vsi',
  'V Nové Vsi',
  'V Nové Čtvrti',
  'V Novém Hloubětíně',
  'V Novém Hloubětíně',
  'V Nových Bohnicích',
  'V Nových Domcích',
  'V Nových Vokovicích',
  'V Náklích',
  'V Násypu',
  'V Nížinách',
  'V Oblouku',
  'V Občanském Domově',
  'V Obůrkách',
  'V Ochozu',
  'V Ohradě',
  'V Ohybu',
  'V Okruží',
  'V Okálech',
  'V Olšinách',
  'V Olšinách',
  'V Olšině',
  'V Ondřejově',
  'V Opatově',
  'V Osikách',
  'V Ostružiní',
  'V Oudolku',
  'V Ořeší',
  'V Pachmance',
  'V Padolině',
  'V Parcelách',
  'V Parku',
  'V Parníku',
  'V Pačátkách',
  'V Pařezinách',
  'V Pevnosti',
  'V Pevnosti',
  'V Pitkovičkách',
  'V Planinách',
  'V Platýzu',
  'V Pláni',
  'V Podbabě',
  'V Podhoří',
  'V Podhájí',
  'V Podhájí',
  'V Podluží',
  'V Podskalí',
  'V Podvrší',
  'V Podzámčí',
  'V Poli',
  'V Polích',
  'V Potokách',
  'V Potočinách',
  'V Potočkách',
  'V Prutinách',
  'V Průhledu',
  'V Průčelí',
  'V Pátém',
  'V Pískovně',
  'V Pěšinkách',
  'V Předním Hloubětíně',
  'V Předním Veleslavíně',
  'V Předpolí',
  'V Předpolí',
  'V Přelomu',
  'V Přístavu',
  'V Remízku',
  'V Rohožníku',
  'V Rohu',
  'V Roháčích',
  'V Rokli',
  'V Roklích',
  'V Rovinách',
  'V Rovinách',
  'V Rybníkách',
  'V Rybníčkách',
  'V Ráji',
  'V Ráji',
  'V Rákosí',
  'V Sadech',
  'V Sedlci',
  'V Sedlci',
  'V Slavětíně',
  'V Soudním',
  'V Stráni',
  'V Středu',
  'V Sudech',
  'V Sídlišti',
  'V Tehovičkách',
  'V Tišině',
  'V Trninách',
  'V Třešňovce',
  'V Tůních',
  'V Uličce',
  'V Uličkách',
  'V Zahradní Čtvrti',
  'V Zahradách',
  'V Zahrádkách',
  'V Zatáčce',
  'V Zeleni',
  'V Zeleném Údolí',
  'V Záhorském',
  'V Záhybu',
  'V Zákopech',
  'V Zákoutí',
  'V Zálesí',
  'V Zálomu',
  'V Zámcích',
  'V Zápolí',
  'V Zátiší',
  'V Zátočce',
  'V Závitu',
  'V Závětří',
  'V Zářezu',
  'V Údolí',
  'V Údolí Hvězd',
  'V Úhlu',
  'V Úhoru',
  'V Úvalu',
  'V Úvoze',
  'V Úzké',
  'V Úžlabině',
  'V Úžlabině',
  'V Čeňku',
  'V Štíhlách',
  'V Šáreckém Údolí',
  'V Žabokřiku',
  'V Žáčku',
  'V. P. Čkalova',
  'V. P. Čkalova',
  'Vachkova',
  'Vackova',
  'Vacovská',
  'Vacínova',
  'Vacínovská',
  'Vajdova',
  'Vajgarská',
  'Valcířská',
  'Valdická',
  'Valdovská',
  'Valdštejnská',
  'Valdštejnské Nám.',
  'Valdštejnské Náměstí',
  'Valentinská',
  'Valentinská',
  'Valentova',
  'Valečovská',
  'Valská',
  'Valtická',
  'Valtínovská',
  'Valčíkova',
  'Valšovská',
  'Vamberská',
  'Vanická',
  'Vaníčkova',
  'Vaníčkova',
  'Varhulíkové',
  'Varnsdorfská',
  'Varšavská',
  'Vavákova',
  'Vavřenova',
  'Vavřinecká',
  'Vazovova',
  'Vačkářova',
  'Vaňkova',
  'Vaňkova',
  'Vašátkova',
  'Ve Dvoře',
  'Ve Lhotce',
  'Ve Lhotce',
  'Ve Skalkách',
  'Ve Skalách',
  'Ve Skále',
  'Ve Slatinách',
  'Ve Smečkách',
  'Ve Smrčině',
  'Ve Stromořadí',
  'Ve Struhách',
  'Ve Struhách',
  'Ve Stráni',
  'Ve Studeném',
  'Ve Stínu',
  'Ve Střešovičkách',
  'Ve Střešovičkách',
  'Ve Svahu',
  'Ve Vilkách',
  'Ve Vilách',
  'Ve Višňovce',
  'Ve Vratech',
  'Ve Vrbách',
  'Ve Vrchu',
  'Ve Vrších',
  'Ve Výhledu',
  'Ve Výhledu',
  'Ve Výrech',
  'Ve Zliči',
  'Ve Štěpnici',
  'Ve Žlíbku',
  'Vedlejší',
  'Vehlovická',
  'Vejražkova',
  'Vejvanovského',
  'Vejvodova',
  'Velebného',
  'Velehradská',
  'Velemínská',
  'Velemínská',
  'Velenická',
  'Velenovského',
  'Veleslavínova',
  'Veleslavínská',
  'Veleslavínská',
  'Veletovská',
  'Veletržní',
  'Veletržní',
  'Veleňská',
  'Velešínská',
  'Velfloviců',
  'Velflíkova',
  'Velhartická',
  'Velichovská',
  'Velimská',
  'Velkoborská',
  'Velkoosecká',
  'Velkopřevorské Nám.',
  'Velkopřevorské Náměstí',
  'Velká Lada',
  'Velká Lada',
  'Velká Skála',
  'Velké Kunratické',
  'Veltruská',
  'Veltěžská',
  'Velvarská',
  'Velínská',
  'Venušina',
  'Verdiho',
  'Verdunská',
  'Verneřická',
  'Verneřická',
  'Vernéřovská',
  'Veronské Nám.',
  'Veselská',
  'Veská',
  'Veslařský Ostrov',
  'Vestavěná',
  'Vestecká',
  'Veverkova',
  'Večerní',
  'Vidimova',
  'Vidimská',
  'Vidlicová',
  'Vidlák',
  'Vidonická',
  'Vidoulská',
  'Vidovická',
  'Vietnamská',
  'Viklefova',
  'Vikova',
  'Viktora Huga',
  'Viktorinova',
  'Viktorčina',
  'Vikářská',
  'Vilová',
  'Vilímkova',
  'Vilímovská',
  'Vimperské Náměstí',
  'Vinařického',
  'Vinařská',
  'Viničná',
  'Vinohradská',
  'Vinohradská',
  'Vinohradská',
  'Vinohradská',
  'Vinohradská',
  'Vinohradská',
  'Vinohradská',
  'Vinohrady',
  'Vinopalnická',
  'Vinořská',
  'Vinořské Nám.',
  'Vinořské Náměstí',
  'Vinšova',
  'Violková',
  'Vitošská',
  'Vitíkova',
  'Vitějovská',
  'Vizovická',
  'Višňovka',
  'Višňovka',
  'Višňová',
  'Vlachova',
  'Vladimírova',
  'Vladislava Vančury',
  'Vladislavova',
  'Vladivostocká',
  'Vladycká',
  'Vlastibořská',
  'Vlastina',
  'Vlastina',
  'Vlastislavova',
  'Vlasty Buriana',
  'Vlasty Hilské',
  'Vlasty Průchové',
  'Vlasákova',
  'Vlašimská',
  'Vlašská',
  'Vlašská',
  'Vlaštovčí',
  'Vlkanovská',
  'Vlkova',
  'Vlkovická',
  'Vlnitá',
  'Vltavanů',
  'Vltavanů',
  'Vltavanů',
  'Vltavická',
  'Vltavská',
  'Vltavínová',
  'Vlárská',
  'Vlásenická',
  'Vlčická',
  'Vlčkova',
  'Vlčnovská',
  'Vnislavova',
  'Vnitřní',
  'Vnoučkova',
  'Vnější',
  'Voborského',
  'Vobrubova',
  'Vocelova',
  'Voctářova',
  'Voctářova',
  'Vodická',
  'Vodičkova',
  'Vodičkova',
  'Vodnická',
  'Vodní',
  'Vodochodská',
  'Vodojemská',
  'Vodácká',
  'Vodárenská',
  'Voděradská',
  'Vodňanská',
  'Vodňanského',
  'Vojenova',
  'Vojetická',
  'Vojická',
  'Vojkovická',
  'Vojslavická',
  'Vojtova',
  'Vojtíškova',
  'Vojtěšská',
  'Vojáčkova',
  'Vokovická',
  'Vokovická',
  'Vokrojova',
  'Vokáčova',
  'Vokřínská',
  'Volarská',
  'Volavkova',
  'Voleníkova',
  'Volkova',
  'Volkovova',
  'Voltova',
  'Volutová',
  'Volyňská',
  'Volšovská',
  'Volšovská',
  'Vondroušova',
  'Vorařská',
  'Voroněžská',
  'Voroněžská',
  'Voráčovská',
  'Voršilská',
  'Voskova',
  'Voskovcova',
  'Vosmíkových',
  'Vostrovská',
  'Vostrého',
  'Vosátkova',
  'Votavova',
  'Votická',
  'Votočkova',
  'Votrubova',
  'Votuzská',
  'Vozová',
  'Vozová',
  'Voňkova',
  'Voříškova',
  'Vošahlíkova',
  'Vožická',
  'Vrabčí',
  'Vranická',
  'Vranovská',
  'Vranská',
  'Vratimovská',
  'Vratislavova',
  'Vratislavská',
  'Vratičová',
  'Vraňanská',
  'Vrbenského',
  'Vrbická',
  'Vrbková',
  'Vrbova',
  'Vrbčanská',
  'Vrchlabská',
  'Vrchlického',
  'Vrchlického Sady',
  'Vrchovinská',
  'Vrátenská',
  'Vrátkovská',
  'Vrázova',
  'Vrážská',
  'Vrútecká',
  'Vršní',
  'Vršovická',
  'Vršovické Nám.',
  'Vršovické Náměstí',
  'Vršovka',
  'Vsetínská',
  'Vstavačová',
  'Vstupní',
  'Vybíralova',
  'Vycpálkova',
  'Vyderská',
  'Vydrova',
  'Vyhlídkova',
  'Vykoukových',
  'Vykáňská',
  'Vyskočilova',
  'Vysokovská',
  'Vysokoškolská',
  'Vysoká Cesta',
  'Vysočanská',
  'Vysočanská',
  'Vysočanská',
  'Vysočanské Nám.',
  'Vysočanské Náměstí',
  'Vyvýšená',
  'Vyšebrodská',
  'Vyšehradská',
  'Vyšší',
  'Vyžlovská',
  'Vzdušná',
  'Vzdálená',
  'Vzestupná',
  'Vzpoury',
  'Váchalova',
  'Václava Balého',
  'Václava Kovaříka',
  'Václava Rady',
  'Václava Trojana',
  'Václava Špačka',
  'Václavická',
  'Václavkova',
  'Václavská',
  'Václavské Nám.',
  'Václavské Náměstí',
  'Vágnerova',
  'Vánková',
  'Vápencová',
  'Vápenná',
  'Vápeníkova',
  'Vášova',
  'Vážská',
  'Vídeňská',
  'Vídeňská',
  'Vídeňská',
  'Vírská',
  'Víta Nejedlého',
  'Vítkova',
  'Vítkovická',
  'Vítovcova',
  'Vítovcova',
  'Vítězná',
  'Vítězná',
  'Vítězné Nám.',
  'Vítězné Nám.',
  'Vítězné Náměstí',
  'Vítězné Náměstí',
  'Východní',
  'Východní Nám.',
  'Východní Náměstí',
  'Výchozí',
  'Výhledová',
  'Výhledské Nám.',
  'Výhledské Náměstí',
  'Výjezdní',
  'Výjezdová',
  'Výletní',
  'Výletní',
  'Výmarova',
  'Výmolova',
  'Výpadová',
  'Výpadová',
  'Výravská',
  'Výrobní',
  'Výstaviště',
  'Výstavní',
  'Výstupní',
  'Výtoňská',
  'Výtvarnická',
  'Výtvarná',
  'Výzkumníků',
  'Včelařská',
  'Včelničná',
  'Věkova',
  'Věstonická',
  'Větrná',
  'Větrovcova',
  'Větrová',
  'Větrušická',
  'Vězeňská',
  'Vězeňská',
  'Věštínská',
  'Věšínova',
  'Věžická',
  'Vřesovická',
  'Vřesová',
  'Všehrdova',
  'Všejanská',
  'Všelipská',
  'Všerubská',
  'Všestarská',
  'Všetatská',
  'Všeňská',
  'Wagnerova',
  'Waldesova',
  'Washingtonova',
  'Wassermannova',
  'Wattova',
  'Weberova',
  'Weberova',
  'Weilova',
  'Weissova',
  'Wenzigova',
  'Wenzigova',
  'Werichova',
  'Wichterlova',
  'Wiedermannova',
  'Wiesenthalova',
  'Wilsonova',
  'Wilsonova',
  'Winklerova',
  'Wolfova',
  'Wolkerova',
  'Wuchterlova',
  'Xaveriova',
  'Xaverovská',
  'Za Archivem',
  'Za Arielem',
  'Za Avií',
  'Za Bažantnicí',
  'Za Botičem',
  'Za Brankou',
  'Za Brumlovkou',
  'Za Brůdkem',
  'Za Břízami',
  'Za Chalupami',
  'Za Cukrovarem',
  'Za Císařským Mlýnem',
  'Za Dolejšákem',
  'Za Drahou',
  'Za Dvorem',
  'Za Dálnicí',
  'Za Dálnicí',
  'Za Elektrárnou',
  'Za Elektrárnou',
  'Za Farou',
  'Za Fořtem',
  'Za Hanspaulkou',
  'Za Haštalem',
  'Za Hládkovem',
  'Za Horou',
  'Za Horou',
  'Za Hospodou',
  'Za Hrází',
  'Za Humny',
  'Za Hájem',
  'Za Hájem',
  'Za Hájovnou',
  'Za Hřbitovem',
  'Za Invalidovnou',
  'Za Jalovým Dvorem',
  'Za Jednotou',
  'Za Kajetánkou',
  'Za Kapličkou',
  'Za Karlínským Přístavem',
  'Za Kačabkou',
  'Za Klíčovem',
  'Za Knotkem',
  'Za Knotkem',
  'Za Kostelem',
  'Za Kovárnou',
  'Za Kovářským Rybníkem',
  'Za Křížem',
  'Za Křížkem',
  'Za Lesíkem',
  'Za Lidovým Domem',
  'Za Luhem',
  'Za Lužinami',
  'Za Lány',
  'Za Lázeňkou',
  'Za Mlýnem',
  'Za Mosty',
  'Za Mosty',
  'Za Mototechnou',
  'Za Můstkem',
  'Za Nadýmačem',
  'Za Novákovou Zahradou',
  'Za Návsí',
  'Za Obecním Úřadem',
  'Za Oborou',
  'Za Opravnou',
  'Za Opusem',
  'Za Ovčínem',
  'Za Papírnou',
  'Za Parkem',
  'Za Pavilónem',
  'Za Pekařkou',
  'Za Pekárnou',
  'Za Pivovarem',
  'Za Ploty',
  'Za Podjezdem',
  'Za Pohořelcem',
  'Za Pohádkou',
  'Za Potokem',
  'Za Poříčskou Branou',
  'Za Poříčskou Bránou',
  'Za Poštou',
  'Za Poštovskou Zahradou',
  'Za Poštovskou Zahradou',
  'Za Prodejnou',
  'Za Pruhy',
  'Za Průsekem',
  'Za Pískovnou',
  'Za Radostí',
  'Za Rokytkou',
  'Za Rybníkem',
  'Za Rybníčky',
  'Za Rybářstvím',
  'Za Rájem',
  'Za Sadem',
  'Za Sedmidomky',
  'Za Skalkou',
  'Za Skalkou',
  'Za Slatinami',
  'Za Slovankou',
  'Za Sokolovnou',
  'Za Stadionem',
  'Za Statkem',
  'Za Statky',
  'Za Stodolami',
  'Za Stodolou',
  'Za Strahovem',
  'Za Strašnickou Vozovnou',
  'Za Strašnickou Vozovnou',
  'Za Strojírnami',
  'Za Studánkou',
  'Za Střelnicí',
  'Za Sídlištěm',
  'Za Teplárnou',
  'Za Tratí',
  'Za Tratí',
  'Za Třebešínem',
  'Za Vackovem',
  'Za Valem',
  'Za Viaduktem',
  'Za Vinicí',
  'Za Vlasačkou',
  'Za Vodárnou',
  'Za Vokovickou Vozovnou',
  'Za Vokovickou Vozovnou',
  'Za Větrem',
  'Za Zahradami',
  'Za Zahradou',
  'Za Zastávkou',
  'Za Zelenou Liškou',
  'Za Zámečkem',
  'Za Černým Mostem',
  'Za Černým Mostem',
  'Za Černým Mostem',
  'Za Školkou',
  'Za Školou',
  'Za Šmatlíkem',
  'Za Železnicí',
  'Za Ženskými Domovy',
  'Za Žižkovskou Vozovnou',
  'Zacharská',
  'Zachova',
  'Zadní',
  'Zahrada Na Baště',
  'Zahradnická',
  'Zahradní',
  'Zahradníčkova',
  'Zahradníčkova',
  'Zahrádecká',
  'Zahrádecká',
  'Zahrádkářská',
  'Zahrádkářů',
  'Zaječická',
  'Zaječí',
  'Zaječí',
  'Zakouřilova',
  'Zakrytá',
  'Zakšínská',
  'Zalešanská',
  'Zalinská',
  'Zamašská',
  'Zamenhofova',
  'Zapadlá',
  'Zapomenutá',
  'Zapova',
  'Zapských',
  'Zastavěná',
  'Zastrčená',
  'Zavadilova',
  'Zavátá',
  'Zaříčanská',
  'Zbečenská',
  'Zborovská',
  'Zborovská',
  'Zbraslavská',
  'Zbraslavská',
  'Zbraslavské Nám.',
  'Zbraslavské Náměstí',
  'Zbrojnická',
  'Zbudovská',
  'Zbuzanská',
  'Zbuzkova',
  'Zbynická',
  'Zbyslavská',
  'Zbytinská',
  'Zbýšovská',
  'Zdaru',
  'Zdařilá',
  'Zderazská',
  'Zdeňky Nyplové',
  'Zdibská',
  'Zdická',
  'Zdiměřická',
  'Zdislavická',
  'Zdobnická',
  'Zdoňovská',
  'Zdíkovská',
  'Zelenečská',
  'Zelenečská',
  'Zelenkova',
  'Zelenky-Hajského',
  'Zelenohorská',
  'Zelená',
  'Zelená',
  'Zelená Louka',
  'Zelený Pruh',
  'Zelený Pruh',
  'Zelený Pruh',
  'Zelinářská',
  'Zemanka',
  'Zemské Právo',
  'Zemědělská',
  'Zengrova',
  'Zenklova',
  'Zenklova',
  'Zeyerova Alej',
  'Zhořelecká',
  'Zikova',
  'Zimova',
  'Zimákova',
  'Zkrácená',
  'Zlatnice',
  'Zlatnická',
  'Zlatokorunská',
  'Zlatá',
  'Zlatá Ulička U Daliborky',
  'Zlenická',
  'Zlešická',
  'Zlivská',
  'Zličínská',
  'Zličínská',
  'Zlonická',
  'Zlonínská',
  'Zlončická',
  'Zlíchovská',
  'Znojemská',
  'Zoubkova',
  'Zrzavého',
  'Ztracená',
  'Zubatého',
  'Zubrnická',
  'Zvolenská',
  'Zvolská',
  'Zvolská',
  'Zvonařova',
  'Zvonařovská',
  'Zvonařská',
  'Zvoncovitá',
  'Zvonická',
  'Zvonková',
  'Zvoníčkova',
  'Zvánovická',
  'Zvíkovská',
  'Záblatská',
  'Záblatská',
  'Zábranská',
  'Zábrodí',
  'Záběhlická',
  'Zádražanská',
  'Záhornická',
  'Záhorského',
  'Záhořanská',
  'Záhořanského',
  'Záhřebská',
  'Zájezdní',
  'Zákolanská',
  'Zákostelní',
  'Zákupská',
  'Zálesí',
  'Zálesí',
  'Zálesí',
  'Záluské',
  'Zálužanského',
  'Zálužická',
  'Zálužská',
  'Zálužská',
  'Zámecká',
  'Zámecké Schody',
  'Zámezí',
  'Zámišova',
  'Zámělská',
  'Západní',
  'Zápasnická',
  'Zápolská',
  'Zápotoční',
  'Zápská',
  'Zárubova',
  'Zárybnická',
  'Zárybničná',
  'Zárybská',
  'Zásadská',
  'Zásmucká',
  'Zátišská',
  'Zátiší',
  'Zátopkova',
  'Zátoňská',
  'Závadova',
  'Záveská',
  'Závist',
  'Závišova',
  'Závišova',
  'Závodní',
  'Závrchy',
  'Závěrka',
  'Zázvorkova',
  'Zářijová',
  'Zítkova',
  'Zívrova',
  'Zúžená',
  'Údlická',
  'Údolní',
  'Údolní',
  'Údolí Hvězd',
  'Úhlavská',
  'Úhlová',
  'Újezd',
  'Újezd',
  'Újezdská',
  'Úlibická',
  'Únorová',
  'Únětická',
  'Únětická',
  'Úpická',
  'Úprkova',
  'Úpská',
  'Úslavská',
  'Ústavní',
  'Ústecká',
  'Ústecká',
  'Ústřední',
  'Útulná',
  'Útulná',
  'Úvalská',
  'Úvoz',
  'Úvoz',
  'Úvozová',
  'Úzká',
  'Čajkovského',
  'Čakovická',
  'Čakovická',
  'Čankovská',
  'Čapkova',
  'Častavina',
  'Častonická',
  'Čechova',
  'Čechtická',
  'Čechurova',
  'Čedičová',
  'Čejetická',
  'Čejkovická',
  'Čekanková',
  'Čekanková',
  'Čekanovská',
  'Čelakovského Sady',
  'Čelakovského Sady',
  'Čeljabinská',
  'Čelkovická',
  'Čelná',
  'Čelákovická',
  'Čenkovská',
  'Čenovická',
  'Čentická',
  'Čenětická',
  'Čeperská',
  'Čeradická',
  'Čerchovská',
  'Čermákova',
  'Černická',
  'Černilovská',
  'Černičná',
  'Černochova',
  'Černockého',
  'Černohorského',
  'Černokostelecká',
  'Černokostelecká',
  'Černokostelecká',
  'Černomořská',
  'Černotínská',
  'Černovická',
  'Černošická',
  'Černá',
  'Černého',
  'Černínova',
  'Černínská',
  'Čerpadlová',
  'Čertouská',
  'Čertouská',
  'Čertův Vršek',
  'Červencová',
  'Červenkova',
  'Červená',
  'Červená Báň',
  'Červený Mlýn',
  'Červeňanského',
  'Červnová',
  'Čerčanská',
  'Českobratrská',
  'Českobrodská',
  'Českobrodská',
  'Českobrodská',
  'Českobrodská',
  'Českobrodská',
  'Českobrodská',
  'Českobrodská',
  'Českobrodská',
  'Českodubská',
  'Českolipská',
  'Českolipská',
  'Českomalínská',
  'Českomoravská',
  'Českomoravská',
  'Československého Exilu',
  'Československého Exilu',
  'Česká',
  'České Družiny',
  'Českého Červeného Kříže',
  'Čestlická',
  'Čestmírova',
  'Česákova',
  'Čečelická',
  'Čeňkova',
  'Češovská',
  'Čibuzská',
  'Čihákova',
  'Čiklova',
  'Čiklova',
  'Čimelická',
  'Čimická',
  'Čimická',
  'Čimická',
  'Čimická',
  'Čirůvková',
  'Čistovická',
  'Čmelická',
  'Čs. Armády',
  'Čs. Tankistů',
  'Čtyřdílná',
  'Čtyřkolská',
  'Čumpelíkova',
  'Čuprova',
  'Čábelecká',
  'Čápova',
  'Čáslavská',
  'Čílova',
  'Čílova',
  'Čínská',
  'Čínská',
  'Čížovská',
  'Ďáblická',
  'Ďáblická',
  'Ďáblická',
  'Řadová',
  'Řehořova',
  'Řepečská',
  'Řepná',
  'Řeporyjská',
  'Řeporyjská',
  'Řeporyjská',
  'Řeporyjské Náměstí',
  'Řepová',
  'Řepská',
  'Řepíková',
  'Řepínská',
  'Řepčická',
  'Řepčická',
  'Řetězokovářů',
  'Řetězová',
  'Řevnická',
  'Řevnická',
  'Řeznická',
  'Řezáčovo Nám.',
  'Řezáčovo Náměstí',
  'Řečického',
  'Řešetovská',
  'Řešovská',
  'Řipská',
  'Řipská',
  'Řásnovka',
  'Říjnová',
  'Římovská',
  'Římovská',
  'Římská',
  'Říčanova',
  'Říčanská',
  'Říční',
  'Šachovská',
  'Šafaříkova',
  'Šafránecká',
  'Šafránkova',
  'Šafránová',
  'Šafářova',
  'Šakvická',
  'Šaldova',
  'Šalounova',
  'Šalvějová',
  'Šanovská',
  'Šantrochova',
  'Šatrova',
  'Šatrova',
  'Šebelova',
  'Šeberovská',
  'Šebestiánská',
  'Šebkova',
  'Šedivého',
  'Šedova',
  'Šejbalové',
  'Šemberova',
  'Šenovská',
  'Šermířská',
  'Šermířská',
  'Šestajovická',
  'Šestajovická',
  'Šestidomí',
  'Šetelíkova',
  'Ševce Matouše',
  'Ševčenkova',
  'Ševčíkova',
  'Šeříková',
  'Šeříková',
  'Šibřinská',
  'Šikmá',
  'Šimanovská',
  'Šimkova',
  'Šimonova',
  'Šimáčkova',
  'Šimůnkova',
  'Šircova',
  'Široká',
  'Široká',
  'Šiškova',
  'Školní',
  'Školská',
  'Škroupovo Nám.',
  'Škroupovo Náměstí',
  'Škrétova',
  'Škvorecká',
  'Škábova',
  'Šlechtitelská',
  'Šlejnická',
  'Šlikova',
  'Šlitrova',
  'Šluknovská',
  'Šmeralova',
  'Šmilovského',
  'Šmolíkova',
  'Šolínova',
  'Šostakovičovo Nám.',
  'Šostakovičovo Náměstí',
  'Španielova',
  'Španělská',
  'Špačkova',
  'Špeciánova',
  'Šperlova',
  'Špirkova',
  'Špitálská',
  'Šplechnerova',
  'Šporkova',
  'Špotzova',
  'Špálova',
  'Šrobárova',
  'Šrobárova',
  'Šromova',
  'Štamberk',
  'Štefkova',
  'Štefánikova',
  'Štemberova',
  'Šternberkova',
  'Šternova',
  'Šternovská',
  'Štichova',
  'Štiplova',
  'Štičkova',
  'Štiřínská',
  'Štochlova',
  'Štolbova',
  'Štolcova',
  'Štolmířská',
  'Štolmířská',
  'Štorchova',
  'Štorkánova',
  'Štramberská',
  'Štulcova',
  'Štupartská',
  'Štursova',
  'Štverákova',
  'Štychova',
  'Štychova',
  'Štíbrova',
  'Štíhlická',
  'Štítného',
  'Štítová',
  'Štúrova',
  'Štúrova',
  'Štěchovická',
  'Štěpanická',
  'Štěpařská',
  'Štěpničná',
  'Štěpánkova',
  'Štěpánovská',
  'Štěpánská',
  'Štěpánská',
  'Štěrboholská',
  'Štěrková',
  'Štětkova',
  'Štětínská',
  'Šubertova',
  'Šulcova',
  'Šultysova',
  'Šumavská',
  'Šumavského',
  'Šumberova',
  'Šumenská',
  'Šumická',
  'Šumperská',
  'Šustova',
  'Švabinského',
  'Švecova',
  'Švehlova',
  'Švehlova',
  'Švejcarovo Náměstí',
  'Švestková',
  'Švestková',
  'Švestková',
  'Švihovská',
  'Švábky',
  'Švábova',
  'Švédská',
  'Šárecká',
  'Šárovo Kolo',
  'Šárčina',
  'Šátalská',
  'Šífařská',
  'Šímova',
  'Šípková',
  'Šítkova',
  'Šťastného',
  'Šůrova',
  'Žabovřeská',
  'Žacléřská',
  'Žalanského',
  'Žalmanova',
  'Žalovská',
  'Žamberská',
  'Žampašská',
  'Žampiónová',
  'Žandovská',
  'Žatecká',
  'Žatecká',
  'Žateckých',
  'Ždírnická',
  'Žehuňská',
  'Žehušická',
  'Želetavská',
  'Železniční',
  'Železničářů',
  'Železnobrodská',
  'Železná',
  'Želivecká',
  'Želivka',
  'Želivská',
  'Želkovická',
  'Želnavská',
  'Ženíškova',
  'Žeretická',
  'Žermanická',
  'Žernosecká',
  'Žernovská',
  'Žerotínova',
  'Žherská',
  'Žichlínská',
  'Židlického',
  'Žilinská',
  'Žilovská',
  'Žinkovská',
  'Žirovnická',
  'Žitavská',
  'Žitavského',
  'Žitná',
  'Žitná',
  'Žitomírská',
  'Živanická',
  'Živcová',
  'Živcových',
  'Živonínská',
  'Žiželická',
  'Žižkova',
  'Žižkovo Nám.',
  'Žižkovo Náměstí',
  'Žlebská',
  'Žluťásková',
  'Žofie Podlipské',
  'Žufanova',
  'Žukovského',
  'Žukovského',
  'Žulová',
  'Županovická',
  'Žvahovská',
  'Žábova',
  'Žákovská',
  'Žárovická',
  'Žíšovská',
  'Žďárská',
];

export default data;
