import { fetchData } from '../../../utils';
import {
  PSD2Banks,
  PSD2ClientAccount,
  PSD2ClientConsentInformation,
  PSD2IdentificationPayment,
  PSD2ProcessResponse,
  PSD2SessionStatusResponse,
} from './usePsd2Offline';
import { useMutation, useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export type PSD2ProxyQueryParams = {
  clientNumber: string;
  product: string;
};

export function usePSD2ProxyAccountsQuery(
  { clientNumber, product }: PSD2ProxyQueryParams,
  enabled: boolean,
) {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useQuery<{
    bankAccounts: PSD2ClientAccount[];
  }>({
    queryKey: ['psd', 'proxy', 'accounts', { clientNumber, product }],
    queryFn: async () => {
      try {
        const searchParams = new URLSearchParams({ clientNumber, product });

        const resp = await fetchData(
          `/proxy/psd/accounts${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
        );

        return resp;
      } catch (e) {
        error('[usePSD2ProxyAccountsQuery][GET /proxy/psd/accounts]', e);
        showError();
        throw e;
      }
    },
    enabled,
  });
}

export function usePSD2ProxyBanksQuery() {
  const { showError } = useError();
  return useQuery<{
    banks: PSD2Banks[];
  }>({
    queryKey: ['psd2', 'proxy', 'banks'],
    queryFn: async () => {
      try {
        const resp = await fetchData('/proxy/psd/banks');

        return resp;
      } catch (e) {
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2ProxyClientConsentsQuery(
  { clientNumber, product }: PSD2ProxyQueryParams,
  enabled: boolean,
) {
  const { showError } = useError();
  return useQuery<PSD2ClientConsentInformation>({
    queryKey: [
      'psd2',
      'proxy',
      'consents',
      'search',
      { clientNumber, product },
    ],
    queryFn: async () => {
      try {
        const searchParams = new URLSearchParams({
          clientNumber,
          product,
        });

        const resp = await fetchData(
          `/proxy/psd/consents/search${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
        );

        return resp;
      } catch (e) {
        showError();
        throw e;
      }
    },
    enabled,
  });
}

export function usePSD2ProxyInitPaymentMutation() {
  const { showError } = useError();
  return useMutation<
    PSD2IdentificationPayment,
    unknown,
    { product: string; clientIBAN: string; clientNumber: string }
  >({
    mutationFn: async ({ product, clientIBAN, clientNumber }) => {
      try {
        const searchParams = new URLSearchParams({
          clientNumber,
          product,
          clientIban: clientIBAN,
        });

        const resp = await fetchData(
          `/proxy/psd/payment${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
          { method: 'POST' },
        );

        return resp;
      } catch (e) {
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2ProxySessionsDownloadMutation() {
  const { showError } = useError();
  return useMutation<void, unknown, { clientNumber: string; product: string }>({
    mutationFn: async ({ clientNumber, product }) => {
      try {
        const searchParams = new URLSearchParams({
          clientNumber,
          product,
        });

        await fetchData(
          `/proxy/psd/sessions/download${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
          {
            method: 'POST',
          },
        );
      } catch (e) {
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2ProxySessionsInitMutation() {
  const { showError } = useError();
  return useMutation<
    void,
    unknown,
    {
      product: string;
      clientNumber: string;
      bankAccountChange: boolean;
      bankCode: string;
    }
  >({
    mutationFn: async (data) => {
      try {
        await fetchData('/proxy/psd/sessions/init', {
          method: 'POST',
          body: JSON.stringify(data),
        });
      } catch (e) {
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2ProxyConfirmConsentsMutation() {
  const { showError } = useError();
  return useMutation<
    PSD2ProcessResponse,
    unknown,
    { clientNumber: string; product: string; consent: boolean }
  >({
    mutationFn: async ({ clientNumber, product, consent }) => {
      try {
        const searchParams = new URLSearchParams({
          clientNumber,
          product,
          consent: String(consent),
        });

        const resp = await fetchData(
          `/proxy/psd/sessions/process${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
          { method: 'POST' },
        );

        return resp;
      } catch (e) {
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2ProxyStatusQuery({
  clientNumber,
  product,
}: PSD2ProxyQueryParams) {
  const { showError } = useError();
  return useQuery<PSD2SessionStatusResponse>({
    queryKey: [
      'psd2',
      'proxy',
      'status',
      {
        clientNumber,
        product,
      },
    ],
    queryFn: async () => {
      try {
        const searchParams = new URLSearchParams({
          clientNumber,
          product,
        });

        const resp = await fetchData(
          `/proxy/psd/status${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
        );

        return resp;
      } catch (e) {
        showError();
        throw e;
      }
    },
    enabled: true,
    refetchInterval: 5000,
  });
}
