import { fetchData } from '../../../utils';
import { useMutation } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useAcceptOfferMutation() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useMutation<
    {
      rejected: boolean;
    },
    unknown,
    {
      amount: number;
      term: number;
    }
  >({
    mutationFn: async (payload) => {
      try {
        const resp = await fetchData('/client/application/accept-offer', {
          method: 'post',
          body: JSON.stringify(payload),
        });
        return resp;
      } catch (e) {
        error(
          '[useAcceptOfferMutation][POST /client/application/accept-offer]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}
