import { fetchData } from '../../utils';
import { useCallback, useMemo } from 'react';
import Cookie from 'js-cookie';
import useError from '../useError';
import useSmartlook from '../useSmartlook';

type HouseholdOptions = {
  body: {
    numberOfInhabitants: number;
    numberOfAdults: number;
    ownership?: string;
  };
};
type AddressOptions = {
  declaredAddress: {
    city: string;
    streetName: string;
    houseNumber: string;
    postalCode: string;
  };
};
type IdOptions = {
  idCardNumber: string;
};
type HouseholdResponse = {};
type ClientInfo = {
  body: {
    personalId: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    email: string;
    password: string;
    acceptAgreement: boolean;
    acceptProfiling: boolean;
    acceptFaceIdProcessing: boolean;
  };
};

// TODO: Refactor to react-query
const useRegistration = () => {
  const { showError } = useError();
  const { error } = useSmartlook();

  const getAnalyticsCookies = useCallback(() => {
    const analyticsCookies = [];

    const ga = Cookie.get('_ga');

    if (ga) {
      analyticsCookies.push({
        name: 'ga',
        value: ga,
      });
    }

    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
    if (!measurementId) {
      return analyticsCookies;
    }

    const [, secondPart] = measurementId.split('-');

    if (!secondPart) {
      return analyticsCookies;
    }

    const ga4 = Cookie.get(`_ga_${secondPart}`);

    if (!ga4) {
      return analyticsCookies;
    }

    analyticsCookies.push({
      name: `ga_${secondPart}`,
      value: Cookie.get(`_ga_${secondPart}`),
    });
    return analyticsCookies;
  }, []);

  const partialRegister = useCallback(
    async ({ body }: ClientInfo, recaptchaToken?: string | null) => {
      const gaCookies = getAnalyticsCookies();

      try {
        const data = await fetchData(
          '/registration/partial',
          {
            method: 'post',
            body: JSON.stringify({
              ...body,
              analyticsCookies: gaCookies.length > 0 ? gaCookies : undefined,
            }),
          },
          { responseToken: recaptchaToken },
        );

        return data;
      } catch (e) {
        error('[useRegistration][partialRegister]', e);
        if (e.name !== 'ValidationError') {
          showError();
        }
        throw e;
      }
    },
    [showError],
  );

  const putEmployment = useCallback(
    async (body) => {
      try {
        const data = await fetchData('/client/employment', {
          method: 'put',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        error('[useRegistration][putEmployment]', e);
        if (e.name !== 'ValidationError') {
          showError();
          throw e;
        }
        // Don't throw error otherwise
      }
    },
    [showError],
  );
  const putHousehold = useCallback(
    async ({ body }: HouseholdOptions): Promise<HouseholdResponse | void> => {
      try {
        const data = await fetchData('/client/household', {
          method: 'put',
          body: JSON.stringify(body),
        });

        return data;
      } catch (e) {
        error('[useRegistration][putHousehold]', e);
        if (e.name !== 'ValidationError') {
          showError();
          throw e;
        }
        // Don't throw error otherwise
      }
    },
    [showError],
  );
  const updateAddress = useCallback(
    async (body: AddressOptions) => {
      const { city, streetName, postalCode, houseNumber } =
        body.declaredAddress;

      try {
        const data = await fetchData('/client/address', {
          method: 'put',
          body: JSON.stringify({
            declaredAddress: { city, streetName, postalCode, houseNumber },
            actualAddress: { city, streetName, postalCode, houseNumber },
          }),
        });
        return data;
      } catch (e) {
        error('[useRegistration][updateAddress]', e);
        if (e.name !== 'ValidationError') {
          showError();
        }
        throw e;
      }
    },
    [showError],
  );

  const updateId = useCallback(
    async (body: IdOptions) => {
      try {
        const data = await fetchData('/client/identity-document', {
          method: 'put',
          body: JSON.stringify(body),
        });
        return data;
      } catch (e) {
        error('[useRegistration][updateId]', e);
        if (e.name !== 'ValidationError') {
          showError();
        }
        throw e;
      }
    },
    [showError],
  );

  return useMemo(() => {
    return {
      putHousehold,
      putEmployment,
      partialRegister,
      updateAddress,
      updateId,
    };
  }, [putHousehold, putEmployment, partialRegister, updateAddress, updateId]);
};

export default useRegistration;
