import { memo, useCallback, useEffect, useState } from 'react';
import { Typography } from '..';
import classes from 'classnames';
import Ripples from 'react-ripples';
import styles from './DocumentUpload.module.scss';
import translate from '../../utils/translate';

import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as HazardIcon } from '../../assets/icons/hazard.svg';
import { ReactComponent as IdBackIcon } from '../../assets/icons/id-back.svg';
import { ReactComponent as IdFrontIcon } from '../../assets/icons/id-front.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as SelfieIcon } from '../../assets/icons/selfie.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import DocumentIconPath, {
  ReactComponent as DocumentIcon,
} from '../../assets/icons/document.svg';

export type Props = {
  type: 'ID_FRONT' | 'ID_BACK' | 'SELFIE' | 'INCOME_PROOF' | 'OTHER_DOCUMENT';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  error?: string;
  isTouched?: boolean;
  value?: File | null;
  onDelete: (name: string) => void;
  dataTest?: string;
  name: string;
  containerClassName?: string;
};

const UPLOAD_TYPE = {
  ID_FRONT: {
    icon: IdFrontIcon,
    title: translate('document_upload.id_front_title'),
    subtitle: translate('document_upload.id_front_subtitle'),
  },
  ID_BACK: {
    icon: IdBackIcon,
    title: translate('document_upload.id_back_title'),
    subtitle: translate('document_upload.id_back_subtitle'),
  },
  SELFIE: {
    icon: SelfieIcon,
    title: translate('document_upload.selfie_title'),
    subtitle: translate('document_upload.selfie_subtitle'),
  },
  INCOME_PROOF: {
    icon: DocumentIcon,
    title: translate('document_upload.income_proof_title'),
    subtitle: '',
  },
  OTHER_DOCUMENT: {
    icon: DocumentIcon,
    title: translate('document_upload.other_document_title'),
    subtitle: translate('document_upload.other_document_subtitle'),
  },
};

const getBase64FromFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      }
    });

    reader.addEventListener('error', () => {
      reject();
    });

    reader.readAsDataURL(file);
  });
};

export default memo(function DocumentUpload({
  type,
  value,
  onChange,
  className,
  error,
  isTouched,
  onDelete,
  dataTest,
  name,
  containerClassName,
}: Props) {
  const [img, setImg] = useState('');
  const Icon = UPLOAD_TYPE[type].icon;
  const showError = isTouched && error;

  useEffect(() => {
    const createImg = async () => {
      if (!value) return;

      const convertedImg = await getBase64FromFile(value);
      setImg(convertedImg);
    };

    createImg();
  }, [value]);

  const handleDelete = useCallback(() => {
    if (!onDelete) return;

    onDelete(name);
  }, [name, onDelete]);

  return (
    <div className={className}>
      <Ripples
        className={classes(styles.ripples, !value && styles.focusable)}
        color={value ? 'rgba(0, 0, 0, 0)' : 'rgba(92, 182, 14, 0.3)'}
      >
        <div
          className={classes(
            styles.container,
            containerClassName,
            showError && styles.error,
          )}
          data-test={dataTest}
        >
          {value ? (
            <div
              style={{
                backgroundImage: `url(${img}), url(${DocumentIconPath})`,
              }}
              className={styles.preview}
            >
              <CheckIcon className={styles['overlayed-plus']} />
            </div>
          ) : (
            <>
              <input
                name={name}
                className={styles.input}
                onChange={onChange}
                type="file"
                accept="image/x-png, image/png, image/jpeg, application/pdf"
              />
              <Icon className={styles.icon} />
            </>
          )}
          <div className={styles.text}>
            <Typography
              className={classes(styles.title, showError && styles.error)}
              size="m"
            >
              {UPLOAD_TYPE[type].title}
            </Typography>
            {UPLOAD_TYPE[type].subtitle && (
              <Typography size="xs">{UPLOAD_TYPE[type].subtitle}</Typography>
            )}
          </div>
          {value ? (
            <button
              className={styles.delete}
              type="button"
              onClick={handleDelete}
            >
              <TrashIcon />
            </button>
          ) : (
            <PlusIcon
              className={classes(styles.plus, showError && styles.error)}
            />
          )}
        </div>
      </Ripples>
      {showError && (
        <div className={styles['error-wrapper']}>
          <HazardIcon className={styles['error-icon']} />
          <Typography className={styles.error}>{error}</Typography>
        </div>
      )}
    </div>
  );
});
