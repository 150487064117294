import { useSeonSessionMutation } from './client/useSeonSessionMutation';
import { useState } from 'react';

interface SeonConfig {
  host: string;
  session_id: string;
  audio_fingerprint: boolean;
  canvas_fingerprint: boolean;
  webgl_fingerprint: boolean;
  max_proxy_delay: number;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

interface SeonInstance {
  config: (body: SeonConfig) => void;
  getBase64Session: () => Promise<string>;
}

declare global {
  interface Window {
    seon: SeonInstance;
  }
}

export const useSeon = () => {
  const [sessionId, setSessionId] = useState<string | undefined>();
  const { mutateAsync: sendSeonSession } = useSeonSessionMutation();

  const createSeonScript = () => {
    const seonScript = document.createElement('script');
    seonScript.src = 'https://cdn.seondf.com/js/v5/agent.js';
    seonScript.async = true;
    seonScript.defer = true;
    seonScript.id = 'seon-agent';
    document.head.appendChild(seonScript);
  };

  const getSessionId = async () => {
    if (sessionId) return sessionId;

    const session_id = await window.seon.getBase64Session();
    setSessionId(session_id);
    return session_id;
  };

  const sendSeonFraudInfo = async () => {
    const session_id = await getSessionId();

    window.seon.config({
      host: 'seondf.com',
      session_id,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      max_proxy_delay: 1000,
      onSuccess: () => undefined,
      onError: () => undefined,
    });

    await sendSeonSession({
      session: session_id,
    });
  };

  return {
    sendSeonFraudInfo,
    createSeonScript,
  };
};
