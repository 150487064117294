import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { memo, ReactNode } from 'react';
import classes from 'classnames';
import styles from './Notification.module.scss';

export type Props = {
  children: ReactNode;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  dataTest?: string;
  type?: 'warning' | 'error';
  className?: string;
};

export default memo(function Notification({
  children,
  onClose,
  dataTest,
  type = 'error',
  className,
}: Props) {
  return (
    <div
      className={classes(styles.container, styles[type], className)}
      data-test={dataTest}
    >
      {children}
      {onClose && (
        <button type="button" className={styles.close} onClick={onClose}>
          <CloseIcon className={styles.icon} />
        </button>
      )}
    </div>
  );
});
