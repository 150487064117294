import { fetchData } from '../../../utils';
import { useMutation } from '@tanstack/react-query';
import useSmartlook from '../../useSmartlook';

export function useSeonSessionMutation() {
  const { error } = useSmartlook();
  return useMutation<
    {
      rejected: boolean;
    },
    unknown,
    { session: string }
  >({
    mutationFn: async (payload) => {
      try {
        const resp = await fetchData('/client/device-fingerprint-session', {
          method: 'post',
          body: JSON.stringify(payload),
        });
        return resp;
      } catch (e) {
        error(
          '[useSeonSessionMutation][POST /client/device-fingerprint-session]',
          e,
        );
      }
    },
  });
}
