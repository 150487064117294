import { memo, ReactNode, useCallback } from 'react';
import classes from 'classnames';
import Ripples from 'react-ripples';
import styles from './BankCard.module.scss';

type Props = {
  code?: string;
  children?: ReactNode;
  name?: string;
  className?: string;
  dataTest?: string;
  onClick?: (code?: string, name?: string) => void;
};

export default memo(function BankCard({
  code,
  children,
  name,
  className,
  dataTest,
  onClick,
}: Props) {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(code, name);
    }
  }, [code, name, onClick]);

  return (
    <div className={classes(styles.container, className)}>
      <Ripples className={styles.ripples} color="rgba(92, 182, 14, 0.3)">
        <button
          className={styles.button}
          data-test={dataTest}
          onClick={handleClick}
          type="button"
        >
          <div className={styles['logo-container']}>{children}</div>
          {name && (
            <div className={styles.footer}>
              <span>{name}</span>
            </div>
          )}
        </button>
      </Ripples>
    </div>
  );
});
