import { fetchData, ValidationError } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useClientOfferQuery({
  amount,
  term,
}: {
  amount?: number;
  term?: number;
}) {
  const { showError } = useError();
  const { error } = useSmartlook();

  return useQuery<ClientOffer>({
    queryKey: ['client', 'application', 'offer', { amount, term }],
    queryFn: async () => {
      try {
        const resp = await fetchData(
          `/client/application/offer?amount=${amount}&term=${term}`,
          {
            headers: {
              Accept: 'application/json',
            },
          },
        );

        return resp;
      } catch (e: unknown) {
        error(
          '[useClientOfferQuery][GET /client/application/offer?amount={amount}&term={term}]',
          e,
        );
        if (
          e instanceof ValidationError &&
          e.fieldErrors[0].source !== 'CalculateExistingClientOfferCommand' &&
          e.fieldErrors[0].message !== 'not_in_range'
        ) {
          showError();
          throw e;
        }
      }
    },
    staleTime: Infinity,
    enabled: !!amount && !!term,
    keepPreviousData: true,
  });
}

export type ClientOffer = {
  term: number;
  newPrincipal: number;
  totalPrincipal: number;
  interestRate: number;
  newInterest: number;
  newInterestBeforeDiscount: number;
  firstPaymentInterest: number;
  currentPrincipal: number;
  currentPrincipalWithInterest: number;
  currentPrincipalWithInterestAndInitialCommission: number;
  monthlyPayment: number;
  agreementEndDate: string;
  isAdditionalAmount: boolean;
  discountAmount: number;
  discountPercent: number;
  discounts: any[];
  annualPercentageRate: number;
  totalInterest: number;
  initialCommission: number;
  newInitialCommission: number;
  totalCostOfCredit: number;
  totalRepayableAmount: number;
  firstPaymentDate: string;
  extensionFees: {
    7: number;
    14: number;
    30: number;
  };
  loanExtensionFees: [
    {
      term: number;
      unit: string;
      fee: number;
      interest: number;
      feeWithoutInterest: number;
    },
    {
      term: number;
      unit: string;
      fee: number;
      interest: number;
      feeWithoutInterest: number;
    },
    {
      term: number;
      unit: string;
      fee: number;
      interest: number;
      feeWithoutInterest: number;
    },
  ];
  newAmountUsedDays: number;
  newAmountWithInterestAndInitialCommission: number;
  newPrincipalApr: number;
  totalRoundedAmount: number;
  loanApplicationEnhancements: any[];
  capitalizableCommission: number;
  newPrincipalWithAllCapitalizableFees: number;
  newCapitalizedInterest: number;
  _links: {
    self: {
      href: string;
      title: string;
    };
  };
};
