import {
  ChangeEvent,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ReactComponent as Chevron } from '../../assets/icons/chevron-sharp.svg';
import classes from 'classnames';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import styles from './Checkbox.module.scss';
import translate from '../../utils/translate';

export type Props = {
  checked?: boolean;
  children: ReactNode;
  className?: string;
  dataTest?: string;
  disabled?: boolean;
  error?: string;
  isTouched?: boolean;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: boolean;
  expand?: boolean;
};

export default memo(function Checkbox({
  checked,
  children,
  className,
  dataTest,
  disabled,
  error,
  isTouched,
  name,
  onChange,
  value,
  expand = false,
}: Props) {
  const [expanded, setExpanded] = useState(expand);
  const [expandable, setExpandable] = useState(false);
  const [wasExpanded, setWasExpanded] = useState(false);
  const showError = isTouched && error;

  const handleExpand = useCallback(() => {
    if (!wasExpanded) setWasExpanded(true);

    setExpanded((prev) => !prev);
  }, [wasExpanded]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<any>) => {
      if (expandable && !wasExpanded && !expand) {
        handleExpand();

        return;
      }

      if (onChange) onChange(e);
    },
    [expandable, handleExpand, onChange, wasExpanded, expand],
  );

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.clientHeight > 120 && setExpandable(true);
    }
  }, [children]);

  return (
    <div className={classes(className, styles.wrapper)}>
      <label
        className={classes(
          styles.container,
          (expanded || !expandable) && styles.expanded,
        )}
      >
        <input
          type="checkbox"
          name={name}
          id={name}
          onChange={handleOnChange}
          value={String(value)}
          checked={value}
          data-test={dataTest}
          disabled={disabled}
          className={classes(styles.invisible)}
        />
        {!expanded && expandable && <div className={styles.overlay} />}
        <div ref={containerRef}>{children}</div>
        <span
          className={classes(styles.checkmark, showError && styles.error)}
        />
      </label>
      {expandable && !expand && (
        <div className={styles['read-more']} onClick={handleExpand}>
          <Chevron className={classes(styles.chevron, expanded && styles.up)} />
          <span className={styles['read-more-label']}>
            {expanded
              ? translate('common.show_less')
              : translate('common.show_more')}
          </span>
        </div>
      )}
      {showError && (
        <div className={styles['error-message']}>
          <ErrorMessage errors={error} />
        </div>
      )}
    </div>
  );
});
