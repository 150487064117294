import { fetchData } from '../../../utils';
import { useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useApplicationQuery(enabled = false) {
  const { showError } = useError();
  const { error } = useSmartlook();
  return useQuery<ClientApplication>({
    queryKey: ['client', 'application'],
    queryFn: async () => {
      try {
        const resp = await fetchData('/client/application', {
          headers: {
            'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
          },
        });

        return resp;
      } catch (e) {
        error('[useApplicationQuery][GET /client/application]', e);
        showError();
        throw e;
      }
    },
    enabled,
    keepPreviousData: true,
  });
}

export type ClientApplication = {
  confirmed: boolean;
  id: string;
  status: string;
  amount: number;
  term: number;
  resolution: string;
  resolutionDetail:
    | 'LOW_INCOME'
    | 'UPSALE_OFFER'
    | 'NEW_OFFER'
    | 'EXPIRED'
    | 'CREDIT_LIMIT_REACHED';
  type: string;
};
