import { fetchData } from '../../../utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function usePSD2OfflineBanksQuery() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useQuery<{
    banks: PSD2Banks[];
  }>({
    queryKey: ['psd2', 'offline', 'banks'],
    queryFn: async () => {
      try {
        const responseData = await fetchData(`/psd/offline/banks`);

        return responseData;
      } catch (e) {
        error('[usePSD2OfflineBanksQuery][GET /psd/offline/banks]', e);
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2OfflineChooseBankMutation() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useMutation<void, unknown, { token: string; bankCode: string }>({
    mutationFn: async ({ token, bankCode }) => {
      try {
        const searchParams = new URLSearchParams({
          bankCode,
        });
        return await fetchData(
          `/psd/offline/bank/${token}/${
            searchParams.get('bankCode') ? '?' + searchParams.toString() : ''
          }`,
          { method: 'PUT' },
        );
      } catch (e) {
        error(
          '[usePSD2OfflineChooseBankMutation][POST /psd/offline/bank/{token}?{bankCode}]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2OfflineAccountsQuery(token: string) {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useQuery<{
    bankAccounts: PSD2ClientAccount[];
  }>({
    queryKey: ['psd2', 'offline', 'accounts', token],
    queryFn: async () => {
      try {
        const resp = await fetchData(`/psd/offline/accounts/${token}`);

        return resp;
      } catch (e) {
        error(
          '[usePSD2OfflineAccountsQuery][GET /psd/offline/accounts/{token}]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2OfflineClientConsentsQuery(
  token: string,
  isEnabled: boolean,
) {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useQuery<PSD2ClientConsentInformation>({
    queryKey: ['psd2', 'offline', 'consents', 'search', token],
    queryFn: async () => {
      try {
        const responseData = await fetchData(
          `/psd/offline/consents/search/${token}`,
        );

        return responseData;
      } catch (e) {
        error(
          '[usePSD2OfflineClientConsentsQuery][GET /psd/offline/consents/search/{token}]',
          e,
        );
        showError();
        throw e;
      }
    },
    enabled: isEnabled,
  });
}

export function usePSD2OfflineSessionsDownloadMutation() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useMutation({
    mutationFn: async (token: string) => {
      try {
        await fetchData(`/psd/offline/sessions/download/${token}`, {
          method: 'POST',
        });
      } catch (e) {
        error(
          '[usePSD2OfflineSessionsDownloadMutation][POST /psd/offline/sessions/download/{token}]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2OfflineSessionsInitMutation() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useMutation({
    mutationFn: async (token: string) => {
      try {
        await fetchData(`/psd/offline/sessions/init/${token}`, {
          method: 'POST',
          body: JSON.stringify({
            psdSource: 'OFFLINE',
          }),
        });
      } catch (e) {
        error(
          '[usePSD2OfflineSessionsInitMutation][POST /psd/offline/sessions/init/{token}]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2OfflineConfirmConsentsMutation() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useMutation<
    PSD2ProcessResponse,
    unknown,
    { token: string; consent: boolean }
  >({
    mutationFn: async ({ token, consent }) => {
      try {
        const searchParams = new URLSearchParams({
          consent: String(consent),
        });

        const resp = await fetchData(
          `/psd/offline/sessions/process/${token}${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
          { method: 'POST' },
        );

        return resp;
      } catch (e) {
        error(
          '[usePSD2OfflineConfirmConsentsMutation][POST /psd/offline/sessions/process/{token}]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2OfflineInitPaymentMutation() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useMutation<
    PSD2IdentificationPayment,
    unknown,
    { token: string; clientIBAN: string }
  >({
    mutationFn: async ({ token, clientIBAN }) => {
      try {
        const searchParams = new URLSearchParams({
          clientIban: clientIBAN,
        });

        const resp = await fetchData(
          `/psd/offline/payment/${token}${
            Array.from(searchParams.values()).length
              ? '?' + searchParams.toString()
              : ''
          }`,
          { method: 'POST' },
        );

        return resp;
      } catch (e) {
        error(
          '[usePSD2OfflineInitPaymentMutation][POST /psd/offline/payment/{token}]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}

export function usePSD2OfflineStatusQuery(token: string) {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useQuery<PSD2SessionStatusResponse>({
    queryKey: ['psd2', 'offline', 'status', token],
    queryFn: async () => {
      try {
        const resp = await fetchData(`/psd/offline/status/${token}`);

        return resp;
      } catch (e) {
        error(
          '[usePSD2OfflineStatusQuery][GET /psd/offline/status/{token}]',
          e,
        );
        showError();
        throw e;
      }
    },
    enabled: false,
  });
}

export type PSD2ClientAccount = {
  number: string;
  iban: string;
};

export type PSD2Banks = {
  code: string;
  name: string;
  logo: string;
};

export type PSD2ClientConsentInformation = {
  consentType: string;
};

export type PSD2ProcessResponse = {
  authenticated: boolean;
  url: string;
};

export type PSD2IdentificationPayment = {
  url: string;
};

export type PSD2SessionStatusResponse = {
  typeOfService: string;
  transactionHistory: string;
  paymentStatus: string;
  status: string;
};
