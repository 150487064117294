import { memo, ReactNode } from 'react';
import { ReactNode as ReachRouterReactNode } from 'reach__router/node_modules/@types/react';
import { Link as RouterLink } from '@reach/router';

import classes from 'classnames';
import styles from './Link.module.scss';

export type Props = {
  to?: string;
  children: ReactNode;
  disabled?: boolean;
  pending?: boolean;
  className?: string;
  external?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top';
  dataTest?: string;
  size?: 'l' | 'm' | 's';
  sizeStyleDisabled?: boolean;
  onClick?: () => void;
};

export default memo(function Link({
  to = '',
  children,
  disabled = false,
  pending = false,
  className,
  external = false,
  target = '_self',
  dataTest,
  size = 'l',
  sizeStyleDisabled = false,
  onClick,
}: Props) {
  if (disabled || pending)
    return (
      <span
        data-test={dataTest}
        className={classes(
          styles.link,
          !sizeStyleDisabled && styles[size],
          styles.disabled,
          className,
        )}
      >
        {children}
      </span>
    );

  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        data-test={dataTest}
        className={classes(
          styles.link,
          !sizeStyleDisabled && styles[size],
          className,
        )}
      >
        {children}
      </button>
    );
  }

  if (external)
    return (
      <a
        onClick={onClick}
        data-test={dataTest}
        className={classes(
          styles.link,
          !sizeStyleDisabled && styles[size],
          className,
        )}
        href={to}
        target={target}
      >
        {children}
      </a>
    );

  return (
    <RouterLink
      data-test={dataTest}
      className={classes(
        styles.link,
        !sizeStyleDisabled && styles[size],
        className,
      )}
      to={to}
    >
      {children as ReachRouterReactNode}
    </RouterLink>
  );
});
