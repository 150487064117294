import { ReactNode } from 'react';
import classes from 'classnames';
import styles from './Placeholder.module.scss';

export type Props = {
  children: ReactNode;
  dataTest?: string;
  placeholder?: string;
  loading?: boolean;
  /** Replace current children with placeholder */
  replace?: boolean;
};

export default function Placeholder({
  children,
  loading,
  placeholder = 'Loading',
  dataTest,
  replace = false,
}: Props) {
  return (
    <span className={classes(loading && styles.wrapper)} data-test={dataTest}>
      {(children || children === 0) && (!replace || !loading)
        ? children
        : placeholder}
    </span>
  );
}
