import { fetchData } from '../../../utils';
import { useMutation } from '@tanstack/react-query';
import useError from '../../useError';
import useSmartlook from '../../useSmartlook';

export function useAcceptUpsaleMutation() {
  const { error } = useSmartlook();
  const { showError } = useError();
  return useMutation<
    {
      rejected: boolean;
    },
    unknown,
    {
      amount: number;
    }
  >({
    mutationFn: async (payload) => {
      try {
        const resp = await fetchData('/client/application/upsale/accept', {
          method: 'post',
          body: JSON.stringify(payload),
        });

        return resp;
      } catch (e) {
        error(
          '[useAcceptUpsaleMutation][POST /client/application/upsale/accept]',
          e,
        );
        showError();
        throw e;
      }
    },
  });
}
