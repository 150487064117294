import { memo, ReactNode, useCallback, useState } from 'react';
import styles from './ToggleList.module.scss';
import ToggleItem from './ToggleItem';

export type Props = {
  items: Array<{
    title: string;
    content: string | ReactNode;
  }>;
  dataTest?: string;
  className?: string;
};

export default memo(function ToggleList({ items, dataTest, className }: Props) {
  const [openTab, setOpenTab] = useState('');

  const toggleTab = useCallback(
    (tab: string) => {
      openTab === tab ? setOpenTab('') : setOpenTab(tab);
    },
    [openTab],
  );

  return (
    <div data-test={dataTest} className={className}>
      {items?.map((item, i) => (
        <div className={styles.tab} key={item.title}>
          <ToggleItem
            title={item.title}
            onClick={toggleTab}
            active={openTab === item.title}
          >
            {item.content}
          </ToggleItem>
        </div>
      ))}
    </div>
  );
});
