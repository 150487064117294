import { memo, useCallback, useEffect } from 'react';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import classes from 'classnames';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import styles from './Stepper.module.scss';

export type Props = {
  name?: string;
  value?: number;
  max?: number;
  min?: number;
  step?: number;
  disabled?: boolean;
  className?: string;
  dataTest?: string;
  error?: string;
  onChange?: (name: string, value: number) => void;
};

export default memo(function Stepper({
  name = 'default',
  value = 0,
  disabled = false,
  className,
  dataTest,
  onChange,
  min = 0,
  max = 99,
  step = 1,
  error,
}: Props) {
  useEffect(() => {
    if (onChange) {
      if (value > max) {
        onChange(name, max);
      } else if (value < min) {
        onChange(name, min);
      }
    }
  }, [value, onChange, min, max, name]);

  const decrease = useCallback(() => {
    if (!disabled && value > min) {
      if (onChange) {
        onChange(name, value - step);
      }
    }
  }, [value, disabled, min, step, name, onChange]);

  const increase = useCallback(() => {
    if (!disabled && value < max) {
      if (onChange) {
        onChange(name, value + step);
      }
    }
  }, [value, disabled, max, step, name, onChange]);

  return (
    <>
      <div className={classes(className, styles.stepper)}>
        <div
          onClick={decrease}
          className={classes(styles.button, styles.decrease)}
        >
          <MinusIcon />
        </div>
        <input
          type="number"
          id={name}
          name={name}
          disabled={disabled}
          className={classes(styles.value)}
          value={value}
          data-test={dataTest}
          readOnly={true}
        />
        <div
          onClick={increase}
          className={classes(styles.button, styles.increase)}
        >
          <PlusIcon />
        </div>
      </div>
      {!!error && (
        <div className={styles['error-message']}>
          <ErrorMessage errors={error} />
        </div>
      )}
    </>
  );
});
