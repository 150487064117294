export default function sanitizePhone(phoneNumber: string): string {
  // Remove all non-digit characters
  let digits = phoneNumber.replace(/\D/g, '');

  // Check if country code is already present
  if (!digits.startsWith('420')) {
    // Add Spain's country code if not present
    digits = '420' + digits;
  }

  return '+' + digits;
}
