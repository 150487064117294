import sanitizeGmail from './sanitizeGmail';
import sanitizePhone from './sanitizePhone';
import sha256 from './sha256';

export default class StringProcessor {
  private value: string;

  constructor(value: string) {
    this.value = value;
  }

  sanitizeGmail() {
    this.value = sanitizeGmail(this.value);
    return this;
  }

  sanitizePhone() {
    this.value = sanitizePhone(this.value);
    return this;
  }

  async sha256() {
    this.value = await sha256(this.value);
    return this;
  }

  getValue(): string {
    return this.value;
  }
}
