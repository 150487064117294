import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { translate } from '../utils';

type Props = {
  children: ReactNode;
};

type ErrorType = 'notification' | 'modal';

type Error = {
  message?: string;
  // modals are flow blocking errors whereas notifications are not
  type?: ErrorType;
};

type ErrorContextType = {
  error: Error | undefined;
  showError: (error?: Error) => void;
  removeError: () => void;
};

const ErrorContext = createContext<ErrorContextType>({} as ErrorContextType);

const useError = (): ErrorContextType => useContext(ErrorContext);
const ErrorProvider = ({ children }: Props): JSX.Element => {
  const [error, setError] = useState<Error | undefined>();

  const showError = useCallback((error: Error = {}) => {
    const defaultError: Error = {
      message: translate('error.generic'),
      type: 'notification',
    };

    setError({
      ...defaultError,
      ...error,
    });
  }, []);

  const removeError = useCallback(() => {
    setError(undefined);
  }, []);

  const errorContextValue = useMemo(
    () => ({
      error,
      showError,
      removeError,
    }),
    [error, showError, removeError],
  );
  return (
    <ErrorContext.Provider value={errorContextValue}>
      {children}
    </ErrorContext.Provider>
  );
};

export { useError as default, ErrorProvider };
